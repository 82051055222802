.cra-main-page {
  min-height: 100vh;

  .cra-main-header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
    height: 56px;
    padding: 0 20px;

    .wrapper-logo {
      width: 10%;

      .logo {
        width: 100px;
      }
    }

    .cra-header-menu-left {
      padding: 0 30px;
      width: 50%;
      display: flex;
      align-items: center;

      .anticon {
        margin-right: 15px;
      }

      .header-menu {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0;

        .ant-menu-item {
          .ant-menu-title-content {
            a {
              text-decoration: none;
              color: #333;
            }
          }

          &:hover {
            background-color: #f7f0ff;
            color: #9b27b0;

            .ant-menu-title-content {
              a {
                color: #9b27b0;
              }
            }
          }
        }
      }
    }

    .cra-header-menu-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .menu-right {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        .nav-menu-right {
          list-style: none;
          margin: 0 10px;

          .anticon {
            color: #333 !important;

            &:hover {
              color: #9b27b0 !important;
            }
          }
        }
      }
    }

    .ant-menu-item {
      margin: 0;
      height: auto;
      line-height: inherit;
      margin-bottom: 0 !important;
    }

    .ant-menu-item-selected {
      margin: 0;
      height: auto;
      line-height: inherit;
      margin-bottom: 0 !important;
    }
  }

  .cra-main-layout {
    .cra-main-sider {
      background-color: #fff;
      padding-top: 56px;
      border-right: 1px solid rgba(33, 33, 33, 0.08);

      .sidebar-menu {
        .ant-menu-item {
          margin: 0;
          margin-bottom: 0 !important;
        }

        .ant-menu-item-selected {
          margin: 0;
          margin-bottom: 0 !important;

          a {
            color: #9b27b0 !important;
          }
        }

        .menu-item {
          .ant-menu-title-content {
            a {
              text-decoration: none;
            }
          }

          .ant-menu-submenu {
            &:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
              color: #9b27b0;
            }
          }

          &::after {
            border-right: 0 !important;
          }

          &:hover {
            background-color: #f7f0ff;

            .ant-menu-title-content {
              a {
                color: #9b27b0;
              }
            }
          }
        }

        .sub-menu-item {
          .ant-menu-submenu-title {
            margin: 0;

            &:hover {
              background-color: #f7f0ff;
            }
          }

          .sub-menu-item-name {
            a {
              text-decoration: none;
            }

            &:hover {
              background-color: #f7f0ff;

              a:hover {
                color: #9b27b0;
              }
            }
          }

          &:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
            color: #9b27b0;
          }
        }
      }
    }

    .cra-main-layout-content {
      padding: 0px;
      padding-top: 56px;
      background: white;

      .cra-content {
        padding: 0 16px 16px 16px;
        margin: 0;
        min-height: 280px;
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f7f0ff;
  color: #9b27b0;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #9b27b0 !important;
}
