.studio {
  [class*='ant-']::-ms-clear,
  [class*='ant-'] input::-ms-clear,
  [class*='ant-'] input::-ms-reveal,
  [class^='ant-']::-ms-clear,
  [class^='ant-'] input::-ms-clear,
  [class^='ant-'] input::-ms-reveal {
    display: none;
  }
  body,
  html {
    height: 100%;
    width: 100%;
  }
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: sans-serif;
    line-height: 1.15;
  }
  @-ms-viewport {
    width: device-width;
  }
  body {
    font-feature-settings: 'tnum';
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    margin: 0;
  }
  [tabindex='-1']:focus {
    outline: none !important;
  }
  hr {
    box-sizing: initial;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
  p {
    margin-bottom: 1em;
    margin-top: 0;
  }
  abbr[data-original-title],
  abbr[title] {
    border-bottom: 0;
    cursor: help;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }
  address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1em;
  }
  input[type='number'],
  input[type='password'],
  input[type='text'],
  textarea {
    -webkit-appearance: none;
  }
  dl,
  ol,
  ul {
    margin-bottom: 1em;
    margin-top: 0;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1em;
  }
  dfn {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: initial;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    -webkit-text-decoration-skip: objects;
    background-color: initial;
    color: #1890ff;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;
  }
  a:hover {
    color: #40a9ff;
  }
  a:active {
    color: #096dd9;
  }
  a:active,
  a:focus,
  a:hover {
    outline: 0;
    text-decoration: none;
  }
  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
    font-size: 1em;
  }
  pre {
    margin-bottom: 1em;
    margin-top: 0;
    overflow: auto;
  }
  figure {
    margin: 0 0 1em;
  }
  img {
    border-style: none;
    vertical-align: middle;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  [role='button'],
  a,
  area,
  button,
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    caption-side: bottom;
    color: rgba(0, 0, 0, 0.45);
    padding-bottom: 0.3em;
    padding-top: 0.75em;
    text-align: left;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type='reset'],
  [type='submit'],
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='date'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='time'] {
    -webkit-appearance: listbox;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
  }
  legend {
    color: inherit;
    display: block;
    font-size: 1.5em;
    line-height: inherit;
    margin-bottom: 0.5em;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%;
  }
  progress {
    vertical-align: initial;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    -webkit-appearance: none;
    outline-offset: -2px;
  }
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  mark {
    background-color: #feffe6;
    padding: 0.2em;
  }
  ::-moz-selection {
    background: #1890ff;
    color: #fff;
  }
  ::selection {
    background: #1890ff;
    color: #fff;
  }
  .clearfix:after,
  .clearfix:before {
    content: '';
    display: table;
  }
  .clearfix:after {
    clear: both;
  }
  .anticon {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
  }
  .anticon > * {
    line-height: 1;
  }
  .anticon svg {
    display: inline-block;
  }
  .anticon:before {
    display: none;
  }
  .anticon .anticon-icon {
    display: block;
  }
  .anticon[tabindex] {
    cursor: pointer;
  }
  .anticon-spin,
  .anticon-spin:before {
    -webkit-animation: loadingCircle 1s linear infinite;
    animation: loadingCircle 1s linear infinite;
    display: inline-block;
  }
  .ant-fade-appear,
  .ant-fade-enter,
  .ant-fade-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-fade-appear.ant-fade-appear-active,
  .ant-fade-enter.ant-fade-enter-active {
    -webkit-animation-name: antFadeIn;
    animation-name: antFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-fade-leave.ant-fade-leave-active {
    -webkit-animation-name: antFadeOut;
    animation-name: antFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-fade-appear,
  .ant-fade-enter {
    opacity: 0;
  }
  .ant-fade-appear,
  .ant-fade-enter,
  .ant-fade-leave {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  @-webkit-keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .ant-move-up-appear,
  .ant-move-up-enter,
  .ant-move-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-move-up-appear.ant-move-up-appear-active,
  .ant-move-up-enter.ant-move-up-enter-active {
    -webkit-animation-name: antMoveUpIn;
    animation-name: antMoveUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-move-up-leave.ant-move-up-leave-active {
    -webkit-animation-name: antMoveUpOut;
    animation-name: antMoveUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-move-up-appear,
  .ant-move-up-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
  }
  .ant-move-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ant-move-down-appear,
  .ant-move-down-enter,
  .ant-move-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-move-down-appear.ant-move-down-appear-active,
  .ant-move-down-enter.ant-move-down-enter-active {
    -webkit-animation-name: antMoveDownIn;
    animation-name: antMoveDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-move-down-leave.ant-move-down-leave-active {
    -webkit-animation-name: antMoveDownOut;
    animation-name: antMoveDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-move-down-appear,
  .ant-move-down-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
  }
  .ant-move-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ant-move-left-appear,
  .ant-move-left-enter,
  .ant-move-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-move-left-appear.ant-move-left-appear-active,
  .ant-move-left-enter.ant-move-left-enter-active {
    -webkit-animation-name: antMoveLeftIn;
    animation-name: antMoveLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-move-left-leave.ant-move-left-leave-active {
    -webkit-animation-name: antMoveLeftOut;
    animation-name: antMoveLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-move-left-appear,
  .ant-move-left-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
  }
  .ant-move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ant-move-right-appear,
  .ant-move-right-enter,
  .ant-move-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-move-right-appear.ant-move-right-appear-active,
  .ant-move-right-enter.ant-move-right-enter-active {
    -webkit-animation-name: antMoveRightIn;
    animation-name: antMoveRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-move-right-leave.ant-move-right-leave-active {
    -webkit-animation-name: antMoveRightOut;
    animation-name: antMoveRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-move-right-appear,
  .ant-move-right-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
  }
  .ant-move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  @-webkit-keyframes antMoveDownIn {
    0% {
      opacity: 0;
      transform: translateY(100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveDownIn {
    0% {
      opacity: 0;
      transform: translateY(100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveDownOut {
    0% {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateY(100%);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateY(100%);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveLeftIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveLeftOut {
    0% {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateX(-100%);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateX(-100%);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveRightIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveRightOut {
    0% {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateX(100%);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      opacity: 1;
      transform: translateX(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateX(100%);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveUpIn {
    0% {
      opacity: 0;
      transform: translateY(-100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      opacity: 0;
      transform: translateY(-100%);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antMoveUpOut {
    0% {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateY(-100%);
      transform-origin: 0 0;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      opacity: 1;
      transform: translateY(0);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: translateY(-100%);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes loadingCircle {
    to {
      transform: rotate(1turn);
    }
  }
  @keyframes loadingCircle {
    to {
      transform: rotate(1turn);
    }
  }
  [ant-click-animating-without-extra-node='true'],
  [ant-click-animating='true'] {
    position: relative;
  }
  html {
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
  }
  .ant-click-animating-node,
  [ant-click-animating-without-extra-node='true']:after {
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    border-radius: inherit;
    bottom: 0;
    box-shadow: 0 0 0 0 #1890ff;
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    content: '';
    display: block;
    left: 0;
    opacity: 0.2;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
  @-webkit-keyframes waveEffect {
    to {
      box-shadow: 0 0 0 #1890ff;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @keyframes waveEffect {
    to {
      box-shadow: 0 0 0 #1890ff;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @-webkit-keyframes fadeEffect {
    to {
      opacity: 0;
    }
  }
  @keyframes fadeEffect {
    to {
      opacity: 0;
    }
  }
  .ant-slide-up-appear,
  .ant-slide-up-enter,
  .ant-slide-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-slide-up-appear.ant-slide-up-appear-active,
  .ant-slide-up-enter.ant-slide-up-enter-active {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-slide-up-leave.ant-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-slide-up-appear,
  .ant-slide-up-enter {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
  }
  .ant-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ant-slide-down-appear,
  .ant-slide-down-enter,
  .ant-slide-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-slide-down-appear.ant-slide-down-appear-active,
  .ant-slide-down-enter.ant-slide-down-enter-active {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-slide-down-leave.ant-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-slide-down-appear,
  .ant-slide-down-enter {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
  }
  .ant-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ant-slide-left-appear,
  .ant-slide-left-enter,
  .ant-slide-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-slide-left-appear.ant-slide-left-appear-active,
  .ant-slide-left-enter.ant-slide-left-enter-active {
    -webkit-animation-name: antSlideLeftIn;
    animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-slide-left-leave.ant-slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
    animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-slide-left-appear,
  .ant-slide-left-enter {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
  }
  .ant-slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ant-slide-right-appear,
  .ant-slide-right-enter,
  .ant-slide-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-slide-right-appear.ant-slide-right-appear-active,
  .ant-slide-right-enter.ant-slide-right-enter-active {
    -webkit-animation-name: antSlideRightIn;
    animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-slide-right-leave.ant-slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
    animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-slide-right-appear,
  .ant-slide-right-enter {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
  }
  .ant-slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @-webkit-keyframes antSlideUpIn {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 0 0;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antSlideUpOut {
    0% {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 0 0;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antSlideDownIn {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
    }
    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 100% 100%;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
    }
    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 100% 100%;
    }
  }
  @-webkit-keyframes antSlideDownOut {
    0% {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 100% 100%;
    }
    to {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 100% 100%;
    }
    to {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
    }
  }
  @-webkit-keyframes antSlideLeftIn {
    0% {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antSlideLeftOut {
    0% {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 0 0;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 0 0;
    }
    to {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes antSlideRightIn {
    0% {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 100% 0;
    }
    to {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 100% 0;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 100% 0;
    }
    to {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 100% 0;
    }
  }
  @-webkit-keyframes antSlideRightOut {
    0% {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 100% 0;
    }
    to {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 100% 0;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: 100% 0;
    }
    to {
      opacity: 0;
      transform: scaleX(0.8);
      transform-origin: 100% 0;
    }
  }
  .ant-zoom-appear,
  .ant-zoom-enter,
  .ant-zoom-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-appear.ant-zoom-appear-active,
  .ant-zoom-enter.ant-zoom-enter-active {
    -webkit-animation-name: antZoomIn;
    animation-name: antZoomIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-leave.ant-zoom-leave-active {
    -webkit-animation-name: antZoomOut;
    animation-name: antZoomOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-appear,
  .ant-zoom-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-appear-prepare,
  .ant-zoom-enter-prepare {
    transform: none;
  }
  .ant-zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-zoom-big-appear,
  .ant-zoom-big-enter,
  .ant-zoom-big-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-big-appear.ant-zoom-big-appear-active,
  .ant-zoom-big-enter.ant-zoom-big-enter-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-big-leave.ant-zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-big-appear,
  .ant-zoom-big-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-big-appear-prepare,
  .ant-zoom-big-enter-prepare {
    transform: none;
  }
  .ant-zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-zoom-big-fast-appear,
  .ant-zoom-big-fast-enter,
  .ant-zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active,
  .ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-big-fast-appear,
  .ant-zoom-big-fast-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-big-fast-appear-prepare,
  .ant-zoom-big-fast-enter-prepare {
    transform: none;
  }
  .ant-zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-zoom-up-appear,
  .ant-zoom-up-enter,
  .ant-zoom-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-up-appear.ant-zoom-up-appear-active,
  .ant-zoom-up-enter.ant-zoom-up-enter-active {
    -webkit-animation-name: antZoomUpIn;
    animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-up-leave.ant-zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
    animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-up-appear,
  .ant-zoom-up-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-up-appear-prepare,
  .ant-zoom-up-enter-prepare {
    transform: none;
  }
  .ant-zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-zoom-down-appear,
  .ant-zoom-down-enter,
  .ant-zoom-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-down-appear.ant-zoom-down-appear-active,
  .ant-zoom-down-enter.ant-zoom-down-enter-active {
    -webkit-animation-name: antZoomDownIn;
    animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-down-leave.ant-zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
    animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-down-appear,
  .ant-zoom-down-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-down-appear-prepare,
  .ant-zoom-down-enter-prepare {
    transform: none;
  }
  .ant-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-zoom-left-appear,
  .ant-zoom-left-enter,
  .ant-zoom-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-left-appear.ant-zoom-left-appear-active,
  .ant-zoom-left-enter.ant-zoom-left-enter-active {
    -webkit-animation-name: antZoomLeftIn;
    animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-left-leave.ant-zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
    animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-left-appear,
  .ant-zoom-left-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-left-appear-prepare,
  .ant-zoom-left-enter-prepare {
    transform: none;
  }
  .ant-zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-zoom-right-appear,
  .ant-zoom-right-enter,
  .ant-zoom-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-zoom-right-appear.ant-zoom-right-appear-active,
  .ant-zoom-right-enter.ant-zoom-right-enter-active {
    -webkit-animation-name: antZoomRightIn;
    animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-zoom-right-leave.ant-zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
    animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-zoom-right-appear,
  .ant-zoom-right-enter {
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    opacity: 0;
    transform: scale(0);
  }
  .ant-zoom-right-appear-prepare,
  .ant-zoom-right-enter-prepare {
    transform: none;
  }
  .ant-zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  @-webkit-keyframes antZoomIn {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes antZoomIn {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes antZoomOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.2);
    }
  }
  @keyframes antZoomOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.2);
    }
  }
  @-webkit-keyframes antZoomBigIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes antZoomBigIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes antZoomBigOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }
  @keyframes antZoomBigOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }
  @-webkit-keyframes antZoomUpIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 0;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 0;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 0;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 0;
    }
  }
  @-webkit-keyframes antZoomUpOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 0;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 0;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 0;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 0;
    }
  }
  @-webkit-keyframes antZoomLeftIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 0 50%;
    }
    to {
      transform: scale(1);
      transform-origin: 0 50%;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 0 50%;
    }
    to {
      transform: scale(1);
      transform-origin: 0 50%;
    }
  }
  @-webkit-keyframes antZoomLeftOut {
    0% {
      transform: scale(1);
      transform-origin: 0 50%;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 0 50%;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      transform: scale(1);
      transform-origin: 0 50%;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 0 50%;
    }
  }
  @-webkit-keyframes antZoomRightIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 100% 50%;
    }
    to {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 100% 50%;
    }
    to {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
  }
  @-webkit-keyframes antZoomRightOut {
    0% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 100% 50%;
    }
  }
  @-webkit-keyframes antZoomDownIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 100%;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 100%;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
  }
  @-webkit-keyframes antZoomDownOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: 50% 100%;
    }
  }
  .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ant-motion-collapse,
  .ant-motion-collapse-legacy-active {
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ant-motion-collapse {
    overflow: hidden;
  }
  .ant-affix {
    position: fixed;
    z-index: 10;
  }
  .ant-alert {
    font-feature-settings: 'tnum';
    word-wrap: break-word;
    align-items: center;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 8px 15px;
    position: relative;
  }
  .ant-alert-content {
    flex: 1;
    min-width: 0;
  }
  .ant-alert-icon {
    margin-right: 8px;
  }
  .ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  .ant-alert-success .ant-alert-icon {
    color: #52c41a;
  }
  .ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  .ant-alert-info .ant-alert-icon {
    color: #1890ff;
  }
  .ant-alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  .ant-alert-warning .ant-alert-icon {
    color: #faad14;
  }
  .ant-alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }
  .ant-alert-error .ant-alert-icon {
    color: #ff4d4f;
  }
  .ant-alert-error .ant-alert-description > pre {
    margin: 0;
    padding: 0;
  }
  .ant-alert-action {
    margin-left: 8px;
  }
  .ant-alert-close-icon {
    background-color: initial;
    border: none;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    margin-left: 8px;
    outline: none;
    overflow: hidden;
    padding: 0;
  }
  .ant-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
  }
  .ant-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-alert-close-text {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
  }
  .ant-alert-close-text:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-alert-with-description {
    align-items: flex-start;
    padding: 15px 15px 15px 24px;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 15px;
  }
  .ant-alert-with-description .ant-alert-icon {
    font-size: 24px;
    margin-right: 15px;
  }
  .ant-alert-with-description .ant-alert-message {
    color: rgba(0, 0, 0, 0.85);
    display: block;
    font-size: 16px;
    margin-bottom: 4px;
  }
  .ant-alert-message {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-alert-with-description .ant-alert-description {
    display: block;
  }
  .ant-alert.ant-alert-motion-leave {
    opacity: 1;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-alert.ant-alert-motion-leave-active {
    margin-bottom: 0 !important;
    max-height: 0;
    opacity: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .ant-alert-banner {
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
  }
  .ant-alert.ant-alert-rtl {
    direction: rtl;
  }
  .ant-alert-rtl.ant-alert.ant-alert-no-icon {
    padding: 8px 15px;
  }
  .ant-alert-rtl .ant-alert-icon {
    margin-left: 8px;
    margin-right: auto;
  }
  .ant-alert-rtl .ant-alert-action,
  .ant-alert-rtl .ant-alert-close-icon {
    margin-left: auto;
    margin-right: 8px;
  }
  .ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
    margin-left: 15px;
    margin-right: auto;
  }
  .ant-anchor {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0 0 0 2px;
    position: relative;
  }
  .ant-anchor-wrapper {
    background-color: initial;
    margin-left: -4px;
    overflow: auto;
    padding-left: 4px;
  }
  .ant-anchor-ink {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
  .ant-anchor-ink:before {
    background-color: #f0f0f0;
    content: ' ';
    display: block;
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 2px;
  }
  .ant-anchor-ink-ball {
    background-color: #fff;
    border: 2px solid #1890ff;
    border-radius: 8px;
    display: none;
    height: 8px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: top 0.3s ease-in-out;
    width: 8px;
  }
  .ant-anchor-ink-ball.visible {
    display: inline-block;
  }
  .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
    display: none;
  }
  .ant-anchor-link {
    line-height: 1.143;
    padding: 7px 0 7px 16px;
  }
  .ant-anchor-link-title {
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 6px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .ant-anchor-link-title:only-child {
    margin-bottom: 0;
  }
  .ant-anchor-link-active > .ant-anchor-link-title {
    color: #1890ff;
  }
  .ant-anchor-link .ant-anchor-link {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .ant-anchor-rtl {
    direction: rtl;
  }
  .ant-anchor-rtl.ant-anchor-wrapper {
    margin-left: 0;
    margin-right: -4px;
    padding-left: 0;
    padding-right: 4px;
  }
  .ant-anchor-rtl .ant-anchor-ink {
    left: auto;
    right: 0;
  }
  .ant-anchor-rtl .ant-anchor-ink-ball {
    left: 0;
    right: 50%;
    transform: translateX(50%);
  }
  .ant-anchor-rtl .ant-anchor-link {
    padding: 7px 16px 7px 0;
  }
  .ant-select-auto-complete {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-select-auto-complete .ant-select-clear {
    right: 13px;
  }
  .ant-select-single .ant-select-selector {
    display: flex;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    bottom: 0;
    left: 11px;
    position: absolute;
    right: 11px;
    top: 0;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
    padding: 0;
    transition: all 0.3s;
  }
  @supports (-moz-appearance: meterbar) {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    pointer-events: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item:after,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
  .ant-select-single .ant-select-selector:after {
    content: ' ';
    display: inline-block;
    visibility: hidden;
    width: 0;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 25px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #bfbfbf;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
    padding: 0 11px;
    width: 100%;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 30px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
    line-height: 30px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector:after {
    display: none;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
    position: static;
    width: 100%;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    left: 0;
    padding: 0 11px;
    position: absolute;
    right: 0;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder:after {
    display: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after {
    line-height: 38px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 38px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector:after {
    line-height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
    left: 7px;
    right: 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 28px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 21px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
  .ant-select-selection-overflow {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
    position: relative;
  }
  .ant-select-selection-overflow-item {
    align-self: center;
    flex: none;
    max-width: 100%;
  }
  .ant-select-multiple .ant-select-selector {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 1px 4px;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector:after {
    content: ' ';
    display: inline-block;
    line-height: 24px;
    margin: 2px 0;
    width: 0;
  }
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector,
  .ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-right: 24px;
  }
  .ant-select-multiple .ant-select-selection-item {
    -webkit-margin-end: 4px;
    -webkit-padding-start: 8px;
    -webkit-padding-end: 4px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    display: flex;
    flex: none;
    height: 24px;
    line-height: 22px;
    margin-bottom: 2px;
    margin-inline-end: 4px;
    margin-top: 2px;
    max-width: 100%;
    padding-inline-end: 4px;
    padding-inline-start: 8px;
    position: relative;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    border-color: #d9d9d9;
    color: #bfbfbf;
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 0;
    line-height: inherit;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
  }
  .ant-select-multiple .ant-select-selection-item-remove > * {
    line-height: 1;
  }
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
  }
  .ant-select-multiple .ant-select-selection-item-remove:before {
    display: none;
  }
  .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
    display: block;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.2em;
  }
  .ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }
  .ant-select-multiple .ant-select-selection-search {
    -webkit-margin-start: 7px;
    margin-bottom: 2px;
    margin-inline-start: 7px;
    margin-top: 2px;
    max-width: 100%;
    position: relative;
  }
  .ant-select-multiple .ant-select-selection-search-input,
  .ant-select-multiple .ant-select-selection-search-mirror {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    height: 24px;
    line-height: 24px;
    transition: all 0.3s;
  }
  .ant-select-multiple .ant-select-selection-search-input {
    min-width: 4.1px;
    width: 100%;
  }
  .ant-select-multiple .ant-select-selection-search-mirror {
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    white-space: pre;
    z-index: 999;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    left: 11px;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selector:after {
    line-height: 32px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
    height: 32px;
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selector:after {
    line-height: 16px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
    height: 16px;
    line-height: 16px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    left: 7px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
    -webkit-margin-start: 3px;
    margin-inline-start: 3px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-disabled .ant-select-selection-item-remove {
    display: none;
  }
  .ant-select {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f5f5;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    margin: 0;
    outline: none;
    padding: 0;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-select-selection-item {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (-ms-high-contrast: none) {
    .ant-select-selection-item,
    .ant-select-selection-item ::-ms-backdrop {
      flex: auto;
    }
  }
  .ant-select-selection-placeholder {
    color: #bfbfbf;
    flex: 1;
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (-ms-high-contrast: none) {
    .ant-select-selection-placeholder,
    .ant-select-selection-placeholder ::-ms-backdrop {
      flex: auto;
    }
  }
  .ant-select-arrow {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    color: rgba(0, 0, 0, 0.25);
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    height: 12px;
    line-height: 0;
    line-height: 1;
    margin-top: -6px;
    pointer-events: none;
    position: absolute;
    right: 11px;
    text-align: center;
    text-transform: none;
    top: 53%;
    vertical-align: -0.125em;
    width: 12px;
  }
  .ant-select-arrow > * {
    line-height: 1;
  }
  .ant-select-arrow svg {
    display: inline-block;
  }
  .ant-select-arrow:before {
    display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    display: block;
  }
  .ant-select-arrow .anticon {
    transition: transform 0.3s;
    vertical-align: top;
  }
  .ant-select-arrow .anticon > svg {
    vertical-align: top;
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: auto;
  }
  .ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
  }
  .ant-select-clear {
    text-rendering: auto;
    background: #fff;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    height: 12px;
    line-height: 1;
    margin-top: -6px;
    opacity: 0;
    position: absolute;
    right: 11px;
    text-align: center;
    text-transform: none;
    top: 50%;
    transition: color 0.3s ease, opacity 0.15s ease;
    width: 12px;
    z-index: 1;
  }
  .ant-select-clear:before {
    display: block;
  }
  .ant-select-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select:hover .ant-select-clear {
    opacity: 1;
  }
  .ant-select-dropdown {
    font-feature-settings: 'tnum';
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    font-variant: normal;
    left: -9999px;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 4px 0;
    position: absolute;
    top: -9999px;
    z-index: 1050;
  }
  .ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-empty {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item-empty {
    color: rgba(0, 0, 0, 0.85);
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item,
  .ant-select-item-empty {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    min-height: 32px;
    padding: 5px 12px;
    position: relative;
  }
  .ant-select-item {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
    cursor: default;
    font-size: 12px;
  }
  .ant-select-item-option {
    display: flex;
  }
  .ant-select-item-option-content {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-select-item-option-state {
    flex: none;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #e6f7ff;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #1890ff;
  }
  .ant-select-item-option-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-item-option-grouped {
    padding-left: 24px;
  }
  .ant-select-lg {
    font-size: 16px;
  }
  .ant-select-borderless .ant-select-selector {
    background-color: initial !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-select-rtl {
    direction: rtl;
  }
  .ant-select-rtl .ant-select-arrow,
  .ant-select-rtl .ant-select-clear {
    left: 11px;
    right: auto;
  }
  .ant-select-dropdown-rtl {
    direction: rtl;
  }
  .ant-select-dropdown-rtl .ant-select-item-option-grouped {
    padding-left: 12px;
    padding-right: 24px;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector,
  .ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-left: 24px;
    padding-right: 4px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item {
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
    margin-left: 4px;
    margin-right: 0;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
    left: auto;
    right: 0;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
    left: auto;
    right: 11px;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    right: 7px;
  }
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    left: 9px;
    right: 0;
    text-align: right;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    left: 25px;
    right: 11px;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 18px;
    padding-right: 0;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 6px;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 21px;
    padding-right: 0;
  }
  .ant-empty {
    font-size: 14px;
    line-height: 1.5715;
    margin: 0 8px;
    text-align: center;
  }
  .ant-empty-image {
    height: 100px;
    margin-bottom: 8px;
  }
  .ant-empty-image img {
    height: 100%;
  }
  .ant-empty-image svg {
    height: 100%;
    margin: auto;
  }
  .ant-empty-footer {
    margin-top: 16px;
  }
  .ant-empty-normal {
    color: rgba(0, 0, 0, 0.25);
    margin: 32px 0;
  }
  .ant-empty-normal .ant-empty-image {
    height: 40px;
  }
  .ant-empty-small {
    color: rgba(0, 0, 0, 0.25);
    margin: 8px 0;
  }
  .ant-empty-small .ant-empty-image {
    height: 35px;
  }
  .ant-empty-img-default-ellipse {
    fill: #f5f5f5;
    fill-opacity: 0.8;
  }
  .ant-empty-img-default-path-1 {
    fill: #aeb8c2;
  }
  .ant-empty-img-default-path-2 {
    fill: url(#linearGradient-1);
  }
  .ant-empty-img-default-path-3 {
    fill: #f5f5f7;
  }
  .ant-empty-img-default-path-4,
  .ant-empty-img-default-path-5 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-g {
    fill: #fff;
  }
  .ant-empty-img-simple-ellipse {
    fill: #f5f5f5;
  }
  .ant-empty-img-simple-g {
    stroke: #d9d9d9;
  }
  .ant-empty-img-simple-path {
    fill: #fafafa;
  }
  .ant-empty-rtl {
    direction: rtl;
  }
  .ant-avatar {
    font-feature-settings: 'tnum';
    background: #ccc;
    border-radius: 50%;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    height: 32px;
    line-height: 1.5715;
    line-height: 32px;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 32px;
  }
  .ant-avatar-image {
    background: transparent;
  }
  .ant-avatar .ant-image-img {
    display: block;
  }
  .ant-avatar-string {
    left: 50%;
    position: absolute;
    transform-origin: 0 center;
  }
  .ant-avatar.ant-avatar-icon {
    font-size: 18px;
  }
  .ant-avatar.ant-avatar-icon > .anticon {
    margin: 0;
  }
  .ant-avatar-lg {
    border-radius: 50%;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  .ant-avatar-lg-string {
    left: 50%;
    position: absolute;
    transform-origin: 0 center;
  }
  .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px;
  }
  .ant-avatar-lg.ant-avatar-icon > .anticon {
    margin: 0;
  }
  .ant-avatar-sm {
    border-radius: 50%;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
  .ant-avatar-sm-string {
    left: 50%;
    position: absolute;
    transform-origin: 0 center;
  }
  .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px;
  }
  .ant-avatar-sm.ant-avatar-icon > .anticon {
    margin: 0;
  }
  .ant-avatar-square {
    border-radius: 2px;
  }
  .ant-avatar > img {
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }
  .ant-avatar-group {
    display: inline-flex;
  }
  .ant-avatar-group .ant-avatar {
    border: 1px solid #fff;
  }
  .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -8px;
  }
  .ant-avatar-group-popover .ant-avatar + .ant-avatar {
    margin-left: 3px;
  }
  .ant-avatar-group-rtl .ant-avatar:not(:first-child) {
    margin-left: 0;
    margin-right: -8px;
  }
  .ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
    margin-left: 0;
    margin-right: 3px;
  }
  .ant-popover {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: auto;
    font-size: 14px;
    font-variant: tabular-nums;
    font-weight: 400;
    left: 0;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 0;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    white-space: normal;
    z-index: 1030;
  }
  .ant-popover:after {
    background: rgba(255, 255, 255, 0.01);
    content: '';
    position: absolute;
  }
  .ant-popover-hidden {
    display: none;
  }
  .ant-popover-placement-top,
  .ant-popover-placement-topLeft,
  .ant-popover-placement-topRight {
    padding-bottom: 10px;
  }
  .ant-popover-placement-right,
  .ant-popover-placement-rightBottom,
  .ant-popover-placement-rightTop {
    padding-left: 10px;
  }
  .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    padding-top: 10px;
  }
  .ant-popover-placement-left,
  .ant-popover-placement-leftBottom,
  .ant-popover-placement-leftTop {
    padding-right: 10px;
  }
  .ant-popover-inner {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .ant-popover-inner {
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
  }
  .ant-popover-title {
    border-bottom: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin: 0;
    min-height: 32px;
    min-width: 177px;
    padding: 5px 16px 4px;
  }
  .ant-popover-inner-content {
    color: rgba(0, 0, 0, 0.85);
    padding: 12px 16px;
  }
  .ant-popover-message {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    padding: 4px 0 12px;
    position: relative;
  }
  .ant-popover-message > .anticon {
    color: #faad14;
    font-size: 14px;
    position: absolute;
    top: 8.0005px;
  }
  .ant-popover-message-title {
    padding-left: 22px;
  }
  .ant-popover-buttons {
    margin-bottom: 4px;
    text-align: right;
  }
  .ant-popover-buttons button {
    margin-left: 8px;
  }
  .ant-popover-arrow {
    background: transparent;
    display: block;
    height: 8.48528137px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 8.48528137px;
  }
  .ant-popover-arrow-content {
    background-color: #fff;
    bottom: 0;
    content: '';
    display: block;
    height: 6px;
    left: 0;
    margin: auto;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 6px;
  }
  .ant-popover-placement-top .ant-popover-arrow,
  .ant-popover-placement-topLeft .ant-popover-arrow,
  .ant-popover-placement-topRight .ant-popover-arrow {
    bottom: 1.51471863px;
  }
  .ant-popover-placement-top .ant-popover-arrow-content,
  .ant-popover-placement-topLeft .ant-popover-arrow-content,
  .ant-popover-placement-topRight .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(-4.24264069px) rotate(45deg);
  }
  .ant-popover-placement-top .ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-popover-placement-topLeft .ant-popover-arrow {
    left: 16px;
  }
  .ant-popover-placement-topRight .ant-popover-arrow {
    right: 16px;
  }
  .ant-popover-placement-right .ant-popover-arrow,
  .ant-popover-placement-rightBottom .ant-popover-arrow,
  .ant-popover-placement-rightTop .ant-popover-arrow {
    left: 1.51471863px;
  }
  .ant-popover-placement-right .ant-popover-arrow-content,
  .ant-popover-placement-rightBottom .ant-popover-arrow-content,
  .ant-popover-placement-rightTop .ant-popover-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(4.24264069px) rotate(45deg);
  }
  .ant-popover-placement-right .ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-popover-placement-rightTop .ant-popover-arrow {
    top: 12px;
  }
  .ant-popover-placement-rightBottom .ant-popover-arrow {
    bottom: 12px;
  }
  .ant-popover-placement-bottom .ant-popover-arrow,
  .ant-popover-placement-bottomLeft .ant-popover-arrow,
  .ant-popover-placement-bottomRight .ant-popover-arrow {
    top: 1.51471863px;
  }
  .ant-popover-placement-bottom .ant-popover-arrow-content,
  .ant-popover-placement-bottomLeft .ant-popover-arrow-content,
  .ant-popover-placement-bottomRight .ant-popover-arrow-content {
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    transform: translateY(4.24264069px) rotate(45deg);
  }
  .ant-popover-placement-bottom .ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-popover-placement-bottomLeft .ant-popover-arrow {
    left: 16px;
  }
  .ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 16px;
  }
  .ant-popover-placement-left .ant-popover-arrow,
  .ant-popover-placement-leftBottom .ant-popover-arrow,
  .ant-popover-placement-leftTop .ant-popover-arrow {
    right: 1.51471863px;
  }
  .ant-popover-placement-left .ant-popover-arrow-content,
  .ant-popover-placement-leftBottom .ant-popover-arrow-content,
  .ant-popover-placement-leftTop .ant-popover-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(-4.24264069px) rotate(45deg);
  }
  .ant-popover-placement-left .ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-popover-placement-leftTop .ant-popover-arrow {
    top: 12px;
  }
  .ant-popover-placement-leftBottom .ant-popover-arrow {
    bottom: 12px;
  }
  .ant-popover-magenta .ant-popover-arrow-content,
  .ant-popover-magenta .ant-popover-inner,
  .ant-popover-pink .ant-popover-arrow-content,
  .ant-popover-pink .ant-popover-inner {
    background-color: #eb2f96;
  }
  .ant-popover-red .ant-popover-arrow-content,
  .ant-popover-red .ant-popover-inner {
    background-color: #f5222d;
  }
  .ant-popover-volcano .ant-popover-arrow-content,
  .ant-popover-volcano .ant-popover-inner {
    background-color: #fa541c;
  }
  .ant-popover-orange .ant-popover-arrow-content,
  .ant-popover-orange .ant-popover-inner {
    background-color: #fa8c16;
  }
  .ant-popover-yellow .ant-popover-arrow-content,
  .ant-popover-yellow .ant-popover-inner {
    background-color: #fadb14;
  }
  .ant-popover-gold .ant-popover-arrow-content,
  .ant-popover-gold .ant-popover-inner {
    background-color: #faad14;
  }
  .ant-popover-cyan .ant-popover-arrow-content,
  .ant-popover-cyan .ant-popover-inner {
    background-color: #13c2c2;
  }
  .ant-popover-lime .ant-popover-arrow-content,
  .ant-popover-lime .ant-popover-inner {
    background-color: #a0d911;
  }
  .ant-popover-green .ant-popover-arrow-content,
  .ant-popover-green .ant-popover-inner {
    background-color: #52c41a;
  }
  .ant-popover-blue .ant-popover-arrow-content,
  .ant-popover-blue .ant-popover-inner {
    background-color: #1890ff;
  }
  .ant-popover-geekblue .ant-popover-arrow-content,
  .ant-popover-geekblue .ant-popover-inner {
    background-color: #2f54eb;
  }
  .ant-popover-purple .ant-popover-arrow-content,
  .ant-popover-purple .ant-popover-inner {
    background-color: #722ed1;
  }
  .ant-popover-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-popover-rtl .ant-popover-message-title {
    padding-left: 16px;
    padding-right: 22px;
  }
  .ant-popover-rtl .ant-popover-buttons {
    text-align: left;
  }
  .ant-popover-rtl .ant-popover-buttons button {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-back-top {
    font-feature-settings: 'tnum';
    bottom: 50px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    height: 40px;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 100px;
    width: 40px;
    z-index: 10;
  }
  .ant-back-top:empty {
    display: none;
  }
  .ant-back-top-rtl {
    direction: rtl;
    left: 100px;
    right: auto;
  }
  .ant-back-top-content {
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 20px;
    color: #fff;
    height: 40px;
    overflow: hidden;
    text-align: center;
    transition: all 0.3s;
    width: 40px;
  }
  .ant-back-top-content:hover {
    background-color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;
  }
  .ant-back-top-icon {
    font-size: 24px;
    line-height: 40px;
  }
  @media screen and (max-width: 768px) {
    .ant-back-top {
      right: 60px;
    }
  }
  @media screen and (max-width: 480px) {
    .ant-back-top {
      right: 20px;
    }
  }
  .ant-badge {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ant-badge-count {
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    min-width: 20px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    z-index: auto;
  }
  .ant-badge-count a,
  .ant-badge-count a:hover {
    color: #fff;
  }
  .ant-badge-count-sm {
    border-radius: 7px;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    min-width: 14px;
    padding: 0;
  }
  .ant-badge-multiple-words {
    padding: 0 8px;
  }
  .ant-badge-dot {
    background: #ff4d4f;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    height: 6px;
    min-width: 6px;
    width: 6px;
    z-index: auto;
  }
  .ant-badge-count,
  .ant-badge-dot,
  .ant-badge .ant-scroll-number-custom-component {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
  .ant-badge-count.anticon-spin,
  .ant-badge-dot.anticon-spin,
  .ant-badge .ant-scroll-number-custom-component.anticon-spin {
    -webkit-animation: antBadgeLoadingCircle 1s linear infinite;
    animation: antBadgeLoadingCircle 1s linear infinite;
  }
  .ant-badge-status {
    line-height: inherit;
    vertical-align: initial;
  }
  .ant-badge-status-dot {
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 6px;
  }
  .ant-badge-status-success {
    background-color: #52c41a;
  }
  .ant-badge-status-processing {
    background-color: #1890ff;
    position: relative;
  }
  .ant-badge-status-processing:after {
    -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
    animation: antStatusProcessing 1.2s ease-in-out infinite;
    border: 1px solid #1890ff;
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .ant-badge-status-default {
    background-color: #d9d9d9;
  }
  .ant-badge-status-error {
    background-color: #ff4d4f;
  }
  .ant-badge-status-warning {
    background-color: #faad14;
  }
  .ant-badge-status-magenta,
  .ant-badge-status-pink {
    background: #eb2f96;
  }
  .ant-badge-status-red {
    background: #f5222d;
  }
  .ant-badge-status-volcano {
    background: #fa541c;
  }
  .ant-badge-status-orange {
    background: #fa8c16;
  }
  .ant-badge-status-yellow {
    background: #fadb14;
  }
  .ant-badge-status-gold {
    background: #faad14;
  }
  .ant-badge-status-cyan {
    background: #13c2c2;
  }
  .ant-badge-status-lime {
    background: #a0d911;
  }
  .ant-badge-status-green {
    background: #52c41a;
  }
  .ant-badge-status-blue {
    background: #1890ff;
  }
  .ant-badge-status-geekblue {
    background: #2f54eb;
  }
  .ant-badge-status-purple {
    background: #722ed1;
  }
  .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin-left: 8px;
  }
  .ant-badge-zoom-appear,
  .ant-badge-zoom-enter {
    -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .ant-badge-zoom-leave {
    -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .ant-badge-not-a-wrapper .ant-badge-zoom-appear,
  .ant-badge-not-a-wrapper .ant-badge-zoom-enter {
    -webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .ant-badge-not-a-wrapper .ant-badge-zoom-leave {
    -webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  }
  .ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: middle;
  }
  .ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
    transform: none;
  }
  .ant-badge-not-a-wrapper .ant-scroll-number,
  .ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
    display: block;
    position: relative;
    top: auto;
    transform-origin: 50% 50%;
  }
  @-webkit-keyframes antStatusProcessing {
    0% {
      opacity: 0.5;
      transform: scale(0.8);
    }
    to {
      opacity: 0;
      transform: scale(2.4);
    }
  }
  @keyframes antStatusProcessing {
    0% {
      opacity: 0.5;
      transform: scale(0.8);
    }
    to {
      opacity: 0;
      transform: scale(2.4);
    }
  }
  .ant-scroll-number {
    overflow: hidden;
  }
  .ant-scroll-number-only {
    display: inline-block;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-scroll-number-only,
  .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    -webkit-backface-visibility: hidden;
    height: 20px;
    -webkit-transform-style: preserve-3d;
  }
  .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    margin: 0;
  }
  .ant-scroll-number-symbol {
    vertical-align: top;
  }
  @-webkit-keyframes antZoomBadgeIn {
    0% {
      opacity: 0;
      transform: scale(0) translate(50%, -50%);
    }
    to {
      transform: scale(1) translate(50%, -50%);
    }
  }
  @keyframes antZoomBadgeIn {
    0% {
      opacity: 0;
      transform: scale(0) translate(50%, -50%);
    }
    to {
      transform: scale(1) translate(50%, -50%);
    }
  }
  @-webkit-keyframes antZoomBadgeOut {
    0% {
      transform: scale(1) translate(50%, -50%);
    }
    to {
      opacity: 0;
      transform: scale(0) translate(50%, -50%);
    }
  }
  @keyframes antZoomBadgeOut {
    0% {
      transform: scale(1) translate(50%, -50%);
    }
    to {
      opacity: 0;
      transform: scale(0) translate(50%, -50%);
    }
  }
  @-webkit-keyframes antNoWrapperZoomBadgeIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes antNoWrapperZoomBadgeIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes antNoWrapperZoomBadgeOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }
  @keyframes antNoWrapperZoomBadgeOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }
  @-webkit-keyframes antBadgeLoadingCircle {
    0% {
      transform-origin: 50%;
    }
    to {
      transform: translate(50%, -50%) rotate(1turn);
      transform-origin: 50%;
    }
  }
  @keyframes antBadgeLoadingCircle {
    0% {
      transform-origin: 50%;
    }
    to {
      transform: translate(50%, -50%) rotate(1turn);
      transform-origin: 50%;
    }
  }
  .ant-ribbon-wrapper {
    position: relative;
  }
  .ant-ribbon {
    font-feature-settings: 'tnum';
    background-color: #1890ff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    color: #fff;
    font-size: 14px;
    font-variant: tabular-nums;
    height: 22px;
    line-height: 1.5715;
    line-height: 22px;
    list-style: none;
    margin: 0;
    padding: 0 8px;
    position: absolute;
    top: 8px;
    white-space: nowrap;
  }
  .ant-ribbon-text {
    color: #fff;
  }
  .ant-ribbon-corner {
    border: 4px solid;
    color: currentColor;
    height: 8px;
    position: absolute;
    top: 100%;
    transform: scaleY(0.75);
    transform-origin: top;
    width: 8px;
  }
  .ant-ribbon-corner:after {
    border: inherit;
    color: rgba(0, 0, 0, 0.25);
    content: '';
    height: inherit;
    left: -4px;
    position: absolute;
    top: -4px;
    width: inherit;
  }
  .ant-ribbon-color-magenta,
  .ant-ribbon-color-pink {
    background: #eb2f96;
    color: #eb2f96;
  }
  .ant-ribbon-color-red {
    background: #f5222d;
    color: #f5222d;
  }
  .ant-ribbon-color-volcano {
    background: #fa541c;
    color: #fa541c;
  }
  .ant-ribbon-color-orange {
    background: #fa8c16;
    color: #fa8c16;
  }
  .ant-ribbon-color-yellow {
    background: #fadb14;
    color: #fadb14;
  }
  .ant-ribbon-color-gold {
    background: #faad14;
    color: #faad14;
  }
  .ant-ribbon-color-cyan {
    background: #13c2c2;
    color: #13c2c2;
  }
  .ant-ribbon-color-lime {
    background: #a0d911;
    color: #a0d911;
  }
  .ant-ribbon-color-green {
    background: #52c41a;
    color: #52c41a;
  }
  .ant-ribbon-color-blue {
    background: #1890ff;
    color: #1890ff;
  }
  .ant-ribbon-color-geekblue {
    background: #2f54eb;
    color: #2f54eb;
  }
  .ant-ribbon-color-purple {
    background: #722ed1;
    color: #722ed1;
  }
  .ant-ribbon.ant-ribbon-placement-end {
    border-bottom-right-radius: 0;
    right: -8px;
  }
  .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
    border-color: currentColor transparent transparent currentColor;
    right: 0;
  }
  .ant-ribbon.ant-ribbon-placement-start {
    border-bottom-left-radius: 0;
    left: -8px;
  }
  .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
    border-color: currentColor currentColor transparent transparent;
    left: 0;
  }
  .ant-badge-rtl {
    direction: rtl;
  }
  .ant-badge-rtl .ant-badge-count,
  .ant-badge-rtl .ant-badge-dot,
  .ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
    direction: ltr;
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
  }
  .ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
  }
  .ant-badge-rtl .ant-badge-status-text {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-badge-rtl .ant-badge-zoom-appear,
  .ant-badge-rtl .ant-badge-zoom-enter {
    -webkit-animation-name: antZoomBadgeInRtl;
    animation-name: antZoomBadgeInRtl;
  }
  .ant-badge-rtl .ant-badge-zoom-leave {
    -webkit-animation-name: antZoomBadgeOutRtl;
    animation-name: antZoomBadgeOutRtl;
  }
  .ant-badge-not-a-wrapper .ant-badge-count {
    transform: none;
  }
  .ant-ribbon-rtl {
    direction: rtl;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-end {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 2px;
    left: -8px;
    right: unset;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
    left: 0;
    right: unset;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner,
  .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner:after {
    border-color: currentColor currentColor transparent transparent;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-start {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 0;
    left: unset;
    right: -8px;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
    left: unset;
    right: 0;
  }
  .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner,
  .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner:after {
    border-color: currentColor transparent transparent currentColor;
  }
  @-webkit-keyframes antZoomBadgeInRtl {
    0% {
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    }
    to {
      transform: scale(1) translate(-50%, -50%);
    }
  }
  @keyframes antZoomBadgeInRtl {
    0% {
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    }
    to {
      transform: scale(1) translate(-50%, -50%);
    }
  }
  @-webkit-keyframes antZoomBadgeOutRtl {
    0% {
      transform: scale(1) translate(-50%, -50%);
    }
    to {
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    }
  }
  @keyframes antZoomBadgeOutRtl {
    0% {
      transform: scale(1) translate(-50%, -50%);
    }
    to {
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    }
  }
  .ant-breadcrumb {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-breadcrumb .anticon {
    font-size: 14px;
  }
  .ant-breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
  }
  .ant-breadcrumb a:hover {
    color: #40a9ff;
  }
  .ant-breadcrumb > span:last-child,
  .ant-breadcrumb > span:last-child a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
    display: none;
  }
  .ant-breadcrumb-separator {
    color: rgba(0, 0, 0, 0.45);
    margin: 0 8px;
  }
  .ant-breadcrumb-link > .anticon + a,
  .ant-breadcrumb-link > .anticon + span,
  .ant-breadcrumb-overlay-link > .anticon {
    margin-left: 4px;
  }
  .ant-breadcrumb-rtl {
    direction: rtl;
  }
  .ant-breadcrumb-rtl:before {
    content: '';
    display: table;
  }
  .ant-breadcrumb-rtl:after {
    clear: both;
    content: '';
    display: table;
  }
  .ant-breadcrumb-rtl > span {
    float: right;
  }
  .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a,
  .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
  .ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-menu-item-danger.ant-menu-item,
  .ant-menu-item-danger.ant-menu-item-active,
  .ant-menu-item-danger.ant-menu-item:hover {
    color: #ff4d4f;
  }
  .ant-menu-item-danger.ant-menu-item:active {
    background: #fff1f0;
  }
  .ant-menu-item-danger.ant-menu-item-selected,
  .ant-menu-item-danger.ant-menu-item-selected > a,
  .ant-menu-item-danger.ant-menu-item-selected > a:hover {
    color: #ff4d4f;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #fff1f0;
  }
  .ant-menu-inline .ant-menu-item-danger.ant-menu-item:after {
    border-right-color: #ff4d4f;
  }
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: #ff4d4f;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #ff4d4f;
    color: #fff;
  }
  .ant-menu {
    font-feature-settings: 'tnum';
    background: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 0;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    text-align: left;
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .ant-menu:after,
  .ant-menu:before {
    content: '';
    display: table;
  }
  .ant-menu:after {
    clear: both;
  }
  .ant-menu.ant-menu-root:focus-visible {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-menu ol,
  .ant-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-menu-overflow {
    display: flex;
  }
  .ant-menu-overflow-item {
    flex: none;
  }
  .ant-menu-hidden,
  .ant-menu-submenu-hidden {
    display: none;
  }
  .ant-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    height: 1.5715;
    line-height: 1.5715;
    padding: 8px 16px;
    transition: all 0.3s;
  }
  .ant-menu-horizontal .ant-menu-submenu {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-selected {
    color: #1890ff;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: #e6f7ff;
  }
  .ant-menu-submenu .ant-menu-sub {
    cursor: auto;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-item a:hover {
    color: #1890ff;
  }
  .ant-menu-item a:before {
    background-color: initial;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-menu-item > .ant-badge a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-item > .ant-badge a:hover {
    color: #1890ff;
  }
  .ant-menu-item-divider {
    background-color: #f0f0f0;
    height: 1px;
    line-height: 0;
    overflow: hidden;
  }
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px;
  }
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: initial;
  }
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #1890ff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 1px solid #f0f0f0;
  }
  .ant-menu-vertical-right {
    border-left: 1px solid #f0f0f0;
  }
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub,
  .ant-menu-vertical.ant-menu-sub {
    border-right: 0;
    max-height: calc(100vh - 100px);
    min-width: 160px;
    overflow: hidden;
    padding: 0;
  }
  .ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
  .ant-menu-vertical-right.ant-menu-sub:not([class*='-active']),
  .ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
  .ant-menu-vertical.ant-menu-sub .ant-menu-item {
    border-right: 0;
    left: 0;
    margin-left: 0;
  }
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after,
  .ant-menu-vertical.ant-menu-sub .ant-menu-item:after {
    border-right: 0;
  }
  .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
    transform-origin: 0 0;
  }
  .ant-menu-horizontal.ant-menu-sub {
    min-width: 114px;
  }
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu-title {
    transition: border-color 0.3s, background 0.3s;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 0 20px;
    position: relative;
    transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    white-space: nowrap;
  }
  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-submenu-title .anticon {
    font-size: 14px;
    min-width: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-submenu-title .anticon + span {
    margin-left: 10px;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
  }
  .ant-menu-item .ant-menu-item-icon.svg,
  .ant-menu-submenu-title .ant-menu-item-icon.svg {
    vertical-align: -0.125em;
  }
  .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
  .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
  .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-right: 0;
  }
  .ant-menu-item:focus-visible,
  .ant-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-menu > .ant-menu-item-divider {
    background-color: #f0f0f0;
    height: 1px;
    line-height: 0;
    margin: 1px 0;
    overflow: hidden;
    padding: 0;
  }
  .ant-menu-submenu-popup {
    background: transparent;
    border-radius: 2px;
    box-shadow: none;
    position: absolute;
    transform-origin: 0 0;
    z-index: 1050;
  }
  .ant-menu-submenu-popup:before {
    bottom: 0;
    content: ' ';
    height: 100%;
    left: 0;
    opacity: 0.0001;
    position: absolute;
    right: 0;
    top: -7px;
    width: 100%;
    z-index: -1;
  }
  .ant-menu-submenu-placement-rightTop:before {
    left: -7px;
    top: 0;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: #fff;
    border-radius: 2px;
  }
  .ant-menu-submenu > .ant-menu-submenu-title:after {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-popup > .ant-menu {
    background-color: #fff;
  }
  .ant-menu-submenu-arrow,
  .ant-menu-submenu-expand-icon {
    color: rgba(0, 0, 0, 0.85);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 10px;
  }
  .ant-menu-submenu-arrow:after,
  .ant-menu-submenu-arrow:before {
    background-color: currentColor;
    border-radius: 2px;
    content: '';
    height: 1.5px;
    position: absolute;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 6px;
  }
  .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateY(-2.5px);
  }
  .ant-menu-submenu-arrow:after {
    transform: rotate(-45deg) translateY(2.5px);
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
    color: #1890ff;
  }
  .ant-menu-inline-collapsed .ant-menu-submenu-arrow:before,
  .ant-menu-submenu-inline .ant-menu-submenu-arrow:before {
    transform: rotate(-45deg) translateX(2.5px);
  }
  .ant-menu-inline-collapsed .ant-menu-submenu-arrow:after,
  .ant-menu-submenu-inline .ant-menu-submenu-arrow:after {
    transform: rotate(45deg) translateX(-2.5px);
  }
  .ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    transform: translateY(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
    transform: rotate(-45deg) translateX(-2.5px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateX(2.5px);
  }
  .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ant-menu-vertical-right .ant-menu-submenu-selected,
  .ant-menu-vertical .ant-menu-submenu-selected {
    color: #1890ff;
  }
  .ant-menu-horizontal {
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: none;
    line-height: 46px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-bottom: 0;
    margin-top: -1px;
    padding: 0 20px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
    color: #1890ff;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
    border-bottom: 2px solid #1890ff;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    position: relative;
    top: 1px;
    vertical-align: bottom;
  }
  .ant-menu-horizontal > .ant-menu-item:after,
  .ant-menu-horizontal > .ant-menu-submenu:after {
    border-bottom: 2px solid transparent;
    bottom: 0;
    content: '';
    left: 20px;
    position: absolute;
    right: 20px;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0;
  }
  .ant-menu-horizontal > .ant-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-horizontal > .ant-menu-item a:hover {
    color: #1890ff;
  }
  .ant-menu-horizontal > .ant-menu-item a:before {
    bottom: -2px;
  }
  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: #1890ff;
  }
  .ant-menu-horizontal:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-vertical .ant-menu-item {
    position: relative;
  }
  .ant-menu-inline .ant-menu-item:after,
  .ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-vertical .ant-menu-item:after {
    border-right: 3px solid #1890ff;
    bottom: 0;
    content: '';
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleY(0.0001);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    margin-bottom: 4px;
    margin-top: 4px;
    overflow: hidden;
    padding: 0 16px;
    text-overflow: ellipsis;
  }
  .ant-menu-inline .ant-menu-submenu,
  .ant-menu-vertical-left .ant-menu-submenu,
  .ant-menu-vertical-right .ant-menu-submenu,
  .ant-menu-vertical .ant-menu-submenu {
    padding-bottom: 0.02px;
  }
  .ant-menu-inline .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
  }
  .ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ant-menu-inline {
    width: 100%;
  }
  .ant-menu-inline .ant-menu-item-selected:after,
  .ant-menu-inline .ant-menu-selected:after {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
  }
  .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    align-items: center;
    display: flex;
    transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > *,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
    flex: none;
  }
  .ant-menu.ant-menu-inline-collapsed {
    width: 80px;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 calc(50% - 8px);
    text-overflow: clip;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 16px;
    line-height: 40px;
    margin: 0;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: inline-block;
    opacity: 0;
  }
  .ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
  .ant-menu.ant-menu-inline-collapsed .anticon {
    display: inline-block;
  }
  .ant-menu.ant-menu-inline-collapsed-tooltip {
    pointer-events: none;
  }
  .ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
  .ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
    display: none;
  }
  .ant-menu.ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
    overflow: hidden;
    padding-left: 4px;
    padding-right: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
  .ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right {
    box-shadow: none;
  }
  .ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
  .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
    font-size: 16px;
    text-align: center;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fafafa;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
  }
  .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px;
  }
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    background: none;
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
  }
  .ant-menu-item-disabled:after,
  .ant-menu-submenu-disabled:after {
    border-color: transparent !important;
  }
  .ant-menu-item-disabled a,
  .ant-menu-submenu-disabled a {
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
    background: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-layout-header .ant-menu {
    line-height: inherit;
  }
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #1890ff;
  }
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #001529;
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s;
  }
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    background: #fff;
  }
  .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
  }
  .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    border-color: #001529;
    border-bottom: 0;
    margin-top: 0;
    padding: 0 20px;
    top: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #1890ff;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a:before {
    bottom: 0;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-menu-dark.ant-menu-inline,
  .ant-menu-dark.ant-menu-vertical,
  .ant-menu-dark.ant-menu-vertical-left,
  .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item {
    border-right: 0;
    left: 0;
    margin-left: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item:after,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item:after {
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: initial;
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-active > a,
  .ant-menu-dark .ant-menu-item-active > span > a,
  .ant-menu-dark .ant-menu-item:hover > a,
  .ant-menu-dark .ant-menu-item:hover > span > a,
  .ant-menu-dark .ant-menu-submenu-active > a,
  .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ant-menu-dark .ant-menu-submenu-open > a,
  .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ant-menu-dark .ant-menu-submenu-selected > a,
  .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
    background: #fff;
  }
  .ant-menu-dark .ant-menu-item:hover {
    background-color: initial;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1890ff;
  }
  .ant-menu-dark .ant-menu-item-selected {
    border-right: 0;
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-selected:after {
    border-right: 0;
  }
  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
  .ant-menu-dark .ant-menu-item-selected .anticon,
  .ant-menu-dark .ant-menu-item-selected .anticon + span,
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ant-menu-dark .ant-menu-item-selected > span > a,
  .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff;
  }
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
  .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff;
  }
  .ant-menu-dark .ant-menu-item-disabled,
  .ant-menu-dark .ant-menu-item-disabled > a,
  .ant-menu-dark .ant-menu-item-disabled > span > a,
  .ant-menu-dark .ant-menu-submenu-disabled,
  .ant-menu-dark .ant-menu-submenu-disabled > a,
  .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 0.8;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35) !important;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
    background: rgba(255, 255, 255, 0.35) !important;
  }
  .ant-menu.ant-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-menu-rtl .ant-menu-item-group-title {
    text-align: right;
  }
  .ant-menu-rtl.ant-menu-inline,
  .ant-menu-rtl.ant-menu-vertical {
    border-left: 1px solid #f0f0f0;
    border-right: none;
  }
  .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
  .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
    border-left: none;
  }
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
    transform-origin: top right;
  }
  .ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
  .ant-menu-rtl .ant-menu-item .anticon,
  .ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-rtl .ant-menu-submenu-title .anticon {
    margin-left: 10px;
    margin-right: auto;
  }
  .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
  .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
  .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-left: 0;
  }
  .ant-menu-submenu-rtl.ant-menu-submenu-popup {
    transform-origin: 100% 0;
  }
  .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    left: 16px;
    right: auto;
  }
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    transform: rotate(-45deg) translateY(-2px);
  }
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    transform: rotate(45deg) translateY(2px);
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-item:after,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-item:after {
    left: 0;
    right: auto;
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-item,
  .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
    text-align: right;
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    padding-left: 34px;
    padding-right: 0;
  }
  .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
    padding-left: 34px;
    padding-right: 16px;
  }
  .ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 calc(50% - 8px);
  }
  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 28px 0 16px;
  }
  .ant-menu-sub.ant-menu-inline {
    border: 0;
  }
  .ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 0;
    padding-right: 32px;
  }
  .ant-tooltip {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    max-width: 250px;
    padding: 0;
    position: absolute;
    visibility: visible;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    z-index: 1070;
  }
  .ant-tooltip-hidden {
    display: none;
  }
  .ant-tooltip-placement-top,
  .ant-tooltip-placement-topLeft,
  .ant-tooltip-placement-topRight {
    padding-bottom: 8px;
  }
  .ant-tooltip-placement-right,
  .ant-tooltip-placement-rightBottom,
  .ant-tooltip-placement-rightTop {
    padding-left: 8px;
  }
  .ant-tooltip-placement-bottom,
  .ant-tooltip-placement-bottomLeft,
  .ant-tooltip-placement-bottomRight {
    padding-top: 8px;
  }
  .ant-tooltip-placement-left,
  .ant-tooltip-placement-leftBottom,
  .ant-tooltip-placement-leftTop {
    padding-right: 8px;
  }
  .ant-tooltip-inner {
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    color: #fff;
    min-height: 32px;
    min-width: 30px;
    padding: 6px 8px;
    text-align: left;
    text-decoration: none;
  }
  .ant-tooltip-arrow {
    background: transparent;
    display: block;
    height: 13.07106781px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 13.07106781px;
  }
  .ant-tooltip-arrow-content {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    content: '';
    display: block;
    height: 5px;
    left: 0;
    margin: auto;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow,
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: -5.07106781px;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(-6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 13px;
  }
  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 13px;
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    left: -5.07106781px;
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 5px;
  }
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 5px;
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow,
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    right: -5.07106781px;
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(-6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 5px;
  }
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 5px;
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: -5.07106781px;
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(6.53553391px) rotate(45deg);
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 13px;
  }
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 13px;
  }
  .ant-tooltip-magenta .ant-tooltip-arrow-content,
  .ant-tooltip-magenta .ant-tooltip-inner,
  .ant-tooltip-pink .ant-tooltip-arrow-content,
  .ant-tooltip-pink .ant-tooltip-inner {
    background-color: #eb2f96;
  }
  .ant-tooltip-red .ant-tooltip-arrow-content,
  .ant-tooltip-red .ant-tooltip-inner {
    background-color: #f5222d;
  }
  .ant-tooltip-volcano .ant-tooltip-arrow-content,
  .ant-tooltip-volcano .ant-tooltip-inner {
    background-color: #fa541c;
  }
  .ant-tooltip-orange .ant-tooltip-arrow-content,
  .ant-tooltip-orange .ant-tooltip-inner {
    background-color: #fa8c16;
  }
  .ant-tooltip-yellow .ant-tooltip-arrow-content,
  .ant-tooltip-yellow .ant-tooltip-inner {
    background-color: #fadb14;
  }
  .ant-tooltip-gold .ant-tooltip-arrow-content,
  .ant-tooltip-gold .ant-tooltip-inner {
    background-color: #faad14;
  }
  .ant-tooltip-cyan .ant-tooltip-arrow-content,
  .ant-tooltip-cyan .ant-tooltip-inner {
    background-color: #13c2c2;
  }
  .ant-tooltip-lime .ant-tooltip-arrow-content,
  .ant-tooltip-lime .ant-tooltip-inner {
    background-color: #a0d911;
  }
  .ant-tooltip-green .ant-tooltip-arrow-content,
  .ant-tooltip-green .ant-tooltip-inner {
    background-color: #52c41a;
  }
  .ant-tooltip-blue .ant-tooltip-arrow-content,
  .ant-tooltip-blue .ant-tooltip-inner {
    background-color: #1890ff;
  }
  .ant-tooltip-geekblue .ant-tooltip-arrow-content,
  .ant-tooltip-geekblue .ant-tooltip-inner {
    background-color: #2f54eb;
  }
  .ant-tooltip-purple .ant-tooltip-arrow-content,
  .ant-tooltip-purple .ant-tooltip-inner {
    background-color: #722ed1;
  }
  .ant-tooltip-rtl {
    direction: rtl;
  }
  .ant-tooltip-rtl .ant-tooltip-inner {
    text-align: right;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #ff4d4f;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    background-color: #ff4d4f;
    color: #fff;
  }
  .ant-dropdown {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    font-size: 14px;
    font-variant: tabular-nums;
    left: -9999px;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -9999px;
    z-index: 1050;
  }
  .ant-dropdown:before {
    bottom: -4px;
    content: ' ';
    left: -7px;
    opacity: 0.0001;
    position: absolute;
    right: 0;
    top: -4px;
    z-index: -9999;
  }
  .ant-dropdown-wrap {
    position: relative;
  }
  .ant-dropdown-wrap .ant-btn > .anticon-down {
    font-size: 10px;
  }
  .ant-dropdown-wrap .anticon-down:before {
    transition: transform 0.2s;
  }
  .ant-dropdown-wrap-open .anticon-down:before {
    transform: rotate(180deg);
  }
  .ant-dropdown-hidden,
  .ant-dropdown-menu-hidden,
  .ant-dropdown-menu-submenu-hidden {
    display: none;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
    padding-bottom: 10px;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
    padding-top: 10px;
  }
  .ant-dropdown-arrow {
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    display: block;
    height: 8.48528137px;
    position: absolute;
    transform: rotate(45deg);
    width: 8.48528137px;
    z-index: 1;
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    border-color: transparent #fff #fff transparent;
    bottom: 6.2px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    border-color: #fff transparent transparent #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    top: 6px;
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 4px 0;
    position: relative;
    text-align: left;
  }
  .ant-dropdown-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    padding: 5px 12px;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-submenu-popup {
    background: transparent;
    box-shadow: none;
    position: absolute;
    transform-origin: 0 0;
    z-index: 1050;
  }
  .ant-dropdown-menu-submenu-popup li,
  .ant-dropdown-menu-submenu-popup ul {
    list-style: none;
  }
  .ant-dropdown-menu-submenu-popup ul {
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
  .ant-dropdown-menu-item {
    align-items: center;
    display: flex;
    position: relative;
  }
  .ant-dropdown-menu-item-icon {
    font-size: 12px;
    margin-right: 8px;
    min-width: 12px;
  }
  .ant-dropdown-menu-title-content {
    flex: auto;
  }
  .ant-dropdown-menu-title-content > a {
    color: inherit;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-title-content > a:hover {
    color: inherit;
  }
  .ant-dropdown-menu-title-content > a:after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    clear: both;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    background-color: #e6f7ff;
    color: #1890ff;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f5f5f5;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-disabled a,
  .ant-dropdown-menu-submenu-title-disabled a {
    pointer-events: none;
  }
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    background-color: #f0f0f0;
    height: 1px;
    line-height: 0;
    margin: 4px 0;
    overflow: hidden;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
    position: absolute;
    right: 8px;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    font-style: normal;
    margin-right: 0 !important;
  }
  .ant-dropdown-menu-item-group-list {
    list-style: none;
    margin: 0 8px;
    padding: 0;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 24px;
  }
  .ant-dropdown-menu-submenu-vertical {
    position: relative;
  }
  .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    left: 100%;
    margin-left: 4px;
    min-width: 100%;
    position: absolute;
    top: 0;
    transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: #1890ff;
  }
  .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight,
  .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
  }
  .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight,
  .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
  }
  .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
  }
  .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
  }
  .ant-dropdown-button > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down,
  .ant-dropdown-trigger > .anticon.anticon-down {
    font-size: 10px;
    vertical-align: initial;
  }
  .ant-dropdown-button {
    white-space: nowrap;
  }
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow:after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow:after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover {
    background: transparent;
    color: #fff;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    background: #1890ff;
    color: #fff;
  }
  .ant-dropdown-rtl {
    direction: rtl;
  }
  .ant-dropdown-rtl.ant-dropdown:before {
    left: 0;
    right: -7px;
  }
  .ant-dropdown-menu.ant-dropdown-menu-rtl,
  .ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
    direction: rtl;
    text-align: right;
  }
  .ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
    transform-origin: 100% 0;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    left: 8px;
    right: auto;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-left: 0 !important;
    transform: scaleX(-1);
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    padding-left: 24px;
    padding-right: 12px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    left: 0;
    margin-left: 0;
    margin-right: 4px;
    right: 100%;
  }
  .ant-btn {
    background-image: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    line-height: 1.5715;
    padding: 4px 15px;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
  }
  .ant-btn > .anticon {
    line-height: 1;
  }
  .ant-btn,
  .ant-btn:active,
  .ant-btn:focus {
    outline: 0;
  }
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ant-btn:not([disabled]):active {
    box-shadow: none;
    outline: 0;
  }
  .ant-btn[disabled] {
    cursor: not-allowed;
  }
  .ant-btn[disabled] > * {
    pointer-events: none;
  }
  .ant-btn-lg {
    border-radius: 2px;
    font-size: 16px;
    height: 40px;
    padding: 6.4px 15px;
  }
  .ant-btn-sm {
    border-radius: 2px;
    font-size: 14px;
    height: 24px;
    padding: 0 7px;
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background: #fff;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn:focus > a:only-child,
  .ant-btn:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn:focus > a:only-child:after,
  .ant-btn:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn:active {
    background: #fff;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn:active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn[disabled]:active > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn[disabled]:active > a:only-child:after,
  .ant-btn[disabled]:focus > a:only-child:after,
  .ant-btn[disabled]:hover > a:only-child:after,
  .ant-btn[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn:active,
  .ant-btn:focus,
  .ant-btn:hover {
    background: #fff;
    text-decoration: none;
  }
  .ant-btn > span {
    display: inline-block;
  }
  .ant-btn-primary {
    background: #1890ff;
    border-color: #1890ff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  }
  .ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-primary:focus,
  .ant-btn-primary:hover {
    background: #40a9ff;
    border-color: #40a9ff;
    color: #fff;
  }
  .ant-btn-primary:focus > a:only-child,
  .ant-btn-primary:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:focus > a:only-child:after,
  .ant-btn-primary:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-primary:active {
    background: #096dd9;
    border-color: #096dd9;
    color: #fff;
  }
  .ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-primary[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary[disabled]:active > a:only-child:after,
  .ant-btn-primary[disabled]:focus > a:only-child:after,
  .ant-btn-primary[disabled]:hover > a:only-child:after,
  .ant-btn-primary[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-left-color: #40a9ff;
    border-right-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
    border-left-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
    border-left-color: #d9d9d9;
  }
  .ant-btn-ghost {
    background: transparent;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-btn-ghost > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-ghost:focus,
  .ant-btn-ghost:hover {
    background: transparent;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-ghost:focus > a:only-child,
  .ant-btn-ghost:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:focus > a:only-child:after,
  .ant-btn-ghost:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-ghost:active {
    background: transparent;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-ghost:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-ghost[disabled],
  .ant-btn-ghost[disabled]:active,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-ghost[disabled]:active > a:only-child,
  .ant-btn-ghost[disabled]:focus > a:only-child,
  .ant-btn-ghost[disabled]:hover > a:only-child,
  .ant-btn-ghost[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost[disabled]:active > a:only-child:after,
  .ant-btn-ghost[disabled]:focus > a:only-child:after,
  .ant-btn-ghost[disabled]:hover > a:only-child:after,
  .ant-btn-ghost[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dashed {
    background: #fff;
    border-color: #d9d9d9;
    border-style: dashed;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-btn-dashed > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dashed:focus,
  .ant-btn-dashed:hover {
    background: #fff;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-dashed:focus > a:only-child,
  .ant-btn-dashed:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:focus > a:only-child:after,
  .ant-btn-dashed:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dashed:active {
    background: #fff;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-dashed:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dashed[disabled],
  .ant-btn-dashed[disabled]:active,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-dashed[disabled]:active > a:only-child,
  .ant-btn-dashed[disabled]:focus > a:only-child,
  .ant-btn-dashed[disabled]:hover > a:only-child,
  .ant-btn-dashed[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed[disabled]:active > a:only-child:after,
  .ant-btn-dashed[disabled]:focus > a:only-child:after,
  .ant-btn-dashed[disabled]:hover > a:only-child:after,
  .ant-btn-dashed[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-danger {
    background: #ff4d4f;
    border-color: #ff4d4f;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  }
  .ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-danger:focus,
  .ant-btn-danger:hover {
    background: #ff7875;
    border-color: #ff7875;
    color: #fff;
  }
  .ant-btn-danger:focus > a:only-child,
  .ant-btn-danger:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:focus > a:only-child:after,
  .ant-btn-danger:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-danger:active {
    background: #d9363e;
    border-color: #d9363e;
    color: #fff;
  }
  .ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-danger[disabled],
  .ant-btn-danger[disabled]:active,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-danger[disabled]:active > a:only-child,
  .ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-danger[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger[disabled]:active > a:only-child:after,
  .ant-btn-danger[disabled]:focus > a:only-child:after,
  .ant-btn-danger[disabled]:hover > a:only-child:after,
  .ant-btn-danger[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-link {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: #1890ff;
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-link:focus,
  .ant-btn-link:hover {
    background: transparent;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-link:focus > a:only-child,
  .ant-btn-link:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:focus > a:only-child:after,
  .ant-btn-link:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-link:active {
    background: transparent;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:active,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-btn-link:hover {
    background: transparent;
  }
  .ant-btn-link:active,
  .ant-btn-link:focus,
  .ant-btn-link:hover {
    border-color: transparent;
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:active,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:hover {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled]:active > a:only-child:after,
  .ant-btn-link[disabled]:focus > a:only-child:after,
  .ant-btn-link[disabled]:hover > a:only-child:after,
  .ant-btn-link[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-text {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-text > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: transparent;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-text:focus > a:only-child,
  .ant-btn-text:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:focus > a:only-child:after,
  .ant-btn-text:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-text:active {
    background: transparent;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:active,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-btn-text:active {
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:active,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:hover {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-text[disabled]:active > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled]:active > a:only-child:after,
  .ant-btn-text[disabled]:focus > a:only-child:after,
  .ant-btn-text[disabled]:hover > a:only-child:after,
  .ant-btn-text[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous {
    background: #fff;
    border-color: #ff4d4f;
    color: #ff4d4f;
  }
  .ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous:focus,
  .ant-btn-dangerous:hover {
    background: #fff;
    border-color: #ff7875;
    color: #ff7875;
  }
  .ant-btn-dangerous:focus > a:only-child,
  .ant-btn-dangerous:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:focus > a:only-child:after,
  .ant-btn-dangerous:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous:active {
    background: #fff;
    border-color: #d9363e;
    color: #d9363e;
  }
  .ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous[disabled],
  .ant-btn-dangerous[disabled]:active,
  .ant-btn-dangerous[disabled]:focus,
  .ant-btn-dangerous[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-dangerous[disabled]:active > a:only-child,
  .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-dangerous[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous[disabled]:active > a:only-child:after,
  .ant-btn-dangerous[disabled]:focus > a:only-child:after,
  .ant-btn-dangerous[disabled]:hover > a:only-child:after,
  .ant-btn-dangerous[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-primary {
    background: #ff4d4f;
    border-color: #ff4d4f;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-primary:focus,
  .ant-btn-dangerous.ant-btn-primary:hover {
    background: #ff7875;
    border-color: #ff7875;
    color: #fff;
  }
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child:after,
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-primary:active {
    background: #d9363e;
    border-color: #d9363e;
    color: #fff;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled],
  .ant-btn-dangerous.ant-btn-primary[disabled]:active,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child:after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child:after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child:after,
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-link {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: #ff4d4f;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-link:focus,
  .ant-btn-dangerous.ant-btn-link:hover {
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-dangerous.ant-btn-link:active {
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:active,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-btn-dangerous.ant-btn-link:focus,
  .ant-btn-dangerous.ant-btn-link:hover {
    background: transparent;
    border-color: transparent;
    color: #ff7875;
  }
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child:after,
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-link:active {
    background: transparent;
    border-color: transparent;
    color: #d9363e;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:active,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child:after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child:after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child:after,
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-text {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: #ff4d4f;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-text:focus,
  .ant-btn-dangerous.ant-btn-text:hover {
    background: transparent;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-dangerous.ant-btn-text:active {
    background: transparent;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:active,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-btn-dangerous.ant-btn-text:focus,
  .ant-btn-dangerous.ant-btn-text:hover {
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
    color: #ff7875;
  }
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child:after,
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-text:active {
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
    color: #d9363e;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:active,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child:after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child:after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child:after,
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-icon-only {
    border-radius: 2px;
    font-size: 16px;
    height: 32px;
    padding: 2.4px 0;
    vertical-align: -1px;
    width: 32px;
  }
  .ant-btn-icon-only > * {
    font-size: 16px;
  }
  .ant-btn-icon-only.ant-btn-lg {
    border-radius: 2px;
    font-size: 18px;
    height: 40px;
    padding: 4.9px 0;
    width: 40px;
  }
  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 18px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    border-radius: 2px;
    font-size: 14px;
    height: 24px;
    padding: 0;
    width: 24px;
  }
  .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 14px;
  }
  .ant-btn-round {
    border-radius: 32px;
    font-size: 14px;
    height: 32px;
    padding: 4px 16px;
  }
  .ant-btn-round.ant-btn-lg {
    border-radius: 40px;
    font-size: 16px;
    height: 40px;
    padding: 6.4px 20px;
  }
  .ant-btn-round.ant-btn-sm {
    border-radius: 24px;
    font-size: 14px;
    height: 24px;
    padding: 0 12px;
  }
  .ant-btn-round.ant-btn-icon-only {
    width: auto;
  }
  .ant-btn-circle {
    border-radius: 50%;
    min-width: 32px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  .ant-btn-circle.ant-btn-lg {
    border-radius: 50%;
    min-width: 40px;
  }
  .ant-btn-circle.ant-btn-sm {
    border-radius: 50%;
    min-width: 24px;
  }
  .ant-btn:before {
    background: #fff;
    border-radius: inherit;
    bottom: -1px;
    content: '';
    display: none;
    left: -1px;
    opacity: 0.35;
    pointer-events: none;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: opacity 0.2s;
    z-index: 1;
  }
  .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn .anticon.anticon-minus > svg,
  .ant-btn .anticon.anticon-plus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ant-btn.ant-btn-loading {
    position: relative;
  }
  .ant-btn.ant-btn-loading:not([disabled]) {
    pointer-events: none;
  }
  .ant-btn.ant-btn-loading:before {
    display: block;
  }
  .ant-btn > .ant-btn-loading-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn > .ant-btn-loading-icon .anticon {
    -webkit-animation: none;
    animation: none;
    padding-right: 8px;
  }
  .ant-btn > .ant-btn-loading-icon .anticon svg {
    -webkit-animation: loadingCircle 1s linear infinite;
    animation: loadingCircle 1s linear infinite;
  }
  .ant-btn-group {
    display: inline-flex;
  }
  .ant-btn-group,
  .ant-btn-group > .ant-btn,
  .ant-btn-group > span > .ant-btn {
    position: relative;
  }
  .ant-btn-group > .ant-btn:active,
  .ant-btn-group > .ant-btn:focus,
  .ant-btn-group > .ant-btn:hover,
  .ant-btn-group > span > .ant-btn:active,
  .ant-btn-group > span > .ant-btn:focus,
  .ant-btn-group > span > .ant-btn:hover {
    z-index: 2;
  }
  .ant-btn-group > .ant-btn[disabled],
  .ant-btn-group > span > .ant-btn[disabled] {
    z-index: 0;
  }
  .ant-btn-group .ant-btn-icon-only {
    font-size: 14px;
  }
  .ant-btn-group-lg > .ant-btn,
  .ant-btn-group-lg > span > .ant-btn {
    border-radius: 0;
    font-size: 16px;
    height: 40px;
    padding: 6.4px 15px;
  }
  .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
    height: 40px;
    padding-left: 0;
    padding-right: 0;
    width: 40px;
  }
  .ant-btn-group-sm > .ant-btn,
  .ant-btn-group-sm > span > .ant-btn {
    border-radius: 0;
    font-size: 14px;
    height: 24px;
    padding: 0 7px;
  }
  .ant-btn-group-sm > .ant-btn > .anticon,
  .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px;
  }
  .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
    height: 24px;
    padding-left: 0;
    padding-right: 0;
    width: 24px;
  }
  .ant-btn + .ant-btn-group,
  .ant-btn-group + .ant-btn,
  .ant-btn-group + .ant-btn-group,
  .ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-group .ant-btn + span,
  .ant-btn-group > span + span,
  .ant-btn-group span + .ant-btn {
    margin-left: -1px;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .ant-btn-group .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn:first-child,
  .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0;
  }
  .ant-btn-group > .ant-btn:only-child,
  .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 2px;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
  .ant-btn-group-sm > .ant-btn:only-child,
  .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 2px;
  }
  .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
  .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
  .ant-btn-group > .ant-btn-group {
    float: left;
  }
  .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px;
  }
  .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 8px;
  }
  .ant-btn-group-rtl.ant-btn + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-group-rtl.ant-btn-group > span + span,
  .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-rtl.ant-btn + .ant-btn-group,
  .ant-btn-rtl.ant-btn-group + .ant-btn,
  .ant-btn-rtl.ant-btn-group + .ant-btn-group,
  .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-rtl.ant-btn-group > span + span,
  .ant-btn-rtl.ant-btn-group span + .ant-btn {
    margin-left: auto;
    margin-right: -1px;
  }
  .ant-btn-group.ant-btn-group-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
  }
  .ant-btn:active > span,
  .ant-btn:focus > span {
    position: relative;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 8px;
  }
  .ant-btn-background-ghost {
    background: transparent !important;
    border-color: #fff;
    color: #fff;
  }
  .ant-btn-background-ghost.ant-btn-primary {
    background: transparent;
    border-color: #1890ff;
    color: #1890ff;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-primary:focus,
  .ant-btn-background-ghost.ant-btn-primary:hover {
    background: transparent;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-primary:active {
    background: transparent;
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-danger {
    background: transparent;
    border-color: #ff4d4f;
    color: #ff4d4f;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-danger:focus,
  .ant-btn-background-ghost.ant-btn-danger:hover {
    background: transparent;
    border-color: #ff7875;
    color: #ff7875;
  }
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-danger:active {
    background: transparent;
    border-color: #d9363e;
    color: #d9363e;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous {
    background: transparent;
    border-color: #ff4d4f;
    color: #ff4d4f;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:focus,
  .ant-btn-background-ghost.ant-btn-dangerous:hover {
    background: transparent;
    border-color: #ff7875;
    color: #ff7875;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active {
    background: transparent;
    border-color: #d9363e;
    color: #d9363e;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    background: transparent;
    border-color: transparent;
    color: #ff4d4f;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover {
    background: transparent;
    border-color: transparent;
    color: #ff7875;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    background: transparent;
    border-color: transparent;
    color: #d9363e;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child:after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-btn-two-chinese-chars:first-letter {
    letter-spacing: 0.34em;
  }
  .ant-btn-two-chinese-chars > :not(.anticon) {
    letter-spacing: 0.34em;
    margin-right: -0.34em;
  }
  .ant-btn-block {
    width: 100%;
  }
  .ant-btn:empty {
    content: ' ';
    display: inline-block;
    visibility: hidden;
    width: 0;
  }
  a.ant-btn {
    line-height: 30px;
    padding-top: 0.01px !important;
  }
  a.ant-btn-lg {
    line-height: 38px;
  }
  a.ant-btn-sm {
    line-height: 22px;
  }
  .ant-btn-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
    border-left-color: #d9d9d9;
    border-right-color: #40a9ff;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
    border-left-color: #40a9ff;
    border-right-color: #d9d9d9;
  }
  .ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
    padding-left: 8px;
    padding-right: 0;
  }
  .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-btn-rtl.ant-btn > .anticon + span,
  .ant-btn-rtl.ant-btn > span + .anticon {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-picker-calendar {
    font-feature-settings: 'tnum';
    background: #fff;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-picker-calendar-header {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    min-width: 80px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    margin-left: 8px;
    min-width: 70px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    margin-left: 8px;
  }
  .ant-picker-calendar .ant-picker-panel {
    background: #fff;
    border: 0;
    border-radius: 0;
    border-top: 1px solid #f0f0f0;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel,
  .ant-picker-calendar .ant-picker-panel .ant-picker-month-panel {
    width: auto;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 0;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-content {
    width: 100%;
  }
  .ant-picker-calendar-mini {
    border-radius: 2px;
  }
  .ant-picker-calendar-mini .ant-picker-calendar-header {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-picker-calendar-mini .ant-picker-panel {
    border-radius: 0 0 2px 2px;
  }
  .ant-picker-calendar-mini .ant-picker-content {
    height: 256px;
  }
  .ant-picker-calendar-mini .ant-picker-content th {
    height: auto;
    line-height: 18px;
    padding: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel {
    background: #fff;
    border: 0;
    display: block;
    text-align: right;
    width: 100%;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: auto;
    line-height: 18px;
    padding: 0 12px 5px 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:before {
    display: none;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
    background: #f5f5f5;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today:before {
    display: none;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #e6f7ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value {
    color: #1890ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border: 0;
    border-radius: 0;
    border-top: 2px solid #f0f0f0;
    display: block;
    height: auto;
    margin: 0 4px;
    padding: 4px 8px 0;
    transition: background 0.3s;
    width: auto;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    line-height: 24px;
    transition: color 0.3s;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    color: rgba(0, 0, 0, 0.85);
    height: 86px;
    line-height: 1.5715;
    overflow-y: auto;
    position: static;
    text-align: left;
    width: auto;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #1890ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: rgba(0, 0, 0, 0.85);
  }
  @media only screen and (max-width: 480px) {
    .ant-picker-calendar-header {
      display: block;
    }
    .ant-picker-calendar-header .ant-picker-calendar-year-select {
      width: 50%;
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select {
      width: calc(50% - 8px);
    }
    .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
      margin-left: 0;
      margin-top: 8px;
      width: 100%;
    }
    .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
      text-align: center;
      width: 50%;
    }
  }
  .ant-picker-calendar-rtl {
    direction: rtl;
  }
  .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch,
  .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
    text-align: left;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0 0 5px 12px;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    text-align: right;
  }
  .ant-radio-group {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-size: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: unset;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-radio-group .ant-badge-count {
    z-index: 1;
  }
  .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
    border-left: none;
  }
  .ant-radio-wrapper {
    font-feature-settings: 'tnum';
    align-items: baseline;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0 8px 0 0;
    padding: 0;
    position: relative;
  }
  .ant-radio-wrapper:after {
    content: ' ';
    display: inline-block;
    overflow: hidden;
    width: 0;
  }
  .ant-radio {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    top: 0.2em;
  }
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner {
    border-color: #1890ff;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }
  .ant-radio-checked:after {
    -webkit-animation: antRadioEffect 0.36s ease-in-out;
    animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 1px solid #1890ff;
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
  }
  .ant-radio-wrapper:hover .ant-radio:after,
  .ant-radio:hover:after {
    visibility: visible;
  }
  .ant-radio-inner {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    display: block;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    transition: all 0.3s;
    width: 16px;
  }
  .ant-radio-inner:after {
    background-color: #1890ff;
    border-left: 0;
    border-radius: 8px;
    border-top: 0;
    content: ' ';
    display: block;
    height: 8px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    width: 8px;
  }
  .ant-radio-input {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
  }
  .ant-radio-checked .ant-radio-inner:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-radio-disabled {
    cursor: not-allowed;
  }
  .ant-radio-disabled .ant-radio-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
    cursor: not-allowed;
  }
  .ant-radio-disabled .ant-radio-inner:after {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ant-radio-disabled .ant-radio-input {
    cursor: not-allowed;
  }
  .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  span.ant-radio + * {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-radio-button-wrapper {
    background: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1.02px 1px 1px 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 32px;
    line-height: 30px;
    margin: 0;
    padding: 0 15px;
    position: relative;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  }
  .ant-radio-button-wrapper a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-radio-button-wrapper > .ant-radio-button {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .ant-radio-group-large .ant-radio-button-wrapper {
    font-size: 16px;
    height: 40px;
    line-height: 38px;
  }
  .ant-radio-group-small .ant-radio-button-wrapper {
    height: 24px;
    line-height: 22px;
    padding: 0 7px;
  }
  .ant-radio-button-wrapper:not(:first-child):before {
    background-color: #d9d9d9;
    box-sizing: initial;
    content: '';
    display: block;
    height: 100%;
    left: -1px;
    padding: 1px 0;
    position: absolute;
    top: -1px;
    transition: background-color 0.3s;
    width: 1px;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 2px 2px 0;
  }
  .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 2px;
  }
  .ant-radio-button-wrapper:hover {
    color: #1890ff;
    position: relative;
  }
  .ant-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }
  .ant-radio-button-wrapper .ant-radio-inner,
  .ant-radio-button-wrapper input[type='checkbox'],
  .ant-radio-button-wrapper input[type='radio'] {
    height: 0;
    opacity: 0;
    pointer-events: none;
    width: 0;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #fff;
    border-color: #1890ff;
    color: #1890ff;
    z-index: 1;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: #1890ff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #1890ff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: #40a9ff;
    color: #40a9ff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
    background-color: #40a9ff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    border-color: #096dd9;
    color: #096dd9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active:before {
    background-color: #096dd9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #40a9ff;
    border-color: #40a9ff;
    color: #fff;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    background: #096dd9;
    border-color: #096dd9;
    color: #fff;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }
  .ant-radio-button-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-radio-button-wrapper-disabled,
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #d9d9d9;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
  }
  @-webkit-keyframes antRadioEffect {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.6);
    }
  }
  @keyframes antRadioEffect {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.6);
    }
  }
  .ant-radio-group.ant-radio-group-rtl {
    direction: rtl;
  }
  .ant-radio-wrapper.ant-radio-wrapper-rtl {
    direction: rtl;
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
    border-left-width: 1px;
    border-right-width: 0;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child):before {
    left: 0;
    right: -1px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
    border-radius: 0 2px 2px 0;
    border-right: 1px solid #d9d9d9;
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #40a9ff;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
    border-radius: 2px 0 0 2px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
    border-right-color: #d9d9d9;
  }
  .ant-picker {
    font-feature-settings: 'tnum';
    align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 4px 11px;
    position: relative;
    transition: border 0.3s, box-shadow 0.3s;
  }
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-picker.ant-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker.ant-picker-borderless {
    background-color: initial !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input {
    align-items: center;
    display: inline-flex;
    position: relative;
    width: 100%;
  }
  .ant-picker-input > input {
    background-color: #fff;
    background-image: none;
    background: transparent;
    border: 0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    flex: auto;
    font-size: 14px;
    height: auto;
    line-height: 1.5715;
    min-width: 0;
    min-width: 1px;
    padding: 0;
    position: relative;
    transition: all 0.3s;
    width: 100%;
  }
  .ant-picker-input > input::-moz-placeholder {
    opacity: 1;
  }
  .ant-picker-input > input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input::placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input-focused,
  .ant-picker-input > input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-picker-input > input-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input-borderless,
  .ant-picker-input > input-borderless-disabled,
  .ant-picker-input > input-borderless-focused,
  .ant-picker-input > input-borderless:focus,
  .ant-picker-input > input-borderless:hover,
  .ant-picker-input > input-borderless[disabled] {
    background-color: initial;
    border: none;
    box-shadow: none;
  }
  textarea.ant-picker-input > input {
    height: auto;
    line-height: 1.5715;
    max-width: 100%;
    min-height: 32px;
    transition: all 0.3s, height 0s;
    vertical-align: bottom;
  }
  .ant-picker-input > input-lg {
    font-size: 16px;
    padding: 6.5px 11px;
  }
  .ant-picker-input > input-sm {
    padding: 0 7px;
  }
  .ant-picker-input > input:focus {
    box-shadow: none;
  }
  .ant-picker-input > input[disabled] {
    background: transparent;
  }
  .ant-picker-input:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-input-placeholder > input {
    color: #bfbfbf;
  }
  .ant-picker-large {
    padding: 6.5px 11px;
  }
  .ant-picker-large .ant-picker-input > input {
    font-size: 16px;
  }
  .ant-picker-small {
    padding: 0 7px;
  }
  .ant-picker-suffix {
    align-self: center;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    margin-left: 4px;
    pointer-events: none;
  }
  .ant-picker-suffix > * {
    vertical-align: top;
  }
  .ant-picker-clear {
    background: #fff;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    line-height: 1;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s, color 0.3s;
  }
  .ant-picker-clear > * {
    vertical-align: top;
  }
  .ant-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-separator {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
    display: inline-block;
    font-size: 16px;
    height: 16px;
    position: relative;
    vertical-align: top;
    width: 1em;
  }
  .ant-picker-focused .ant-picker-separator {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
    cursor: not-allowed;
  }
  .ant-picker-range {
    display: inline-flex;
    position: relative;
  }
  .ant-picker-range .ant-picker-clear {
    right: 11px;
  }
  .ant-picker-range:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #1890ff;
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-out;
  }
  .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    opacity: 1;
  }
  .ant-picker-range-separator {
    align-items: center;
    line-height: 1;
    padding: 0 8px;
  }
  .ant-picker-range.ant-picker-small .ant-picker-clear {
    right: 7px;
  }
  .ant-picker-range.ant-picker-small .ant-picker-active-bar {
    margin-left: 7px;
  }
  .ant-picker-dropdown {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1050;
  }
  .ant-picker-dropdown-hidden {
    display: none;
  }
  .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    display: block;
    top: 1.66666667px;
    transform: rotate(-45deg);
  }
  .ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
    bottom: 1.66666667px;
    display: block;
    transform: rotate(135deg);
  }
  .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
  .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight,
  .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
  .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
  }
  .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
  .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight,
  .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
  .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
  }
  .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
  .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
  }
  .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
  .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
  }
  .ant-picker-dropdown-range {
    padding: 6.66666667px 0;
  }
  .ant-picker-dropdown-range-hidden {
    display: none;
  }
  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    padding-top: 4px;
  }
  .ant-picker-ranges {
    line-height: 34px;
    list-style: none;
    margin-bottom: 0;
    overflow: hidden;
    padding: 4px 12px;
    text-align: left;
  }
  .ant-picker-ranges > li {
    display: inline-block;
  }
  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    background: #e6f7ff;
    border-color: #91d5ff;
    color: #1890ff;
    cursor: pointer;
  }
  .ant-picker-ranges .ant-picker-ok {
    float: right;
    margin-left: 8px;
  }
  .ant-picker-range-wrapper {
    display: flex;
  }
  .ant-picker-range-arrow {
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
    display: none;
    height: 10px;
    margin-left: 16.5px;
    position: absolute;
    transition: left 0.3s ease-out;
    width: 10px;
    z-index: 1;
  }
  .ant-picker-range-arrow:after {
    border-color: #fff #fff transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    height: 10px;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 10px;
  }
  .ant-picker-panel-container {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: margin 0.3s;
    vertical-align: top;
  }
  .ant-picker-panel-container .ant-picker-panels {
    direction: ltr;
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .ant-picker-panel-container .ant-picker-panel {
    background: transparent;
    border-radius: 0;
    border-width: 0 0 1px;
    vertical-align: top;
  }
  .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #f0f0f0;
  }
  .ant-picker-panel {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    display: inline-flex;
    flex-direction: column;
    outline: none;
    text-align: center;
  }
  .ant-picker-panel-focused {
    border-color: #1890ff;
  }
  .ant-picker-date-panel,
  .ant-picker-decade-panel,
  .ant-picker-month-panel,
  .ant-picker-quarter-panel,
  .ant-picker-time-panel,
  .ant-picker-week-panel,
  .ant-picker-year-panel {
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  .ant-picker-header {
    border-bottom: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    padding: 0 8px;
  }
  .ant-picker-header > * {
    flex: none;
  }
  .ant-picker-header button {
    background: transparent;
    border: 0;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    line-height: 40px;
    padding: 0;
    transition: color 0.3s;
  }
  .ant-picker-header > button {
    font-size: 14px;
    min-width: 1.6em;
  }
  .ant-picker-header > button:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-header-view {
    flex: auto;
    font-weight: 500;
    line-height: 40px;
  }
  .ant-picker-header-view button {
    color: inherit;
    font-weight: inherit;
  }
  .ant-picker-header-view button:not(:first-child) {
    margin-left: 8px;
  }
  .ant-picker-header-view button:hover {
    color: #1890ff;
  }
  .ant-picker-next-icon,
  .ant-picker-prev-icon,
  .ant-picker-super-next-icon,
  .ant-picker-super-prev-icon {
    display: inline-block;
    height: 7px;
    position: relative;
    width: 7px;
  }
  .ant-picker-next-icon:before,
  .ant-picker-prev-icon:before,
  .ant-picker-super-next-icon:before,
  .ant-picker-super-prev-icon:before {
    border: 0 solid;
    border-width: 1.5px 0 0 1.5px;
    content: '';
    display: inline-block;
    height: 7px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  .ant-picker-super-next-icon:after,
  .ant-picker-super-prev-icon:after {
    border: 0 solid;
    border-width: 1.5px 0 0 1.5px;
    content: '';
    display: inline-block;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 7px;
  }
  .ant-picker-prev-icon,
  .ant-picker-super-prev-icon {
    transform: rotate(-45deg);
  }
  .ant-picker-next-icon,
  .ant-picker-super-next-icon {
    transform: rotate(135deg);
  }
  .ant-picker-content {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }
  .ant-picker-content td,
  .ant-picker-content th {
    font-weight: 400;
    min-width: 24px;
    position: relative;
  }
  .ant-picker-content th {
    color: rgba(0, 0, 0, 0.85);
    height: 30px;
    line-height: 30px;
  }
  .ant-picker-cell {
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    padding: 3px 0;
  }
  .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-cell-disabled {
    cursor: not-allowed;
  }
  .ant-picker-cell:before {
    content: '';
    height: 24px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid #1890ff;
    border-radius: 2px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range {
    position: relative;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #1890ff;
    color: #fff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:before {
    left: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:before {
    right: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
    border-bottom: 1px dashed #7ec1ff;
    border-top: 1px dashed #7ec1ff;
    content: '';
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
  .ant-picker-cell-range-hover-end:after,
  .ant-picker-cell-range-hover-start:after,
  .ant-picker-cell-range-hover:after {
    left: 2px;
    right: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before {
    background: #cbe6ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
    background: #cbe6ff;
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
    left: 0;
    right: -6px;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
    left: -6px;
    right: 0;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
    right: 50%;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
    left: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
  .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
    border-bottom-left-radius: 2px;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    left: 6px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
    border-bottom-right-radius: 2px;
    border-right: 1px dashed #7ec1ff;
    border-top-right-radius: 2px;
    right: 6px;
  }
  .ant-picker-cell-disabled {
    pointer-events: none;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: transparent;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-cell-disabled:before {
    background: #f5f5f5;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner:before {
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-decade-panel .ant-picker-content,
  .ant-picker-month-panel .ant-picker-content,
  .ant-picker-quarter-panel .ant-picker-content,
  .ant-picker-year-panel .ant-picker-content {
    height: 264px;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-inner {
    padding: 0 8px;
  }
  .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-quarter-panel .ant-picker-content {
    height: 56px;
  }
  .ant-picker-footer {
    border-bottom: 1px solid transparent;
    line-height: 38px;
    min-width: 100%;
    text-align: center;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #f0f0f0;
  }
  .ant-picker-footer-extra {
    line-height: 38px;
    padding: 0 12px;
    text-align: left;
  }
  .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-now {
    text-align: left;
  }
  .ant-picker-today-btn {
    color: #1890ff;
  }
  .ant-picker-today-btn:hover {
    color: #40a9ff;
  }
  .ant-picker-today-btn:active {
    color: #096dd9;
  }
  .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner {
    padding: 0 4px;
  }
  .ant-picker-decade-panel .ant-picker-cell:before {
    display: none;
  }
  .ant-picker-month-panel .ant-picker-body,
  .ant-picker-quarter-panel .ant-picker-body,
  .ant-picker-year-panel .ant-picker-body {
    padding: 0 8px;
  }
  .ant-picker-month-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-inner {
    width: 60px;
  }
  .ant-picker-month-panel .ant-picker-cell-range-hover-start:after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after,
  .ant-picker-year-panel .ant-picker-cell-range-hover-start:after {
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px 0 0 2px;
    left: 14px;
  }
  .ant-picker-month-panel .ant-picker-cell-range-hover-end:after,
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start:after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after,
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start:after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after,
  .ant-picker-year-panel .ant-picker-cell-range-hover-end:after {
    border-radius: 0 2px 2px 0;
    border-right: 1px dashed #7ec1ff;
    right: 14px;
  }
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end:after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after,
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end:after {
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px 0 0 2px;
    left: 14px;
  }
  .ant-picker-week-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner {
    background: transparent !important;
  }
  .ant-picker-week-panel-row td {
    transition: background 0.3s;
  }
  .ant-picker-week-panel-row:hover td {
    background: #f5f5f5;
  }
  .ant-picker-week-panel-row-selected:hover td,
  .ant-picker-week-panel-row-selected td {
    background: #1890ff;
  }
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week,
  .ant-picker-week-panel-row-selected td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner:before,
  .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner:before {
    border-color: #fff;
  }
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner {
    color: #fff;
  }
  .ant-picker-date-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-date-panel .ant-picker-content {
    width: 252px;
  }
  .ant-picker-date-panel .ant-picker-content th {
    width: 36px;
  }
  .ant-picker-datetime-panel {
    display: flex;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-datetime-panel .ant-picker-date-panel,
  .ant-picker-datetime-panel .ant-picker-time-panel {
    transition: opacity 0.3s;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel,
  .ant-picker-datetime-panel-active .ant-picker-time-panel {
    opacity: 0.3;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel-active,
  .ant-picker-datetime-panel-active .ant-picker-time-panel-active {
    opacity: 1;
  }
  .ant-picker-time-panel {
    min-width: auto;
    width: auto;
  }
  .ant-picker-time-panel .ant-picker-content {
    display: flex;
    flex: auto;
    height: 224px;
  }
  .ant-picker-time-panel-column {
    flex: 1 0 auto;
    list-style: none;
    margin: 0;
    overflow-y: hidden;
    padding: 0;
    text-align: left;
    transition: background 0.3s;
    width: 56px;
  }
  .ant-picker-time-panel-column:after {
    content: '';
    display: block;
    height: 196px;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel-column:after {
    height: 198px;
  }
  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-time-panel-column-active {
    background: rgba(230, 247, 255, 0.2);
  }
  .ant-picker-time-panel-column:hover {
    overflow-y: auto;
  }
  .ant-picker-time-panel-column > li {
    margin: 0;
    padding: 0;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    border-radius: 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: block;
    height: 28px;
    line-height: 28px;
    margin: 0;
    padding: 0 0 0 14px;
    transition: background 0.3s;
    width: 100%;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: #f5f5f5;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #e6f7ff;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    background: transparent;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  :root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  :root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
    padding: 21px 0;
  }
  .ant-picker-rtl {
    direction: rtl;
  }
  .ant-picker-rtl .ant-picker-suffix {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-picker-rtl .ant-picker-clear {
    left: 0;
    right: auto;
  }
  .ant-picker-rtl .ant-picker-separator {
    transform: rotate(180deg);
  }
  .ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-clear {
    left: 11px;
    right: auto;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
    margin-left: 0;
    margin-right: 11px;
  }
  .ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
    margin-right: 7px;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges {
    text-align: right;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
    float: left;
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-picker-panel-rtl {
    direction: rtl;
  }
  .ant-picker-panel-rtl .ant-picker-prev-icon,
  .ant-picker-panel-rtl .ant-picker-super-prev-icon {
    transform: rotate(135deg);
  }
  .ant-picker-panel-rtl .ant-picker-next-icon,
  .ant-picker-panel-rtl .ant-picker-super-next-icon {
    transform: rotate(-45deg);
  }
  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 2px;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    min-width: 24px;
    position: relative;
    transition: background 0.3s, border 0.3s;
    z-index: 2;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:before {
    left: 0;
    right: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:before {
    left: 50%;
    right: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end:before {
    left: 50%;
    right: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
    left: -6px;
    right: 0;
  }
  .ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
    left: 0;
    right: -6px;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
    left: 50%;
    right: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
    left: 0;
    right: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child:after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 2px;
    border-left: none;
    border-right: 1px dashed #7ec1ff;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    left: 0;
    right: 6px;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child:after {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 0;
    border-left: 1px dashed #7ec1ff;
    border-right: none;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    left: 6px;
    right: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover):after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child:after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child:after {
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px;
    border-right: 1px dashed #7ec1ff;
    left: 6px;
    right: 6px;
  }
  .ant-picker-dropdown-rtl .ant-picker-footer-extra {
    direction: rtl;
    text-align: right;
  }
  .ant-picker-panel-rtl .ant-picker-time-panel {
    direction: ltr;
  }
  .ant-tag {
    font-feature-settings: 'tnum';
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    font-size: 12px;
    font-variant: tabular-nums;
    height: auto;
    line-height: 1.5715;
    line-height: 20px;
    list-style: none;
    margin: 0 8px 0 0;
    opacity: 1;
    padding: 0 7px;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tag > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }
  .ant-tag-close-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    font-size: 10px;
    margin-left: 3px;
    transition: all 0.3s;
  }
  .ant-tag-close-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tag-has-color {
    border-color: transparent;
  }
  .ant-tag-has-color,
  .ant-tag-has-color .anticon-close,
  .ant-tag-has-color .anticon-close:hover,
  .ant-tag-has-color a,
  .ant-tag-has-color a:hover {
    color: #fff;
  }
  .ant-tag-checkable {
    background-color: initial;
    border-color: transparent;
    cursor: pointer;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #1890ff;
  }
  .ant-tag-checkable-checked,
  .ant-tag-checkable:active {
    color: #fff;
  }
  .ant-tag-checkable-checked {
    background-color: #1890ff;
  }
  .ant-tag-checkable:active {
    background-color: #096dd9;
  }
  .ant-tag-hidden {
    display: none;
  }
  .ant-tag-pink {
    background: #fff0f6;
    border-color: #ffadd2;
    color: #c41d7f;
  }
  .ant-tag-pink-inverse {
    background: #eb2f96;
    border-color: #eb2f96;
    color: #fff;
  }
  .ant-tag-magenta {
    background: #fff0f6;
    border-color: #ffadd2;
    color: #c41d7f;
  }
  .ant-tag-magenta-inverse {
    background: #eb2f96;
    border-color: #eb2f96;
    color: #fff;
  }
  .ant-tag-red {
    background: #fff1f0;
    border-color: #ffa39e;
    color: #cf1322;
  }
  .ant-tag-red-inverse {
    background: #f5222d;
    border-color: #f5222d;
    color: #fff;
  }
  .ant-tag-volcano {
    background: #fff2e8;
    border-color: #ffbb96;
    color: #d4380d;
  }
  .ant-tag-volcano-inverse {
    background: #fa541c;
    border-color: #fa541c;
    color: #fff;
  }
  .ant-tag-orange {
    background: #fff7e6;
    border-color: #ffd591;
    color: #d46b08;
  }
  .ant-tag-orange-inverse {
    background: #fa8c16;
    border-color: #fa8c16;
    color: #fff;
  }
  .ant-tag-yellow {
    background: #feffe6;
    border-color: #fffb8f;
    color: #d4b106;
  }
  .ant-tag-yellow-inverse {
    background: #fadb14;
    border-color: #fadb14;
    color: #fff;
  }
  .ant-tag-gold {
    background: #fffbe6;
    border-color: #ffe58f;
    color: #d48806;
  }
  .ant-tag-gold-inverse {
    background: #faad14;
    border-color: #faad14;
    color: #fff;
  }
  .ant-tag-cyan {
    background: #e6fffb;
    border-color: #87e8de;
    color: #08979c;
  }
  .ant-tag-cyan-inverse {
    background: #13c2c2;
    border-color: #13c2c2;
    color: #fff;
  }
  .ant-tag-lime {
    background: #fcffe6;
    border-color: #eaff8f;
    color: #7cb305;
  }
  .ant-tag-lime-inverse {
    background: #a0d911;
    border-color: #a0d911;
    color: #fff;
  }
  .ant-tag-green {
    background: #f6ffed;
    border-color: #b7eb8f;
    color: #389e0d;
  }
  .ant-tag-green-inverse {
    background: #52c41a;
    border-color: #52c41a;
    color: #fff;
  }
  .ant-tag-blue {
    background: #e6f7ff;
    border-color: #91d5ff;
    color: #096dd9;
  }
  .ant-tag-blue-inverse {
    background: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }
  .ant-tag-geekblue {
    background: #f0f5ff;
    border-color: #adc6ff;
    color: #1d39c4;
  }
  .ant-tag-geekblue-inverse {
    background: #2f54eb;
    border-color: #2f54eb;
    color: #fff;
  }
  .ant-tag-purple {
    background: #f9f0ff;
    border-color: #d3adf7;
    color: #531dab;
  }
  .ant-tag-purple-inverse {
    background: #722ed1;
    border-color: #722ed1;
    color: #fff;
  }
  .ant-tag-success {
    background: #f6ffed;
    border-color: #b7eb8f;
    color: #52c41a;
  }
  .ant-tag-processing {
    background: #e6f7ff;
    border-color: #91d5ff;
    color: #1890ff;
  }
  .ant-tag-error {
    background: #fff1f0;
    border-color: #ffa39e;
    color: #f5222d;
  }
  .ant-tag-warning {
    background: #fff7e6;
    border-color: #ffd591;
    color: #fa8c16;
  }
  .ant-tag > .anticon + span,
  .ant-tag > span + .anticon {
    margin-left: 7px;
  }
  .ant-tag.ant-tag-rtl {
    direction: rtl;
    margin-left: 8px;
    margin-right: 0;
    text-align: right;
  }
  .ant-tag-rtl .ant-tag-close-icon {
    margin-left: 0;
    margin-right: 3px;
  }
  .ant-tag-rtl.ant-tag > .anticon + span,
  .ant-tag-rtl.ant-tag > span + .anticon {
    margin-left: 0;
    margin-right: 7px;
  }
  .ant-card {
    font-feature-settings: 'tnum';
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ant-card-rtl {
    direction: rtl;
  }
  .ant-card-hoverable {
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s;
  }
  .ant-card-hoverable:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
  .ant-card-bordered {
    border: 1px solid #f0f0f0;
  }
  .ant-card-head {
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: -1px;
    min-height: 48px;
    padding: 0 24px;
  }
  .ant-card-head:after,
  .ant-card-head:before {
    content: '';
    display: table;
  }
  .ant-card-head:after {
    clear: both;
  }
  .ant-card-head-wrapper {
    align-items: center;
    display: flex;
  }
  .ant-card-head-title {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    padding: 16px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-card-head-title > .ant-typography,
  .ant-card-head-title > .ant-typography-edit-content {
    left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  .ant-card-head .ant-tabs {
    clear: both;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -17px;
  }
  .ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-card-extra {
    color: rgba(0, 0, 0, 0.85);
    float: right;
    font-size: 14px;
    font-weight: 400;
    margin-left: auto;
    padding: 16px 0;
  }
  .ant-card-rtl .ant-card-extra {
    margin-left: 0;
    margin-right: auto;
  }
  .ant-card-body {
    padding: 24px;
  }
  .ant-card-body:after,
  .ant-card-body:before {
    content: '';
    display: table;
  }
  .ant-card-body:after {
    clear: both;
  }
  .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
    margin: -1px 0 0 -1px;
    padding: 0;
  }
  .ant-card-grid {
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, inset 1px 0 0 0 #f0f0f0, inset 0 1px 0 0 #f0f0f0;
    float: left;
    padding: 24px;
    transition: all 0.3s;
    width: 33.33%;
  }
  .ant-card-rtl .ant-card-grid {
    float: right;
  }
  .ant-card-grid-hoverable:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    position: relative;
    z-index: 1;
  }
  .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
    min-height: 32px;
    padding-bottom: 0;
  }
  .ant-card-contain-tabs > .ant-card-head .ant-card-extra {
    padding-bottom: 0;
  }
  .ant-card-bordered .ant-card-cover {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
  }
  .ant-card-cover > * {
    display: block;
    width: 100%;
  }
  .ant-card-cover img {
    border-radius: 2px 2px 0 0;
  }
  .ant-card-actions {
    background: #fff;
    border-top: 1px solid #f0f0f0;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-card-actions:after,
  .ant-card-actions:before {
    content: '';
    display: table;
  }
  .ant-card-actions:after {
    clear: both;
  }
  .ant-card-actions > li {
    color: rgba(0, 0, 0, 0.45);
    float: left;
    margin: 12px 0;
    text-align: center;
  }
  .ant-card-rtl .ant-card-actions > li {
    float: right;
  }
  .ant-card-actions > li > span {
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 1.5715;
    min-width: 32px;
    position: relative;
  }
  .ant-card-actions > li > span:hover {
    color: #1890ff;
    transition: color 0.3s;
  }
  .ant-card-actions > li > span > .anticon,
  .ant-card-actions > li > span a:not(.ant-btn) {
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
    line-height: 22px;
    transition: color 0.3s;
    width: 100%;
  }
  .ant-card-actions > li > span > .anticon:hover,
  .ant-card-actions > li > span a:not(.ant-btn):hover {
    color: #1890ff;
  }
  .ant-card-actions > li > span > .anticon {
    font-size: 16px;
    line-height: 22px;
  }
  .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid #f0f0f0;
  }
  .ant-card-rtl .ant-card-actions > li:not(:last-child) {
    border-left: 1px solid #f0f0f0;
    border-right: none;
  }
  .ant-card-type-inner .ant-card-head {
    background: #fafafa;
    padding: 0 24px;
  }
  .ant-card-type-inner .ant-card-head-title {
    font-size: 14px;
    padding: 12px 0;
  }
  .ant-card-type-inner .ant-card-body {
    padding: 16px 24px;
  }
  .ant-card-type-inner .ant-card-extra {
    padding: 13.5px 0;
  }
  .ant-card-meta {
    margin: -4px 0;
  }
  .ant-card-meta:after,
  .ant-card-meta:before {
    content: '';
    display: table;
  }
  .ant-card-meta:after {
    clear: both;
  }
  .ant-card-meta-avatar {
    float: left;
    padding-right: 16px;
  }
  .ant-card-rtl .ant-card-meta-avatar {
    float: right;
    padding-left: 16px;
    padding-right: 0;
  }
  .ant-card-meta-detail {
    overflow: hidden;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-card-meta-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-card-loading {
    overflow: hidden;
  }
  .ant-card-loading .ant-card-body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-card-loading-content p {
    margin: 0;
  }
  .ant-card-loading-block {
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
    background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    background-size: 600% 600%;
    border-radius: 2px;
    height: 14px;
    margin: 4px 0;
  }
  @-webkit-keyframes card-loading {
    0%,
    to {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  @keyframes card-loading {
    0%,
    to {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  .ant-card-small > .ant-card-head {
    font-size: 14px;
    min-height: 36px;
    padding: 0 12px;
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 8px 0;
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
    font-size: 14px;
    padding: 8px 0;
  }
  .ant-card-small > .ant-card-body {
    padding: 12px;
  }
  .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    padding: 8px 0;
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    padding: 16px 0;
  }
  .ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 16px;
  }
  .ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 7px 16px 6px;
  }
  .ant-tabs-rtl {
    direction: rtl;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 0 32px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
    margin-left: 0;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-left: 12px;
    margin-right: 0;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
    margin-left: -4px;
    margin-right: 8px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    margin: 0;
  }
  .ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
    order: 1;
  }
  .ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
    order: 0;
  }
  .ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
    order: 1;
  }
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 2px;
    margin-right: 0;
  }
  .ant-tabs-dropdown-rtl {
    direction: rtl;
  }
  .ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
    text-align: right;
  }
  .ant-tabs-bottom,
  .ant-tabs-top {
    flex-direction: column;
  }
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin: 0 0 16px;
  }
  .ant-tabs-bottom > .ant-tabs-nav:before,
  .ant-tabs-bottom > div > .ant-tabs-nav:before,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    border-bottom: 1px solid #f0f0f0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
    bottom: 0;
    top: 0;
    width: 30px;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
    left: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
    right: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after {
    opacity: 1;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    bottom: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0;
    margin-top: 16px;
    order: 1;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav:before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav:before {
    top: 0;
  }
  .ant-tabs-bottom > .ant-tabs-content-holder,
  .ant-tabs-bottom > div > .ant-tabs-content-holder {
    order: 0;
  }
  .ant-tabs-left > .ant-tabs-nav,
  .ant-tabs-left > div > .ant-tabs-nav,
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    flex-direction: column;
    min-width: 50px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 24px;
    text-align: center;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 16px 0 0;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex-direction: column;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
    height: 30px;
    left: 0;
    right: 0;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
    box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
    top: 0;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
    bottom: 0;
    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before {
    opacity: 1;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    width: 2px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: height 0.3s, top 0.3s;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
    flex: 1 0 auto;
    flex-direction: column;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
    right: 0;
  }
  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: 1px solid #f0f0f0;
    margin-left: -1px;
  }
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 24px;
  }
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    order: 1;
  }
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0;
  }
  .ant-tabs-right > .ant-tabs-content-holder,
  .ant-tabs-right > div > .ant-tabs-content-holder {
    border-right: 1px solid #f0f0f0;
    margin-right: -1px;
    order: 0;
  }
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-right: 24px;
  }
  .ant-tabs-dropdown {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    font-size: 14px;
    font-variant: tabular-nums;
    left: -9999px;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -9999px;
    z-index: 1050;
  }
  .ant-tabs-dropdown-hidden {
    display: none;
  }
  .ant-tabs-dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    list-style-type: none;
    margin: 0;
    max-height: 200px;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4px 0;
    text-align: left;
  }
  .ant-tabs-dropdown-menu-item {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    min-width: 120px;
    overflow: hidden;
    padding: 5px 12px;
    text-overflow: ellipsis;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .ant-tabs-dropdown-menu-item:hover {
    background: #f5f5f5;
  }
  .ant-tabs-dropdown-menu-item-disabled,
  .ant-tabs-dropdown-menu-item-disabled:hover {
    background: transparent;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: #fafafa;
    border: 1px solid #f0f0f0;
    margin: 0;
    padding: 8px 16px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff;
    color: #1890ff;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
    visibility: hidden;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 2px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 2px 0 0;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fff;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 0 2px 2px;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-top-color: #fff;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-top: 2px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 0 0 2px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-right-color: #fff;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 2px 2px 0;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-left-color: #fff;
  }
  .ant-tabs {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    align-items: center;
    display: flex;
    flex: none;
    position: relative;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    align-self: stretch;
    display: inline-block;
    display: flex;
    flex: auto;
    overflow: hidden;
    position: relative;
    transform: translate(0);
    white-space: nowrap;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap:before,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap:after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
    content: '';
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.3s;
    z-index: 1;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    display: flex;
    position: relative;
    transition: transform 0.3s;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    align-self: stretch;
    display: flex;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    pointer-events: none;
    position: absolute;
    visibility: hidden;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    background: transparent;
    border: 0;
    padding: 8px 16px;
    position: relative;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more:after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more:after {
    bottom: 0;
    content: '';
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(100%);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    cursor: pointer;
    min-width: 40px;
    outline: none;
    padding: 0 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
    color: #40a9ff;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
    color: #096dd9;
  }
  .ant-tabs-extra-content {
    flex: none;
  }
  .ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
  .ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
    justify-content: center;
  }
  .ant-tabs-ink-bar {
    background: #1890ff;
    pointer-events: none;
    position: absolute;
  }
  .ant-tabs-tab {
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    outline: none;
    padding: 12px 0;
    position: relative;
  }
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:active,
  .ant-tabs-tab-remove:focus {
    color: #096dd9;
  }
  .ant-tabs-tab-btn,
  .ant-tabs-tab-remove {
    outline: none;
    transition: all 0.3s;
  }
  .ant-tabs-tab-remove {
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    flex: none;
    font-size: 12px;
    margin-left: 8px;
    margin-right: -4px;
  }
  .ant-tabs-tab-remove:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tabs-tab:hover {
    color: #40a9ff;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    text-shadow: 0 0 0.25px currentColor;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    margin: 0;
  }
  .ant-tabs-tab .anticon {
    margin-right: 12px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 32px;
  }
  .ant-tabs-content {
    display: flex;
    width: 100%;
  }
  .ant-tabs-content-holder {
    flex: auto;
    min-height: 0;
    min-width: 0;
  }
  .ant-tabs-content-animated {
    transition: margin 0.3s;
  }
  .ant-tabs-tabpane {
    flex: none;
    outline: none;
    width: 100%;
  }
  .ant-row {
    flex-flow: row wrap;
  }
  .ant-row,
  .ant-row:after,
  .ant-row:before {
    display: flex;
  }
  .ant-row-no-wrap {
    flex-wrap: nowrap;
  }
  .ant-row-start {
    justify-content: flex-start;
  }
  .ant-row-center {
    justify-content: center;
  }
  .ant-row-end {
    justify-content: flex-end;
  }
  .ant-row-space-between {
    justify-content: space-between;
  }
  .ant-row-space-around {
    justify-content: space-around;
  }
  .ant-row-top {
    align-items: flex-start;
  }
  .ant-row-middle {
    align-items: center;
  }
  .ant-row-bottom {
    align-items: flex-end;
  }
  .ant-col {
    max-width: 100%;
    min-height: 1px;
    position: relative;
  }
  .ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-push-24 {
    left: 100%;
  }
  .ant-col-pull-24 {
    right: 100%;
  }
  .ant-col-offset-24 {
    margin-left: 100%;
  }
  .ant-col-order-24 {
    order: 24;
  }
  .ant-col-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-push-23 {
    left: 95.83333333%;
  }
  .ant-col-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-order-23 {
    order: 23;
  }
  .ant-col-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-push-22 {
    left: 91.66666667%;
  }
  .ant-col-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-order-22 {
    order: 22;
  }
  .ant-col-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-push-21 {
    left: 87.5%;
  }
  .ant-col-pull-21 {
    right: 87.5%;
  }
  .ant-col-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-order-21 {
    order: 21;
  }
  .ant-col-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-push-20 {
    left: 83.33333333%;
  }
  .ant-col-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-order-20 {
    order: 20;
  }
  .ant-col-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-push-19 {
    left: 79.16666667%;
  }
  .ant-col-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-order-19 {
    order: 19;
  }
  .ant-col-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-push-18 {
    left: 75%;
  }
  .ant-col-pull-18 {
    right: 75%;
  }
  .ant-col-offset-18 {
    margin-left: 75%;
  }
  .ant-col-order-18 {
    order: 18;
  }
  .ant-col-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-push-17 {
    left: 70.83333333%;
  }
  .ant-col-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-order-17 {
    order: 17;
  }
  .ant-col-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-push-16 {
    left: 66.66666667%;
  }
  .ant-col-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-order-16 {
    order: 16;
  }
  .ant-col-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-push-15 {
    left: 62.5%;
  }
  .ant-col-pull-15 {
    right: 62.5%;
  }
  .ant-col-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-order-15 {
    order: 15;
  }
  .ant-col-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-push-14 {
    left: 58.33333333%;
  }
  .ant-col-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-order-14 {
    order: 14;
  }
  .ant-col-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-push-13 {
    left: 54.16666667%;
  }
  .ant-col-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-order-13 {
    order: 13;
  }
  .ant-col-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-push-12 {
    left: 50%;
  }
  .ant-col-pull-12 {
    right: 50%;
  }
  .ant-col-offset-12 {
    margin-left: 50%;
  }
  .ant-col-order-12 {
    order: 12;
  }
  .ant-col-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-push-11 {
    left: 45.83333333%;
  }
  .ant-col-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-order-11 {
    order: 11;
  }
  .ant-col-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-push-10 {
    left: 41.66666667%;
  }
  .ant-col-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-order-10 {
    order: 10;
  }
  .ant-col-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-push-9 {
    left: 37.5%;
  }
  .ant-col-pull-9 {
    right: 37.5%;
  }
  .ant-col-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-order-9 {
    order: 9;
  }
  .ant-col-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-push-8 {
    left: 33.33333333%;
  }
  .ant-col-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-order-8 {
    order: 8;
  }
  .ant-col-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-push-7 {
    left: 29.16666667%;
  }
  .ant-col-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-order-7 {
    order: 7;
  }
  .ant-col-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-push-6 {
    left: 25%;
  }
  .ant-col-pull-6 {
    right: 25%;
  }
  .ant-col-offset-6 {
    margin-left: 25%;
  }
  .ant-col-order-6 {
    order: 6;
  }
  .ant-col-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-push-5 {
    left: 20.83333333%;
  }
  .ant-col-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-order-5 {
    order: 5;
  }
  .ant-col-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-push-4 {
    left: 16.66666667%;
  }
  .ant-col-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-order-4 {
    order: 4;
  }
  .ant-col-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-push-3 {
    left: 12.5%;
  }
  .ant-col-pull-3 {
    right: 12.5%;
  }
  .ant-col-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-order-3 {
    order: 3;
  }
  .ant-col-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-push-2 {
    left: 8.33333333%;
  }
  .ant-col-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-order-2 {
    order: 2;
  }
  .ant-col-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-push-1 {
    left: 4.16666667%;
  }
  .ant-col-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-order-1 {
    order: 1;
  }
  .ant-col-0 {
    display: none;
  }
  .ant-col-offset-0 {
    margin-left: 0;
  }
  .ant-col-order-0 {
    order: 0;
  }
  .ant-col-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-push-1.ant-col-rtl {
    left: auto;
    right: 4.16666667%;
  }
  .ant-col-pull-1.ant-col-rtl {
    left: 4.16666667%;
    right: auto;
  }
  .ant-col-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16666667%;
  }
  .ant-col-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333333%;
  }
  .ant-col-pull-2.ant-col-rtl {
    left: 8.33333333%;
    right: auto;
  }
  .ant-col-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333333%;
  }
  .ant-col-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }
  .ant-col-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }
  .ant-col-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }
  .ant-col-push-4.ant-col-rtl {
    left: auto;
    right: 16.66666667%;
  }
  .ant-col-pull-4.ant-col-rtl {
    left: 16.66666667%;
    right: auto;
  }
  .ant-col-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.66666667%;
  }
  .ant-col-push-5.ant-col-rtl {
    left: auto;
    right: 20.83333333%;
  }
  .ant-col-pull-5.ant-col-rtl {
    left: 20.83333333%;
    right: auto;
  }
  .ant-col-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.83333333%;
  }
  .ant-col-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }
  .ant-col-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }
  .ant-col-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }
  .ant-col-push-7.ant-col-rtl {
    left: auto;
    right: 29.16666667%;
  }
  .ant-col-pull-7.ant-col-rtl {
    left: 29.16666667%;
    right: auto;
  }
  .ant-col-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.16666667%;
  }
  .ant-col-push-8.ant-col-rtl {
    left: auto;
    right: 33.33333333%;
  }
  .ant-col-pull-8.ant-col-rtl {
    left: 33.33333333%;
    right: auto;
  }
  .ant-col-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.33333333%;
  }
  .ant-col-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }
  .ant-col-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }
  .ant-col-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }
  .ant-col-push-10.ant-col-rtl {
    left: auto;
    right: 41.66666667%;
  }
  .ant-col-pull-10.ant-col-rtl {
    left: 41.66666667%;
    right: auto;
  }
  .ant-col-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.66666667%;
  }
  .ant-col-push-11.ant-col-rtl {
    left: auto;
    right: 45.83333333%;
  }
  .ant-col-pull-11.ant-col-rtl {
    left: 45.83333333%;
    right: auto;
  }
  .ant-col-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.83333333%;
  }
  .ant-col-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }
  .ant-col-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }
  .ant-col-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }
  .ant-col-push-13.ant-col-rtl {
    left: auto;
    right: 54.16666667%;
  }
  .ant-col-pull-13.ant-col-rtl {
    left: 54.16666667%;
    right: auto;
  }
  .ant-col-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.16666667%;
  }
  .ant-col-push-14.ant-col-rtl {
    left: auto;
    right: 58.33333333%;
  }
  .ant-col-pull-14.ant-col-rtl {
    left: 58.33333333%;
    right: auto;
  }
  .ant-col-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.33333333%;
  }
  .ant-col-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }
  .ant-col-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }
  .ant-col-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }
  .ant-col-push-16.ant-col-rtl {
    left: auto;
    right: 66.66666667%;
  }
  .ant-col-pull-16.ant-col-rtl {
    left: 66.66666667%;
    right: auto;
  }
  .ant-col-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.66666667%;
  }
  .ant-col-push-17.ant-col-rtl {
    left: auto;
    right: 70.83333333%;
  }
  .ant-col-pull-17.ant-col-rtl {
    left: 70.83333333%;
    right: auto;
  }
  .ant-col-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.83333333%;
  }
  .ant-col-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }
  .ant-col-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }
  .ant-col-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }
  .ant-col-push-19.ant-col-rtl {
    left: auto;
    right: 79.16666667%;
  }
  .ant-col-pull-19.ant-col-rtl {
    left: 79.16666667%;
    right: auto;
  }
  .ant-col-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.16666667%;
  }
  .ant-col-push-20.ant-col-rtl {
    left: auto;
    right: 83.33333333%;
  }
  .ant-col-pull-20.ant-col-rtl {
    left: 83.33333333%;
    right: auto;
  }
  .ant-col-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.33333333%;
  }
  .ant-col-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }
  .ant-col-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }
  .ant-col-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }
  .ant-col-push-22.ant-col-rtl {
    left: auto;
    right: 91.66666667%;
  }
  .ant-col-pull-22.ant-col-rtl {
    left: 91.66666667%;
    right: auto;
  }
  .ant-col-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.66666667%;
  }
  .ant-col-push-23.ant-col-rtl {
    left: auto;
    right: 95.83333333%;
  }
  .ant-col-pull-23.ant-col-rtl {
    left: 95.83333333%;
    right: auto;
  }
  .ant-col-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.83333333%;
  }
  .ant-col-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }
  .ant-col-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }
  .ant-col-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
  .ant-col-xs-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-push-24 {
    left: 100%;
  }
  .ant-col-xs-pull-24 {
    right: 100%;
  }
  .ant-col-xs-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xs-order-24 {
    order: 24;
  }
  .ant-col-xs-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xs-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xs-order-23 {
    order: 23;
  }
  .ant-col-xs-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xs-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xs-order-22 {
    order: 22;
  }
  .ant-col-xs-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xs-push-21 {
    left: 87.5%;
  }
  .ant-col-xs-pull-21 {
    right: 87.5%;
  }
  .ant-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xs-order-21 {
    order: 21;
  }
  .ant-col-xs-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xs-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xs-order-20 {
    order: 20;
  }
  .ant-col-xs-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xs-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xs-order-19 {
    order: 19;
  }
  .ant-col-xs-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xs-push-18 {
    left: 75%;
  }
  .ant-col-xs-pull-18 {
    right: 75%;
  }
  .ant-col-xs-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xs-order-18 {
    order: 18;
  }
  .ant-col-xs-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xs-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xs-order-17 {
    order: 17;
  }
  .ant-col-xs-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xs-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xs-order-16 {
    order: 16;
  }
  .ant-col-xs-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xs-push-15 {
    left: 62.5%;
  }
  .ant-col-xs-pull-15 {
    right: 62.5%;
  }
  .ant-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xs-order-15 {
    order: 15;
  }
  .ant-col-xs-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xs-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xs-order-14 {
    order: 14;
  }
  .ant-col-xs-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xs-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xs-order-13 {
    order: 13;
  }
  .ant-col-xs-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xs-push-12 {
    left: 50%;
  }
  .ant-col-xs-pull-12 {
    right: 50%;
  }
  .ant-col-xs-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xs-order-12 {
    order: 12;
  }
  .ant-col-xs-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xs-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xs-order-11 {
    order: 11;
  }
  .ant-col-xs-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xs-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xs-order-10 {
    order: 10;
  }
  .ant-col-xs-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xs-push-9 {
    left: 37.5%;
  }
  .ant-col-xs-pull-9 {
    right: 37.5%;
  }
  .ant-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xs-order-9 {
    order: 9;
  }
  .ant-col-xs-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xs-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xs-order-8 {
    order: 8;
  }
  .ant-col-xs-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xs-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xs-order-7 {
    order: 7;
  }
  .ant-col-xs-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xs-push-6 {
    left: 25%;
  }
  .ant-col-xs-pull-6 {
    right: 25%;
  }
  .ant-col-xs-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xs-order-6 {
    order: 6;
  }
  .ant-col-xs-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xs-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xs-order-5 {
    order: 5;
  }
  .ant-col-xs-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xs-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xs-order-4 {
    order: 4;
  }
  .ant-col-xs-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xs-push-3 {
    left: 12.5%;
  }
  .ant-col-xs-pull-3 {
    right: 12.5%;
  }
  .ant-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xs-order-3 {
    order: 3;
  }
  .ant-col-xs-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xs-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xs-order-2 {
    order: 2;
  }
  .ant-col-xs-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xs-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xs-order-1 {
    order: 1;
  }
  .ant-col-xs-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xs-push-0 {
    left: auto;
  }
  .ant-col-xs-pull-0 {
    right: auto;
  }
  .ant-col-xs-offset-0 {
    margin-left: 0;
  }
  .ant-col-xs-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xs-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xs-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xs-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xs-push-1.ant-col-rtl {
    left: auto;
    right: 4.16666667%;
  }
  .ant-col-xs-pull-1.ant-col-rtl {
    left: 4.16666667%;
    right: auto;
  }
  .ant-col-xs-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16666667%;
  }
  .ant-col-xs-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333333%;
  }
  .ant-col-xs-pull-2.ant-col-rtl {
    left: 8.33333333%;
    right: auto;
  }
  .ant-col-xs-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333333%;
  }
  .ant-col-xs-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }
  .ant-col-xs-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }
  .ant-col-xs-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }
  .ant-col-xs-push-4.ant-col-rtl {
    left: auto;
    right: 16.66666667%;
  }
  .ant-col-xs-pull-4.ant-col-rtl {
    left: 16.66666667%;
    right: auto;
  }
  .ant-col-xs-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.66666667%;
  }
  .ant-col-xs-push-5.ant-col-rtl {
    left: auto;
    right: 20.83333333%;
  }
  .ant-col-xs-pull-5.ant-col-rtl {
    left: 20.83333333%;
    right: auto;
  }
  .ant-col-xs-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.83333333%;
  }
  .ant-col-xs-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }
  .ant-col-xs-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }
  .ant-col-xs-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }
  .ant-col-xs-push-7.ant-col-rtl {
    left: auto;
    right: 29.16666667%;
  }
  .ant-col-xs-pull-7.ant-col-rtl {
    left: 29.16666667%;
    right: auto;
  }
  .ant-col-xs-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.16666667%;
  }
  .ant-col-xs-push-8.ant-col-rtl {
    left: auto;
    right: 33.33333333%;
  }
  .ant-col-xs-pull-8.ant-col-rtl {
    left: 33.33333333%;
    right: auto;
  }
  .ant-col-xs-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.33333333%;
  }
  .ant-col-xs-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }
  .ant-col-xs-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }
  .ant-col-xs-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }
  .ant-col-xs-push-10.ant-col-rtl {
    left: auto;
    right: 41.66666667%;
  }
  .ant-col-xs-pull-10.ant-col-rtl {
    left: 41.66666667%;
    right: auto;
  }
  .ant-col-xs-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.66666667%;
  }
  .ant-col-xs-push-11.ant-col-rtl {
    left: auto;
    right: 45.83333333%;
  }
  .ant-col-xs-pull-11.ant-col-rtl {
    left: 45.83333333%;
    right: auto;
  }
  .ant-col-xs-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.83333333%;
  }
  .ant-col-xs-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }
  .ant-col-xs-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }
  .ant-col-xs-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }
  .ant-col-xs-push-13.ant-col-rtl {
    left: auto;
    right: 54.16666667%;
  }
  .ant-col-xs-pull-13.ant-col-rtl {
    left: 54.16666667%;
    right: auto;
  }
  .ant-col-xs-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.16666667%;
  }
  .ant-col-xs-push-14.ant-col-rtl {
    left: auto;
    right: 58.33333333%;
  }
  .ant-col-xs-pull-14.ant-col-rtl {
    left: 58.33333333%;
    right: auto;
  }
  .ant-col-xs-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.33333333%;
  }
  .ant-col-xs-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }
  .ant-col-xs-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }
  .ant-col-xs-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }
  .ant-col-xs-push-16.ant-col-rtl {
    left: auto;
    right: 66.66666667%;
  }
  .ant-col-xs-pull-16.ant-col-rtl {
    left: 66.66666667%;
    right: auto;
  }
  .ant-col-xs-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.66666667%;
  }
  .ant-col-xs-push-17.ant-col-rtl {
    left: auto;
    right: 70.83333333%;
  }
  .ant-col-xs-pull-17.ant-col-rtl {
    left: 70.83333333%;
    right: auto;
  }
  .ant-col-xs-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.83333333%;
  }
  .ant-col-xs-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }
  .ant-col-xs-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }
  .ant-col-xs-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }
  .ant-col-xs-push-19.ant-col-rtl {
    left: auto;
    right: 79.16666667%;
  }
  .ant-col-xs-pull-19.ant-col-rtl {
    left: 79.16666667%;
    right: auto;
  }
  .ant-col-xs-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.16666667%;
  }
  .ant-col-xs-push-20.ant-col-rtl {
    left: auto;
    right: 83.33333333%;
  }
  .ant-col-xs-pull-20.ant-col-rtl {
    left: 83.33333333%;
    right: auto;
  }
  .ant-col-xs-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.33333333%;
  }
  .ant-col-xs-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }
  .ant-col-xs-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }
  .ant-col-xs-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }
  .ant-col-xs-push-22.ant-col-rtl {
    left: auto;
    right: 91.66666667%;
  }
  .ant-col-xs-pull-22.ant-col-rtl {
    left: 91.66666667%;
    right: auto;
  }
  .ant-col-xs-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.66666667%;
  }
  .ant-col-xs-push-23.ant-col-rtl {
    left: auto;
    right: 95.83333333%;
  }
  .ant-col-xs-pull-23.ant-col-rtl {
    left: 95.83333333%;
    right: auto;
  }
  .ant-col-xs-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.83333333%;
  }
  .ant-col-xs-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }
  .ant-col-xs-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }
  .ant-col-xs-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
  @media (min-width: 576px) {
    .ant-col-sm-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-sm-push-24 {
      left: 100%;
    }
    .ant-col-sm-pull-24 {
      right: 100%;
    }
    .ant-col-sm-offset-24 {
      margin-left: 100%;
    }
    .ant-col-sm-order-24 {
      order: 24;
    }
    .ant-col-sm-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-sm-push-23 {
      left: 95.83333333%;
    }
    .ant-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-sm-order-23 {
      order: 23;
    }
    .ant-col-sm-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-sm-push-22 {
      left: 91.66666667%;
    }
    .ant-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-sm-order-22 {
      order: 22;
    }
    .ant-col-sm-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-sm-push-21 {
      left: 87.5%;
    }
    .ant-col-sm-pull-21 {
      right: 87.5%;
    }
    .ant-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-sm-order-21 {
      order: 21;
    }
    .ant-col-sm-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-sm-push-20 {
      left: 83.33333333%;
    }
    .ant-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-sm-order-20 {
      order: 20;
    }
    .ant-col-sm-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-sm-push-19 {
      left: 79.16666667%;
    }
    .ant-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-sm-order-19 {
      order: 19;
    }
    .ant-col-sm-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-sm-push-18 {
      left: 75%;
    }
    .ant-col-sm-pull-18 {
      right: 75%;
    }
    .ant-col-sm-offset-18 {
      margin-left: 75%;
    }
    .ant-col-sm-order-18 {
      order: 18;
    }
    .ant-col-sm-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-sm-push-17 {
      left: 70.83333333%;
    }
    .ant-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-sm-order-17 {
      order: 17;
    }
    .ant-col-sm-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-sm-push-16 {
      left: 66.66666667%;
    }
    .ant-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-sm-order-16 {
      order: 16;
    }
    .ant-col-sm-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-sm-push-15 {
      left: 62.5%;
    }
    .ant-col-sm-pull-15 {
      right: 62.5%;
    }
    .ant-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-sm-order-15 {
      order: 15;
    }
    .ant-col-sm-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-sm-push-14 {
      left: 58.33333333%;
    }
    .ant-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-sm-order-14 {
      order: 14;
    }
    .ant-col-sm-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-sm-push-13 {
      left: 54.16666667%;
    }
    .ant-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-sm-order-13 {
      order: 13;
    }
    .ant-col-sm-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-sm-push-12 {
      left: 50%;
    }
    .ant-col-sm-pull-12 {
      right: 50%;
    }
    .ant-col-sm-offset-12 {
      margin-left: 50%;
    }
    .ant-col-sm-order-12 {
      order: 12;
    }
    .ant-col-sm-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-sm-push-11 {
      left: 45.83333333%;
    }
    .ant-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-sm-order-11 {
      order: 11;
    }
    .ant-col-sm-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-sm-push-10 {
      left: 41.66666667%;
    }
    .ant-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-sm-order-10 {
      order: 10;
    }
    .ant-col-sm-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-sm-push-9 {
      left: 37.5%;
    }
    .ant-col-sm-pull-9 {
      right: 37.5%;
    }
    .ant-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-sm-order-9 {
      order: 9;
    }
    .ant-col-sm-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-sm-push-8 {
      left: 33.33333333%;
    }
    .ant-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-sm-order-8 {
      order: 8;
    }
    .ant-col-sm-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-sm-push-7 {
      left: 29.16666667%;
    }
    .ant-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-sm-order-7 {
      order: 7;
    }
    .ant-col-sm-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-sm-push-6 {
      left: 25%;
    }
    .ant-col-sm-pull-6 {
      right: 25%;
    }
    .ant-col-sm-offset-6 {
      margin-left: 25%;
    }
    .ant-col-sm-order-6 {
      order: 6;
    }
    .ant-col-sm-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-sm-push-5 {
      left: 20.83333333%;
    }
    .ant-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-sm-order-5 {
      order: 5;
    }
    .ant-col-sm-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-sm-push-4 {
      left: 16.66666667%;
    }
    .ant-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-sm-order-4 {
      order: 4;
    }
    .ant-col-sm-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-sm-push-3 {
      left: 12.5%;
    }
    .ant-col-sm-pull-3 {
      right: 12.5%;
    }
    .ant-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-sm-order-3 {
      order: 3;
    }
    .ant-col-sm-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-sm-push-2 {
      left: 8.33333333%;
    }
    .ant-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-sm-order-2 {
      order: 2;
    }
    .ant-col-sm-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-sm-push-1 {
      left: 4.16666667%;
    }
    .ant-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-sm-order-1 {
      order: 1;
    }
    .ant-col-sm-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-sm-push-0 {
      left: auto;
    }
    .ant-col-sm-pull-0 {
      right: auto;
    }
    .ant-col-sm-offset-0 {
      margin-left: 0;
    }
    .ant-col-sm-order-0 {
      order: 0;
    }
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-sm-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-sm-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-sm-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-sm-push-1.ant-col-rtl {
      left: auto;
      right: 4.16666667%;
    }
    .ant-col-sm-pull-1.ant-col-rtl {
      left: 4.16666667%;
      right: auto;
    }
    .ant-col-sm-offset-1.ant-col-rtl {
      margin-left: 0;
      margin-right: 4.16666667%;
    }
    .ant-col-sm-push-2.ant-col-rtl {
      left: auto;
      right: 8.33333333%;
    }
    .ant-col-sm-pull-2.ant-col-rtl {
      left: 8.33333333%;
      right: auto;
    }
    .ant-col-sm-offset-2.ant-col-rtl {
      margin-left: 0;
      margin-right: 8.33333333%;
    }
    .ant-col-sm-push-3.ant-col-rtl {
      left: auto;
      right: 12.5%;
    }
    .ant-col-sm-pull-3.ant-col-rtl {
      left: 12.5%;
      right: auto;
    }
    .ant-col-sm-offset-3.ant-col-rtl {
      margin-left: 0;
      margin-right: 12.5%;
    }
    .ant-col-sm-push-4.ant-col-rtl {
      left: auto;
      right: 16.66666667%;
    }
    .ant-col-sm-pull-4.ant-col-rtl {
      left: 16.66666667%;
      right: auto;
    }
    .ant-col-sm-offset-4.ant-col-rtl {
      margin-left: 0;
      margin-right: 16.66666667%;
    }
    .ant-col-sm-push-5.ant-col-rtl {
      left: auto;
      right: 20.83333333%;
    }
    .ant-col-sm-pull-5.ant-col-rtl {
      left: 20.83333333%;
      right: auto;
    }
    .ant-col-sm-offset-5.ant-col-rtl {
      margin-left: 0;
      margin-right: 20.83333333%;
    }
    .ant-col-sm-push-6.ant-col-rtl {
      left: auto;
      right: 25%;
    }
    .ant-col-sm-pull-6.ant-col-rtl {
      left: 25%;
      right: auto;
    }
    .ant-col-sm-offset-6.ant-col-rtl {
      margin-left: 0;
      margin-right: 25%;
    }
    .ant-col-sm-push-7.ant-col-rtl {
      left: auto;
      right: 29.16666667%;
    }
    .ant-col-sm-pull-7.ant-col-rtl {
      left: 29.16666667%;
      right: auto;
    }
    .ant-col-sm-offset-7.ant-col-rtl {
      margin-left: 0;
      margin-right: 29.16666667%;
    }
    .ant-col-sm-push-8.ant-col-rtl {
      left: auto;
      right: 33.33333333%;
    }
    .ant-col-sm-pull-8.ant-col-rtl {
      left: 33.33333333%;
      right: auto;
    }
    .ant-col-sm-offset-8.ant-col-rtl {
      margin-left: 0;
      margin-right: 33.33333333%;
    }
    .ant-col-sm-push-9.ant-col-rtl {
      left: auto;
      right: 37.5%;
    }
    .ant-col-sm-pull-9.ant-col-rtl {
      left: 37.5%;
      right: auto;
    }
    .ant-col-sm-offset-9.ant-col-rtl {
      margin-left: 0;
      margin-right: 37.5%;
    }
    .ant-col-sm-push-10.ant-col-rtl {
      left: auto;
      right: 41.66666667%;
    }
    .ant-col-sm-pull-10.ant-col-rtl {
      left: 41.66666667%;
      right: auto;
    }
    .ant-col-sm-offset-10.ant-col-rtl {
      margin-left: 0;
      margin-right: 41.66666667%;
    }
    .ant-col-sm-push-11.ant-col-rtl {
      left: auto;
      right: 45.83333333%;
    }
    .ant-col-sm-pull-11.ant-col-rtl {
      left: 45.83333333%;
      right: auto;
    }
    .ant-col-sm-offset-11.ant-col-rtl {
      margin-left: 0;
      margin-right: 45.83333333%;
    }
    .ant-col-sm-push-12.ant-col-rtl {
      left: auto;
      right: 50%;
    }
    .ant-col-sm-pull-12.ant-col-rtl {
      left: 50%;
      right: auto;
    }
    .ant-col-sm-offset-12.ant-col-rtl {
      margin-left: 0;
      margin-right: 50%;
    }
    .ant-col-sm-push-13.ant-col-rtl {
      left: auto;
      right: 54.16666667%;
    }
    .ant-col-sm-pull-13.ant-col-rtl {
      left: 54.16666667%;
      right: auto;
    }
    .ant-col-sm-offset-13.ant-col-rtl {
      margin-left: 0;
      margin-right: 54.16666667%;
    }
    .ant-col-sm-push-14.ant-col-rtl {
      left: auto;
      right: 58.33333333%;
    }
    .ant-col-sm-pull-14.ant-col-rtl {
      left: 58.33333333%;
      right: auto;
    }
    .ant-col-sm-offset-14.ant-col-rtl {
      margin-left: 0;
      margin-right: 58.33333333%;
    }
    .ant-col-sm-push-15.ant-col-rtl {
      left: auto;
      right: 62.5%;
    }
    .ant-col-sm-pull-15.ant-col-rtl {
      left: 62.5%;
      right: auto;
    }
    .ant-col-sm-offset-15.ant-col-rtl {
      margin-left: 0;
      margin-right: 62.5%;
    }
    .ant-col-sm-push-16.ant-col-rtl {
      left: auto;
      right: 66.66666667%;
    }
    .ant-col-sm-pull-16.ant-col-rtl {
      left: 66.66666667%;
      right: auto;
    }
    .ant-col-sm-offset-16.ant-col-rtl {
      margin-left: 0;
      margin-right: 66.66666667%;
    }
    .ant-col-sm-push-17.ant-col-rtl {
      left: auto;
      right: 70.83333333%;
    }
    .ant-col-sm-pull-17.ant-col-rtl {
      left: 70.83333333%;
      right: auto;
    }
    .ant-col-sm-offset-17.ant-col-rtl {
      margin-left: 0;
      margin-right: 70.83333333%;
    }
    .ant-col-sm-push-18.ant-col-rtl {
      left: auto;
      right: 75%;
    }
    .ant-col-sm-pull-18.ant-col-rtl {
      left: 75%;
      right: auto;
    }
    .ant-col-sm-offset-18.ant-col-rtl {
      margin-left: 0;
      margin-right: 75%;
    }
    .ant-col-sm-push-19.ant-col-rtl {
      left: auto;
      right: 79.16666667%;
    }
    .ant-col-sm-pull-19.ant-col-rtl {
      left: 79.16666667%;
      right: auto;
    }
    .ant-col-sm-offset-19.ant-col-rtl {
      margin-left: 0;
      margin-right: 79.16666667%;
    }
    .ant-col-sm-push-20.ant-col-rtl {
      left: auto;
      right: 83.33333333%;
    }
    .ant-col-sm-pull-20.ant-col-rtl {
      left: 83.33333333%;
      right: auto;
    }
    .ant-col-sm-offset-20.ant-col-rtl {
      margin-left: 0;
      margin-right: 83.33333333%;
    }
    .ant-col-sm-push-21.ant-col-rtl {
      left: auto;
      right: 87.5%;
    }
    .ant-col-sm-pull-21.ant-col-rtl {
      left: 87.5%;
      right: auto;
    }
    .ant-col-sm-offset-21.ant-col-rtl {
      margin-left: 0;
      margin-right: 87.5%;
    }
    .ant-col-sm-push-22.ant-col-rtl {
      left: auto;
      right: 91.66666667%;
    }
    .ant-col-sm-pull-22.ant-col-rtl {
      left: 91.66666667%;
      right: auto;
    }
    .ant-col-sm-offset-22.ant-col-rtl {
      margin-left: 0;
      margin-right: 91.66666667%;
    }
    .ant-col-sm-push-23.ant-col-rtl {
      left: auto;
      right: 95.83333333%;
    }
    .ant-col-sm-pull-23.ant-col-rtl {
      left: 95.83333333%;
      right: auto;
    }
    .ant-col-sm-offset-23.ant-col-rtl {
      margin-left: 0;
      margin-right: 95.83333333%;
    }
    .ant-col-sm-push-24.ant-col-rtl {
      left: auto;
      right: 100%;
    }
    .ant-col-sm-pull-24.ant-col-rtl {
      left: 100%;
      right: auto;
    }
    .ant-col-sm-offset-24.ant-col-rtl {
      margin-left: 0;
      margin-right: 100%;
    }
  }
  @media (min-width: 768px) {
    .ant-col-md-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-md-push-24 {
      left: 100%;
    }
    .ant-col-md-pull-24 {
      right: 100%;
    }
    .ant-col-md-offset-24 {
      margin-left: 100%;
    }
    .ant-col-md-order-24 {
      order: 24;
    }
    .ant-col-md-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-md-push-23 {
      left: 95.83333333%;
    }
    .ant-col-md-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-md-order-23 {
      order: 23;
    }
    .ant-col-md-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-md-push-22 {
      left: 91.66666667%;
    }
    .ant-col-md-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-md-order-22 {
      order: 22;
    }
    .ant-col-md-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-md-push-21 {
      left: 87.5%;
    }
    .ant-col-md-pull-21 {
      right: 87.5%;
    }
    .ant-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-md-order-21 {
      order: 21;
    }
    .ant-col-md-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-md-push-20 {
      left: 83.33333333%;
    }
    .ant-col-md-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-md-order-20 {
      order: 20;
    }
    .ant-col-md-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-md-push-19 {
      left: 79.16666667%;
    }
    .ant-col-md-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-md-order-19 {
      order: 19;
    }
    .ant-col-md-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-md-push-18 {
      left: 75%;
    }
    .ant-col-md-pull-18 {
      right: 75%;
    }
    .ant-col-md-offset-18 {
      margin-left: 75%;
    }
    .ant-col-md-order-18 {
      order: 18;
    }
    .ant-col-md-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-md-push-17 {
      left: 70.83333333%;
    }
    .ant-col-md-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-md-order-17 {
      order: 17;
    }
    .ant-col-md-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-md-push-16 {
      left: 66.66666667%;
    }
    .ant-col-md-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-md-order-16 {
      order: 16;
    }
    .ant-col-md-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-md-push-15 {
      left: 62.5%;
    }
    .ant-col-md-pull-15 {
      right: 62.5%;
    }
    .ant-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-md-order-15 {
      order: 15;
    }
    .ant-col-md-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-md-push-14 {
      left: 58.33333333%;
    }
    .ant-col-md-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-md-order-14 {
      order: 14;
    }
    .ant-col-md-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-md-push-13 {
      left: 54.16666667%;
    }
    .ant-col-md-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-md-order-13 {
      order: 13;
    }
    .ant-col-md-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-md-push-12 {
      left: 50%;
    }
    .ant-col-md-pull-12 {
      right: 50%;
    }
    .ant-col-md-offset-12 {
      margin-left: 50%;
    }
    .ant-col-md-order-12 {
      order: 12;
    }
    .ant-col-md-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-md-push-11 {
      left: 45.83333333%;
    }
    .ant-col-md-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-md-order-11 {
      order: 11;
    }
    .ant-col-md-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-md-push-10 {
      left: 41.66666667%;
    }
    .ant-col-md-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-md-order-10 {
      order: 10;
    }
    .ant-col-md-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-md-push-9 {
      left: 37.5%;
    }
    .ant-col-md-pull-9 {
      right: 37.5%;
    }
    .ant-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-md-order-9 {
      order: 9;
    }
    .ant-col-md-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-md-push-8 {
      left: 33.33333333%;
    }
    .ant-col-md-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-md-order-8 {
      order: 8;
    }
    .ant-col-md-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-md-push-7 {
      left: 29.16666667%;
    }
    .ant-col-md-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-md-order-7 {
      order: 7;
    }
    .ant-col-md-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-md-push-6 {
      left: 25%;
    }
    .ant-col-md-pull-6 {
      right: 25%;
    }
    .ant-col-md-offset-6 {
      margin-left: 25%;
    }
    .ant-col-md-order-6 {
      order: 6;
    }
    .ant-col-md-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-md-push-5 {
      left: 20.83333333%;
    }
    .ant-col-md-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-md-order-5 {
      order: 5;
    }
    .ant-col-md-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-md-push-4 {
      left: 16.66666667%;
    }
    .ant-col-md-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-md-order-4 {
      order: 4;
    }
    .ant-col-md-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-md-push-3 {
      left: 12.5%;
    }
    .ant-col-md-pull-3 {
      right: 12.5%;
    }
    .ant-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-md-order-3 {
      order: 3;
    }
    .ant-col-md-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-md-push-2 {
      left: 8.33333333%;
    }
    .ant-col-md-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-md-order-2 {
      order: 2;
    }
    .ant-col-md-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-md-push-1 {
      left: 4.16666667%;
    }
    .ant-col-md-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-md-order-1 {
      order: 1;
    }
    .ant-col-md-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-md-push-0 {
      left: auto;
    }
    .ant-col-md-pull-0 {
      right: auto;
    }
    .ant-col-md-offset-0 {
      margin-left: 0;
    }
    .ant-col-md-order-0 {
      order: 0;
    }
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-md-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-md-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-md-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-md-push-1.ant-col-rtl {
      left: auto;
      right: 4.16666667%;
    }
    .ant-col-md-pull-1.ant-col-rtl {
      left: 4.16666667%;
      right: auto;
    }
    .ant-col-md-offset-1.ant-col-rtl {
      margin-left: 0;
      margin-right: 4.16666667%;
    }
    .ant-col-md-push-2.ant-col-rtl {
      left: auto;
      right: 8.33333333%;
    }
    .ant-col-md-pull-2.ant-col-rtl {
      left: 8.33333333%;
      right: auto;
    }
    .ant-col-md-offset-2.ant-col-rtl {
      margin-left: 0;
      margin-right: 8.33333333%;
    }
    .ant-col-md-push-3.ant-col-rtl {
      left: auto;
      right: 12.5%;
    }
    .ant-col-md-pull-3.ant-col-rtl {
      left: 12.5%;
      right: auto;
    }
    .ant-col-md-offset-3.ant-col-rtl {
      margin-left: 0;
      margin-right: 12.5%;
    }
    .ant-col-md-push-4.ant-col-rtl {
      left: auto;
      right: 16.66666667%;
    }
    .ant-col-md-pull-4.ant-col-rtl {
      left: 16.66666667%;
      right: auto;
    }
    .ant-col-md-offset-4.ant-col-rtl {
      margin-left: 0;
      margin-right: 16.66666667%;
    }
    .ant-col-md-push-5.ant-col-rtl {
      left: auto;
      right: 20.83333333%;
    }
    .ant-col-md-pull-5.ant-col-rtl {
      left: 20.83333333%;
      right: auto;
    }
    .ant-col-md-offset-5.ant-col-rtl {
      margin-left: 0;
      margin-right: 20.83333333%;
    }
    .ant-col-md-push-6.ant-col-rtl {
      left: auto;
      right: 25%;
    }
    .ant-col-md-pull-6.ant-col-rtl {
      left: 25%;
      right: auto;
    }
    .ant-col-md-offset-6.ant-col-rtl {
      margin-left: 0;
      margin-right: 25%;
    }
    .ant-col-md-push-7.ant-col-rtl {
      left: auto;
      right: 29.16666667%;
    }
    .ant-col-md-pull-7.ant-col-rtl {
      left: 29.16666667%;
      right: auto;
    }
    .ant-col-md-offset-7.ant-col-rtl {
      margin-left: 0;
      margin-right: 29.16666667%;
    }
    .ant-col-md-push-8.ant-col-rtl {
      left: auto;
      right: 33.33333333%;
    }
    .ant-col-md-pull-8.ant-col-rtl {
      left: 33.33333333%;
      right: auto;
    }
    .ant-col-md-offset-8.ant-col-rtl {
      margin-left: 0;
      margin-right: 33.33333333%;
    }
    .ant-col-md-push-9.ant-col-rtl {
      left: auto;
      right: 37.5%;
    }
    .ant-col-md-pull-9.ant-col-rtl {
      left: 37.5%;
      right: auto;
    }
    .ant-col-md-offset-9.ant-col-rtl {
      margin-left: 0;
      margin-right: 37.5%;
    }
    .ant-col-md-push-10.ant-col-rtl {
      left: auto;
      right: 41.66666667%;
    }
    .ant-col-md-pull-10.ant-col-rtl {
      left: 41.66666667%;
      right: auto;
    }
    .ant-col-md-offset-10.ant-col-rtl {
      margin-left: 0;
      margin-right: 41.66666667%;
    }
    .ant-col-md-push-11.ant-col-rtl {
      left: auto;
      right: 45.83333333%;
    }
    .ant-col-md-pull-11.ant-col-rtl {
      left: 45.83333333%;
      right: auto;
    }
    .ant-col-md-offset-11.ant-col-rtl {
      margin-left: 0;
      margin-right: 45.83333333%;
    }
    .ant-col-md-push-12.ant-col-rtl {
      left: auto;
      right: 50%;
    }
    .ant-col-md-pull-12.ant-col-rtl {
      left: 50%;
      right: auto;
    }
    .ant-col-md-offset-12.ant-col-rtl {
      margin-left: 0;
      margin-right: 50%;
    }
    .ant-col-md-push-13.ant-col-rtl {
      left: auto;
      right: 54.16666667%;
    }
    .ant-col-md-pull-13.ant-col-rtl {
      left: 54.16666667%;
      right: auto;
    }
    .ant-col-md-offset-13.ant-col-rtl {
      margin-left: 0;
      margin-right: 54.16666667%;
    }
    .ant-col-md-push-14.ant-col-rtl {
      left: auto;
      right: 58.33333333%;
    }
    .ant-col-md-pull-14.ant-col-rtl {
      left: 58.33333333%;
      right: auto;
    }
    .ant-col-md-offset-14.ant-col-rtl {
      margin-left: 0;
      margin-right: 58.33333333%;
    }
    .ant-col-md-push-15.ant-col-rtl {
      left: auto;
      right: 62.5%;
    }
    .ant-col-md-pull-15.ant-col-rtl {
      left: 62.5%;
      right: auto;
    }
    .ant-col-md-offset-15.ant-col-rtl {
      margin-left: 0;
      margin-right: 62.5%;
    }
    .ant-col-md-push-16.ant-col-rtl {
      left: auto;
      right: 66.66666667%;
    }
    .ant-col-md-pull-16.ant-col-rtl {
      left: 66.66666667%;
      right: auto;
    }
    .ant-col-md-offset-16.ant-col-rtl {
      margin-left: 0;
      margin-right: 66.66666667%;
    }
    .ant-col-md-push-17.ant-col-rtl {
      left: auto;
      right: 70.83333333%;
    }
    .ant-col-md-pull-17.ant-col-rtl {
      left: 70.83333333%;
      right: auto;
    }
    .ant-col-md-offset-17.ant-col-rtl {
      margin-left: 0;
      margin-right: 70.83333333%;
    }
    .ant-col-md-push-18.ant-col-rtl {
      left: auto;
      right: 75%;
    }
    .ant-col-md-pull-18.ant-col-rtl {
      left: 75%;
      right: auto;
    }
    .ant-col-md-offset-18.ant-col-rtl {
      margin-left: 0;
      margin-right: 75%;
    }
    .ant-col-md-push-19.ant-col-rtl {
      left: auto;
      right: 79.16666667%;
    }
    .ant-col-md-pull-19.ant-col-rtl {
      left: 79.16666667%;
      right: auto;
    }
    .ant-col-md-offset-19.ant-col-rtl {
      margin-left: 0;
      margin-right: 79.16666667%;
    }
    .ant-col-md-push-20.ant-col-rtl {
      left: auto;
      right: 83.33333333%;
    }
    .ant-col-md-pull-20.ant-col-rtl {
      left: 83.33333333%;
      right: auto;
    }
    .ant-col-md-offset-20.ant-col-rtl {
      margin-left: 0;
      margin-right: 83.33333333%;
    }
    .ant-col-md-push-21.ant-col-rtl {
      left: auto;
      right: 87.5%;
    }
    .ant-col-md-pull-21.ant-col-rtl {
      left: 87.5%;
      right: auto;
    }
    .ant-col-md-offset-21.ant-col-rtl {
      margin-left: 0;
      margin-right: 87.5%;
    }
    .ant-col-md-push-22.ant-col-rtl {
      left: auto;
      right: 91.66666667%;
    }
    .ant-col-md-pull-22.ant-col-rtl {
      left: 91.66666667%;
      right: auto;
    }
    .ant-col-md-offset-22.ant-col-rtl {
      margin-left: 0;
      margin-right: 91.66666667%;
    }
    .ant-col-md-push-23.ant-col-rtl {
      left: auto;
      right: 95.83333333%;
    }
    .ant-col-md-pull-23.ant-col-rtl {
      left: 95.83333333%;
      right: auto;
    }
    .ant-col-md-offset-23.ant-col-rtl {
      margin-left: 0;
      margin-right: 95.83333333%;
    }
    .ant-col-md-push-24.ant-col-rtl {
      left: auto;
      right: 100%;
    }
    .ant-col-md-pull-24.ant-col-rtl {
      left: 100%;
      right: auto;
    }
    .ant-col-md-offset-24.ant-col-rtl {
      margin-left: 0;
      margin-right: 100%;
    }
  }
  @media (min-width: 992px) {
    .ant-col-lg-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-lg-push-24 {
      left: 100%;
    }
    .ant-col-lg-pull-24 {
      right: 100%;
    }
    .ant-col-lg-offset-24 {
      margin-left: 100%;
    }
    .ant-col-lg-order-24 {
      order: 24;
    }
    .ant-col-lg-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-lg-push-23 {
      left: 95.83333333%;
    }
    .ant-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-lg-order-23 {
      order: 23;
    }
    .ant-col-lg-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-lg-push-22 {
      left: 91.66666667%;
    }
    .ant-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-lg-order-22 {
      order: 22;
    }
    .ant-col-lg-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-lg-push-21 {
      left: 87.5%;
    }
    .ant-col-lg-pull-21 {
      right: 87.5%;
    }
    .ant-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-lg-order-21 {
      order: 21;
    }
    .ant-col-lg-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-lg-push-20 {
      left: 83.33333333%;
    }
    .ant-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-lg-order-20 {
      order: 20;
    }
    .ant-col-lg-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-lg-push-19 {
      left: 79.16666667%;
    }
    .ant-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-lg-order-19 {
      order: 19;
    }
    .ant-col-lg-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-lg-push-18 {
      left: 75%;
    }
    .ant-col-lg-pull-18 {
      right: 75%;
    }
    .ant-col-lg-offset-18 {
      margin-left: 75%;
    }
    .ant-col-lg-order-18 {
      order: 18;
    }
    .ant-col-lg-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-lg-push-17 {
      left: 70.83333333%;
    }
    .ant-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-lg-order-17 {
      order: 17;
    }
    .ant-col-lg-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-lg-push-16 {
      left: 66.66666667%;
    }
    .ant-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-lg-order-16 {
      order: 16;
    }
    .ant-col-lg-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-lg-push-15 {
      left: 62.5%;
    }
    .ant-col-lg-pull-15 {
      right: 62.5%;
    }
    .ant-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-lg-order-15 {
      order: 15;
    }
    .ant-col-lg-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-lg-push-14 {
      left: 58.33333333%;
    }
    .ant-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-lg-order-14 {
      order: 14;
    }
    .ant-col-lg-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-lg-push-13 {
      left: 54.16666667%;
    }
    .ant-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-lg-order-13 {
      order: 13;
    }
    .ant-col-lg-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-lg-push-12 {
      left: 50%;
    }
    .ant-col-lg-pull-12 {
      right: 50%;
    }
    .ant-col-lg-offset-12 {
      margin-left: 50%;
    }
    .ant-col-lg-order-12 {
      order: 12;
    }
    .ant-col-lg-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-lg-push-11 {
      left: 45.83333333%;
    }
    .ant-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-lg-order-11 {
      order: 11;
    }
    .ant-col-lg-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-lg-push-10 {
      left: 41.66666667%;
    }
    .ant-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-lg-order-10 {
      order: 10;
    }
    .ant-col-lg-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-lg-push-9 {
      left: 37.5%;
    }
    .ant-col-lg-pull-9 {
      right: 37.5%;
    }
    .ant-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-lg-order-9 {
      order: 9;
    }
    .ant-col-lg-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-lg-push-8 {
      left: 33.33333333%;
    }
    .ant-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-lg-order-8 {
      order: 8;
    }
    .ant-col-lg-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-lg-push-7 {
      left: 29.16666667%;
    }
    .ant-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-lg-order-7 {
      order: 7;
    }
    .ant-col-lg-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-lg-push-6 {
      left: 25%;
    }
    .ant-col-lg-pull-6 {
      right: 25%;
    }
    .ant-col-lg-offset-6 {
      margin-left: 25%;
    }
    .ant-col-lg-order-6 {
      order: 6;
    }
    .ant-col-lg-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-lg-push-5 {
      left: 20.83333333%;
    }
    .ant-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-lg-order-5 {
      order: 5;
    }
    .ant-col-lg-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-lg-push-4 {
      left: 16.66666667%;
    }
    .ant-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-lg-order-4 {
      order: 4;
    }
    .ant-col-lg-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-lg-push-3 {
      left: 12.5%;
    }
    .ant-col-lg-pull-3 {
      right: 12.5%;
    }
    .ant-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-lg-order-3 {
      order: 3;
    }
    .ant-col-lg-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-lg-push-2 {
      left: 8.33333333%;
    }
    .ant-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-lg-order-2 {
      order: 2;
    }
    .ant-col-lg-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-lg-push-1 {
      left: 4.16666667%;
    }
    .ant-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-lg-order-1 {
      order: 1;
    }
    .ant-col-lg-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-lg-push-0 {
      left: auto;
    }
    .ant-col-lg-pull-0 {
      right: auto;
    }
    .ant-col-lg-offset-0 {
      margin-left: 0;
    }
    .ant-col-lg-order-0 {
      order: 0;
    }
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-lg-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-lg-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-lg-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-lg-push-1.ant-col-rtl {
      left: auto;
      right: 4.16666667%;
    }
    .ant-col-lg-pull-1.ant-col-rtl {
      left: 4.16666667%;
      right: auto;
    }
    .ant-col-lg-offset-1.ant-col-rtl {
      margin-left: 0;
      margin-right: 4.16666667%;
    }
    .ant-col-lg-push-2.ant-col-rtl {
      left: auto;
      right: 8.33333333%;
    }
    .ant-col-lg-pull-2.ant-col-rtl {
      left: 8.33333333%;
      right: auto;
    }
    .ant-col-lg-offset-2.ant-col-rtl {
      margin-left: 0;
      margin-right: 8.33333333%;
    }
    .ant-col-lg-push-3.ant-col-rtl {
      left: auto;
      right: 12.5%;
    }
    .ant-col-lg-pull-3.ant-col-rtl {
      left: 12.5%;
      right: auto;
    }
    .ant-col-lg-offset-3.ant-col-rtl {
      margin-left: 0;
      margin-right: 12.5%;
    }
    .ant-col-lg-push-4.ant-col-rtl {
      left: auto;
      right: 16.66666667%;
    }
    .ant-col-lg-pull-4.ant-col-rtl {
      left: 16.66666667%;
      right: auto;
    }
    .ant-col-lg-offset-4.ant-col-rtl {
      margin-left: 0;
      margin-right: 16.66666667%;
    }
    .ant-col-lg-push-5.ant-col-rtl {
      left: auto;
      right: 20.83333333%;
    }
    .ant-col-lg-pull-5.ant-col-rtl {
      left: 20.83333333%;
      right: auto;
    }
    .ant-col-lg-offset-5.ant-col-rtl {
      margin-left: 0;
      margin-right: 20.83333333%;
    }
    .ant-col-lg-push-6.ant-col-rtl {
      left: auto;
      right: 25%;
    }
    .ant-col-lg-pull-6.ant-col-rtl {
      left: 25%;
      right: auto;
    }
    .ant-col-lg-offset-6.ant-col-rtl {
      margin-left: 0;
      margin-right: 25%;
    }
    .ant-col-lg-push-7.ant-col-rtl {
      left: auto;
      right: 29.16666667%;
    }
    .ant-col-lg-pull-7.ant-col-rtl {
      left: 29.16666667%;
      right: auto;
    }
    .ant-col-lg-offset-7.ant-col-rtl {
      margin-left: 0;
      margin-right: 29.16666667%;
    }
    .ant-col-lg-push-8.ant-col-rtl {
      left: auto;
      right: 33.33333333%;
    }
    .ant-col-lg-pull-8.ant-col-rtl {
      left: 33.33333333%;
      right: auto;
    }
    .ant-col-lg-offset-8.ant-col-rtl {
      margin-left: 0;
      margin-right: 33.33333333%;
    }
    .ant-col-lg-push-9.ant-col-rtl {
      left: auto;
      right: 37.5%;
    }
    .ant-col-lg-pull-9.ant-col-rtl {
      left: 37.5%;
      right: auto;
    }
    .ant-col-lg-offset-9.ant-col-rtl {
      margin-left: 0;
      margin-right: 37.5%;
    }
    .ant-col-lg-push-10.ant-col-rtl {
      left: auto;
      right: 41.66666667%;
    }
    .ant-col-lg-pull-10.ant-col-rtl {
      left: 41.66666667%;
      right: auto;
    }
    .ant-col-lg-offset-10.ant-col-rtl {
      margin-left: 0;
      margin-right: 41.66666667%;
    }
    .ant-col-lg-push-11.ant-col-rtl {
      left: auto;
      right: 45.83333333%;
    }
    .ant-col-lg-pull-11.ant-col-rtl {
      left: 45.83333333%;
      right: auto;
    }
    .ant-col-lg-offset-11.ant-col-rtl {
      margin-left: 0;
      margin-right: 45.83333333%;
    }
    .ant-col-lg-push-12.ant-col-rtl {
      left: auto;
      right: 50%;
    }
    .ant-col-lg-pull-12.ant-col-rtl {
      left: 50%;
      right: auto;
    }
    .ant-col-lg-offset-12.ant-col-rtl {
      margin-left: 0;
      margin-right: 50%;
    }
    .ant-col-lg-push-13.ant-col-rtl {
      left: auto;
      right: 54.16666667%;
    }
    .ant-col-lg-pull-13.ant-col-rtl {
      left: 54.16666667%;
      right: auto;
    }
    .ant-col-lg-offset-13.ant-col-rtl {
      margin-left: 0;
      margin-right: 54.16666667%;
    }
    .ant-col-lg-push-14.ant-col-rtl {
      left: auto;
      right: 58.33333333%;
    }
    .ant-col-lg-pull-14.ant-col-rtl {
      left: 58.33333333%;
      right: auto;
    }
    .ant-col-lg-offset-14.ant-col-rtl {
      margin-left: 0;
      margin-right: 58.33333333%;
    }
    .ant-col-lg-push-15.ant-col-rtl {
      left: auto;
      right: 62.5%;
    }
    .ant-col-lg-pull-15.ant-col-rtl {
      left: 62.5%;
      right: auto;
    }
    .ant-col-lg-offset-15.ant-col-rtl {
      margin-left: 0;
      margin-right: 62.5%;
    }
    .ant-col-lg-push-16.ant-col-rtl {
      left: auto;
      right: 66.66666667%;
    }
    .ant-col-lg-pull-16.ant-col-rtl {
      left: 66.66666667%;
      right: auto;
    }
    .ant-col-lg-offset-16.ant-col-rtl {
      margin-left: 0;
      margin-right: 66.66666667%;
    }
    .ant-col-lg-push-17.ant-col-rtl {
      left: auto;
      right: 70.83333333%;
    }
    .ant-col-lg-pull-17.ant-col-rtl {
      left: 70.83333333%;
      right: auto;
    }
    .ant-col-lg-offset-17.ant-col-rtl {
      margin-left: 0;
      margin-right: 70.83333333%;
    }
    .ant-col-lg-push-18.ant-col-rtl {
      left: auto;
      right: 75%;
    }
    .ant-col-lg-pull-18.ant-col-rtl {
      left: 75%;
      right: auto;
    }
    .ant-col-lg-offset-18.ant-col-rtl {
      margin-left: 0;
      margin-right: 75%;
    }
    .ant-col-lg-push-19.ant-col-rtl {
      left: auto;
      right: 79.16666667%;
    }
    .ant-col-lg-pull-19.ant-col-rtl {
      left: 79.16666667%;
      right: auto;
    }
    .ant-col-lg-offset-19.ant-col-rtl {
      margin-left: 0;
      margin-right: 79.16666667%;
    }
    .ant-col-lg-push-20.ant-col-rtl {
      left: auto;
      right: 83.33333333%;
    }
    .ant-col-lg-pull-20.ant-col-rtl {
      left: 83.33333333%;
      right: auto;
    }
    .ant-col-lg-offset-20.ant-col-rtl {
      margin-left: 0;
      margin-right: 83.33333333%;
    }
    .ant-col-lg-push-21.ant-col-rtl {
      left: auto;
      right: 87.5%;
    }
    .ant-col-lg-pull-21.ant-col-rtl {
      left: 87.5%;
      right: auto;
    }
    .ant-col-lg-offset-21.ant-col-rtl {
      margin-left: 0;
      margin-right: 87.5%;
    }
    .ant-col-lg-push-22.ant-col-rtl {
      left: auto;
      right: 91.66666667%;
    }
    .ant-col-lg-pull-22.ant-col-rtl {
      left: 91.66666667%;
      right: auto;
    }
    .ant-col-lg-offset-22.ant-col-rtl {
      margin-left: 0;
      margin-right: 91.66666667%;
    }
    .ant-col-lg-push-23.ant-col-rtl {
      left: auto;
      right: 95.83333333%;
    }
    .ant-col-lg-pull-23.ant-col-rtl {
      left: 95.83333333%;
      right: auto;
    }
    .ant-col-lg-offset-23.ant-col-rtl {
      margin-left: 0;
      margin-right: 95.83333333%;
    }
    .ant-col-lg-push-24.ant-col-rtl {
      left: auto;
      right: 100%;
    }
    .ant-col-lg-pull-24.ant-col-rtl {
      left: 100%;
      right: auto;
    }
    .ant-col-lg-offset-24.ant-col-rtl {
      margin-left: 0;
      margin-right: 100%;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xl-push-24 {
      left: 100%;
    }
    .ant-col-xl-pull-24 {
      right: 100%;
    }
    .ant-col-xl-offset-24 {
      margin-left: 100%;
    }
    .ant-col-xl-order-24 {
      order: 24;
    }
    .ant-col-xl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-xl-push-23 {
      left: 95.83333333%;
    }
    .ant-col-xl-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-xl-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-xl-order-23 {
      order: 23;
    }
    .ant-col-xl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-xl-push-22 {
      left: 91.66666667%;
    }
    .ant-col-xl-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-xl-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-xl-order-22 {
      order: 22;
    }
    .ant-col-xl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-xl-push-21 {
      left: 87.5%;
    }
    .ant-col-xl-pull-21 {
      right: 87.5%;
    }
    .ant-col-xl-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-xl-order-21 {
      order: 21;
    }
    .ant-col-xl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-xl-push-20 {
      left: 83.33333333%;
    }
    .ant-col-xl-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-xl-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-xl-order-20 {
      order: 20;
    }
    .ant-col-xl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-xl-push-19 {
      left: 79.16666667%;
    }
    .ant-col-xl-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-xl-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-xl-order-19 {
      order: 19;
    }
    .ant-col-xl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-xl-push-18 {
      left: 75%;
    }
    .ant-col-xl-pull-18 {
      right: 75%;
    }
    .ant-col-xl-offset-18 {
      margin-left: 75%;
    }
    .ant-col-xl-order-18 {
      order: 18;
    }
    .ant-col-xl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-xl-push-17 {
      left: 70.83333333%;
    }
    .ant-col-xl-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-xl-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-xl-order-17 {
      order: 17;
    }
    .ant-col-xl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-xl-push-16 {
      left: 66.66666667%;
    }
    .ant-col-xl-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-xl-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-xl-order-16 {
      order: 16;
    }
    .ant-col-xl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-xl-push-15 {
      left: 62.5%;
    }
    .ant-col-xl-pull-15 {
      right: 62.5%;
    }
    .ant-col-xl-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-xl-order-15 {
      order: 15;
    }
    .ant-col-xl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-xl-push-14 {
      left: 58.33333333%;
    }
    .ant-col-xl-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-xl-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-xl-order-14 {
      order: 14;
    }
    .ant-col-xl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-xl-push-13 {
      left: 54.16666667%;
    }
    .ant-col-xl-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-xl-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-xl-order-13 {
      order: 13;
    }
    .ant-col-xl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-xl-push-12 {
      left: 50%;
    }
    .ant-col-xl-pull-12 {
      right: 50%;
    }
    .ant-col-xl-offset-12 {
      margin-left: 50%;
    }
    .ant-col-xl-order-12 {
      order: 12;
    }
    .ant-col-xl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-xl-push-11 {
      left: 45.83333333%;
    }
    .ant-col-xl-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-xl-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-xl-order-11 {
      order: 11;
    }
    .ant-col-xl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-xl-push-10 {
      left: 41.66666667%;
    }
    .ant-col-xl-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-xl-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-xl-order-10 {
      order: 10;
    }
    .ant-col-xl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-xl-push-9 {
      left: 37.5%;
    }
    .ant-col-xl-pull-9 {
      right: 37.5%;
    }
    .ant-col-xl-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-xl-order-9 {
      order: 9;
    }
    .ant-col-xl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-xl-push-8 {
      left: 33.33333333%;
    }
    .ant-col-xl-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-xl-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-xl-order-8 {
      order: 8;
    }
    .ant-col-xl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-xl-push-7 {
      left: 29.16666667%;
    }
    .ant-col-xl-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-xl-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-xl-order-7 {
      order: 7;
    }
    .ant-col-xl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-xl-push-6 {
      left: 25%;
    }
    .ant-col-xl-pull-6 {
      right: 25%;
    }
    .ant-col-xl-offset-6 {
      margin-left: 25%;
    }
    .ant-col-xl-order-6 {
      order: 6;
    }
    .ant-col-xl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-xl-push-5 {
      left: 20.83333333%;
    }
    .ant-col-xl-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-xl-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-xl-order-5 {
      order: 5;
    }
    .ant-col-xl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-xl-push-4 {
      left: 16.66666667%;
    }
    .ant-col-xl-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-xl-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-xl-order-4 {
      order: 4;
    }
    .ant-col-xl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-xl-push-3 {
      left: 12.5%;
    }
    .ant-col-xl-pull-3 {
      right: 12.5%;
    }
    .ant-col-xl-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-xl-order-3 {
      order: 3;
    }
    .ant-col-xl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-xl-push-2 {
      left: 8.33333333%;
    }
    .ant-col-xl-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-xl-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-xl-order-2 {
      order: 2;
    }
    .ant-col-xl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-xl-push-1 {
      left: 4.16666667%;
    }
    .ant-col-xl-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-xl-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-xl-order-1 {
      order: 1;
    }
    .ant-col-xl-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-xl-push-0 {
      left: auto;
    }
    .ant-col-xl-pull-0 {
      right: auto;
    }
    .ant-col-xl-offset-0 {
      margin-left: 0;
    }
    .ant-col-xl-order-0 {
      order: 0;
    }
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xl-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-xl-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xl-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-xl-push-1.ant-col-rtl {
      left: auto;
      right: 4.16666667%;
    }
    .ant-col-xl-pull-1.ant-col-rtl {
      left: 4.16666667%;
      right: auto;
    }
    .ant-col-xl-offset-1.ant-col-rtl {
      margin-left: 0;
      margin-right: 4.16666667%;
    }
    .ant-col-xl-push-2.ant-col-rtl {
      left: auto;
      right: 8.33333333%;
    }
    .ant-col-xl-pull-2.ant-col-rtl {
      left: 8.33333333%;
      right: auto;
    }
    .ant-col-xl-offset-2.ant-col-rtl {
      margin-left: 0;
      margin-right: 8.33333333%;
    }
    .ant-col-xl-push-3.ant-col-rtl {
      left: auto;
      right: 12.5%;
    }
    .ant-col-xl-pull-3.ant-col-rtl {
      left: 12.5%;
      right: auto;
    }
    .ant-col-xl-offset-3.ant-col-rtl {
      margin-left: 0;
      margin-right: 12.5%;
    }
    .ant-col-xl-push-4.ant-col-rtl {
      left: auto;
      right: 16.66666667%;
    }
    .ant-col-xl-pull-4.ant-col-rtl {
      left: 16.66666667%;
      right: auto;
    }
    .ant-col-xl-offset-4.ant-col-rtl {
      margin-left: 0;
      margin-right: 16.66666667%;
    }
    .ant-col-xl-push-5.ant-col-rtl {
      left: auto;
      right: 20.83333333%;
    }
    .ant-col-xl-pull-5.ant-col-rtl {
      left: 20.83333333%;
      right: auto;
    }
    .ant-col-xl-offset-5.ant-col-rtl {
      margin-left: 0;
      margin-right: 20.83333333%;
    }
    .ant-col-xl-push-6.ant-col-rtl {
      left: auto;
      right: 25%;
    }
    .ant-col-xl-pull-6.ant-col-rtl {
      left: 25%;
      right: auto;
    }
    .ant-col-xl-offset-6.ant-col-rtl {
      margin-left: 0;
      margin-right: 25%;
    }
    .ant-col-xl-push-7.ant-col-rtl {
      left: auto;
      right: 29.16666667%;
    }
    .ant-col-xl-pull-7.ant-col-rtl {
      left: 29.16666667%;
      right: auto;
    }
    .ant-col-xl-offset-7.ant-col-rtl {
      margin-left: 0;
      margin-right: 29.16666667%;
    }
    .ant-col-xl-push-8.ant-col-rtl {
      left: auto;
      right: 33.33333333%;
    }
    .ant-col-xl-pull-8.ant-col-rtl {
      left: 33.33333333%;
      right: auto;
    }
    .ant-col-xl-offset-8.ant-col-rtl {
      margin-left: 0;
      margin-right: 33.33333333%;
    }
    .ant-col-xl-push-9.ant-col-rtl {
      left: auto;
      right: 37.5%;
    }
    .ant-col-xl-pull-9.ant-col-rtl {
      left: 37.5%;
      right: auto;
    }
    .ant-col-xl-offset-9.ant-col-rtl {
      margin-left: 0;
      margin-right: 37.5%;
    }
    .ant-col-xl-push-10.ant-col-rtl {
      left: auto;
      right: 41.66666667%;
    }
    .ant-col-xl-pull-10.ant-col-rtl {
      left: 41.66666667%;
      right: auto;
    }
    .ant-col-xl-offset-10.ant-col-rtl {
      margin-left: 0;
      margin-right: 41.66666667%;
    }
    .ant-col-xl-push-11.ant-col-rtl {
      left: auto;
      right: 45.83333333%;
    }
    .ant-col-xl-pull-11.ant-col-rtl {
      left: 45.83333333%;
      right: auto;
    }
    .ant-col-xl-offset-11.ant-col-rtl {
      margin-left: 0;
      margin-right: 45.83333333%;
    }
    .ant-col-xl-push-12.ant-col-rtl {
      left: auto;
      right: 50%;
    }
    .ant-col-xl-pull-12.ant-col-rtl {
      left: 50%;
      right: auto;
    }
    .ant-col-xl-offset-12.ant-col-rtl {
      margin-left: 0;
      margin-right: 50%;
    }
    .ant-col-xl-push-13.ant-col-rtl {
      left: auto;
      right: 54.16666667%;
    }
    .ant-col-xl-pull-13.ant-col-rtl {
      left: 54.16666667%;
      right: auto;
    }
    .ant-col-xl-offset-13.ant-col-rtl {
      margin-left: 0;
      margin-right: 54.16666667%;
    }
    .ant-col-xl-push-14.ant-col-rtl {
      left: auto;
      right: 58.33333333%;
    }
    .ant-col-xl-pull-14.ant-col-rtl {
      left: 58.33333333%;
      right: auto;
    }
    .ant-col-xl-offset-14.ant-col-rtl {
      margin-left: 0;
      margin-right: 58.33333333%;
    }
    .ant-col-xl-push-15.ant-col-rtl {
      left: auto;
      right: 62.5%;
    }
    .ant-col-xl-pull-15.ant-col-rtl {
      left: 62.5%;
      right: auto;
    }
    .ant-col-xl-offset-15.ant-col-rtl {
      margin-left: 0;
      margin-right: 62.5%;
    }
    .ant-col-xl-push-16.ant-col-rtl {
      left: auto;
      right: 66.66666667%;
    }
    .ant-col-xl-pull-16.ant-col-rtl {
      left: 66.66666667%;
      right: auto;
    }
    .ant-col-xl-offset-16.ant-col-rtl {
      margin-left: 0;
      margin-right: 66.66666667%;
    }
    .ant-col-xl-push-17.ant-col-rtl {
      left: auto;
      right: 70.83333333%;
    }
    .ant-col-xl-pull-17.ant-col-rtl {
      left: 70.83333333%;
      right: auto;
    }
    .ant-col-xl-offset-17.ant-col-rtl {
      margin-left: 0;
      margin-right: 70.83333333%;
    }
    .ant-col-xl-push-18.ant-col-rtl {
      left: auto;
      right: 75%;
    }
    .ant-col-xl-pull-18.ant-col-rtl {
      left: 75%;
      right: auto;
    }
    .ant-col-xl-offset-18.ant-col-rtl {
      margin-left: 0;
      margin-right: 75%;
    }
    .ant-col-xl-push-19.ant-col-rtl {
      left: auto;
      right: 79.16666667%;
    }
    .ant-col-xl-pull-19.ant-col-rtl {
      left: 79.16666667%;
      right: auto;
    }
    .ant-col-xl-offset-19.ant-col-rtl {
      margin-left: 0;
      margin-right: 79.16666667%;
    }
    .ant-col-xl-push-20.ant-col-rtl {
      left: auto;
      right: 83.33333333%;
    }
    .ant-col-xl-pull-20.ant-col-rtl {
      left: 83.33333333%;
      right: auto;
    }
    .ant-col-xl-offset-20.ant-col-rtl {
      margin-left: 0;
      margin-right: 83.33333333%;
    }
    .ant-col-xl-push-21.ant-col-rtl {
      left: auto;
      right: 87.5%;
    }
    .ant-col-xl-pull-21.ant-col-rtl {
      left: 87.5%;
      right: auto;
    }
    .ant-col-xl-offset-21.ant-col-rtl {
      margin-left: 0;
      margin-right: 87.5%;
    }
    .ant-col-xl-push-22.ant-col-rtl {
      left: auto;
      right: 91.66666667%;
    }
    .ant-col-xl-pull-22.ant-col-rtl {
      left: 91.66666667%;
      right: auto;
    }
    .ant-col-xl-offset-22.ant-col-rtl {
      margin-left: 0;
      margin-right: 91.66666667%;
    }
    .ant-col-xl-push-23.ant-col-rtl {
      left: auto;
      right: 95.83333333%;
    }
    .ant-col-xl-pull-23.ant-col-rtl {
      left: 95.83333333%;
      right: auto;
    }
    .ant-col-xl-offset-23.ant-col-rtl {
      margin-left: 0;
      margin-right: 95.83333333%;
    }
    .ant-col-xl-push-24.ant-col-rtl {
      left: auto;
      right: 100%;
    }
    .ant-col-xl-pull-24.ant-col-rtl {
      left: 100%;
      right: auto;
    }
    .ant-col-xl-offset-24.ant-col-rtl {
      margin-left: 0;
      margin-right: 100%;
    }
  }
  @media (min-width: 1600px) {
    .ant-col-xxl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xxl-push-24 {
      left: 100%;
    }
    .ant-col-xxl-pull-24 {
      right: 100%;
    }
    .ant-col-xxl-offset-24 {
      margin-left: 100%;
    }
    .ant-col-xxl-order-24 {
      order: 24;
    }
    .ant-col-xxl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-xxl-push-23 {
      left: 95.83333333%;
    }
    .ant-col-xxl-pull-23 {
      right: 95.83333333%;
    }
    .ant-col-xxl-offset-23 {
      margin-left: 95.83333333%;
    }
    .ant-col-xxl-order-23 {
      order: 23;
    }
    .ant-col-xxl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-xxl-push-22 {
      left: 91.66666667%;
    }
    .ant-col-xxl-pull-22 {
      right: 91.66666667%;
    }
    .ant-col-xxl-offset-22 {
      margin-left: 91.66666667%;
    }
    .ant-col-xxl-order-22 {
      order: 22;
    }
    .ant-col-xxl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-xxl-push-21 {
      left: 87.5%;
    }
    .ant-col-xxl-pull-21 {
      right: 87.5%;
    }
    .ant-col-xxl-offset-21 {
      margin-left: 87.5%;
    }
    .ant-col-xxl-order-21 {
      order: 21;
    }
    .ant-col-xxl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-xxl-push-20 {
      left: 83.33333333%;
    }
    .ant-col-xxl-pull-20 {
      right: 83.33333333%;
    }
    .ant-col-xxl-offset-20 {
      margin-left: 83.33333333%;
    }
    .ant-col-xxl-order-20 {
      order: 20;
    }
    .ant-col-xxl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-xxl-push-19 {
      left: 79.16666667%;
    }
    .ant-col-xxl-pull-19 {
      right: 79.16666667%;
    }
    .ant-col-xxl-offset-19 {
      margin-left: 79.16666667%;
    }
    .ant-col-xxl-order-19 {
      order: 19;
    }
    .ant-col-xxl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-xxl-push-18 {
      left: 75%;
    }
    .ant-col-xxl-pull-18 {
      right: 75%;
    }
    .ant-col-xxl-offset-18 {
      margin-left: 75%;
    }
    .ant-col-xxl-order-18 {
      order: 18;
    }
    .ant-col-xxl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-xxl-push-17 {
      left: 70.83333333%;
    }
    .ant-col-xxl-pull-17 {
      right: 70.83333333%;
    }
    .ant-col-xxl-offset-17 {
      margin-left: 70.83333333%;
    }
    .ant-col-xxl-order-17 {
      order: 17;
    }
    .ant-col-xxl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-xxl-push-16 {
      left: 66.66666667%;
    }
    .ant-col-xxl-pull-16 {
      right: 66.66666667%;
    }
    .ant-col-xxl-offset-16 {
      margin-left: 66.66666667%;
    }
    .ant-col-xxl-order-16 {
      order: 16;
    }
    .ant-col-xxl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-xxl-push-15 {
      left: 62.5%;
    }
    .ant-col-xxl-pull-15 {
      right: 62.5%;
    }
    .ant-col-xxl-offset-15 {
      margin-left: 62.5%;
    }
    .ant-col-xxl-order-15 {
      order: 15;
    }
    .ant-col-xxl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-xxl-push-14 {
      left: 58.33333333%;
    }
    .ant-col-xxl-pull-14 {
      right: 58.33333333%;
    }
    .ant-col-xxl-offset-14 {
      margin-left: 58.33333333%;
    }
    .ant-col-xxl-order-14 {
      order: 14;
    }
    .ant-col-xxl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-xxl-push-13 {
      left: 54.16666667%;
    }
    .ant-col-xxl-pull-13 {
      right: 54.16666667%;
    }
    .ant-col-xxl-offset-13 {
      margin-left: 54.16666667%;
    }
    .ant-col-xxl-order-13 {
      order: 13;
    }
    .ant-col-xxl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-xxl-push-12 {
      left: 50%;
    }
    .ant-col-xxl-pull-12 {
      right: 50%;
    }
    .ant-col-xxl-offset-12 {
      margin-left: 50%;
    }
    .ant-col-xxl-order-12 {
      order: 12;
    }
    .ant-col-xxl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-xxl-push-11 {
      left: 45.83333333%;
    }
    .ant-col-xxl-pull-11 {
      right: 45.83333333%;
    }
    .ant-col-xxl-offset-11 {
      margin-left: 45.83333333%;
    }
    .ant-col-xxl-order-11 {
      order: 11;
    }
    .ant-col-xxl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-xxl-push-10 {
      left: 41.66666667%;
    }
    .ant-col-xxl-pull-10 {
      right: 41.66666667%;
    }
    .ant-col-xxl-offset-10 {
      margin-left: 41.66666667%;
    }
    .ant-col-xxl-order-10 {
      order: 10;
    }
    .ant-col-xxl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-xxl-push-9 {
      left: 37.5%;
    }
    .ant-col-xxl-pull-9 {
      right: 37.5%;
    }
    .ant-col-xxl-offset-9 {
      margin-left: 37.5%;
    }
    .ant-col-xxl-order-9 {
      order: 9;
    }
    .ant-col-xxl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-xxl-push-8 {
      left: 33.33333333%;
    }
    .ant-col-xxl-pull-8 {
      right: 33.33333333%;
    }
    .ant-col-xxl-offset-8 {
      margin-left: 33.33333333%;
    }
    .ant-col-xxl-order-8 {
      order: 8;
    }
    .ant-col-xxl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-xxl-push-7 {
      left: 29.16666667%;
    }
    .ant-col-xxl-pull-7 {
      right: 29.16666667%;
    }
    .ant-col-xxl-offset-7 {
      margin-left: 29.16666667%;
    }
    .ant-col-xxl-order-7 {
      order: 7;
    }
    .ant-col-xxl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-xxl-push-6 {
      left: 25%;
    }
    .ant-col-xxl-pull-6 {
      right: 25%;
    }
    .ant-col-xxl-offset-6 {
      margin-left: 25%;
    }
    .ant-col-xxl-order-6 {
      order: 6;
    }
    .ant-col-xxl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-xxl-push-5 {
      left: 20.83333333%;
    }
    .ant-col-xxl-pull-5 {
      right: 20.83333333%;
    }
    .ant-col-xxl-offset-5 {
      margin-left: 20.83333333%;
    }
    .ant-col-xxl-order-5 {
      order: 5;
    }
    .ant-col-xxl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-xxl-push-4 {
      left: 16.66666667%;
    }
    .ant-col-xxl-pull-4 {
      right: 16.66666667%;
    }
    .ant-col-xxl-offset-4 {
      margin-left: 16.66666667%;
    }
    .ant-col-xxl-order-4 {
      order: 4;
    }
    .ant-col-xxl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-xxl-push-3 {
      left: 12.5%;
    }
    .ant-col-xxl-pull-3 {
      right: 12.5%;
    }
    .ant-col-xxl-offset-3 {
      margin-left: 12.5%;
    }
    .ant-col-xxl-order-3 {
      order: 3;
    }
    .ant-col-xxl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-xxl-push-2 {
      left: 8.33333333%;
    }
    .ant-col-xxl-pull-2 {
      right: 8.33333333%;
    }
    .ant-col-xxl-offset-2 {
      margin-left: 8.33333333%;
    }
    .ant-col-xxl-order-2 {
      order: 2;
    }
    .ant-col-xxl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-xxl-push-1 {
      left: 4.16666667%;
    }
    .ant-col-xxl-pull-1 {
      right: 4.16666667%;
    }
    .ant-col-xxl-offset-1 {
      margin-left: 4.16666667%;
    }
    .ant-col-xxl-order-1 {
      order: 1;
    }
    .ant-col-xxl-0 {
      display: none;
    }
    .ant-col-push-0 {
      left: auto;
    }
    .ant-col-pull-0 {
      right: auto;
    }
    .ant-col-xxl-push-0 {
      left: auto;
    }
    .ant-col-xxl-pull-0 {
      right: auto;
    }
    .ant-col-xxl-offset-0 {
      margin-left: 0;
    }
    .ant-col-xxl-order-0 {
      order: 0;
    }
    .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xxl-push-0.ant-col-rtl {
      right: auto;
    }
    .ant-col-xxl-pull-0.ant-col-rtl {
      left: auto;
    }
    .ant-col-xxl-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ant-col-xxl-push-1.ant-col-rtl {
      left: auto;
      right: 4.16666667%;
    }
    .ant-col-xxl-pull-1.ant-col-rtl {
      left: 4.16666667%;
      right: auto;
    }
    .ant-col-xxl-offset-1.ant-col-rtl {
      margin-left: 0;
      margin-right: 4.16666667%;
    }
    .ant-col-xxl-push-2.ant-col-rtl {
      left: auto;
      right: 8.33333333%;
    }
    .ant-col-xxl-pull-2.ant-col-rtl {
      left: 8.33333333%;
      right: auto;
    }
    .ant-col-xxl-offset-2.ant-col-rtl {
      margin-left: 0;
      margin-right: 8.33333333%;
    }
    .ant-col-xxl-push-3.ant-col-rtl {
      left: auto;
      right: 12.5%;
    }
    .ant-col-xxl-pull-3.ant-col-rtl {
      left: 12.5%;
      right: auto;
    }
    .ant-col-xxl-offset-3.ant-col-rtl {
      margin-left: 0;
      margin-right: 12.5%;
    }
    .ant-col-xxl-push-4.ant-col-rtl {
      left: auto;
      right: 16.66666667%;
    }
    .ant-col-xxl-pull-4.ant-col-rtl {
      left: 16.66666667%;
      right: auto;
    }
    .ant-col-xxl-offset-4.ant-col-rtl {
      margin-left: 0;
      margin-right: 16.66666667%;
    }
    .ant-col-xxl-push-5.ant-col-rtl {
      left: auto;
      right: 20.83333333%;
    }
    .ant-col-xxl-pull-5.ant-col-rtl {
      left: 20.83333333%;
      right: auto;
    }
    .ant-col-xxl-offset-5.ant-col-rtl {
      margin-left: 0;
      margin-right: 20.83333333%;
    }
    .ant-col-xxl-push-6.ant-col-rtl {
      left: auto;
      right: 25%;
    }
    .ant-col-xxl-pull-6.ant-col-rtl {
      left: 25%;
      right: auto;
    }
    .ant-col-xxl-offset-6.ant-col-rtl {
      margin-left: 0;
      margin-right: 25%;
    }
    .ant-col-xxl-push-7.ant-col-rtl {
      left: auto;
      right: 29.16666667%;
    }
    .ant-col-xxl-pull-7.ant-col-rtl {
      left: 29.16666667%;
      right: auto;
    }
    .ant-col-xxl-offset-7.ant-col-rtl {
      margin-left: 0;
      margin-right: 29.16666667%;
    }
    .ant-col-xxl-push-8.ant-col-rtl {
      left: auto;
      right: 33.33333333%;
    }
    .ant-col-xxl-pull-8.ant-col-rtl {
      left: 33.33333333%;
      right: auto;
    }
    .ant-col-xxl-offset-8.ant-col-rtl {
      margin-left: 0;
      margin-right: 33.33333333%;
    }
    .ant-col-xxl-push-9.ant-col-rtl {
      left: auto;
      right: 37.5%;
    }
    .ant-col-xxl-pull-9.ant-col-rtl {
      left: 37.5%;
      right: auto;
    }
    .ant-col-xxl-offset-9.ant-col-rtl {
      margin-left: 0;
      margin-right: 37.5%;
    }
    .ant-col-xxl-push-10.ant-col-rtl {
      left: auto;
      right: 41.66666667%;
    }
    .ant-col-xxl-pull-10.ant-col-rtl {
      left: 41.66666667%;
      right: auto;
    }
    .ant-col-xxl-offset-10.ant-col-rtl {
      margin-left: 0;
      margin-right: 41.66666667%;
    }
    .ant-col-xxl-push-11.ant-col-rtl {
      left: auto;
      right: 45.83333333%;
    }
    .ant-col-xxl-pull-11.ant-col-rtl {
      left: 45.83333333%;
      right: auto;
    }
    .ant-col-xxl-offset-11.ant-col-rtl {
      margin-left: 0;
      margin-right: 45.83333333%;
    }
    .ant-col-xxl-push-12.ant-col-rtl {
      left: auto;
      right: 50%;
    }
    .ant-col-xxl-pull-12.ant-col-rtl {
      left: 50%;
      right: auto;
    }
    .ant-col-xxl-offset-12.ant-col-rtl {
      margin-left: 0;
      margin-right: 50%;
    }
    .ant-col-xxl-push-13.ant-col-rtl {
      left: auto;
      right: 54.16666667%;
    }
    .ant-col-xxl-pull-13.ant-col-rtl {
      left: 54.16666667%;
      right: auto;
    }
    .ant-col-xxl-offset-13.ant-col-rtl {
      margin-left: 0;
      margin-right: 54.16666667%;
    }
    .ant-col-xxl-push-14.ant-col-rtl {
      left: auto;
      right: 58.33333333%;
    }
    .ant-col-xxl-pull-14.ant-col-rtl {
      left: 58.33333333%;
      right: auto;
    }
    .ant-col-xxl-offset-14.ant-col-rtl {
      margin-left: 0;
      margin-right: 58.33333333%;
    }
    .ant-col-xxl-push-15.ant-col-rtl {
      left: auto;
      right: 62.5%;
    }
    .ant-col-xxl-pull-15.ant-col-rtl {
      left: 62.5%;
      right: auto;
    }
    .ant-col-xxl-offset-15.ant-col-rtl {
      margin-left: 0;
      margin-right: 62.5%;
    }
    .ant-col-xxl-push-16.ant-col-rtl {
      left: auto;
      right: 66.66666667%;
    }
    .ant-col-xxl-pull-16.ant-col-rtl {
      left: 66.66666667%;
      right: auto;
    }
    .ant-col-xxl-offset-16.ant-col-rtl {
      margin-left: 0;
      margin-right: 66.66666667%;
    }
    .ant-col-xxl-push-17.ant-col-rtl {
      left: auto;
      right: 70.83333333%;
    }
    .ant-col-xxl-pull-17.ant-col-rtl {
      left: 70.83333333%;
      right: auto;
    }
    .ant-col-xxl-offset-17.ant-col-rtl {
      margin-left: 0;
      margin-right: 70.83333333%;
    }
    .ant-col-xxl-push-18.ant-col-rtl {
      left: auto;
      right: 75%;
    }
    .ant-col-xxl-pull-18.ant-col-rtl {
      left: 75%;
      right: auto;
    }
    .ant-col-xxl-offset-18.ant-col-rtl {
      margin-left: 0;
      margin-right: 75%;
    }
    .ant-col-xxl-push-19.ant-col-rtl {
      left: auto;
      right: 79.16666667%;
    }
    .ant-col-xxl-pull-19.ant-col-rtl {
      left: 79.16666667%;
      right: auto;
    }
    .ant-col-xxl-offset-19.ant-col-rtl {
      margin-left: 0;
      margin-right: 79.16666667%;
    }
    .ant-col-xxl-push-20.ant-col-rtl {
      left: auto;
      right: 83.33333333%;
    }
    .ant-col-xxl-pull-20.ant-col-rtl {
      left: 83.33333333%;
      right: auto;
    }
    .ant-col-xxl-offset-20.ant-col-rtl {
      margin-left: 0;
      margin-right: 83.33333333%;
    }
    .ant-col-xxl-push-21.ant-col-rtl {
      left: auto;
      right: 87.5%;
    }
    .ant-col-xxl-pull-21.ant-col-rtl {
      left: 87.5%;
      right: auto;
    }
    .ant-col-xxl-offset-21.ant-col-rtl {
      margin-left: 0;
      margin-right: 87.5%;
    }
    .ant-col-xxl-push-22.ant-col-rtl {
      left: auto;
      right: 91.66666667%;
    }
    .ant-col-xxl-pull-22.ant-col-rtl {
      left: 91.66666667%;
      right: auto;
    }
    .ant-col-xxl-offset-22.ant-col-rtl {
      margin-left: 0;
      margin-right: 91.66666667%;
    }
    .ant-col-xxl-push-23.ant-col-rtl {
      left: auto;
      right: 95.83333333%;
    }
    .ant-col-xxl-pull-23.ant-col-rtl {
      left: 95.83333333%;
      right: auto;
    }
    .ant-col-xxl-offset-23.ant-col-rtl {
      margin-left: 0;
      margin-right: 95.83333333%;
    }
    .ant-col-xxl-push-24.ant-col-rtl {
      left: auto;
      right: 100%;
    }
    .ant-col-xxl-pull-24.ant-col-rtl {
      left: 100%;
      right: auto;
    }
    .ant-col-xxl-offset-24.ant-col-rtl {
      margin-left: 0;
      margin-right: 100%;
    }
  }
  .ant-row-rtl {
    direction: rtl;
  }
  .ant-carousel {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-carousel .slick-slider {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
  }
  .ant-carousel .slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  .ant-carousel .slick-list:focus {
    outline: none;
  }
  .ant-carousel .slick-list.dragging {
    cursor: pointer;
  }
  .ant-carousel .slick-list .slick-slide {
    pointer-events: none;
  }
  .ant-carousel .slick-list .slick-slide input.ant-checkbox-input,
  .ant-carousel .slick-list .slick-slide input.ant-radio-input {
    visibility: hidden;
  }
  .ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
  }
  .ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input,
  .ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input {
    visibility: visible;
  }
  .ant-carousel .slick-list .slick-slide > div > div {
    vertical-align: bottom;
  }
  .ant-carousel .slick-slider .slick-list,
  .ant-carousel .slick-slider .slick-track {
    touch-action: pan-y;
    transform: translateZ(0);
  }
  .ant-carousel .slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;
  }
  .ant-carousel .slick-track:after,
  .ant-carousel .slick-track:before {
    content: '';
    display: table;
  }
  .ant-carousel .slick-track:after {
    clear: both;
  }
  .slick-loading .ant-carousel .slick-track {
    visibility: hidden;
  }
  .ant-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  .ant-carousel .slick-slide img {
    display: block;
  }
  .ant-carousel .slick-slide.slick-loading img {
    display: none;
  }
  .ant-carousel .slick-slide.dragging img {
    pointer-events: none;
  }
  .ant-carousel .slick-initialized .slick-slide {
    display: block;
  }
  .ant-carousel .slick-loading .slick-slide {
    visibility: hidden;
  }
  .ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
  }
  .ant-carousel .slick-arrow.slick-hidden {
    display: none;
  }
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev {
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    margin-top: -10px;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 20px;
  }
  .ant-carousel .slick-next,
  .ant-carousel .slick-next:focus,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-prev:hover {
    background: transparent;
    color: transparent;
    outline: none;
  }
  .ant-carousel .slick-next:focus:before,
  .ant-carousel .slick-next:hover:before,
  .ant-carousel .slick-prev:focus:before,
  .ant-carousel .slick-prev:hover:before {
    opacity: 1;
  }
  .ant-carousel .slick-next.slick-disabled:before,
  .ant-carousel .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }
  .ant-carousel .slick-prev {
    left: -25px;
  }
  .ant-carousel .slick-prev:before {
    content: '←';
  }
  .ant-carousel .slick-next {
    right: -25px;
  }
  .ant-carousel .slick-next:before {
    content: '→';
  }
  .ant-carousel .slick-dots {
    bottom: 0;
    display: flex !important;
    justify-content: center;
    left: 0;
    list-style: none;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 0;
    position: absolute;
    right: 0;
    z-index: 15;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 12px;
  }
  .ant-carousel .slick-dots-top {
    bottom: auto;
    top: 12px;
  }
  .ant-carousel .slick-dots li {
    box-sizing: initial;
    display: inline-block;
    flex: 0 1 auto;
    height: 3px;
    margin: 0 3px;
    padding: 0;
    position: relative;
    text-align: center;
    text-indent: -999px;
    transition: all 0.5s;
    vertical-align: top;
    width: 16px;
  }
  .ant-carousel .slick-dots li button {
    background: #fff;
    border: 0;
    border-radius: 1px;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 3px;
    opacity: 0.3;
    outline: none;
    padding: 0;
    transition: all 0.5s;
    width: 100%;
  }
  .ant-carousel .slick-dots li button:focus,
  .ant-carousel .slick-dots li button:hover {
    opacity: 0.75;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: 24px;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
  }
  .ant-carousel .slick-dots li.slick-active:focus,
  .ant-carousel .slick-dots li.slick-active:hover {
    opacity: 1;
  }
  .ant-carousel-vertical .slick-dots {
    bottom: auto;
    flex-direction: column;
    height: auto;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
  }
  .ant-carousel-vertical .slick-dots-left {
    left: 12px;
    right: auto;
  }
  .ant-carousel-vertical .slick-dots-right {
    left: auto;
    right: 12px;
  }
  .ant-carousel-vertical .slick-dots li {
    height: 16px;
    margin: 4px 2px;
    vertical-align: initial;
    width: 3px;
  }
  .ant-carousel-vertical .slick-dots li button {
    height: 16px;
    width: 3px;
  }
  .ant-carousel-vertical .slick-dots li.slick-active,
  .ant-carousel-vertical .slick-dots li.slick-active button {
    height: 24px;
    width: 3px;
  }
  .ant-carousel-rtl {
    direction: rtl;
  }
  .ant-carousel-rtl .ant-carousel .slick-track {
    left: auto;
    right: 0;
  }
  .ant-carousel-rtl .ant-carousel .slick-prev {
    left: auto;
    right: -25px;
  }
  .ant-carousel-rtl .ant-carousel .slick-prev:before {
    content: '→';
  }
  .ant-carousel-rtl .ant-carousel .slick-next {
    left: -25px;
    right: auto;
  }
  .ant-carousel-rtl .ant-carousel .slick-next:before {
    content: '←';
  }
  .ant-carousel-rtl.ant-carousel .slick-dots {
    flex-direction: row-reverse;
  }
  .ant-carousel-rtl.ant-carousel-vertical .slick-dots {
    flex-direction: column;
  }
  .ant-cascader {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-cascader-input.ant-input {
    background-color: initial !important;
    cursor: pointer;
    padding-right: 24px;
    position: static;
    width: 100%;
  }
  .ant-cascader-picker-show-search .ant-cascader-input.ant-input {
    position: relative;
  }
  .ant-cascader-picker {
    font-feature-settings: 'tnum';
    background-color: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 0;
    position: relative;
    transition: color 0.3s;
  }
  .ant-cascader-picker-with-value .ant-cascader-picker-label {
    color: transparent;
  }
  .ant-cascader-picker-disabled {
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-cascader-picker-disabled .ant-cascader-input {
    cursor: not-allowed;
  }
  .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-cascader-picker-borderless .ant-cascader-input {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-cascader-picker-show-search.ant-cascader-picker-focused {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-cascader-picker-label {
    height: 20px;
    left: 0;
    line-height: 20px;
    margin-top: -10px;
    overflow: hidden;
    padding: 0 20px 0 12px;
    position: absolute;
    text-overflow: ellipsis;
    top: 50%;
    white-space: nowrap;
    width: 100%;
  }
  .ant-cascader-picker-clear {
    background: #fff;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 12px;
    height: 12px;
    line-height: 12px;
    margin-top: -6px;
    opacity: 0;
    position: absolute;
    right: 12px;
    top: 50%;
    transition: color 0.3s ease, opacity 0.15s ease;
    width: 12px;
    z-index: 2;
  }
  .ant-cascader-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-cascader-picker:hover .ant-cascader-picker-clear {
    opacity: 1;
  }
  .ant-cascader-picker-arrow {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    height: 12px;
    line-height: 12px;
    margin-top: -6px;
    position: absolute;
    right: 12px;
    top: 50%;
    width: 12px;
    z-index: 1;
  }
  .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-cascader-picker-small .ant-cascader-picker-arrow,
  .ant-cascader-picker-small .ant-cascader-picker-clear {
    right: 8px;
  }
  .ant-cascader-menus {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    position: absolute;
    white-space: nowrap;
    z-index: 1050;
  }
  .ant-cascader-menus ol,
  .ant-cascader-menus ul {
    list-style: none;
    margin: 0;
  }
  .ant-cascader-menus-empty,
  .ant-cascader-menus-hidden {
    display: none;
  }
  .ant-cascader-menus.ant-slide-up-appear.ant-slide-up-appear-active.ant-cascader-menus-placement-bottomLeft,
  .ant-cascader-menus.ant-slide-up-enter.ant-slide-up-enter-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
  }
  .ant-cascader-menus.ant-slide-up-appear.ant-slide-up-appear-active.ant-cascader-menus-placement-topLeft,
  .ant-cascader-menus.ant-slide-up-enter.ant-slide-up-enter-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
  }
  .ant-cascader-menus.ant-slide-up-leave.ant-slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
  }
  .ant-cascader-menus.ant-slide-up-leave.ant-slide-up-leave-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
  }
  .ant-cascader-menu {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border-right: 1px solid #f0f0f0;
    display: inline-block;
    height: 180px;
    list-style: none;
    margin: 0;
    min-width: 111px;
    overflow: auto;
    padding: 4px 0;
    vertical-align: top;
  }
  .ant-cascader-menu:first-child {
    border-radius: 2px 0 0 2px;
  }
  .ant-cascader-menu:last-child {
    border-radius: 0 2px 2px 0;
    border-right-color: transparent;
    margin-right: -1px;
  }
  .ant-cascader-menu:only-child {
    border-radius: 2px;
  }
  .ant-cascader-menu-item {
    cursor: pointer;
    line-height: 22px;
    overflow: hidden;
    padding: 5px 12px;
    text-overflow: ellipsis;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .ant-cascader-menu-item:hover {
    background: #f5f5f5;
  }
  .ant-cascader-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-cascader-menu-item-disabled:hover {
    background: transparent;
  }
  .ant-cascader-menu-empty .ant-cascader-menu-item {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
    pointer-events: none;
  }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: #e6f7ff;
    font-weight: 600;
  }
  .ant-cascader-menu-item-expand {
    padding-right: 24px;
    position: relative;
  }
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-loading-icon {
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    position: absolute;
    right: 12px;
  }
  .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
    color: #ff4d4f;
  }
  .ant-cascader-picker-rtl .ant-cascader-input.ant-input {
    padding-left: 24px;
    padding-right: 11px;
    text-align: right;
  }
  .ant-cascader-picker-rtl {
    direction: rtl;
  }
  .ant-cascader-picker-rtl .ant-cascader-picker-label {
    padding: 0 12px 0 20px;
    text-align: right;
  }
  .ant-cascader-picker-rtl .ant-cascader-picker-arrow,
  .ant-cascader-picker-rtl .ant-cascader-picker-clear {
    left: 12px;
    right: auto;
  }
  .ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow,
  .ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear {
    left: 8px;
    right: auto;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu {
    border-left: 1px solid #f0f0f0;
    border-right: none;
    direction: rtl;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu:first-child {
    border-radius: 0 2px 2px 0;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu:last-child {
    border-left-color: transparent;
    border-radius: 2px 0 0 2px;
    margin-left: -1px;
    margin-right: 0;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu:only-child {
    border-radius: 2px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
    padding-left: 24px;
    padding-right: 12px;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
    left: 12px;
    right: auto;
  }
  .ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
    transform: scaleY(-1);
  }
  .ant-input-affix-wrapper {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    display: inline-flex;
    font-size: 14px;
    line-height: 1.5715;
    min-width: 0;
    padding: 4px 11px;
    position: relative;
    transition: all 0.3s;
    width: 100%;
  }
  .ant-input-affix-wrapper::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-affix-wrapper:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper::placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-left-width: 1px !important;
    border-right-width: 0;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-input-rtl .ant-input-affix-wrapper-focused,
  .ant-input-rtl .ant-input-affix-wrapper:focus {
    border-left-width: 1px !important;
    border-right-width: 0;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper-borderless,
  .ant-input-affix-wrapper-borderless-disabled,
  .ant-input-affix-wrapper-borderless-focused,
  .ant-input-affix-wrapper-borderless:focus,
  .ant-input-affix-wrapper-borderless:hover,
  .ant-input-affix-wrapper-borderless[disabled] {
    background-color: initial;
    border: none;
    box-shadow: none;
  }
  textarea.ant-input-affix-wrapper {
    height: auto;
    line-height: 1.5715;
    max-width: 100%;
    min-height: 32px;
    transition: all 0.3s, height 0s;
    vertical-align: bottom;
  }
  .ant-input-affix-wrapper-lg {
    font-size: 16px;
    padding: 6.5px 11px;
  }
  .ant-input-affix-wrapper-sm {
    padding: 0 7px;
  }
  .ant-input-affix-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    z-index: 1;
  }
  .ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-left-width: 1px !important;
    border-right-width: 0;
  }
  .ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 0;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    z-index: 1;
  }
  .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: transparent;
  }
  .ant-input-affix-wrapper > input.ant-input {
    border: none;
    outline: none;
    padding: 0;
  }
  .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none;
  }
  .ant-input-affix-wrapper:before {
    content: ' ';
    visibility: hidden;
    width: 0;
  }
  .ant-input-prefix,
  .ant-input-suffix {
    align-items: center;
    display: flex;
    flex: none;
  }
  .ant-input-prefix {
    margin-right: 4px;
  }
  .ant-input-suffix {
    margin-left: 4px;
  }
  .ant-input-clear-icon {
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 12px;
    margin: 0 4px;
    transition: color 0.3s;
    vertical-align: -1px;
  }
  .ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-clear-icon:active {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input-clear-icon:last-child {
    margin-right: 0;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn {
    border: 0 !important;
    padding: 0 !important;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
  }
  .ant-input {
    font-feature-settings: 'tnum';
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding: 4px 11px;
    position: relative;
    transition: all 0.3s;
    width: 100%;
  }
  .ant-input::-moz-placeholder {
    opacity: 1;
  }
  .ant-input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input::placeholder {
    color: #bfbfbf;
  }
  .ant-input:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input:hover {
    border-left-width: 1px !important;
    border-right-width: 0;
  }
  .ant-input-focused,
  .ant-input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-input-rtl .ant-input-focused,
  .ant-input-rtl .ant-input:focus {
    border-left-width: 1px !important;
    border-right-width: 0;
  }
  .ant-input-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-borderless,
  .ant-input-borderless-disabled,
  .ant-input-borderless-focused,
  .ant-input-borderless:focus,
  .ant-input-borderless:hover,
  .ant-input-borderless[disabled] {
    background-color: initial;
    border: none;
    box-shadow: none;
  }
  textarea.ant-input {
    height: auto;
    line-height: 1.5715;
    max-width: 100%;
    min-height: 32px;
    transition: all 0.3s, height 0s;
    vertical-align: bottom;
  }
  .ant-input-lg {
    font-size: 16px;
    padding: 6.5px 11px;
  }
  .ant-input-sm {
    padding: 0 7px;
  }
  .ant-input-rtl {
    direction: rtl;
  }
  .ant-input-group {
    font-feature-settings: 'tnum';
    border-collapse: initial;
    border-spacing: 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: table;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .ant-input-group[class*='col-'] {
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
  .ant-input-group > [class*='col-'] {
    padding-right: 8px;
  }
  .ant-input-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap,
  .ant-input-group > .ant-input {
    display: table-cell;
  }
  .ant-input-group-addon:not(:first-child):not(:last-child),
  .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap {
    vertical-align: middle;
    white-space: nowrap;
    width: 1px;
  }
  .ant-input-group-wrap > * {
    display: block !important;
  }
  .ant-input-group .ant-input {
    float: left;
    margin-bottom: 0;
    text-align: inherit;
    width: 100%;
  }
  .ant-input-group .ant-input:focus,
  .ant-input-group .ant-input:hover {
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-search-with-button .ant-input-group .ant-input:hover {
    z-index: 0;
  }
  .ant-input-group-addon {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    padding: 0 11px;
    position: relative;
    text-align: center;
    transition: all 0.3s;
  }
  .ant-input-group-addon .ant-select {
    margin: -5px -11px;
  }
  .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
  }
  .ant-input-group-addon .ant-select-focused .ant-select-selector,
  .ant-input-group-addon .ant-select-open .ant-select-selector {
    color: #1890ff;
  }
  .ant-input-group-addon:first-child,
  .ant-input-group-addon:first-child .ant-select .ant-select-selector,
  .ant-input-group > .ant-input:first-child,
  .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-input-group-addon:first-child {
    border-right: 0;
  }
  .ant-input-group-addon:last-child {
    border-left: 0;
  }
  .ant-input-group-addon:last-child,
  .ant-input-group-addon:last-child .ant-select .ant-select-selector,
  .ant-input-group > .ant-input:last-child,
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .ant-input-group-lg .ant-input,
  .ant-input-group-lg > .ant-input-group-addon {
    font-size: 16px;
    padding: 6.5px 11px;
  }
  .ant-input-group-sm .ant-input,
  .ant-input-group-sm > .ant-input-group-addon {
    padding: 0 7px;
  }
  .ant-input-group-lg .ant-select-single .ant-select-selector {
    height: 40px;
  }
  .ant-input-group-sm .ant-select-single .ant-select-selector {
    height: 24px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact {
    display: block;
  }
  .ant-input-group.ant-input-group-compact:before {
    content: '';
    display: table;
  }
  .ant-input-group.ant-input-group-compact:after {
    clear: both;
    content: '';
    display: table;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > * {
    border-radius: 0;
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  .ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
  .ant-input-group.ant-input-group-compact > .ant-picker-range {
    display: inline-flex;
  }
  .ant-input-group.ant-input-group-compact > :not(:last-child) {
    border-right-width: 1px;
    margin-right: -1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
  }
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector {
    border-radius: 0;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-focused,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > :first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > :last-child {
    border-bottom-right-radius: 2px;
    border-right-width: 1px;
    border-top-right-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top;
  }
  .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
    margin-left: -1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper,
  .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child,
  .ant-input-group > .ant-input-rtl:first-child {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-left: 0;
    border-right: 1px solid #d9d9d9;
  }
  .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-left: 1px solid #d9d9d9;
    border-right: 0;
  }
  .ant-input-group-rtl.ant-input-group-addon:last-child,
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child),
  .ant-input-group-rtl.ant-input-group > .ant-input:last-child {
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > :not(:last-child) {
    border-left-width: 1px;
    margin-left: -1px;
    margin-right: 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > :first-child {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > :last-child {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
    margin-left: 0;
    margin-right: -1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-wrapper {
    display: inline-block;
    text-align: start;
    vertical-align: top;
    width: 100%;
  }
  .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-input-password-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input[type='color'] {
    height: 32px;
  }
  .ant-input[type='color'].ant-input-lg {
    height: 40px;
  }
  .ant-input[type='color'].ant-input-sm {
    height: 24px;
    padding-bottom: 3px;
    padding-top: 3px;
  }
  .ant-input-textarea-show-count:after {
    color: rgba(0, 0, 0, 0.45);
    content: attr(data-count);
    float: right;
    pointer-events: none;
    white-space: nowrap;
  }
  .ant-input-search .ant-input:focus,
  .ant-input-search .ant-input:hover {
    border-color: #40a9ff;
  }
  .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #40a9ff;
  }
  .ant-input-search .ant-input-affix-wrapper {
    border-radius: 0;
  }
  .ant-input-search .ant-input-lg {
    line-height: 1.5713;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    border: 0;
    left: -1px;
    padding: 0;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 2px 2px 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .ant-input-search-button {
    height: 32px;
  }
  .ant-input-search-button:focus,
  .ant-input-search-button:hover {
    z-index: 1;
  }
  .ant-input-search-large .ant-input-search-button {
    height: 40px;
  }
  .ant-input-search-small .ant-input-search-button {
    height: 24px;
  }
  .ant-input-group-rtl,
  .ant-input-group-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: none;
    outline: none;
  }
  .ant-input-affix-wrapper-rtl .ant-input-prefix {
    margin: 0 0 0 4px;
  }
  .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0 4px 0 0;
  }
  .ant-input-textarea-rtl {
    direction: rtl;
  }
  .ant-input-textarea-rtl.ant-input-textarea-show-count:after {
    text-align: left;
  }
  .ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-input-affix-wrapper-rtl .ant-input-clear-icon {
    left: 8px;
    right: auto;
  }
  .ant-input-search-rtl {
    direction: rtl;
  }
  .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #d9d9d9;
    border-right-color: #40a9ff;
  }
  .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused,
  .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover {
    border-right-color: #40a9ff;
  }
  .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
    left: auto;
    right: -1px;
  }
  .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
    border-radius: 2px 0 0 2px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .ant-input {
      height: 32px;
    }
    .ant-input-lg {
      height: 40px;
    }
    .ant-input-sm {
      height: 24px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      height: auto;
    }
  }
  .ant-checkbox {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 1;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    top: 0.2em;
    white-space: nowrap;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #1890ff;
  }
  .ant-checkbox-checked:after {
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    border: 1px solid #1890ff;
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox:after,
  .ant-checkbox:hover:after {
    visibility: visible;
  }
  .ant-checkbox-inner {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-collapse: initial;
    border-radius: 2px;
    direction: ltr;
    display: block;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    transition: all 0.3s;
    width: 16px;
  }
  .ant-checkbox-inner:after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: table;
    height: 9.14285714px;
    left: 22%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    width: 5.71428571px;
  }
  .ant-checkbox-input {
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: table;
    opacity: 1;
    position: absolute;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  .ant-checkbox-disabled {
    cursor: not-allowed;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-checkbox-disabled .ant-checkbox-input {
    cursor: not-allowed;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-collapse: initial;
    border-color: #f5f5f5;
  }
  .ant-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-checkbox-disabled:hover:after,
  .ant-checkbox-wrapper:hover .ant-checkbox-disabled:after {
    visibility: hidden;
  }
  .ant-checkbox-wrapper {
    font-feature-settings: 'tnum';
    align-items: baseline;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: unset;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-checkbox-wrapper:after {
    content: ' ';
    display: inline-block;
    overflow: hidden;
    width: 0;
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-checkbox + span {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-checkbox-group {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-checkbox-group-item {
    margin-right: 8px;
  }
  .ant-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .ant-checkbox-group-item + .ant-checkbox-group-item {
    margin-left: 0;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #1890ff;
    border: 0;
    content: ' ';
    height: 8px;
    left: 50%;
    opacity: 1;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 8px;
  }
  .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-checkbox-rtl {
    direction: rtl;
  }
  .ant-checkbox-group-rtl .ant-checkbox-group-item {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
    margin-left: 8px;
  }
  .ant-collapse {
    font-feature-settings: 'tnum';
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 2px 2px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    line-height: 1.5715;
    padding: 12px 16px;
    position: relative;
    transition: all 0.3s, visibility 0s;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header:before {
    content: '';
    display: table;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header:after {
    clear: both;
    content: '';
    display: table;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: inline-block;
    font-size: 12px;
    margin-right: 12px;
    vertical-align: -1px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    transition: transform 0.24s;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    float: right;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
    outline: none;
  }
  .ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
    cursor: default;
  }
  .ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
    cursor: pointer;
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 12px;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 40px 12px 16px;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: auto;
    margin: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-collapse-content {
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
  .ant-collapse-content-hidden {
    display: none;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 2px 2px;
  }
  .ant-collapse-borderless {
    background-color: #fafafa;
    border: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-collapse-borderless > .ant-collapse-item:last-child,
  .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    border-radius: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: initial;
    border-top: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 4px;
  }
  .ant-collapse-ghost {
    background-color: initial;
    border: 0;
  }
  .ant-collapse-ghost > .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    background-color: initial;
    border-top: 0;
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-collapse-rtl {
    direction: rtl;
  }
  .ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 40px 12px 16px;
  }
  .ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    transform: rotate(180deg);
  }
  .ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    float: left;
  }
  .ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 0;
    padding-right: 12px;
  }
  .ant-comment {
    background-color: inherit;
    position: relative;
  }
  .ant-comment-inner {
    display: flex;
    padding: 16px 0;
  }
  .ant-comment-avatar {
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 12px;
    position: relative;
  }
  .ant-comment-avatar img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
  .ant-comment-content {
    word-wrap: break-word;
    flex: 1 1 auto;
    font-size: 14px;
    min-width: 1px;
    position: relative;
  }
  .ant-comment-content-author {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: flex-start;
    margin-bottom: 4px;
  }
  .ant-comment-content-author > a,
  .ant-comment-content-author > span {
    font-size: 12px;
    line-height: 18px;
    padding-right: 8px;
  }
  .ant-comment-content-author-name {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    transition: color 0.3s;
  }
  .ant-comment-content-author-name > *,
  .ant-comment-content-author-name > :hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-comment-content-author-time {
    color: #ccc;
    cursor: auto;
    white-space: nowrap;
  }
  .ant-comment-content-detail p {
    margin-bottom: inherit;
    white-space: pre-wrap;
  }
  .ant-comment-actions {
    margin-bottom: inherit;
    margin-top: 12px;
    padding-left: 0;
  }
  .ant-comment-actions > li {
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
  }
  .ant-comment-actions > li > span {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    font-size: 12px;
    margin-right: 10px;
    transition: color 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-comment-actions > li > span:hover {
    color: #595959;
  }
  .ant-comment-nested {
    margin-left: 44px;
  }
  .ant-comment-rtl {
    direction: rtl;
  }
  .ant-comment-rtl .ant-comment-avatar {
    margin-left: 12px;
    margin-right: 0;
  }
  .ant-comment-rtl .ant-comment-content-author > a,
  .ant-comment-rtl .ant-comment-content-author > span {
    padding-left: 8px;
    padding-right: 0;
  }
  .ant-comment-rtl .ant-comment-actions {
    padding-right: 0;
  }
  .ant-comment-rtl .ant-comment-actions > li > span {
    margin-left: 10px;
    margin-right: 0;
  }
  .ant-comment-rtl .ant-comment-nested {
    margin-left: 0;
    margin-right: 44px;
  }
  .ant-descriptions-header {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }
  .ant-descriptions-title {
    color: rgba(0, 0, 0, 0.85);
    flex: auto;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5715;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-descriptions-extra {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin-left: auto;
  }
  .ant-descriptions-view {
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
  }
  .ant-descriptions-view table {
    table-layout: fixed;
    width: 100%;
  }
  .ant-descriptions-row > td,
  .ant-descriptions-row > th {
    padding-bottom: 16px;
  }
  .ant-descriptions-row:last-child {
    border-bottom: none;
  }
  .ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5715;
    text-align: start;
  }
  .ant-descriptions-item-label:after {
    content: ':';
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
  }
  .ant-descriptions-item-label.ant-descriptions-item-no-colon:after {
    content: ' ';
  }
  .ant-descriptions-item-no-label:after {
    content: '';
    margin: 0;
  }
  .ant-descriptions-item-content {
    color: rgba(0, 0, 0, 0.85);
    display: table-cell;
    flex: 1;
    font-size: 14px;
    line-height: 1.5715;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  .ant-descriptions-item {
    padding-bottom: 0;
    vertical-align: top;
  }
  .ant-descriptions-item-container {
    display: flex;
  }
  .ant-descriptions-item-container .ant-descriptions-item-content,
  .ant-descriptions-item-container .ant-descriptions-item-label {
    align-items: baseline;
    display: inline-flex;
  }
  .ant-descriptions-middle .ant-descriptions-row > td,
  .ant-descriptions-middle .ant-descriptions-row > th {
    padding-bottom: 12px;
  }
  .ant-descriptions-small .ant-descriptions-row > td,
  .ant-descriptions-small .ant-descriptions-row > th {
    padding-bottom: 8px;
  }
  .ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid #f0f0f0;
  }
  .ant-descriptions-bordered .ant-descriptions-view > table {
    table-layout: auto;
  }
  .ant-descriptions-bordered .ant-descriptions-item-content,
  .ant-descriptions-bordered .ant-descriptions-item-label {
    border-right: 1px solid #f0f0f0;
    padding: 16px 24px;
  }
  .ant-descriptions-bordered .ant-descriptions-item-content:last-child,
  .ant-descriptions-bordered .ant-descriptions-item-label:last-child {
    border-right: none;
  }
  .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #fafafa;
  }
  .ant-descriptions-bordered .ant-descriptions-item-label:after {
    display: none;
  }
  .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-descriptions-bordered .ant-descriptions-row:last-child {
    border-bottom: none;
  }
  .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content,
  .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label {
    padding: 12px 24px;
  }
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
    padding: 8px 16px;
  }
  .ant-descriptions-rtl {
    direction: rtl;
  }
  .ant-descriptions-rtl .ant-descriptions-item-label:after {
    margin: 0 2px 0 8px;
  }
  .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content,
  .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label {
    border-left: 1px solid #f0f0f0;
    border-right: none;
  }
  .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child,
  .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child {
    border-left: none;
  }
  .ant-divider {
    font-feature-settings: 'tnum';
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-divider-vertical {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    border-top: 0;
    display: inline-block;
    height: 0.9em;
    margin: 0 8px;
    position: relative;
    top: -0.06em;
    vertical-align: middle;
  }
  .ant-divider-horizontal {
    clear: both;
    display: flex;
    margin: 24px 0;
    min-width: 100%;
    width: 100%;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    border-top: 0;
    border-top-color: rgba(0, 0, 0, 0.06);
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0;
    text-align: center;
    white-space: nowrap;
  }
  .ant-divider-horizontal.ant-divider-with-text:after,
  .ant-divider-horizontal.ant-divider-with-text:before {
    border-bottom: 0;
    border-top: 1px solid transparent;
    border-top-color: inherit;
    content: '';
    position: relative;
    top: 50%;
    transform: translateY(50%);
    width: 50%;
  }
  .ant-divider-horizontal.ant-divider-with-text-left:before {
    top: 50%;
    width: 5%;
  }
  .ant-divider-horizontal.ant-divider-with-text-left:after,
  .ant-divider-horizontal.ant-divider-with-text-right:before {
    top: 50%;
    width: 95%;
  }
  .ant-divider-horizontal.ant-divider-with-text-right:after {
    top: 50%;
    width: 5%;
  }
  .ant-divider-inner-text {
    display: inline-block;
    padding: 0 1em;
  }
  .ant-divider-dashed {
    background: none;
    border: dashed rgba(0, 0, 0, 0.06);
    border-width: 1px 0 0;
  }
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
    border-top: 0;
  }
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:after,
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:before {
    border-style: dashed none none;
  }
  .ant-divider-vertical.ant-divider-dashed {
    border-width: 0 0 0 1px;
  }
  .ant-divider-plain.ant-divider-with-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
  }
  .ant-divider-rtl {
    direction: rtl;
  }
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:before {
    width: 95%;
  }
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:after,
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:before {
    width: 5%;
  }
  .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:after {
    width: 95%;
  }
  .ant-drawer {
    height: 100%;
    position: fixed;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
    width: 0;
    z-index: 1000;
  }
  .ant-drawer > * {
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }
  .ant-drawer-content-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .ant-drawer .ant-drawer-content {
    height: 100%;
    width: 100%;
  }
  .ant-drawer-left,
  .ant-drawer-right {
    height: 100%;
    top: 0;
    width: 0;
  }
  .ant-drawer-left .ant-drawer-content-wrapper,
  .ant-drawer-right .ant-drawer-content-wrapper {
    height: 100%;
  }
  .ant-drawer-left.ant-drawer-open,
  .ant-drawer-right.ant-drawer-open {
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    width: 100%;
  }
  .ant-drawer-left,
  .ant-drawer-left .ant-drawer-content-wrapper {
    left: 0;
  }
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-right,
  .ant-drawer-right .ant-drawer-content-wrapper {
    right: 0;
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-right.ant-drawer-open.no-mask {
    right: 1px;
    transform: translateX(1px);
  }
  .ant-drawer-bottom,
  .ant-drawer-top {
    height: 0%;
    left: 0;
    width: 100%;
  }
  .ant-drawer-bottom .ant-drawer-content-wrapper,
  .ant-drawer-top .ant-drawer-content-wrapper {
    width: 100%;
  }
  .ant-drawer-bottom.ant-drawer-open,
  .ant-drawer-top.ant-drawer-open {
    height: 100%;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }
  .ant-drawer-top {
    top: 0;
  }
  .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-bottom,
  .ant-drawer-bottom .ant-drawer-content-wrapper {
    bottom: 0;
  }
  .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-bottom.ant-drawer-open.no-mask {
    bottom: 1px;
    transform: translateY(1px);
  }
  .ant-drawer.ant-drawer-open .ant-drawer-mask {
    -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    height: 100%;
    opacity: 1;
    pointer-events: auto;
    transition: none;
  }
  .ant-drawer-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }
  .ant-drawer-content {
    background-clip: padding-box;
    background-color: #fff;
    border: 0;
    overflow: auto;
    position: relative;
    z-index: 1;
  }
  .ant-drawer-close {
    text-rendering: auto;
    background: transparent;
    border: 0;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    outline: 0;
    padding: 20px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    top: 0;
    transition: color 0.3s;
    z-index: 10;
  }
  .ant-drawer-close:focus,
  .ant-drawer-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .ant-drawer-header-no-title .ant-drawer-close {
    margin-right: var(--scroll-bar);
    padding-right: calc(20px - var(--scroll-bar));
  }
  .ant-drawer-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    padding: 16px 24px;
    position: relative;
  }
  .ant-drawer-header,
  .ant-drawer-header-no-title {
    background: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
  }
  .ant-drawer-body {
    word-wrap: break-word;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.5715;
    overflow: auto;
    padding: 24px;
  }
  .ant-drawer-footer {
    border-top: 1px solid #f0f0f0;
    flex-shrink: 0;
    padding: 10px 16px;
  }
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=45);
    height: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.3s linear, height 0s ease 0.3s;
    width: 100%;
  }
  .ant-drawer-open-content {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-drawer .ant-picker-clear {
    background: #fff;
  }
  @-webkit-keyframes antdDrawerFadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes antdDrawerFadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .ant-drawer-rtl {
    direction: rtl;
  }
  .ant-drawer-rtl .ant-drawer-close {
    left: 0;
    right: auto;
  }
  .ant-form-item .ant-mentions,
  .ant-form-item textarea.ant-input {
    height: auto;
  }
  .ant-form-item .ant-upload {
    background: transparent;
  }
  .ant-form-item .ant-upload.ant-upload-drag {
    background: #fafafa;
  }
  .ant-form-item input[type='checkbox'],
  .ant-form-item input[type='radio'] {
    height: 14px;
    width: 14px;
  }
  .ant-form-item .ant-checkbox-inline,
  .ant-form-item .ant-radio-inline {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin-left: 8px;
    vertical-align: middle;
  }
  .ant-form-item .ant-checkbox-inline:first-child,
  .ant-form-item .ant-radio-inline:first-child {
    margin-left: 0;
  }
  .ant-form-item .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical {
    display: block;
  }
  .ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical + .ant-radio-vertical {
    margin-left: 0;
  }
  .ant-form-item .ant-input-number + .ant-form-text {
    margin-left: 8px;
  }
  .ant-form-item .ant-input-number-handler-wrap {
    z-index: 2;
  }
  .ant-form-item .ant-cascader-picker,
  .ant-form-item .ant-select {
    width: 100%;
  }
  .ant-form-item .ant-input-group .ant-cascader-picker,
  .ant-form-item .ant-input-group .ant-select,
  .ant-form-item .ant-picker-calendar-month-select,
  .ant-form-item .ant-picker-calendar-year-select {
    width: auto;
  }
  .ant-form-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-form-inline .ant-form-item {
    flex: none;
    flex-wrap: nowrap;
    margin-bottom: 0;
    margin-right: 16px;
  }
  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 24px;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-control,
  .ant-form-inline .ant-form-item > .ant-form-item-label {
    display: inline-block;
    vertical-align: top;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-label {
    flex: none;
  }
  .ant-form-inline .ant-form-item .ant-form-item-has-feedback,
  .ant-form-inline .ant-form-item .ant-form-text {
    display: inline-block;
  }
  .ant-form-horizontal .ant-form-item-label {
    flex-grow: 0;
  }
  .ant-form-horizontal .ant-form-item-control {
    flex: 1 1 0;
  }
  .ant-form-vertical .ant-form-item {
    flex-direction: column;
  }
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label,
  .ant-form-vertical .ant-form-item-label {
    line-height: 1.5715;
    padding: 0 0 8px;
    text-align: left;
    white-space: normal;
  }
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label,
  .ant-form-vertical .ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-24.ant-form-item-label > label:after,
  .ant-col-xl-24.ant-form-item-label > label:after,
  .ant-form-vertical .ant-form-item-label > label:after {
    display: none;
  }
  .ant-form-rtl.ant-col-24.ant-form-item-label,
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label,
  .ant-form-rtl.ant-form-vertical .ant-form-item-label {
    text-align: right;
  }
  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      line-height: 1.5715;
      padding: 0 0 8px;
      text-align: left;
      white-space: normal;
    }
    .ant-form-item .ant-form-item-label > label {
      margin: 0;
    }
    .ant-form-item .ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-rtl.ant-form-item .ant-form-item-label {
      text-align: right;
    }
    .ant-form .ant-form-item {
      flex-wrap: wrap;
    }
    .ant-form .ant-form-item .ant-form-item-control,
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xs-24.ant-form-item-label {
      line-height: 1.5715;
      padding: 0 0 8px;
      text-align: left;
      white-space: normal;
    }
    .ant-col-xs-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-xs-24.ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 767px) {
    .ant-col-sm-24.ant-form-item-label {
      line-height: 1.5715;
      padding: 0 0 8px;
      text-align: left;
      white-space: normal;
    }
    .ant-col-sm-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-sm-24.ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 991px) {
    .ant-col-md-24.ant-form-item-label {
      line-height: 1.5715;
      padding: 0 0 8px;
      text-align: left;
      white-space: normal;
    }
    .ant-col-md-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-md-24.ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-rtl.ant-col-md-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1199px) {
    .ant-col-lg-24.ant-form-item-label {
      line-height: 1.5715;
      padding: 0 0 8px;
      text-align: left;
      white-space: normal;
    }
    .ant-col-lg-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-lg-24.ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1599px) {
    .ant-col-xl-24.ant-form-item-label {
      line-height: 1.5715;
      padding: 0 0 8px;
      text-align: left;
      white-space: normal;
    }
    .ant-col-xl-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-xl-24.ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
      text-align: right;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
  }
  .ant-form-item-explain.ant-form-item-explain-warning {
    color: #faad14;
  }
  .ant-form-item-has-feedback .ant-input {
    padding-right: 24px;
  }
  .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 18px;
  }
  .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: 28px;
  }
  .ant-form-item-has-feedback .ant-switch {
    margin: 2px 0 4px;
  }
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
  .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback > .ant-select .ant-select-clear {
    right: 32px;
  }
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
  .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value {
    padding-right: 42px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 19px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: 32px;
  }
  .ant-form-item-has-feedback .ant-picker,
  .ant-form-item-has-feedback .ant-picker-large {
    padding-right: 29.2px;
  }
  .ant-form-item-has-feedback .ant-picker-small {
    padding-right: 25.2px;
  }
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    visibility: visible;
    width: 32px;
    z-index: 1;
  }
  .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
    color: #52c41a;
  }
  .ant-form-item-has-warning .ant-form-item-split {
    color: #faad14;
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
  .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-form-item-has-warning .ant-input:not(.ant-input-disabled),
  .ant-form-item-has-warning .ant-input:not(.ant-input-disabled):hover {
    background-color: #fff;
    border-color: #faad14;
  }
  .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input,
  .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) -focused,
  .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
  .ant-form-item-has-warning .ant-input:not(.ant-input-disabled) -focused,
  .ant-form-item-has-warning .ant-input:not(.ant-input-disabled):focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    outline: 0;
  }
  .ant-form-item-has-warning .ant-input-prefix {
    color: #faad14;
  }
  .ant-form-item-has-warning .ant-input-group-addon {
    border-color: #faad14;
    color: #faad14;
  }
  .ant-form-item-has-warning .has-feedback {
    color: #faad14;
  }
  .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
    -webkit-animation-name: diffZoomIn3 !important;
    animation-name: diffZoomIn3 !important;
    color: #faad14;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border-color: #faad14 !important;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
  .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    outline: 0;
  }
  .ant-form-item-has-warning .ant-input-number,
  .ant-form-item-has-warning .ant-picker {
    background-color: #fff;
    border-color: #faad14;
  }
  .ant-form-item-has-warning .ant-input-number-focused,
  .ant-form-item-has-warning .ant-input-number:focus,
  .ant-form-item-has-warning .ant-picker-focused,
  .ant-form-item-has-warning .ant-picker:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    outline: 0;
  }
  .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
    background-color: #fff;
    border-color: #faad14;
  }
  .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-form-item-split {
    color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-form-item-has-error .ant-input:not(.ant-input-disabled),
  .ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input,
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) -focused,
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
  .ant-form-item-has-error .ant-input:not(.ant-input-disabled) -focused,
  .ant-form-item-has-error .ant-input:not(.ant-input-disabled):focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-input-prefix {
    color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-group-addon {
    border-color: #ff4d4f;
    color: #ff4d4f;
  }
  .ant-form-item-has-error .has-feedback {
    color: #ff4d4f;
  }
  .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
    -webkit-animation-name: diffZoomIn2 !important;
    animation-name: diffZoomIn2 !important;
    color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border-color: #ff4d4f !important;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
    border-color: #ff7875;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 0;
    box-shadow: none;
  }
  .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number-focused,
  .ant-form-item-has-error .ant-input-number:focus,
  .ant-form-item-has-error .ant-picker-focused,
  .ant-form-item-has-error .ant-picker:focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,
  .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-cascader-picker:hover .ant-cascader-picker-label:hover + .ant-cascader-input.ant-input {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
    background-color: #fff;
    border-color: #ff7875;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-transfer-list {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-form-item-has-error .ant-radio-button-wrapper {
    border-color: #ff4d4f !important;
  }
  .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child):before {
    background-color: #ff4d4f;
  }
  .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #1890ff;
    display: inline-block;
  }
  .ant-form {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-form legend {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.45);
    display: block;
    font-size: 16px;
    line-height: inherit;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
  }
  .ant-form label {
    font-size: 14px;
  }
  .ant-form input[type='search'] {
    box-sizing: border-box;
  }
  .ant-form input[type='checkbox'],
  .ant-form input[type='radio'] {
    line-height: normal;
  }
  .ant-form input[type='file'] {
    display: block;
  }
  .ant-form input[type='range'] {
    display: block;
    width: 100%;
  }
  .ant-form select[multiple],
  .ant-form select[size] {
    height: auto;
  }
  .ant-form input[type='checkbox']:focus,
  .ant-form input[type='file']:focus,
  .ant-form input[type='radio']:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .ant-form output {
    color: rgba(0, 0, 0, 0.85);
    display: block;
    font-size: 14px;
    line-height: 1.5715;
    padding-top: 15px;
  }
  .ant-form .ant-form-text {
    display: inline-block;
    padding-right: 8px;
  }
  .ant-form-small .ant-form-item-label > label {
    height: 24px;
  }
  .ant-form-small .ant-form-item-control-input {
    min-height: 24px;
  }
  .ant-form-large .ant-form-item-label > label {
    height: 40px;
  }
  .ant-form-large .ant-form-item-control-input {
    min-height: 40px;
  }
  .ant-form-item {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0 0 24px;
    padding: 0;
    vertical-align: top;
  }
  .ant-form-item-with-help {
    margin-bottom: 0;
  }
  .ant-form-item-hidden,
  .ant-form-item-hidden.ant-row {
    display: none;
  }
  .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
  }
  .ant-form-item-label-left {
    text-align: left;
  }
  .ant-form-item-label > label {
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    display: inline-flex;
    font-size: 14px;
    height: 32px;
    position: relative;
  }
  .ant-form-item-label > label > .anticon {
    font-size: 14px;
    vertical-align: top;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    color: #ff4d4f;
    content: '*';
    display: inline-block;
    font-family: SimSun, sans-serif;
    font-size: 14px;
    line-height: 1;
    margin-right: 4px;
  }
  .ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
  }
  .ant-form-item-label > label .ant-form-item-optional {
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
    margin-left: 4px;
  }
  .ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
    display: none;
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    -webkit-margin-start: 4px;
    color: rgba(0, 0, 0, 0.45);
    cursor: help;
    margin-inline-start: 4px;
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
  }
  .ant-form-item-label > label:after {
    content: ':';
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
  }
  .ant-form-item-label > label.ant-form-item-no-colon:after {
    content: ' ';
  }
  .ant-form-item-control {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 100%;
  }
  .ant-form-item-control-input {
    align-items: center;
    display: flex;
    min-height: 32px;
    position: relative;
  }
  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    clear: both;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    min-height: 24px;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ant-form-item .ant-input-textarea-show-count:after {
    margin-bottom: -22px;
  }
  .ant-show-help-appear,
  .ant-show-help-enter,
  .ant-show-help-leave {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-show-help-appear.ant-show-help-appear-active,
  .ant-show-help-enter.ant-show-help-enter-active {
    -webkit-animation-name: antShowHelpIn;
    animation-name: antShowHelpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-show-help-leave.ant-show-help-leave-active {
    -webkit-animation-name: antShowHelpOut;
    animation-name: antShowHelpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  .ant-show-help-appear,
  .ant-show-help-enter {
    opacity: 0;
  }
  .ant-show-help-appear,
  .ant-show-help-enter,
  .ant-show-help-leave {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  @-webkit-keyframes antShowHelpIn {
    0% {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes antShowHelpIn {
    0% {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @-webkit-keyframes antShowHelpOut {
    to {
      opacity: 0;
      transform: translateY(-5px);
    }
  }
  @keyframes antShowHelpOut {
    to {
      opacity: 0;
      transform: translateY(-5px);
    }
  }
  @-webkit-keyframes diffZoomIn1 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn1 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes diffZoomIn2 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn2 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes diffZoomIn3 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn3 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  .ant-form-rtl {
    direction: rtl;
  }
  .ant-form-rtl .ant-form-item-label {
    text-align: left;
  }
  .ant-form-rtl .ant-form-item-label > label.ant-form-item-required:before {
    margin-left: 4px;
    margin-right: 0;
  }
  .ant-form-rtl .ant-form-item-label > label:after {
    margin: 0 2px 0 8px;
  }
  .ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-col-rtl .ant-form-item-control:first-child {
    width: 100%;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input {
    padding-left: 24px;
    padding-right: 11px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-left: 18px;
    padding-right: 11px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
    padding: 0;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    left: 28px;
    right: auto;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
    padding-left: 18px;
  }
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear {
    left: 32px;
    right: auto;
  }
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value {
    padding-left: 42px;
    padding-right: 0;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-left: 19px;
    margin-right: 0;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
    left: 32px;
    right: auto;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker,
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
    padding-left: 29.2px;
    padding-right: 11px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
    padding-left: 25.2px;
    padding-right: 7px;
  }
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    left: 0;
    right: auto;
  }
  .ant-form-rtl.ant-form-inline .ant-form-item {
    margin-left: 16px;
    margin-right: 0;
  }
  .ant-image {
    display: inline-block;
    position: relative;
  }
  .ant-image-img {
    display: block;
    height: auto;
    width: 100%;
  }
  .ant-image-img-placeholder {
    background-color: #f5f5f5;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjUgMi41aC0xM0EuNS41IDAgMCAwIDEgM3YxMGEuNS41IDAgMCAwIC41LjVoMTNhLjUuNSAwIDAgMCAuNS0uNVYzYS41LjUgMCAwIDAtLjUtLjV6TTUuMjgxIDQuNzVhMSAxIDAgMCAxIDAgMiAxIDEgMCAwIDEgMC0yem04LjAzIDYuODNhLjEyNy4xMjcgMCAwIDEtLjA4MS4wM0gyLjc2OWEuMTI1LjEyNSAwIDAgMS0uMDk2LS4yMDdsMi42NjEtMy4xNTZhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTYuMDE2TDcuMDggMTAuMDlsMi40Ny0yLjkzYS4xMjYuMTI2IDAgMCAxIC4xNzctLjAxNmwuMDE1LjAxNiAzLjU4OCA0LjI0NGEuMTI3LjEyNyAwIDAgMS0uMDIuMTc1eiIgZmlsbD0iIzhDOEM4QyIvPjwvc3ZnPg==);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 30%;
  }
  .ant-image-mask {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.3s;
  }
  .ant-image-mask-info .anticon {
    -webkit-margin-end: 4px;
    margin-inline-end: 4px;
  }
  .ant-image-mask:hover {
    opacity: 1;
  }
  .ant-image-placeholder {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-image-preview {
    height: 100%;
    pointer-events: none;
    text-align: center;
  }
  .ant-image-preview.ant-zoom-enter,
  .ant-image-preview.antzoom-appear {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    opacity: 0;
    transform: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.45);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  .ant-image-preview-mask-hidden {
    display: none;
  }
  .ant-image-preview-wrap {
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    left: 0;
    outline: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
  }
  .ant-image-preview-body {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-image-preview-img {
    cursor: -webkit-grab;
    cursor: grab;
    max-height: 100%;
    max-width: 100%;
    pointer-events: auto;
    transform: scaleX(1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  .ant-image-preview-img,
  .ant-image-preview-img-wrapper {
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .ant-image-preview-img-wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-image-preview-img-wrapper:before {
    content: '';
    display: inline-block;
    height: 50%;
    margin-right: -1px;
    width: 1px;
  }
  .ant-image-preview-moving .ant-image-preview-img {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .ant-image-preview-moving .ant-image-preview-img-wrapper {
    transition-duration: 0s;
  }
  .ant-image-preview-wrap {
    z-index: 1080;
  }
  .ant-image-preview-operations {
    font-feature-settings: 'tnum';
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: row-reverse;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .ant-image-preview-operations-operation {
    cursor: pointer;
    margin-left: 12px;
    padding: 12px;
  }
  .ant-image-preview-operations-operation-disabled {
    color: rgba(255, 255, 255, 0.25);
    pointer-events: none;
  }
  .ant-image-preview-operations-operation:last-of-type {
    margin-left: 0;
  }
  .ant-image-preview-operations-icon {
    font-size: 18px;
  }
  .ant-image-preview-switch-left,
  .ant-image-preview-switch-right {
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    margin-top: -22px;
    pointer-events: auto;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 44px;
    z-index: 1;
  }
  .ant-image-preview-switch-left-disabled,
  .ant-image-preview-switch-right-disabled {
    color: rgba(255, 255, 255, 0.25);
    cursor: not-allowed;
  }
  .ant-image-preview-switch-left-disabled > .anticon,
  .ant-image-preview-switch-right-disabled > .anticon {
    cursor: not-allowed;
  }
  .ant-image-preview-switch-left > .anticon,
  .ant-image-preview-switch-right > .anticon {
    font-size: 18px;
  }
  .ant-image-preview-switch-left {
    left: 10px;
  }
  .ant-image-preview-switch-right {
    right: 10px;
  }
  .ant-input-number {
    font-feature-settings: 'tnum';
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding: 0;
    position: relative;
    transition: all 0.3s;
    width: 100%;
    width: 90px;
  }
  .ant-input-number::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-number:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-number::placeholder {
    color: #bfbfbf;
  }
  .ant-input-number:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-input-number:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number-focused,
  .ant-input-number:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-input-number[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number-borderless,
  .ant-input-number-borderless-disabled,
  .ant-input-number-borderless-focused,
  .ant-input-number-borderless:focus,
  .ant-input-number-borderless:hover,
  .ant-input-number-borderless[disabled] {
    background-color: initial;
    border: none;
    box-shadow: none;
  }
  textarea.ant-input-number {
    height: auto;
    line-height: 1.5715;
    max-width: 100%;
    min-height: 32px;
    transition: all 0.3s, height 0s;
    vertical-align: bottom;
  }
  .ant-input-number-lg {
    padding: 6.5px 11px;
  }
  .ant-input-number-sm {
    padding: 0 7px;
  }
  .ant-input-number-handler {
    color: rgba(0, 0, 0, 0.45);
    display: block;
    font-weight: 700;
    height: 50%;
    line-height: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: all 0.1s linear;
    width: 100%;
  }
  .ant-input-number-handler:active {
    background: #f4f4f4;
  }
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
    color: #40a9ff;
  }
  .ant-input-number-handler-down-inner,
  .ant-input-number-handler-up-inner {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
    font-style: normal;
    height: 12px;
    line-height: 0;
    line-height: 12px;
    position: absolute;
    right: 4px;
    text-align: center;
    text-transform: none;
    transition: all 0.1s linear;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: -0.125em;
    width: 12px;
  }
  .ant-input-number-handler-down-inner > *,
  .ant-input-number-handler-up-inner > * {
    line-height: 1;
  }
  .ant-input-number-handler-down-inner svg,
  .ant-input-number-handler-up-inner svg {
    display: inline-block;
  }
  .ant-input-number-handler-down-inner:before,
  .ant-input-number-handler-up-inner:before {
    display: none;
  }
  .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
  .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon {
    display: block;
  }
  .ant-input-number:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-number:hover + .ant-form-item-children-icon {
    opacity: 0;
    transition: opacity 0.24s linear 0.24s;
  }
  .ant-input-number-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-input-number-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number-disabled .ant-input-number-input {
    cursor: not-allowed;
  }
  .ant-input-number-disabled .ant-input-number-handler-wrap,
  .ant-input-number-readonly .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    -moz-appearance: textfield !important;
    background-color: initial;
    border: 0;
    border-radius: 2px;
    height: 30px;
    outline: 0;
    padding: 0 11px;
    text-align: left;
    transition: all 0.3s linear;
    width: 100%;
  }
  .ant-input-number-input::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-number-input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-number-input::placeholder {
    color: #bfbfbf;
  }
  .ant-input-number-input:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number-input:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-input-number-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number-input[type='number']::-webkit-inner-spin-button,
  .ant-input-number-input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-input-number-lg {
    font-size: 16px;
    padding: 0;
  }
  .ant-input-number-lg input {
    height: 38px;
  }
  .ant-input-number-sm {
    padding: 0;
  }
  .ant-input-number-sm input {
    height: 22px;
    padding: 0 7px;
  }
  .ant-input-number-handler-wrap {
    background: #fff;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 2px 2px 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.24s linear 0.1s;
    width: 22px;
  }
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
    font-size: 7px;
    margin-right: 0;
    min-width: auto;
  }
  .ant-input-number-borderless .ant-input-number-handler-wrap {
    border-left-width: 0;
  }
  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 40%;
  }
  .ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 1;
  }
  .ant-input-number-handler-up {
    border-top-right-radius: 2px;
    cursor: pointer;
  }
  .ant-input-number-handler-up-inner {
    margin-top: -5px;
    text-align: center;
    top: 50%;
  }
  .ant-input-number-handler-up:hover {
    height: 60% !important;
  }
  .ant-input-number-handler-down {
    border-bottom-right-radius: 2px;
    border-top: 1px solid #d9d9d9;
    cursor: pointer;
    top: 0;
  }
  .ant-input-number-handler-down-inner {
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-input-number-handler-down:hover {
    height: 60% !important;
  }
  .ant-input-number-borderless .ant-input-number-handler-down {
    border-top-width: 0;
  }
  .ant-input-number-handler-down-disabled,
  .ant-input-number-handler-up-disabled {
    cursor: not-allowed;
  }
  .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
  .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-input-number-borderless {
    box-shadow: none;
  }
  .ant-input-number-out-of-range input {
    color: #ff4d4f;
  }
  .ant-input-number-rtl {
    direction: rtl;
  }
  .ant-input-number-rtl .ant-input-number-handler-wrap {
    border-left: 0;
    border-radius: 2px 0 0 2px;
    border-right: 1px solid #d9d9d9;
    left: 0;
    right: auto;
  }
  .ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
    border-right-width: 0;
  }
  .ant-input-number-rtl .ant-input-number-input {
    direction: ltr;
    text-align: right;
  }
  .ant-layout {
    background: #f0f2f5;
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
  }
  .ant-layout,
  .ant-layout * {
    box-sizing: border-box;
  }
  .ant-layout.ant-layout-has-sider {
    flex-direction: row;
  }
  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 0;
  }
  .ant-layout-footer,
  .ant-layout-header {
    flex: 0 0 auto;
  }
  .ant-layout-header {
    background: #001529;
    color: rgba(0, 0, 0, 0.85);
    height: 64px;
    line-height: 64px;
    padding: 0 50px;
  }
  .ant-layout-footer {
    background: #f0f2f5;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    padding: 24px 50px;
  }
  .ant-layout-content {
    flex: auto;
    min-height: 0;
  }
  .ant-layout-sider {
    background: #001529;
    min-width: 0;
    position: relative;
    transition: all 0.2s;
  }
  .ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
  }
  .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: auto;
  }
  .ant-layout-sider-has-trigger {
    padding-bottom: 48px;
  }
  .ant-layout-sider-right {
    order: 1;
  }
  .ant-layout-sider-trigger {
    background: #002140;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    position: fixed;
    text-align: center;
    transition: all 0.2s;
    z-index: 1;
  }
  .ant-layout-sider-zero-width > * {
    overflow: hidden;
  }
  .ant-layout-sider-zero-width-trigger {
    background: #001529;
    border-radius: 0 2px 2px 0;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    height: 42px;
    line-height: 42px;
    position: absolute;
    right: -36px;
    text-align: center;
    top: 64px;
    transition: background 0.3s ease;
    width: 36px;
    z-index: 1;
  }
  .ant-layout-sider-zero-width-trigger:after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s;
  }
  .ant-layout-sider-zero-width-trigger:hover:after {
    background: rgba(255, 255, 255, 0.1);
  }
  .ant-layout-sider-zero-width-trigger-right {
    border-radius: 2px 0 0 2px;
    left: -36px;
  }
  .ant-layout-sider-light {
    background: #fff;
  }
  .ant-layout-sider-light .ant-layout-sider-trigger,
  .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    background: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-layout-rtl {
    direction: rtl;
  }
  .ant-list {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ant-list * {
    outline: none;
  }
  .ant-list-pagination {
    margin-top: 24px;
    text-align: right;
  }
  .ant-list-pagination .ant-pagination-options {
    text-align: left;
  }
  .ant-list-more {
    margin-top: 12px;
    text-align: center;
  }
  .ant-list-more button {
    padding-left: 32px;
    padding-right: 32px;
  }
  .ant-list-spin {
    min-height: 40px;
    text-align: center;
  }
  .ant-list-empty-text {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    padding: 16px;
    text-align: center;
  }
  .ant-list-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-list-item {
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
  }
  .ant-list-item-meta {
    align-items: flex-start;
    display: flex;
    flex: 1;
    max-width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 16px;
  }
  .ant-list-item-meta-content {
    color: rgba(0, 0, 0, 0.85);
    flex: 1 0;
    width: 0;
  }
  .ant-list-item-meta-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    margin-bottom: 4px;
  }
  .ant-list-item-meta-title > a {
    color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;
  }
  .ant-list-item-meta-title > a:hover {
    color: #1890ff;
  }
  .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-list-item-action {
    flex: 0 0 auto;
    font-size: 0;
    list-style: none;
    margin-left: 48px;
    padding: 0;
  }
  .ant-list-item-action > li {
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
    font-size: 14px;
    line-height: 1.5715;
    padding: 0 8px;
    position: relative;
    text-align: center;
  }
  .ant-list-item-action > li:first-child {
    padding-left: 0;
  }
  .ant-list-item-action-split {
    background-color: #f0f0f0;
    height: 14px;
    margin-top: -7px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
  }
  .ant-list-footer,
  .ant-list-header {
    background: transparent;
  }
  .ant-list-footer,
  .ant-list-header {
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .ant-list-empty {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    padding: 16px 0;
    text-align: center;
  }
  .ant-list-split .ant-list-item {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-list-split .ant-list-item:last-child {
    border-bottom: none;
  }
  .ant-list-split .ant-list-header {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-list-split.ant-list-empty .ant-list-footer {
    border-top: 1px solid #f0f0f0;
  }
  .ant-list-loading .ant-list-spin-nested-loading {
    min-height: 32px;
  }
  .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-list-lg .ant-list-item {
    padding: 16px 24px;
  }
  .ant-list-sm .ant-list-item {
    padding: 8px 16px;
  }
  .ant-list-vertical .ant-list-item {
    align-items: normal;
  }
  .ant-list-vertical .ant-list-item-main {
    display: block;
    flex: 1;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 40px;
  }
  .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 16px;
  }
  .ant-list-vertical .ant-list-item-meta-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .ant-list-vertical .ant-list-item-action {
    margin-left: auto;
    margin-top: 16px;
  }
  .ant-list-vertical .ant-list-item-action > li {
    padding: 0 16px;
  }
  .ant-list-vertical .ant-list-item-action > li:first-child {
    padding-left: 0;
  }
  .ant-list-grid .ant-col > .ant-list-item {
    border-bottom: none;
    display: block;
    margin-bottom: 16px;
    max-width: 100%;
    padding-bottom: 0;
    padding-top: 0;
  }
  .ant-list-item-no-flex {
    display: block;
  }
  .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
    float: right;
  }
  .ant-list-bordered {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .ant-list-bordered .ant-list-footer,
  .ant-list-bordered .ant-list-header,
  .ant-list-bordered .ant-list-item {
    padding-left: 24px;
    padding-right: 24px;
  }
  .ant-list-bordered .ant-list-pagination {
    margin: 16px 24px;
  }
  .ant-list-bordered.ant-list-sm .ant-list-footer,
  .ant-list-bordered.ant-list-sm .ant-list-header,
  .ant-list-bordered.ant-list-sm .ant-list-item {
    padding: 8px 16px;
  }
  .ant-list-bordered.ant-list-lg .ant-list-footer,
  .ant-list-bordered.ant-list-lg .ant-list-header,
  .ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 16px 24px;
  }
  @media screen and (max-width: 768px) {
    .ant-list-item-action,
    .ant-list-vertical .ant-list-item-extra {
      margin-left: 24px;
    }
  }
  @media screen and (max-width: 576px) {
    .ant-list-item {
      flex-wrap: wrap;
    }
    .ant-list-item-action {
      margin-left: 12px;
    }
    .ant-list-vertical .ant-list-item {
      flex-wrap: wrap-reverse;
    }
    .ant-list-vertical .ant-list-item-main {
      min-width: 220px;
    }
    .ant-list-vertical .ant-list-item-extra {
      margin: auto auto 16px;
    }
  }
  .ant-list-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-list-rtl .ReactVirtualized__List .ant-list-item {
    direction: rtl;
  }
  .ant-list-rtl .ant-list-pagination {
    text-align: left;
  }
  .ant-list-rtl .ant-list-item-meta-avatar {
    margin-left: 16px;
    margin-right: 0;
  }
  .ant-list-rtl .ant-list-item-action {
    margin-left: 0;
    margin-right: 48px;
  }
  .ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
    padding-left: 16px;
    padding-right: 0;
  }
  .ant-list-rtl .ant-list-item-action-split {
    left: 0;
    right: auto;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin-left: 0;
    margin-right: 40px;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-action {
    margin-right: auto;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
    padding-left: 16px;
    padding-right: 0;
  }
  .ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
    float: left;
  }
  @media screen and (max-width: 768px) {
    .ant-list-rtl .ant-list-item-action,
    .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
      margin-left: 0;
      margin-right: 24px;
    }
  }
  @media screen and (max-width: 576px) {
    .ant-list-rtl .ant-list-item-action {
      margin-left: 0;
      margin-right: 22px;
    }
    .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
      margin: auto auto 16px;
    }
  }
  .ant-spin {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    color: #1890ff;
    display: none;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    vertical-align: middle;
  }
  .ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    position: static;
  }
  .ant-spin-nested-loading {
    position: relative;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    display: block;
    height: 100%;
    left: 0;
    max-height: 400px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    left: 50%;
    margin: -10px;
    position: absolute;
    top: 50%;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    padding-top: 5px;
    position: absolute;
    text-shadow: 0 1px 2px #fff;
    top: 50%;
    width: 100%;
  }
  .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -20px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
    margin: -7px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
    padding-top: 2px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
    margin-top: -17px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: -16px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    padding-top: 11px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
    margin-top: -26px;
  }
  .ant-spin-container {
    position: relative;
    transition: opacity 0.3s;
  }
  .ant-spin-container:after {
    background: #fff;
    bottom: 0;
    content: '';
    display: none\9;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s;
    width: 100%;
    z-index: 10;
  }
  .ant-spin-blur {
    clear: both;
    opacity: 0.5;
    overflow: hidden;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-spin-blur:after {
    opacity: 0.4;
    pointer-events: auto;
  }
  .ant-spin-tip {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-spin-dot {
    display: inline-block;
    font-size: 20px;
    height: 1em;
    position: relative;
    width: 1em;
  }
  .ant-spin-dot-item {
    -webkit-animation: antSpinMove 1s linear infinite alternate;
    animation: antSpinMove 1s linear infinite alternate;
    background-color: #1890ff;
    border-radius: 100%;
    display: block;
    height: 9px;
    opacity: 0.3;
    position: absolute;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    width: 9px;
  }
  .ant-spin-dot-item:first-child {
    left: 0;
    top: 0;
  }
  .ant-spin-dot-item:nth-child(2) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    right: 0;
    top: 0;
  }
  .ant-spin-dot-item:nth-child(3) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    bottom: 0;
    right: 0;
  }
  .ant-spin-dot-item:nth-child(4) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
    bottom: 0;
    left: 0;
  }
  .ant-spin-dot-spin {
    -webkit-animation: antRotate 1.2s linear infinite;
    animation: antRotate 1.2s linear infinite;
    transform: rotate(45deg);
  }
  .ant-spin-sm .ant-spin-dot {
    font-size: 14px;
  }
  .ant-spin-sm .ant-spin-dot i {
    height: 6px;
    width: 6px;
  }
  .ant-spin-lg .ant-spin-dot {
    font-size: 32px;
  }
  .ant-spin-lg .ant-spin-dot i {
    height: 14px;
    width: 14px;
  }
  .ant-spin.ant-spin-show-text .ant-spin-text {
    display: block;
  }
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ant-spin-blur {
      background: #fff;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }
  @keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes antRotate {
    to {
      transform: rotate(405deg);
    }
  }
  @keyframes antRotate {
    to {
      transform: rotate(405deg);
    }
  }
  .ant-spin-rtl {
    direction: rtl;
  }
  .ant-spin-rtl .ant-spin-dot-spin {
    -webkit-animation-name: antRotateRtl;
    animation-name: antRotateRtl;
    transform: rotate(-45deg);
  }
  @-webkit-keyframes antRotateRtl {
    to {
      transform: rotate(-405deg);
    }
  }
  @keyframes antRotateRtl {
    to {
      transform: rotate(-405deg);
    }
  }
  .ant-pagination {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
  }
  .ant-pagination,
  .ant-pagination ol,
  .ant-pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-pagination:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  .ant-pagination-item,
  .ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    line-height: 30px;
    margin-right: 8px;
    vertical-align: middle;
  }
  .ant-pagination-item {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    list-style: none;
    min-width: 32px;
    outline: 0;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-pagination-item a {
    color: rgba(0, 0, 0, 0.85);
    display: block;
    padding: 0 6px;
    transition: none;
  }
  .ant-pagination-item a:hover {
    text-decoration: none;
  }
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover {
    border-color: #1890ff;
    transition: all 0.3s;
  }
  .ant-pagination-item:focus-visible a,
  .ant-pagination-item:hover a {
    color: #1890ff;
  }
  .ant-pagination-item-active {
    background: #fff;
    border-color: #1890ff;
    font-weight: 500;
  }
  .ant-pagination-item-active a {
    color: #1890ff;
  }
  .ant-pagination-item-active:focus-visible,
  .ant-pagination-item-active:hover {
    border-color: #40a9ff;
  }
  .ant-pagination-item-active:focus-visible a,
  .ant-pagination-item-active:hover a {
    color: #40a9ff;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    outline: 0;
  }
  .ant-pagination-jump-next .ant-pagination-item-container,
  .ant-pagination-jump-prev .ant-pagination-item-container {
    position: relative;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #1890ff;
    font-size: 12px;
    letter-spacing: -1px;
    opacity: 0;
    transition: all 0.2s;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    bottom: 0;
    left: 0;
    margin: auto;
    right: 0;
    top: 0;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    bottom: 0;
    color: rgba(0, 0, 0, 0.25);
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    left: 0;
    letter-spacing: 2px;
    margin: auto;
    opacity: 1;
    position: absolute;
    right: 0;
    text-align: center;
    text-indent: 0.13em;
    top: 0;
    transition: all 0.2s;
  }
  .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
    opacity: 1;
  }
  .ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
    opacity: 0;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-prev {
    margin-right: 8px;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev {
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    height: 32px;
    line-height: 32px;
    list-style: none;
    min-width: 32px;
    text-align: center;
    transition: all 0.3s;
    vertical-align: middle;
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    font-family: Arial, Helvetica, sans-serif;
    outline: 0;
  }
  .ant-pagination-next button,
  .ant-pagination-prev button {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-pagination-next:hover button,
  .ant-pagination-prev:hover button {
    border-color: #40a9ff;
  }
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: block;
    font-size: 12px;
    height: 100%;
    outline: none;
    padding: 0;
    text-align: center;
    transition: all 0.3s;
    width: 100%;
  }
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: #1890ff;
    color: #1890ff;
  }
  .ant-pagination-disabled,
  .ant-pagination-disabled:focus-visible,
  .ant-pagination-disabled:hover {
    cursor: not-allowed;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media (-ms-high-contrast: none) {
    .ant-pagination-options,
    .ant-pagination-options ::-ms-backdrop {
      vertical-align: top;
    }
  }
  .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
  }
  .ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    margin-left: 8px;
    vertical-align: top;
  }
  .ant-pagination-options-quick-jumper input {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    height: 32px;
    line-height: 1.5715;
    margin: 0 8px;
    min-width: 0;
    padding: 4px 11px;
    position: relative;
    transition: all 0.3s;
    width: 100%;
    width: 50px;
  }
  .ant-pagination-options-quick-jumper input::-moz-placeholder {
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input::placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input-focused,
  .ant-pagination-options-quick-jumper input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-pagination-options-quick-jumper input-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input-borderless,
  .ant-pagination-options-quick-jumper input-borderless-disabled,
  .ant-pagination-options-quick-jumper input-borderless-focused,
  .ant-pagination-options-quick-jumper input-borderless:focus,
  .ant-pagination-options-quick-jumper input-borderless:hover,
  .ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: initial;
    border: none;
    box-shadow: none;
  }
  textarea.ant-pagination-options-quick-jumper input {
    height: auto;
    line-height: 1.5715;
    max-width: 100%;
    min-height: 32px;
    transition: all 0.3s, height 0s;
    vertical-align: bottom;
  }
  .ant-pagination-options-quick-jumper input-lg {
    font-size: 16px;
    padding: 6.5px 11px;
  }
  .ant-pagination-options-quick-jumper input-sm {
    padding: 0 7px;
  }
  .ant-pagination-simple .ant-pagination-next,
  .ant-pagination-simple .ant-pagination-prev {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
    background-color: initial;
    border: 0;
    height: 24px;
  }
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after,
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    outline: none;
    padding: 0 6px;
    text-align: center;
    transition: border-color 0.3s;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #1890ff;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-pagination.mini .ant-pagination-simple-pager,
  .ant-pagination.mini .ant-pagination-total-text {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-item {
    height: 24px;
    line-height: 22px;
    margin: 0;
    min-width: 24px;
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-next,
  .ant-pagination.mini .ant-pagination-prev {
    height: 24px;
    line-height: 24px;
    margin: 0;
    min-width: 24px;
  }
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after,
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-jump-next,
  .ant-pagination.mini .ant-pagination-jump-prev {
    height: 24px;
    line-height: 24px;
    margin-right: 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    margin-left: 2px;
  }
  .ant-pagination.mini .ant-pagination-options-size-changer {
    top: 0;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    height: 24px;
    padding: 0 7px;
    width: 44px;
  }
  .ant-pagination.ant-pagination-disabled {
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: #dbdbdb;
    border-color: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
    opacity: 0;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
    opacity: 1;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 992px) {
    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-pagination-options {
      display: none;
    }
  }
  .ant-pagination-rtl .ant-pagination-item,
  .ant-pagination-rtl .ant-pagination-jump-next,
  .ant-pagination-rtl .ant-pagination-jump-prev,
  .ant-pagination-rtl .ant-pagination-prev,
  .ant-pagination-rtl .ant-pagination-total-text {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-pagination-rtl .ant-pagination-slash {
    margin: 0 5px 0 10px;
  }
  .ant-pagination-rtl .ant-pagination-options {
    margin-left: 0;
    margin-right: 16px;
  }
  .ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
    margin-left: 0;
  }
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager,
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
    margin-left: 0;
    margin-right: 2px;
  }
  .ant-mentions {
    font-feature-settings: 'tnum';
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    height: auto;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: all 0.3s;
    vertical-align: bottom;
    white-space: pre-wrap;
    width: 100%;
  }
  .ant-mentions::-moz-placeholder {
    opacity: 1;
  }
  .ant-mentions:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-mentions::placeholder {
    color: #bfbfbf;
  }
  .ant-mentions:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-mentions:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-mentions:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-mentions-focused,
  .ant-mentions:focus,
  .ant-mentions:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-mentions-focused,
  .ant-mentions:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-mentions-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-mentions-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-mentions[disabled] {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-mentions[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-mentions-borderless,
  .ant-mentions-borderless-disabled,
  .ant-mentions-borderless-focused,
  .ant-mentions-borderless:focus,
  .ant-mentions-borderless:hover,
  .ant-mentions-borderless[disabled] {
    background-color: initial;
    border: none;
    box-shadow: none;
  }
  textarea.ant-mentions {
    height: auto;
    line-height: 1.5715;
    max-width: 100%;
    min-height: 32px;
    transition: all 0.3s, height 0s;
    vertical-align: bottom;
  }
  .ant-mentions-lg {
    font-size: 16px;
    padding: 6.5px 11px;
  }
  .ant-mentions-sm {
    padding: 0 7px;
  }
  .ant-mentions-disabled > textarea {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-mentions-disabled > textarea:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-mentions-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
  .ant-mentions-measure,
  .ant-mentions > textarea {
    word-wrap: break-word;
    direction: inherit;
    font-family: inherit;
    font-size: inherit;
    font-size-adjust: inherit;
    font-stretch: inherit;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    min-height: 30px;
    overflow: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4px 11px;
    -moz-tab-size: inherit;
    -o-tab-size: inherit;
    tab-size: inherit;
    text-align: inherit;
    vertical-align: top;
    white-space: inherit;
    word-break: inherit;
  }
  .ant-mentions > textarea {
    border: none;
    outline: none;
    resize: none;
    width: 100%;
  }
  .ant-mentions > textarea::-moz-placeholder {
    opacity: 1;
  }
  .ant-mentions > textarea:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-mentions > textarea::placeholder {
    color: #bfbfbf;
  }
  .ant-mentions > textarea:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-mentions > textarea:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-mentions > textarea:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-mentions-measure {
    bottom: 0;
    color: transparent;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .ant-mentions-measure > span {
    display: inline-block;
    min-height: 1em;
  }
  .ant-mentions-dropdown {
    font-feature-settings: 'tnum';
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    font-variant: normal;
    left: -9999px;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: -9999px;
    z-index: 1050;
  }
  .ant-mentions-dropdown-hidden {
    display: none;
  }
  .ant-mentions-dropdown-menu {
    list-style: none;
    margin-bottom: 0;
    max-height: 250px;
    outline: none;
    overflow: auto;
    padding-left: 0;
  }
  .ant-mentions-dropdown-menu-item {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.5715;
    min-width: 100px;
    overflow: hidden;
    padding: 5px 12px;
    position: relative;
    text-overflow: ellipsis;
    transition: background 0.3s ease;
    white-space: nowrap;
  }
  .ant-mentions-dropdown-menu-item:hover {
    background-color: #f5f5f5;
  }
  .ant-mentions-dropdown-menu-item:first-child {
    border-radius: 2px 2px 0 0;
  }
  .ant-mentions-dropdown-menu-item:last-child {
    border-radius: 0 0 2px 2px;
  }
  .ant-mentions-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-mentions-dropdown-menu-item-disabled:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-mentions-dropdown-menu-item-selected {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }
  .ant-mentions-dropdown-menu-item-active {
    background-color: #f5f5f5;
  }
  .ant-mentions-rtl {
    direction: rtl;
  }
  .ant-message {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    left: 0;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    pointer-events: none;
    position: fixed;
    top: 8px;
    width: 100%;
    z-index: 1010;
  }
  .ant-message-notice {
    padding: 8px;
    text-align: center;
  }
  .ant-message-notice-content {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    display: inline-block;
    padding: 10px 16px;
    pointer-events: all;
  }
  .ant-message-success .anticon {
    color: #52c41a;
  }
  .ant-message-error .anticon {
    color: #ff4d4f;
  }
  .ant-message-warning .anticon {
    color: #faad14;
  }
  .ant-message-info .anticon,
  .ant-message-loading .anticon {
    color: #1890ff;
  }
  .ant-message .anticon {
    font-size: 16px;
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
  .ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: MessageMoveOut;
    animation-name: MessageMoveOut;
  }
  @-webkit-keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      opacity: 1;
      padding: 8px;
    }
    to {
      max-height: 0;
      opacity: 0;
      padding: 0;
    }
  }
  @keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      opacity: 1;
      padding: 8px;
    }
    to {
      max-height: 0;
      opacity: 0;
      padding: 0;
    }
  }
  .ant-message-rtl,
  .ant-message-rtl span {
    direction: rtl;
  }
  .ant-message-rtl .anticon {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-modal {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0 auto;
    max-width: calc(100vw - 32px);
    padding: 0 0 24px;
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
  }
  .ant-modal.ant-zoom-enter,
  .ant-modal.antzoom-appear {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    opacity: 0;
    transform: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  .ant-modal-mask-hidden {
    display: none;
  }
  .ant-modal-wrap {
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    left: 0;
    outline: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  .ant-modal-title {
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }
  .ant-modal-content {
    background-clip: padding-box;
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    position: relative;
  }
  .ant-modal-close {
    background: transparent;
    border: 0;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    outline: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    transition: color 0.3s;
    z-index: 10;
  }
  .ant-modal-close-x {
    text-rendering: auto;
    display: block;
    font-size: 16px;
    font-style: normal;
    height: 56px;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    width: 56px;
  }
  .ant-modal-close:focus,
  .ant-modal-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .ant-modal-header {
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    color: rgba(0, 0, 0, 0.85);
    padding: 16px 24px;
  }
  .ant-modal-body {
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.5715;
    padding: 24px;
  }
  .ant-modal-footer {
    background: transparent;
    border-radius: 0 0 2px 2px;
    border-top: 1px solid #f0f0f0;
    padding: 10px 16px;
    text-align: right;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .ant-modal-open {
    overflow: hidden;
  }
  .ant-modal-centered {
    text-align: center;
  }
  .ant-modal-centered:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
  .ant-modal-centered .ant-modal {
    display: inline-block;
    text-align: left;
    top: 0;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    .ant-modal {
      margin: 8px auto;
      max-width: calc(100vw - 16px);
    }
    .ant-modal-centered .ant-modal {
      flex: 1;
    }
  }
  .ant-modal-confirm .ant-modal-header {
    display: none;
  }
  .ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px;
  }
  .ant-modal-confirm-body-wrapper:before {
    content: '';
    display: table;
  }
  .ant-modal-confirm-body-wrapper:after {
    clear: both;
    content: '';
    display: table;
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: rgba(0, 0, 0, 0.85);
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    overflow: hidden;
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin-top: 8px;
  }
  .ant-modal-confirm-body > .anticon {
    float: left;
    font-size: 22px;
    margin-right: 16px;
  }
  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 38px;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px;
  }
  .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #ff4d4f;
  }
  .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
  .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon {
    color: #faad14;
  }
  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #1890ff;
  }
  .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #52c41a;
  }
  .ant-modal-wrap-rtl {
    direction: rtl;
  }
  .ant-modal-wrap-rtl .ant-modal-close {
    left: 0;
    right: auto;
  }
  .ant-modal-wrap-rtl .ant-modal-footer {
    text-align: left;
  }
  .ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-body {
    direction: rtl;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
    float: right;
    margin-left: 16px;
    margin-right: 0;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0;
    margin-right: 38px;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-btns {
    float: left;
  }
  .ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
    text-align: right;
  }
  .ant-notification {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0 24px 0 0;
    padding: 0;
    position: fixed;
    z-index: 1010;
  }
  .ant-notification-bottomLeft,
  .ant-notification-topLeft {
    margin-left: 24px;
    margin-right: 0;
  }
  .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name: NotificationLeftFadeIn;
  }
  .ant-notification-close-icon {
    cursor: pointer;
    font-size: 14px;
  }
  .ant-notification-hook-holder {
    position: relative;
  }
  .ant-notification-notice {
    word-wrap: break-word;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    line-height: 1.5715;
    margin-bottom: 16px;
    margin-left: auto;
    max-width: calc(100vw - 48px);
    overflow: hidden;
    padding: 16px 24px;
    position: relative;
    width: 384px;
  }
  .ant-notification-bottomLeft .ant-notification-notice,
  .ant-notification-topLeft .ant-notification-notice {
    margin-left: 0;
    margin-right: auto;
  }
  .ant-notification-notice-message {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .ant-notification-notice-message-single-line-auto-margin {
    background-color: initial;
    display: block;
    max-width: 4px;
    pointer-events: none;
    width: calc(264px - 100%);
  }
  .ant-notification-notice-message-single-line-auto-margin:before {
    content: '';
    display: block;
  }
  .ant-notification-notice-description {
    font-size: 14px;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 16px;
    margin-bottom: 4px;
    margin-left: 48px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 14px;
    margin-left: 48px;
  }
  .ant-notification-notice-icon {
    font-size: 24px;
    line-height: 24px;
    margin-left: 4px;
    position: absolute;
  }
  .anticon.ant-notification-notice-icon-success {
    color: #52c41a;
  }
  .anticon.ant-notification-notice-icon-info {
    color: #1890ff;
  }
  .anticon.ant-notification-notice-icon-warning {
    color: #faad14;
  }
  .anticon.ant-notification-notice-icon-error {
    color: #ff4d4f;
  }
  .ant-notification-notice-close {
    color: rgba(0, 0, 0, 0.45);
    outline: none;
    position: absolute;
    right: 22px;
    top: 16px;
  }
  .ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67);
  }
  .ant-notification-notice-btn {
    float: right;
    margin-top: 16px;
  }
  .ant-notification .notification-fade-effect {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-notification-fade-appear,
  .ant-notification-fade-enter {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    opacity: 0;
  }
  .ant-notification-fade-appear,
  .ant-notification-fade-enter,
  .ant-notification-fade-leave {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-notification-fade-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-fade-enter.ant-notification-fade-enter-active {
    -webkit-animation-name: NotificationFadeIn;
    animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .ant-notification-fade-leave.ant-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
    animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  @-webkit-keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationLeftFadeIn {
    0% {
      opacity: 0;
      right: 384px;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      opacity: 0;
      right: 384px;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }
  @-webkit-keyframes NotificationFadeOut {
    0% {
      margin-bottom: 16px;
      max-height: 150px;
      opacity: 1;
    }
    to {
      margin-bottom: 0;
      max-height: 0;
      opacity: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      margin-bottom: 16px;
      max-height: 150px;
      opacity: 1;
    }
    to {
      margin-bottom: 0;
      max-height: 0;
      opacity: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .ant-notification-rtl {
    direction: rtl;
  }
  .ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
  }
  .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description,
  .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-left: 0;
    margin-right: 48px;
  }
  .ant-notification-rtl .ant-notification-notice-icon {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-notification-rtl .ant-notification-notice-close {
    left: 22px;
    right: auto;
  }
  .ant-notification-rtl .ant-notification-notice-btn {
    float: left;
  }
  .ant-page-header {
    font-feature-settings: 'tnum';
    background-color: #fff;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 16px 24px;
    position: relative;
  }
  .ant-page-header-ghost {
    background-color: inherit;
  }
  .ant-page-header.has-breadcrumb {
    padding-top: 12px;
  }
  .ant-page-header.has-footer {
    padding-bottom: 0;
  }
  .ant-page-header-back {
    font-size: 16px;
    line-height: 1;
    margin-right: 16px;
  }
  .ant-page-header-back-button {
    color: #1890ff;
    color: #000;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;
  }
  .ant-page-header-back-button:focus,
  .ant-page-header-back-button:hover {
    color: #40a9ff;
  }
  .ant-page-header-back-button:active {
    color: #096dd9;
  }
  .ant-page-header .ant-divider-vertical {
    height: 14px;
    margin: 0 12px;
    vertical-align: middle;
  }
  .ant-breadcrumb + .ant-page-header-heading {
    margin-top: 8px;
  }
  .ant-page-header-heading {
    display: flex;
    justify-content: space-between;
  }
  .ant-page-header-heading-left {
    align-items: center;
    display: flex;
    margin: 4px 0;
    overflow: hidden;
  }
  .ant-page-header-heading-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0;
    margin-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-page-header-heading .ant-avatar {
    margin-right: 12px;
  }
  .ant-page-header-heading-sub-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    margin-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-page-header-heading-extra {
    margin: 4px 0;
    white-space: nowrap;
  }
  .ant-page-header-heading-extra > * {
    margin-left: 12px;
    white-space: unset;
  }
  .ant-page-header-heading-extra > :first-child {
    margin-left: 0;
  }
  .ant-page-header-content {
    padding-top: 12px;
  }
  .ant-page-header-footer {
    margin-top: 16px;
  }
  .ant-page-header-footer .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .ant-page-header-footer .ant-tabs > .ant-tabs-nav:before {
    border: none;
  }
  .ant-page-header-footer .ant-tabs .ant-tabs-tab {
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .ant-page-header-compact .ant-page-header-heading {
    flex-wrap: wrap;
  }
  .ant-page-header-rtl {
    direction: rtl;
  }
  .ant-page-header-rtl .ant-page-header-back {
    float: right;
    margin-left: 16px;
    margin-right: 0;
  }
  .ant-page-header-rtl .ant-page-header-heading-title,
  .ant-page-header-rtl .ant-page-header-heading .ant-avatar {
    margin-left: 12px;
    margin-right: 0;
  }
  .ant-page-header-rtl .ant-page-header-heading-sub-title {
    float: right;
    margin-left: 12px;
    margin-right: 0;
  }
  .ant-page-header-rtl .ant-page-header-heading-tags {
    float: right;
  }
  .ant-page-header-rtl .ant-page-header-heading-extra {
    float: left;
  }
  .ant-page-header-rtl .ant-page-header-heading-extra > * {
    margin-left: 0;
    margin-right: 12px;
  }
  .ant-page-header-rtl .ant-page-header-heading-extra > :first-child {
    margin-right: 0;
  }
  .ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
    float: right;
  }
  .ant-popconfirm {
    z-index: 1060;
  }
  .ant-progress {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-progress-line {
    font-size: 14px;
    position: relative;
    width: 100%;
  }
  .ant-progress-steps {
    display: inline-block;
  }
  .ant-progress-steps-outer {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .ant-progress-steps-item {
    background: #f3f3f3;
    flex-shrink: 0;
    margin-right: 2px;
    min-width: 2px;
    transition: all 0.3s;
  }
  .ant-progress-steps-item-active {
    background: #1890ff;
  }
  .ant-progress-small.ant-progress-line,
  .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
    font-size: 12px;
  }
  .ant-progress-outer {
    display: inline-block;
    margin-right: 0;
    padding-right: 0;
    width: 100%;
  }
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px);
  }
  .ant-progress-inner {
    background-color: #f5f5f5;
    border-radius: 100px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: 100%;
  }
  .ant-progress-circle-trail {
    stroke: #f5f5f5;
  }
  .ant-progress-circle-path {
    -webkit-animation: ant-progress-appear 0.3s;
    animation: ant-progress-appear 0.3s;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #1890ff;
  }
  .ant-progress-bg,
  .ant-progress-success-bg {
    background-color: #1890ff;
    border-radius: 100px;
    position: relative;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  }
  .ant-progress-success-bg {
    background-color: #52c41a;
    left: 0;
    position: absolute;
    top: 0;
  }
  .ant-progress-text {
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 1em;
    line-height: 1;
    margin-left: 8px;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    width: 2em;
    word-break: normal;
  }
  .ant-progress-text .anticon {
    font-size: 14px;
  }
  .ant-progress-status-active .ant-progress-bg:before {
    -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    background: #fff;
    border-radius: 10px;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-progress-status-exception .ant-progress-bg {
    background-color: #ff4d4f;
  }
  .ant-progress-status-exception .ant-progress-text {
    color: #ff4d4f;
  }
  .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #ff4d4f;
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: #52c41a;
  }
  .ant-progress-status-success .ant-progress-text {
    color: #52c41a;
  }
  .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #52c41a;
  }
  .ant-progress-circle .ant-progress-inner {
    background-color: initial;
    line-height: 1;
    position: relative;
  }
  .ant-progress-circle .ant-progress-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1em;
    left: 50%;
    line-height: 1;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: normal;
    width: 100%;
  }
  .ant-progress-circle .ant-progress-text .anticon {
    font-size: 1.16666667em;
  }
  .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: #ff4d4f;
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #52c41a;
  }
  @-webkit-keyframes ant-progress-active {
    0% {
      opacity: 0.1;
      transform: translateX(-100%) scaleX(0);
    }
    20% {
      opacity: 0.5;
      transform: translateX(-100%) scaleX(0);
    }
    to {
      opacity: 0;
      transform: translateX(0) scaleX(1);
    }
  }
  @keyframes ant-progress-active {
    0% {
      opacity: 0.1;
      transform: translateX(-100%) scaleX(0);
    }
    20% {
      opacity: 0.5;
      transform: translateX(-100%) scaleX(0);
    }
    to {
      opacity: 0;
      transform: translateX(0) scaleX(1);
    }
  }
  .ant-progress-rtl {
    direction: rtl;
  }
  .ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
    margin-left: calc(-2em - 8px);
    margin-right: 0;
    padding-left: calc(2em + 8px);
    padding-right: 0;
  }
  .ant-progress-rtl .ant-progress-success-bg {
    left: auto;
    right: 0;
  }
  .ant-progress-rtl.ant-progress-line .ant-progress-text,
  .ant-progress-rtl.ant-progress-steps .ant-progress-text {
    margin-left: 0;
    margin-right: 8px;
    text-align: right;
  }
  .ant-rate {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    color: #fadb14;
    display: inline-block;
    font-size: 14px;
    font-size: 20px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: unset;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
  }
  .ant-rate-disabled .ant-rate-star {
    cursor: default;
  }
  .ant-rate-disabled .ant-rate-star:hover {
    transform: scale(1);
  }
  .ant-rate-star {
    color: inherit;
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 8px;
  }
  .ant-rate-star > div {
    transition: all 0.3s;
  }
  .ant-rate-star > div:focus-visible,
  .ant-rate-star > div:hover {
    transform: scale(1.1);
  }
  .ant-rate-star > div:focus:not(:focus-visible) {
    outline: 0;
  }
  .ant-rate-star-first,
  .ant-rate-star-second {
    color: #f0f0f0;
    transition: all 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-rate-star-first .anticon,
  .ant-rate-star-second .anticon {
    vertical-align: middle;
  }
  .ant-rate-star-first {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 50%;
  }
  .ant-rate-star-half .ant-rate-star-first,
  .ant-rate-star-half .ant-rate-star-second {
    opacity: 1;
  }
  .ant-rate-star-full .ant-rate-star-second,
  .ant-rate-star-half .ant-rate-star-first {
    color: inherit;
  }
  .ant-rate-text {
    display: inline-block;
    font-size: 14px;
    margin: 0 8px;
  }
  .ant-rate-rtl {
    direction: rtl;
  }
  .ant-rate-rtl .ant-rate-star:not(:last-child) {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-rate-rtl .ant-rate-star-first {
    left: auto;
    right: 0;
  }
  .ant-result {
    padding: 48px 32px;
  }
  .ant-result-success .ant-result-icon > .anticon {
    color: #52c41a;
  }
  .ant-result-error .ant-result-icon > .anticon {
    color: #ff4d4f;
  }
  .ant-result-info .ant-result-icon > .anticon {
    color: #1890ff;
  }
  .ant-result-warning .ant-result-icon > .anticon {
    color: #faad14;
  }
  .ant-result-image {
    height: 295px;
    margin: auto;
    width: 250px;
  }
  .ant-result-icon {
    margin-bottom: 24px;
    text-align: center;
  }
  .ant-result-icon > .anticon {
    font-size: 72px;
  }
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }
  .ant-result-subtitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }
  .ant-result-extra {
    margin: 24px 0 0;
    text-align: center;
  }
  .ant-result-extra > * {
    margin-right: 8px;
  }
  .ant-result-extra > :last-child {
    margin-right: 0;
  }
  .ant-result-content {
    background-color: #fafafa;
    margin-top: 24px;
    padding: 24px 40px;
  }
  .ant-result-rtl {
    direction: rtl;
  }
  .ant-result-rtl .ant-result-extra > * {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-result-rtl .ant-result-extra > :last-child {
    margin-left: 0;
  }
  .ant-skeleton {
    display: table;
    width: 100%;
  }
  .ant-skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top;
  }
  .ant-skeleton-header .ant-skeleton-avatar {
    background: rgba(190, 190, 190, 0.2);
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    width: 32px;
  }
  .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-header .ant-skeleton-avatar-sm {
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-content {
    display: table-cell;
    vertical-align: top;
    width: 100%;
  }
  .ant-skeleton-content .ant-skeleton-title {
    background: rgba(190, 190, 190, 0.2);
    border-radius: 4px;
    height: 16px;
    margin-top: 16px;
    width: 100%;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 24px;
  }
  .ant-skeleton-content .ant-skeleton-paragraph {
    padding: 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: rgba(190, 190, 190, 0.2);
    border-radius: 4px;
    height: 16px;
    list-style: none;
    width: 100%;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 16px;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin-top: 12px;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 28px;
  }
  .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li,
  .ant-skeleton-round .ant-skeleton-content .ant-skeleton-title {
    border-radius: 100px;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-button,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-image,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    background: linear-gradient(90deg, hsla(0, 0%, 75%, 0.2) 25%, hsla(0, 0%, 51%, 0.24) 37%, hsla(0, 0%, 75%, 0.2) 63%);
    background-size: 400% 100%;
  }
  .ant-skeleton-element {
    display: inline-block;
    width: auto;
  }
  .ant-skeleton-element .ant-skeleton-button {
    background: rgba(190, 190, 190, 0.2);
    border-radius: 2px;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    width: 64px;
  }
  .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
    border-radius: 50%;
    width: 32px;
  }
  .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
    border-radius: 32px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg {
    height: 40px;
    line-height: 40px;
    width: 80px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
    border-radius: 50%;
    width: 40px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
    border-radius: 40px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm {
    height: 24px;
    line-height: 24px;
    width: 48px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
    border-radius: 50%;
    width: 24px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
    border-radius: 24px;
  }
  .ant-skeleton-element .ant-skeleton-avatar {
    background: rgba(190, 190, 190, 0.2);
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    width: 32px;
  }
  .ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-avatar-sm {
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
  .ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-input {
    background: rgba(190, 190, 190, 0.2);
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    width: 100%;
  }
  .ant-skeleton-element .ant-skeleton-input-lg {
    height: 40px;
    line-height: 40px;
    width: 100%;
  }
  .ant-skeleton-element .ant-skeleton-input-sm {
    height: 24px;
    line-height: 24px;
    width: 100%;
  }
  .ant-skeleton-element .ant-skeleton-image {
    align-items: center;
    background: rgba(190, 190, 190, 0.2);
    display: flex;
    height: 96px;
    justify-content: center;
    line-height: 96px;
    vertical-align: top;
    width: 96px;
  }
  .ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-image-path {
    fill: #bfbfbf;
  }
  .ant-skeleton-element .ant-skeleton-image-svg {
    height: 48px;
    line-height: 48px;
    max-height: 192px;
    max-width: 192px;
    width: 48px;
  }
  .ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
    border-radius: 50%;
  }
  @-webkit-keyframes ant-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }
  @keyframes ant-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }
  .ant-skeleton-rtl {
    direction: rtl;
  }
  .ant-skeleton-rtl .ant-skeleton-header {
    padding-left: 16px;
    padding-right: 0;
  }
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title {
    -webkit-animation-name: ant-skeleton-loading-rtl;
    animation-name: ant-skeleton-loading-rtl;
  }
  @-webkit-keyframes ant-skeleton-loading-rtl {
    0% {
      background-position: 0 50%;
    }
    to {
      background-position: 100% 50%;
    }
  }
  @keyframes ant-skeleton-loading-rtl {
    0% {
      background-position: 0 50%;
    }
    to {
      background-position: 100% 50%;
    }
  }
  .ant-slider {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    height: 12px;
    line-height: 1.5715;
    list-style: none;
    margin: 10px 6px;
    padding: 4px 0;
    position: relative;
    touch-action: none;
  }
  .ant-slider-vertical {
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px;
    width: 12px;
  }
  .ant-slider-vertical .ant-slider-rail {
    height: 100%;
    width: 4px;
  }
  .ant-slider-vertical .ant-slider-track {
    width: 4px;
  }
  .ant-slider-vertical .ant-slider-handle {
    margin-left: -5px;
    margin-top: -6px;
  }
  .ant-slider-vertical .ant-slider-mark {
    height: 100%;
    left: 12px;
    top: 0;
    width: 18px;
  }
  .ant-slider-vertical .ant-slider-mark-text {
    left: 4px;
    white-space: nowrap;
  }
  .ant-slider-vertical .ant-slider-step {
    height: 100%;
    width: 4px;
  }
  .ant-slider-vertical .ant-slider-dot {
    left: 2px;
    margin-bottom: -4px;
    top: auto;
  }
  .ant-slider-tooltip .ant-tooltip-inner {
    min-width: unset;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
    margin-left: 0;
    margin-right: -5px;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
    left: auto;
    right: 12px;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
    left: auto;
    right: 4px;
  }
  .ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
    left: auto;
    right: 2px;
  }
  .ant-slider-with-marks {
    margin-bottom: 28px;
  }
  .ant-slider-rail {
    background-color: #f5f5f5;
    width: 100%;
  }
  .ant-slider-rail,
  .ant-slider-track {
    border-radius: 2px;
    height: 4px;
    position: absolute;
    transition: background-color 0.3s;
  }
  .ant-slider-track {
    background-color: #91d5ff;
  }
  .ant-slider-handle {
    background-color: #fff;
    border: 2px solid #91d5ff;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    height: 14px;
    margin-top: -5px;
    position: absolute;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    width: 14px;
  }
  .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging,
  .ant-slider-handle:focus {
    border-color: #46a6ff;
    box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  }
  .ant-slider-handle:focus {
    outline: none;
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #69c0ff;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
  }
  .ant-slider-mark {
    font-size: 14px;
    left: 0;
    position: absolute;
    top: 14px;
    width: 100%;
  }
  .ant-slider-mark-text {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    display: inline-block;
    position: absolute;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    word-break: keep-all;
  }
  .ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-slider-step {
    background: transparent;
    height: 4px;
    position: absolute;
    width: 100%;
  }
  .ant-slider-dot {
    background-color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    cursor: pointer;
    height: 8px;
    position: absolute;
    top: -2px;
    width: 8px;
  }
  .ant-slider-dot,
  .ant-slider-dot:first-child,
  .ant-slider-dot:last-child {
    margin-left: -4px;
  }
  .ant-slider-dot-active {
    border-color: #8cc8ff;
  }
  .ant-slider-disabled {
    cursor: not-allowed;
  }
  .ant-slider-disabled .ant-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-slider-disabled .ant-slider-dot,
  .ant-slider-disabled .ant-slider-handle {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: none;
    cursor: not-allowed;
  }
  .ant-slider-disabled .ant-slider-dot,
  .ant-slider-disabled .ant-slider-mark-text {
    cursor: not-allowed !important;
  }
  .ant-slider-rtl {
    direction: rtl;
  }
  .ant-slider-rtl .ant-slider-mark {
    left: auto;
    right: 0;
  }
  .ant-slider-rtl .ant-slider-dot,
  .ant-slider-rtl .ant-slider-dot:first-child,
  .ant-slider-rtl .ant-slider-dot:last-child {
    margin-left: 0;
    margin-right: -4px;
  }
  .ant-space {
    display: inline-flex;
  }
  .ant-space-vertical {
    flex-direction: column;
  }
  .ant-space-align-center {
    align-items: center;
  }
  .ant-space-align-start {
    align-items: flex-start;
  }
  .ant-space-align-end {
    align-items: flex-end;
  }
  .ant-space-align-baseline {
    align-items: baseline;
  }
  .ant-space-item:empty {
    display: none;
  }
  .ant-space-rtl {
    direction: rtl;
  }
  .ant-statistic {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-statistic-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin-bottom: 4px;
  }
  .ant-statistic-content {
    color: rgba(0, 0, 0, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 24px;
  }
  .ant-statistic-content-value {
    direction: ltr;
    display: inline-block;
  }
  .ant-statistic-content-prefix,
  .ant-statistic-content-suffix {
    display: inline-block;
  }
  .ant-statistic-content-prefix {
    margin-right: 4px;
  }
  .ant-statistic-content-suffix {
    margin-left: 4px;
  }
  .ant-statistic-rtl {
    direction: rtl;
  }
  .ant-statistic-rtl .ant-statistic-content-prefix {
    margin-left: 4px;
    margin-right: 0;
  }
  .ant-statistic-rtl .ant-statistic-content-suffix {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-steps {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    font-size: 14px;
    font-size: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: initial;
    width: 100%;
  }
  .ant-steps-item {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    position: relative;
    vertical-align: top;
  }
  .ant-steps-item-container {
    outline: none;
  }
  .ant-steps-item:last-child {
    flex: none;
  }
  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after,
  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
    display: none;
  }
  .ant-steps-item-content,
  .ant-steps-item-icon {
    display: inline-block;
    vertical-align: top;
  }
  .ant-steps-item-icon {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    margin: 0 8px 0 0;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s;
    width: 32px;
  }
  .ant-steps-item-icon .ant-steps-icon {
    color: #1890ff;
    line-height: 1;
    position: relative;
    top: -0.5px;
  }
  .ant-steps-item-tail {
    left: 0;
    padding: 0 10px;
    position: absolute;
    top: 12px;
    width: 100%;
  }
  .ant-steps-item-tail:after {
    background: #f0f0f0;
    border-radius: 1px;
    content: '';
    display: inline-block;
    height: 1px;
    transition: background 0.3s;
    width: 100%;
  }
  .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 16px;
    line-height: 32px;
    padding-right: 16px;
    position: relative;
  }
  .ant-steps-item-title:after {
    background: #f0f0f0;
    content: '';
    display: block;
    height: 1px;
    left: 100%;
    position: absolute;
    top: 16px;
    width: 9999px;
  }
  .ant-steps-item-subtitle {
    display: inline;
    font-weight: 400;
    margin-left: 8px;
  }
  .ant-steps-item-description,
  .ant-steps-item-subtitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: #1890ff;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #1890ff;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #1890ff;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #1890ff;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
    font-weight: 500;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #1890ff;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #1890ff;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #1890ff;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #1890ff;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: #1890ff;
  }
  .ant-steps-item-error .ant-steps-item-icon {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: #ff4d4f;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #ff4d4f;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #ff4d4f;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: #ff4d4f;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: #f0f0f0;
  }
  .ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
    background: #ff4d4f;
  }
  .ant-steps-item-disabled {
    cursor: not-allowed;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
    cursor: pointer;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title {
    transition: color 0.3s;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
    color: #1890ff;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
    border-color: #1890ff;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
    color: #1890ff;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 16px;
    white-space: nowrap;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-right: 0;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    display: none;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 140px;
    white-space: normal;
  }
  .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
    background: none;
    border: 0;
    height: auto;
  }
  .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    font-size: 24px;
    height: 32px;
    left: 0.5px;
    line-height: 32px;
    top: 0;
    width: 32px;
  }
  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #1890ff;
  }
  .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
    width: auto;
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 12px;
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
  }
  .ant-steps-small .ant-steps-item-icon {
    border-radius: 24px;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    margin: 0 8px 0 0;
    text-align: center;
    width: 24px;
  }
  .ant-steps-small .ant-steps-item-title {
    font-size: 14px;
    line-height: 24px;
    padding-right: 12px;
  }
  .ant-steps-small .ant-steps-item-title:after {
    top: 12px;
  }
  .ant-steps-small .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-steps-small .ant-steps-item-tail {
    top: 8px;
  }
  .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
    border: 0;
    border-radius: 0;
    height: inherit;
    line-height: inherit;
    width: inherit;
  }
  .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    font-size: 24px;
    line-height: 24px;
    transform: none;
  }
  .ant-steps-vertical {
    display: flex;
    flex-direction: column;
  }
  .ant-steps-vertical > .ant-steps-item {
    display: block;
    flex: 1 0 auto;
    overflow: visible;
    padding-left: 0;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    height: 100%;
    left: 16px;
    padding: 38px 0 6px;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
    height: 100%;
    width: 1px;
  }
  .ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
    display: none;
  }
  .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    left: 12px;
    padding: 30px 0 6px;
    position: absolute;
    top: 0;
  }
  .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
  .ant-steps-label-vertical .ant-steps-item {
    overflow: visible;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 58px;
    padding: 3.5px 24px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    display: block;
    margin-top: 8px;
    text-align: center;
    width: 116px;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
  }
  .ant-steps-label-vertical .ant-steps-item-title {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-steps-label-vertical .ant-steps-item-title:after {
    display: none;
  }
  .ant-steps-label-vertical .ant-steps-item-subtitle {
    display: block;
    line-height: 1.5715;
    margin-bottom: 4px;
    margin-left: 0;
  }
  .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 46px;
  }
  .ant-steps-dot .ant-steps-item-title,
  .ant-steps-dot.ant-steps-small .ant-steps-item-title {
    line-height: 1.5715;
  }
  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 0 0 70px;
    padding: 0;
    top: 2px;
    width: 100%;
  }
  .ant-steps-dot .ant-steps-item-tail:after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
    height: 3px;
    margin-left: 12px;
    width: calc(100% - 20px);
  }
  .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
  .ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 2px;
  }
  .ant-steps-dot .ant-steps-item-icon,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    background: transparent;
    border: 0;
    height: 8px;
    line-height: 8px;
    margin-left: 67px;
    padding-right: 0;
    width: 8px;
  }
  .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    border-radius: 100px;
    float: left;
    height: 100%;
    position: relative;
    transition: all 0.3s;
    width: 100%;
  }
  .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
    background: rgba(0, 0, 0, 0.001);
    content: '';
    height: 32px;
    left: -26px;
    position: absolute;
    top: -12px;
    width: 60px;
  }
  .ant-steps-dot .ant-steps-item-content,
  .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 140px;
  }
  .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    background: none;
    height: 10px;
    line-height: 10px;
    position: relative;
    top: -1px;
    width: 10px;
  }
  .ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
  .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
    left: 0;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    background: none;
    margin-left: 0;
    margin-top: 8px;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: -9px;
    margin: 0;
    padding: 22px 0 4px;
    top: 2px;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 0;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
    width: inherit;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
    left: -2px;
  }
  .ant-steps-navigation {
    padding-top: 12px;
  }
  .ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-left: -12px;
  }
  .ant-steps-navigation .ant-steps-item {
    overflow: visible;
    text-align: center;
  }
  .ant-steps-navigation .ant-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    transition: opacity 0.3s;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
    max-width: auto;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    max-width: 100%;
    overflow: hidden;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title:after {
    display: none;
  }
  .ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
    cursor: pointer;
  }
  .ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
    opacity: 0.85;
  }
  .ant-steps-navigation .ant-steps-item:last-child {
    flex: 1;
  }
  .ant-steps-navigation .ant-steps-item:last-child:after {
    display: none;
  }
  .ant-steps-navigation .ant-steps-item:after {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: none;
    border-left: none;
    content: '';
    display: inline-block;
    height: 12px;
    left: 100%;
    margin-left: -2px;
    margin-top: -14px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 12px;
  }
  .ant-steps-navigation .ant-steps-item:before {
    background-color: #1890ff;
    bottom: 0;
    content: '';
    display: inline-block;
    height: 2px;
    left: 50%;
    position: absolute;
    transition: width 0.3s, left 0.3s;
    transition-timing-function: ease-out;
    width: 0;
  }
  .ant-steps-navigation .ant-steps-item.ant-steps-item-active:before {
    left: 0;
    width: 100%;
  }
  .ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
    margin-right: 0 !important;
  }
  .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:before {
    display: none;
  }
  .ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active:before {
    display: block;
    height: calc(100% - 24px);
    left: unset;
    right: 0;
    top: 0;
    width: 3px;
  }
  .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
    display: block;
    height: 8px;
    left: 50%;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    top: -2px;
    transform: rotate(135deg);
    width: 8px;
  }
  .ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden;
  }
  .ant-steps-rtl {
    direction: rtl;
  }
  .ant-steps.ant-steps-rtl .ant-steps-item-icon {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-steps-rtl .ant-steps-item-tail {
    left: auto;
    right: 0;
  }
  .ant-steps-rtl .ant-steps-item-title {
    padding-left: 16px;
    padding-right: 0;
  }
  .ant-steps-rtl .ant-steps-item-title:after {
    left: auto;
    right: 100%;
  }
  .ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
    padding-right: 16px;
  }
  .ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-right: 0;
  }
  .ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-left: 0;
  }
  .ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    left: auto;
    right: 0.5px;
  }
  .ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-left: 0;
    margin-right: -12px;
  }
  .ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
    margin-left: 0;
    margin-right: -16px;
    text-align: right;
  }
  .ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    padding-left: 0;
  }
  .ant-steps-rtl.ant-steps-navigation .ant-steps-item:after {
    left: auto;
    margin-left: 0;
    margin-right: -2px;
    right: 100%;
    transform: rotate(225deg);
  }
  .ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
    padding-right: 12px;
  }
  .ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-right: 0;
  }
  .ant-steps-rtl.ant-steps-small .ant-steps-item-title {
    padding-left: 12px;
    padding-right: 0;
  }
  .ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    float: right;
    margin-left: 16px;
    margin-right: 0;
  }
  .ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: auto;
    right: 16px;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    left: auto;
    right: 12px;
  }
  .ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
    padding-left: 0;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 70px 0 0;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-tail:after,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
    margin-left: 0;
    margin-right: 12px;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: auto;
    right: 2px;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-left: 0;
    margin-right: 67px;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    float: right;
  }
  .ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after,
  .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
    left: auto;
    right: -26px;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-left: 16px;
    margin-right: 0;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: auto;
    right: -9px;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: auto;
    right: 0;
  }
  .ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    left: auto;
    right: -2px;
  }
  .ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
    padding-right: 4px;
  }
  .ant-steps-with-progress .ant-steps-item {
    padding-top: 4px;
  }
  .ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
    top: 4px !important;
  }
  .ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
    padding-bottom: 4px;
    padding-left: 4px;
  }
  .ant-steps-with-progress .ant-steps-item-icon {
    position: relative;
  }
  .ant-steps-with-progress .ant-steps-item-icon .ant-progress {
    bottom: -5px;
    left: -5px;
    position: absolute;
    right: -5px;
    top: -5px;
  }
  .ant-switch {
    font-feature-settings: 'tnum';
    background-color: rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    height: 22px;
    line-height: 1.5715;
    line-height: 22px;
    list-style: none;
    margin: 0;
    min-width: 44px;
    padding: 0;
    position: relative;
    transition: all 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  .ant-switch:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    outline: 0;
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-switch:focus:hover {
    box-shadow: none;
  }
  .ant-switch-checked {
    background-color: #1890ff;
  }
  .ant-switch-disabled,
  .ant-switch-loading {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .ant-switch-disabled *,
  .ant-switch-loading * {
    box-shadow: none;
    cursor: not-allowed;
  }
  .ant-switch-inner {
    color: #fff;
    display: block;
    font-size: 12px;
    margin: 0 7px 0 25px;
    transition: margin 0.2s;
  }
  .ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
  }
  .ant-switch-handle {
    height: 18px;
    left: 2px;
    top: 2px;
    width: 18px;
  }
  .ant-switch-handle,
  .ant-switch-handle:before {
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
  .ant-switch-handle:before {
    background-color: #fff;
    border-radius: 9px;
    bottom: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    content: '';
    left: 0;
    right: 0;
    top: 0;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px);
  }
  .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle:before {
    left: 0;
    right: -30%;
  }
  .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle:before {
    left: -30%;
    right: 0;
  }
  .ant-switch-loading-icon {
    color: rgba(0, 0, 0, 0.65);
    position: relative;
    top: 2px;
    vertical-align: top;
  }
  .ant-switch-checked .ant-switch-loading-icon {
    color: #1890ff;
  }
  .ant-switch-small {
    height: 16px;
    line-height: 16px;
    min-width: 28px;
  }
  .ant-switch-small .ant-switch-inner {
    font-size: 12px;
    margin: 0 5px 0 18px;
  }
  .ant-switch-small .ant-switch-handle {
    height: 12px;
    width: 12px;
  }
  .ant-switch-small .ant-switch-loading-icon {
    font-size: 9px;
    top: 1.5px;
  }
  .ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin: 0 18px 0 5px;
  }
  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 14px);
  }
  .ant-switch-rtl {
    direction: rtl;
  }
  .ant-switch-rtl .ant-switch-inner {
    margin: 0 25px 0 7px;
  }
  .ant-switch-rtl .ant-switch-handle {
    left: auto;
    right: 2px;
  }
  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle:before {
    left: -30%;
    right: 0;
  }
  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle:before {
    left: 0;
    right: -30%;
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-inner {
    margin: 0 7px 0 25px;
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 20px);
  }
  .ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 14px);
  }
  .ant-table.ant-table-middle {
    font-size: 14px;
  }
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle tfoot > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th {
    padding: 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-filter-trigger {
    margin-right: -4px;
  }
  .ant-table.ant-table-middle .ant-table-expanded-row-fixed {
    margin: -12px -8px;
  }
  .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -12px -8px -12px 25px;
  }
  .ant-table.ant-table-small {
    font-size: 14px;
  }
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small tfoot > tr > td,
  .ant-table.ant-table-small tfoot > tr > th {
    padding: 8px;
  }
  .ant-table.ant-table-small .ant-table-filter-trigger {
    margin-right: -4px;
  }
  .ant-table.ant-table-small .ant-table-expanded-row-fixed {
    margin: -8px;
  }
  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -8px -8px -8px 25px;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #fafafa;
  }
  .ant-table-small .ant-table-selection-column {
    min-width: 46px;
    width: 46px;
  }
  .ant-table.ant-table-bordered > .ant-table-title {
    border: 1px solid #f0f0f0;
    border-bottom: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid #f0f0f0;
    border-bottom: 0;
    border-right: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th:before,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:before,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:before,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th:before {
    background-color: initial !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first:after {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
    margin: -16px -17px;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed:after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed:after {
    border-right: 1px solid #f0f0f0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 1px;
    top: 0;
  }
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
    border-right: 0;
  }
  .ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed {
    margin: -12px -9px;
  }
  .ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed {
    margin: -8px -9px;
  }
  .ant-table.ant-table-bordered > .ant-table-footer {
    border: 1px solid #f0f0f0;
    border-top: 0;
  }
  .ant-table-cell .ant-table-container:first-child {
    border-top: 0;
  }
  .ant-table-cell-scrollbar {
    box-shadow: 0 1px 0 1px #fafafa;
  }
  .ant-table-wrapper {
    clear: both;
    max-width: 100%;
  }
  .ant-table-wrapper:before {
    content: '';
    display: table;
  }
  .ant-table-wrapper:after {
    clear: both;
    content: '';
    display: table;
  }
  .ant-table {
    font-feature-settings: 'tnum';
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ant-table table {
    border-collapse: initial;
    border-radius: 2px 2px 0 0;
    border-spacing: 0;
    text-align: left;
    width: 100%;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    overflow-wrap: break-word;
    padding: 16px;
    position: relative;
  }
  .ant-table-cell-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
  }
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
    overflow: visible;
  }
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table-cell-ellipsis .ant-table-column-title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .ant-table-title {
    padding: 16px;
  }
  .ant-table-footer {
    background: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    padding: 16px;
  }
  .ant-table-thead > tr > th {
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    position: relative;
    text-align: left;
    transition: background 0.3s ease;
  }
  .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align: center;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    background-color: rgba(0, 0, 0, 0.06);
    content: '';
    height: 1.6em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    width: 1px;
  }
  .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
  }
  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px -16px -16px 33px;
  }
  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
    border-radius: 0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fafafa;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #e6f7ff;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #dcf4ff;
  }
  .ant-table-summary {
    background: #fff;
  }
  div.ant-table-summary {
    box-shadow: 0 -1px 0 #f0f0f0;
  }
  .ant-table-summary > tr > td,
  .ant-table-summary > tr > th {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px 0;
  }
  .ant-table-pagination {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .ant-table-pagination > * {
    flex: none;
  }
  .ant-table-pagination-left {
    justify-content: flex-start;
  }
  .ant-table-pagination-center {
    justify-content: center;
  }
  .ant-table-pagination-right {
    justify-content: flex-end;
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover:before {
    background-color: initial !important;
  }
  .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
  .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover,
  .ant-table-thead th.ant-table-column-sort {
    background: #f5f5f5;
  }
  .ant-table-thead th.ant-table-column-sort:before {
    background-color: initial !important;
  }
  td.ant-table-column-sort {
    background: #fafafa;
  }
  .ant-table-column-title {
    flex: 1;
    position: relative;
    z-index: 1;
  }
  .ant-table-column-sorters {
    align-items: center;
    display: flex;
    flex: auto;
    justify-content: space-between;
  }
  .ant-table-column-sorters:after {
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  .ant-table-column-sorter {
    color: #bfbfbf;
    font-size: 0;
    transition: color 0.3s;
  }
  .ant-table-column-sorter-inner {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
  }
  .ant-table-column-sorter-down,
  .ant-table-column-sorter-up {
    font-size: 11px;
  }
  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: #1890ff;
  }
  .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.3em;
  }
  .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #a6a6a6;
  }
  .ant-table-filter-column {
    display: flex;
    justify-content: space-between;
  }
  .ant-table-filter-trigger {
    align-items: center;
    border-radius: 2px;
    color: #bfbfbf;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    margin: -4px -8px -4px 4px;
    padding: 0 4px;
    position: relative;
    transition: all 0.3s;
  }
  .ant-table-filter-trigger:hover {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-table-filter-trigger.active {
    color: #1890ff;
  }
  .ant-table-filter-dropdown {
    font-feature-settings: 'tnum';
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 120px;
    padding: 0;
  }
  .ant-table-filter-dropdown .ant-dropdown-menu {
    border: 0;
    box-shadow: none;
    max-height: 264px;
    overflow-x: hidden;
  }
  .ant-table-filter-dropdown-submenu > ul {
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
  .ant-table-filter-dropdown .ant-checkbox-wrapper + span {
    padding-left: 8px;
  }
  .ant-table-filter-dropdown-btns {
    background-color: inherit;
    border-top: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 7px 8px 7px 3px;
  }
  .ant-table-selection-col {
    width: 32px;
  }
  .ant-table-bordered .ant-table-selection-col {
    width: 50px;
  }
  table tr td.ant-table-selection-column,
  table tr th.ant-table-selection-column {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  }
  table tr td.ant-table-selection-column .ant-radio-wrapper,
  table tr th.ant-table-selection-column .ant-radio-wrapper {
    margin-right: 0;
  }
  table tr th.ant-table-selection-column:after {
    background-color: initial !important;
  }
  .ant-table-selection {
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }
  .ant-table-selection-extra {
    -webkit-margin-start: 100%;
    -webkit-padding-start: 4px;
    cursor: pointer;
    margin-inline-start: 100%;
    padding-inline-start: 4px;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    z-index: 1;
  }
  .ant-table-selection-extra .anticon {
    color: #bfbfbf;
    font-size: 10px;
  }
  .ant-table-selection-extra .anticon:hover {
    color: #a6a6a6;
  }
  .ant-table-expand-icon-col {
    width: 48px;
  }
  .ant-table-row-expand-icon-cell {
    text-align: center;
  }
  .ant-table-row-indent {
    float: left;
    height: 1px;
  }
  .ant-table-row-expand-icon {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    box-sizing: border-box;
    color: #1890ff;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    float: left;
    height: 17px;
    line-height: 17px;
    outline: none;
    padding: 0;
    position: relative;
    text-decoration: none;
    transform: scale(0.94117647);
    transition: color 0.3s;
    transition: all 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 17px;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: #40a9ff;
  }
  .ant-table-row-expand-icon:active {
    color: #096dd9;
  }
  .ant-table-row-expand-icon:active,
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    border-color: currentColor;
  }
  .ant-table-row-expand-icon:after,
  .ant-table-row-expand-icon:before {
    background: currentColor;
    content: '';
    position: absolute;
    transition: transform 0.3s ease-out;
  }
  .ant-table-row-expand-icon:before {
    height: 1px;
    left: 3px;
    right: 3px;
    top: 7px;
  }
  .ant-table-row-expand-icon:after {
    bottom: 3px;
    left: 7px;
    top: 3px;
    transform: rotate(90deg);
    width: 1px;
  }
  .ant-table-row-expand-icon-collapsed:before {
    transform: rotate(-180deg);
  }
  .ant-table-row-expand-icon-collapsed:after {
    transform: rotate(0deg);
  }
  .ant-table-row-expand-icon-spaced {
    background: transparent;
    border: 0;
    visibility: hidden;
  }
  .ant-table-row-expand-icon-spaced:after,
  .ant-table-row-expand-icon-spaced:before {
    content: none;
    display: none;
  }
  .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-right: 8px;
    margin-top: 2.5005px;
  }
  tr.ant-table-expanded-row:hover > td,
  tr.ant-table-expanded-row > td {
    background: #fbfbfb;
  }
  tr.ant-table-expanded-row .ant-descriptions-view {
    display: flex;
  }
  tr.ant-table-expanded-row .ant-descriptions-view table {
    flex: auto;
    width: auto;
  }
  .ant-table .ant-table-expanded-row-fixed {
    margin: -16px;
    padding: 16px;
    position: relative;
  }
  .ant-table-tbody > tr.ant-table-placeholder {
    text-align: center;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #fff;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: #fff;
    position: sticky !important;
    z-index: 2;
  }
  .ant-table-cell-fix-left-first:after,
  .ant-table-cell-fix-left-last:after {
    bottom: -1px;
    content: '';
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    width: 30px;
  }
  .ant-table-cell-fix-right-first:after,
  .ant-table-cell-fix-right-last:after {
    bottom: -1px;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    width: 30px;
  }
  .ant-table .ant-table-container:after,
  .ant-table .ant-table-container:before {
    bottom: 0;
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: box-shadow 0.3s;
    width: 30px;
    z-index: 1;
  }
  .ant-table .ant-table-container:before {
    left: 0;
  }
  .ant-table .ant-table-container:after {
    right: 0;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
    position: relative;
  }
  .ant-table-ping-left .ant-table-cell-fix-left-first:after,
  .ant-table-ping-left .ant-table-cell-fix-left-last:after,
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container:before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-last:before {
    background-color: initial !important;
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
    position: relative;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first:after,
  .ant-table-ping-right .ant-table-cell-fix-right-last:after,
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-sticky-holder,
  .ant-table-sticky-scroll {
    position: sticky;
    z-index: 3;
  }
  .ant-table-sticky-scroll {
    align-items: center;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    bottom: 0;
    display: flex;
    opacity: 0.6;
  }
  .ant-table-sticky-scroll:hover {
    transform-origin: center bottom;
  }
  .ant-table-sticky-scroll-bar {
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    height: 8px;
  }
  .ant-table-sticky-scroll-bar-active,
  .ant-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  @media (-ms-high-contrast: none) {
    .ant-table-ping-left .ant-table-cell-fix-left-last:after,
    .ant-table-ping-right .ant-table-cell-fix-right-first:after {
      box-shadow: none !important;
    }
  }
  .ant-table-title {
    border-radius: 2px 2px 0 0;
  }
  .ant-table-title + .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0;
  }
  .ant-table-container {
    border-top-right-radius: 2px;
  }
  .ant-table-container,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 2px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
  }
  .ant-table-footer {
    border-radius: 0 0 2px 2px;
  }
  .ant-table-rtl,
  .ant-table-wrapper-rtl {
    direction: rtl;
  }
  .ant-table-wrapper-rtl .ant-table table {
    text-align: right;
  }
  .ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align: center;
  }
  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    text-align: right;
  }
  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
    margin: -16px 33px -16px -16px;
  }
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
    justify-content: flex-end;
  }
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
    justify-content: flex-start;
  }
  .ant-table-wrapper-rtl .ant-table-column-sorter {
    margin-left: 0;
    margin-right: 8px;
  }
  .ant-table-wrapper-rtl .ant-table-filter-column-title {
    padding: 16px 16px 16px 2.3em;
  }
  .ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
    padding: 0 0 0 2.3em;
  }
  .ant-table-wrapper-rtl .ant-table-filter-trigger-container {
    left: 0;
    right: auto;
  }
  .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
  .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
  .ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span {
    padding-left: 0;
    padding-right: 8px;
  }
  .ant-table-wrapper-rtl .ant-table-selection {
    text-align: center;
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon,
  .ant-table-wrapper-rtl .ant-table-row-indent {
    float: right;
  }
  .ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon:after {
    transform: rotate(-90deg);
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:before {
    transform: rotate(180deg);
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:after {
    transform: rotate(0deg);
  }
  .ant-timeline {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-timeline-item {
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding-bottom: 20px;
    position: relative;
  }
  .ant-timeline-item-tail {
    border-left: 2px solid #f0f0f0;
    height: calc(100% - 10px);
    left: 4px;
    position: absolute;
    top: 10px;
  }
  .ant-timeline-item-pending .ant-timeline-item-head {
    background-color: initial;
    font-size: 12px;
  }
  .ant-timeline-item-pending .ant-timeline-item-tail {
    display: none;
  }
  .ant-timeline-item-head {
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 100px;
    height: 10px;
    position: absolute;
    width: 10px;
  }
  .ant-timeline-item-head-blue {
    border-color: #1890ff;
    color: #1890ff;
  }
  .ant-timeline-item-head-red {
    border-color: #ff4d4f;
    color: #ff4d4f;
  }
  .ant-timeline-item-head-green {
    border-color: #52c41a;
    color: #52c41a;
  }
  .ant-timeline-item-head-gray {
    border-color: rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-timeline-item-head-custom {
    border: 0;
    border-radius: 0;
    height: auto;
    left: 5px;
    line-height: 1;
    margin-top: 0;
    padding: 3px 1px;
    position: absolute;
    text-align: center;
    top: 5.5px;
    transform: translate(-50%, -50%);
    width: auto;
  }
  .ant-timeline-item-content {
    margin: 0 0 0 26px;
    position: relative;
    top: -7.001px;
    word-break: break-word;
  }
  .ant-timeline-item-last > .ant-timeline-item-tail {
    display: none;
  }
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: 48px;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail {
    left: 50%;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head {
    margin-left: -4px;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
    margin-left: 1px;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(50% - 4px);
    text-align: left;
    width: calc(50% - 14px);
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    margin: 0;
    text-align: right;
    width: calc(50% - 12px);
  }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
    left: calc(100% - 6px);
  }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(100% - 18px);
  }
  .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    border-left: 2px dotted #f0f0f0;
    display: block;
    height: calc(100% - 14px);
  }
  .ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
    display: none;
  }
  .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    border-left: 2px dotted #f0f0f0;
    display: block;
    height: calc(100% - 15px);
    top: 15px;
  }
  .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
    min-height: 48px;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    position: absolute;
    text-align: right;
    top: -7.001px;
    width: calc(50% - 12px);
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
    left: calc(50% + 14px);
    text-align: left;
    width: calc(50% - 14px);
  }
  .ant-timeline-rtl {
    direction: rtl;
  }
  .ant-timeline-rtl .ant-timeline-item-tail {
    border-left: none;
    border-right: 2px solid #f0f0f0;
    left: auto;
    right: 4px;
  }
  .ant-timeline-rtl .ant-timeline-item-head-custom {
    left: auto;
    right: 5px;
    transform: translate(50%, -50%);
  }
  .ant-timeline-rtl .ant-timeline-item-content {
    margin: 0 18px 0 0;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
    left: auto;
    right: 50%;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head {
    margin-left: 0;
    margin-right: -4px;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
    margin-left: 0;
    margin-right: 1px;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
    left: auto;
    right: calc(50% - 4px);
    text-align: right;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    text-align: left;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
    left: auto;
    right: 0;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    margin-right: 18px;
    text-align: right;
    width: 100%;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail,
  .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    border-left: none;
    border-right: 2px dotted #f0f0f0;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
    text-align: left;
  }
  .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
    right: calc(50% + 14px);
    text-align: right;
  }
  .ant-transfer-customize-list .ant-transfer-list {
    flex: 1 1 50%;
    height: auto;
    min-height: 200px;
    width: auto;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
    border: 0;
    border-radius: 0;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background: #fafafa;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
    margin: 0;
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 16px 0 4px;
  }
  .ant-transfer-customize-list .ant-input[disabled] {
    background-color: initial;
  }
  .ant-transfer {
    font-feature-settings: 'tnum';
    align-items: stretch;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .ant-transfer-disabled .ant-transfer-list {
    background: #f5f5f5;
  }
  .ant-transfer-list {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 180px;
  }
  .ant-transfer-list-with-pagination {
    height: auto;
    width: 250px;
  }
  .ant-transfer-list-search {
    padding-left: 8px;
    padding-right: 24px;
  }
  .ant-transfer-list-search-action {
    bottom: 12px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 32px;
    position: absolute;
    right: 12px;
    text-align: center;
    top: 12px;
    width: 28px;
  }
  .ant-transfer-list-search-action .anticon {
    color: rgba(0, 0, 0, 0.25);
    transition: all 0.3s;
  }
  .ant-transfer-list-search-action .anticon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  span.ant-transfer-list-search-action {
    pointer-events: none;
  }
  .ant-transfer-list-header {
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex: none;
    height: 40px;
    padding: 8px 12px 9px;
  }
  .ant-transfer-list-header > :not(:last-child) {
    margin-right: 4px;
  }
  .ant-transfer-list-header > * {
    flex: none;
  }
  .ant-transfer-list-header-title {
    flex: auto;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-transfer-list-header-dropdown {
    cursor: pointer;
    font-size: 10px;
    transform: translateY(10%);
  }
  .ant-transfer-list-header-dropdown[disabled] {
    cursor: not-allowed;
  }
  .ant-transfer-list-body {
    display: flex;
    flex: auto;
    flex-direction: column;
    font-size: 14px;
    overflow: hidden;
  }
  .ant-transfer-list-body-search-wrapper {
    flex: none;
    padding: 12px;
    position: relative;
  }
  .ant-transfer-list-content {
    flex: auto;
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0;
  }
  .ant-transfer-list-content-item {
    align-items: center;
    display: flex;
    line-height: 20px;
    min-height: 32px;
    padding: 6px 12px;
    transition: all 0.3s;
  }
  .ant-transfer-list-content-item > :not(:last-child) {
    margin-right: 8px;
  }
  .ant-transfer-list-content-item > * {
    flex: none;
  }
  .ant-transfer-list-content-item-text {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-transfer-list-content-item-remove {
    color: #1890ff;
    color: #d9d9d9;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    transition: color 0.3s;
  }
  .ant-transfer-list-content-item-remove:focus,
  .ant-transfer-list-content-item-remove:hover {
    color: #40a9ff;
  }
  .ant-transfer-list-content-item-remove:active {
    color: #096dd9;
  }
  .ant-transfer-list-content-item-remove:after {
    bottom: -6px;
    content: '';
    left: -50%;
    position: absolute;
    right: -50%;
    top: -6px;
  }
  .ant-transfer-list-content-item-remove:hover {
    color: #40a9ff;
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background-color: #dcf4ff;
  }
  .ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background: transparent;
    cursor: default;
  }
  .ant-transfer-list-content-item-checked {
    background-color: #e6f7ff;
  }
  .ant-transfer-list-content-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-transfer-list-pagination {
    border-top: 1px solid #f0f0f0;
    padding: 8px 0;
    text-align: right;
  }
  .ant-transfer-list-body-not-found {
    color: rgba(0, 0, 0, 0.25);
    flex: none;
    margin: auto 0;
    text-align: center;
    width: 100%;
  }
  .ant-transfer-list-footer {
    border-top: 1px solid #f0f0f0;
  }
  .ant-transfer-operation {
    align-self: center;
    display: flex;
    flex: none;
    flex-direction: column;
    margin: 0 8px;
    vertical-align: middle;
  }
  .ant-transfer-operation .ant-btn {
    display: block;
  }
  .ant-transfer-operation .ant-btn:first-child {
    margin-bottom: 4px;
  }
  .ant-transfer-operation .ant-btn .anticon {
    font-size: 12px;
  }
  .ant-transfer .ant-empty-image {
    max-height: -2px;
  }
  .ant-transfer-rtl {
    direction: rtl;
  }
  .ant-transfer-rtl .ant-transfer-list-search {
    padding-left: 24px;
    padding-right: 8px;
  }
  .ant-transfer-rtl .ant-transfer-list-search-action {
    left: 12px;
    right: auto;
  }
  .ant-transfer-rtl .ant-transfer-list-header > :not(:last-child) {
    margin-left: 4px;
    margin-right: 0;
  }
  .ant-transfer-rtl .ant-transfer-list-header {
    left: auto;
    right: 0;
  }
  .ant-transfer-rtl .ant-transfer-list-header-title {
    text-align: left;
  }
  .ant-transfer-rtl .ant-transfer-list-content-item > :not(:last-child) {
    margin-left: 8px;
    margin-right: 0;
  }
  .ant-transfer-rtl .ant-transfer-list-pagination {
    text-align: left;
  }
  .ant-transfer-rtl .ant-transfer-list-footer {
    left: auto;
    right: 0;
  }
  .ant-select-tree-checkbox {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 1;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    top: 0.2em;
    white-space: nowrap;
  }
  .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner,
  .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
  .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
    border-color: #1890ff;
  }
  .ant-select-tree-checkbox-checked:after {
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    border: 1px solid #1890ff;
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
  }
  .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after,
  .ant-select-tree-checkbox:hover:after {
    visibility: visible;
  }
  .ant-select-tree-checkbox-inner {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-collapse: initial;
    border-radius: 2px;
    direction: ltr;
    display: block;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    transition: all 0.3s;
    width: 16px;
  }
  .ant-select-tree-checkbox-inner:after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: table;
    height: 9.14285714px;
    left: 22%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    width: 5.71428571px;
  }
  .ant-select-tree-checkbox-input {
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: table;
    opacity: 1;
    position: absolute;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  .ant-select-tree-checkbox-disabled {
    cursor: not-allowed;
  }
  .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
    cursor: not-allowed;
  }
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-collapse: initial;
    border-color: #f5f5f5;
  }
  .ant-select-tree-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-tree-checkbox-disabled:hover:after,
  .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled:after {
    visibility: hidden;
  }
  .ant-select-tree-checkbox-wrapper {
    font-feature-settings: 'tnum';
    align-items: baseline;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: unset;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-select-tree-checkbox-wrapper:after {
    content: ' ';
    display: inline-block;
    overflow: hidden;
    width: 0;
  }
  .ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-select-tree-checkbox + span {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-select-tree-checkbox-group {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-select-tree-checkbox-group-item {
    margin-right: 8px;
  }
  .ant-select-tree-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
    margin-left: 0;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: #1890ff;
    border: 0;
    content: ' ';
    height: 8px;
    left: 50%;
    opacity: 1;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 8px;
  }
  .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-tree-select-dropdown {
    padding: 8px 4px 0;
  }
  .ant-tree-select-dropdown-rtl {
    direction: rtl;
  }
  .ant-tree-select-dropdown .ant-select-tree {
    border-radius: 0;
  }
  .ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
    align-items: stretch;
  }
  .ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
    padding-bottom: 8px;
  }
  .ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
    flex: auto;
  }
  .ant-select-tree {
    font-feature-settings: 'tnum';
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: background-color 0.3s;
  }
  .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
    background: #e6f7ff;
  }
  .ant-select-tree-list-holder-inner {
    align-items: flex-start;
  }
  .ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
    align-items: stretch;
  }
  .ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
    flex: auto;
  }
  .ant-select-tree .ant-select-tree-treenode {
    align-items: flex-start;
    display: flex;
    outline: none;
    padding: 0 0 4px;
  }
  .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
    background: #f5f5f5;
  }
  .ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
    color: inherit;
    font-weight: 500;
  }
  .ant-select-tree-indent {
    align-self: stretch;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
  }
  .ant-select-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }
  .ant-select-tree-switcher {
    align-self: stretch;
    cursor: pointer;
    flex: none;
    line-height: 24px;
    margin: 0;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 24px;
  }
  .ant-select-tree-switcher .ant-select-tree-switcher-icon,
  .ant-select-tree-switcher .ant-tree-switcher-icon {
    display: inline-block;
    font-size: 10px;
    vertical-align: initial;
  }
  .ant-select-tree-switcher .ant-select-tree-switcher-icon svg,
  .ant-select-tree-switcher .ant-tree-switcher-icon svg {
    transition: transform 0.3s;
  }
  .ant-select-tree-switcher-noop {
    cursor: default;
  }
  .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
    transform: rotate(-90deg);
  }
  .ant-select-tree-switcher-loading-icon {
    color: #1890ff;
  }
  .ant-select-tree-switcher-leaf-line {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .ant-select-tree-switcher-leaf-line:before {
    border-left: 1px solid #d9d9d9;
    bottom: -4px;
    content: ' ';
    margin-left: -1px;
    position: absolute;
    top: 0;
  }
  .ant-select-tree-switcher-leaf-line:after {
    border-bottom: 1px solid #d9d9d9;
    content: ' ';
    height: 14px;
    margin-left: -1px;
    position: absolute;
    width: 10px;
  }
  .ant-select-tree-checkbox {
    margin: 4px 8px 0 0;
    top: auto;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper {
    background: transparent;
    border-radius: 2px;
    color: inherit;
    cursor: pointer;
    line-height: 24px;
    margin: 0;
    min-height: 24px;
    padding: 0 4px;
    position: relative;
    transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
    z-index: auto;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: #f5f5f5;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #bae7ff;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
    width: 24px;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
    display: none;
  }
  .ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
    background-color: initial;
  }
  .ant-select-tree-node-content-wrapper[draggable='true'] {
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
    background-color: #1890ff;
    border-radius: 1px;
    height: 2px;
    pointer-events: none;
    position: absolute;
    z-index: 1;
  }
  .ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator:after {
    background-color: initial;
    border: 2px solid #1890ff;
    border-radius: 50%;
    content: '';
    height: 8px;
    left: -6px;
    position: absolute;
    top: -3px;
    width: 8px;
  }
  .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px #1890ff;
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit {
    height: 100%;
    position: relative;
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit:before {
    border-right: 1px solid #d9d9d9;
    bottom: -4px;
    content: '';
    position: absolute;
    right: 12px;
    top: 0;
  }
  .ant-select-tree-show-line .ant-select-tree-indent-unit-end:before {
    display: none;
  }
  .ant-select-tree-show-line .ant-select-tree-switcher {
    background: #fff;
  }
  .ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
    vertical-align: -0.225em;
  }
  .ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
    transform: rotate(90deg);
  }
  .ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
    transform: scaleY(-1);
  }
  @-webkit-keyframes antCheckboxEffect {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.6);
    }
  }
  @keyframes antCheckboxEffect {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.6);
    }
  }
  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
    bottom: auto !important;
    height: 14px !important;
    top: auto !important;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode {
    position: relative;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode:before {
    bottom: 4px;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 0.3s;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode:hover:before {
    background: #f5f5f5;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode > * {
    z-index: 1;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
    transition: color 0.3s;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: transparent;
    color: #fff;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:before,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover:before {
    background: #1890ff;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
    color: #fff;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
    background: transparent;
    color: #fff;
  }
  .ant-tree-checkbox {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 1;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    top: 0.2em;
    white-space: nowrap;
  }
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: #1890ff;
  }
  .ant-tree-checkbox-checked:after {
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    border: 1px solid #1890ff;
    border-radius: 2px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
  }
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after,
  .ant-tree-checkbox:hover:after {
    visibility: visible;
  }
  .ant-tree-checkbox-inner {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-collapse: initial;
    border-radius: 2px;
    direction: ltr;
    display: block;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    transition: all 0.3s;
    width: 16px;
  }
  .ant-tree-checkbox-inner:after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: table;
    height: 9.14285714px;
    left: 22%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    width: 5.71428571px;
  }
  .ant-tree-checkbox-input {
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: table;
    opacity: 1;
    position: absolute;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  .ant-tree-checkbox-disabled {
    cursor: not-allowed;
  }
  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
    cursor: not-allowed;
  }
  .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-collapse: initial;
    border-color: #f5f5f5;
  }
  .ant-tree-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-tree-checkbox-disabled:hover:after,
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled:after {
    visibility: hidden;
  }
  .ant-tree-checkbox-wrapper {
    font-feature-settings: 'tnum';
    align-items: baseline;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: unset;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-tree-checkbox-wrapper:after {
    content: ' ';
    display: inline-block;
    overflow: hidden;
    width: 0;
  }
  .ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-tree-checkbox + span {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ant-tree-checkbox-group {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-tree-checkbox-group-item {
    margin-right: 8px;
  }
  .ant-tree-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
    margin-left: 0;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    background-color: #1890ff;
    border: 0;
    content: ' ';
    height: 8px;
    left: 50%;
    opacity: 1;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 8px;
  }
  .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-tree {
    font-feature-settings: 'tnum';
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: background-color 0.3s;
  }
  .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
    background: #e6f7ff;
  }
  .ant-tree-list-holder-inner {
    align-items: flex-start;
  }
  .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
    align-items: stretch;
  }
  .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
    flex: auto;
  }
  .ant-tree .ant-tree-treenode {
    align-items: flex-start;
    display: flex;
    outline: none;
    padding: 0 0 4px;
  }
  .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
    background: #f5f5f5;
  }
  .ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
    color: inherit;
    font-weight: 500;
  }
  .ant-tree-indent {
    align-self: stretch;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
  }
  .ant-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }
  .ant-tree-switcher {
    align-self: stretch;
    cursor: pointer;
    flex: none;
    line-height: 24px;
    margin: 0;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 24px;
  }
  .ant-tree-switcher .ant-select-tree-switcher-icon,
  .ant-tree-switcher .ant-tree-switcher-icon {
    display: inline-block;
    font-size: 10px;
    vertical-align: initial;
  }
  .ant-tree-switcher .ant-select-tree-switcher-icon svg,
  .ant-tree-switcher .ant-tree-switcher-icon svg {
    transition: transform 0.3s;
  }
  .ant-tree-switcher-noop {
    cursor: default;
  }
  .ant-tree-switcher_close .ant-tree-switcher-icon svg {
    transform: rotate(-90deg);
  }
  .ant-tree-switcher-loading-icon {
    color: #1890ff;
  }
  .ant-tree-switcher-leaf-line {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .ant-tree-switcher-leaf-line:before {
    border-left: 1px solid #d9d9d9;
    bottom: -4px;
    content: ' ';
    margin-left: -1px;
    position: absolute;
    top: 0;
  }
  .ant-tree-switcher-leaf-line:after {
    border-bottom: 1px solid #d9d9d9;
    content: ' ';
    height: 14px;
    margin-left: -1px;
    position: absolute;
    width: 10px;
  }
  .ant-tree-checkbox {
    margin: 4px 8px 0 0;
    top: auto;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    background: transparent;
    border-radius: 2px;
    color: inherit;
    cursor: pointer;
    line-height: 24px;
    margin: 0;
    min-height: 24px;
    padding: 0 4px;
    position: relative;
    transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
    z-index: auto;
  }
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: #f5f5f5;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #bae7ff;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
    width: 24px;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
    display: none;
  }
  .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
    background-color: initial;
  }
  .ant-tree-node-content-wrapper[draggable='true'] {
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
    background-color: #1890ff;
    border-radius: 1px;
    height: 2px;
    pointer-events: none;
    position: absolute;
    z-index: 1;
  }
  .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator:after {
    background-color: initial;
    border: 2px solid #1890ff;
    border-radius: 50%;
    content: '';
    height: 8px;
    left: -6px;
    position: absolute;
    top: -3px;
    width: 8px;
  }
  .ant-tree .ant-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px #1890ff;
  }
  .ant-tree-show-line .ant-tree-indent-unit {
    height: 100%;
    position: relative;
  }
  .ant-tree-show-line .ant-tree-indent-unit:before {
    border-right: 1px solid #d9d9d9;
    bottom: -4px;
    content: '';
    position: absolute;
    right: 12px;
    top: 0;
  }
  .ant-tree-show-line .ant-tree-indent-unit-end:before {
    display: none;
  }
  .ant-tree-show-line .ant-tree-switcher {
    background: #fff;
  }
  .ant-tree-show-line .ant-tree-switcher-line-icon {
    vertical-align: -0.225em;
  }
  .ant-tree-rtl {
    direction: rtl;
  }
  .ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator:after {
    left: unset;
    right: -6px;
  }
  .ant-tree .ant-tree-treenode-rtl {
    direction: rtl;
  }
  .ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
    transform: rotate(90deg);
  }
  .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit:before {
    border-left: 1px solid #d9d9d9;
    border-right: none;
    left: -13px;
    right: auto;
  }
  .ant-tree-rtl.ant-tree-checkbox,
  .ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
    margin: 4px 0 0 8px;
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.85);
    overflow-wrap: break-word;
  }
  .ant-typography.ant-typography-secondary {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-typography.ant-typography-success {
    color: #52c41a;
  }
  .ant-typography.ant-typography-warning {
    color: #faad14;
  }
  .ant-typography.ant-typography-danger {
    color: #ff4d4f;
  }
  a.ant-typography.ant-typography-danger:active,
  a.ant-typography.ant-typography-danger:focus,
  a.ant-typography.ant-typography-danger:hover {
    color: #ff7875;
  }
  .ant-typography.ant-typography-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-typography p,
  div.ant-typography {
    margin-bottom: 1em;
  }
  .ant-typography h1,
  h1.ant-typography {
    color: rgba(0, 0, 0, 0.85);
    font-size: 38px;
    font-weight: 600;
    line-height: 1.23;
    margin-bottom: 0.5em;
  }
  .ant-typography h2,
  h2.ant-typography {
    color: rgba(0, 0, 0, 0.85);
    font-size: 30px;
    font-weight: 600;
    line-height: 1.35;
    margin-bottom: 0.5em;
  }
  .ant-typography h3,
  h3.ant-typography {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-weight: 600;
    line-height: 1.35;
    margin-bottom: 0.5em;
  }
  .ant-typography h4,
  h4.ant-typography {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0.5em;
  }
  .ant-typography h5,
  h5.ant-typography {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.5em;
  }
  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography,
  .ant-typography + h5.ant-typography,
  .ant-typography div + h1,
  .ant-typography div + h2,
  .ant-typography div + h3,
  .ant-typography div + h4,
  .ant-typography div + h5,
  .ant-typography h1 + h1,
  .ant-typography h1 + h2,
  .ant-typography h1 + h3,
  .ant-typography h1 + h4,
  .ant-typography h1 + h5,
  .ant-typography h2 + h1,
  .ant-typography h2 + h2,
  .ant-typography h2 + h3,
  .ant-typography h2 + h4,
  .ant-typography h2 + h5,
  .ant-typography h3 + h1,
  .ant-typography h3 + h2,
  .ant-typography h3 + h3,
  .ant-typography h3 + h4,
  .ant-typography h3 + h5,
  .ant-typography h4 + h1,
  .ant-typography h4 + h2,
  .ant-typography h4 + h3,
  .ant-typography h4 + h4,
  .ant-typography h4 + h5,
  .ant-typography h5 + h1,
  .ant-typography h5 + h2,
  .ant-typography h5 + h3,
  .ant-typography h5 + h4,
  .ant-typography h5 + h5,
  .ant-typography li + h1,
  .ant-typography li + h2,
  .ant-typography li + h3,
  .ant-typography li + h4,
  .ant-typography li + h5,
  .ant-typography p + h1,
  .ant-typography p + h2,
  .ant-typography p + h3,
  .ant-typography p + h4,
  .ant-typography p + h5,
  .ant-typography ul + h1,
  .ant-typography ul + h2,
  .ant-typography ul + h3,
  .ant-typography ul + h4,
  .ant-typography ul + h5 {
    margin-top: 1.2em;
  }
  a.ant-typography-ellipsis,
  span.ant-typography-ellipsis {
    display: inline-block;
  }
  .ant-typography a,
  a.ant-typography {
    color: #1890ff;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;
  }
  .ant-typography a:focus,
  .ant-typography a:hover,
  a.ant-typography:focus,
  a.ant-typography:hover {
    color: #40a9ff;
  }
  .ant-typography a:active,
  a.ant-typography:active {
    color: #096dd9;
  }
  .ant-typography a:active,
  .ant-typography a:hover,
  a.ant-typography:active,
  a.ant-typography:hover {
    text-decoration: none;
  }
  .ant-typography a.ant-typography-disabled,
  .ant-typography a[disabled],
  a.ant-typography.ant-typography-disabled,
  a.ant-typography[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-typography a.ant-typography-disabled:active,
  .ant-typography a.ant-typography-disabled:hover,
  .ant-typography a[disabled]:active,
  .ant-typography a[disabled]:hover,
  a.ant-typography.ant-typography-disabled:active,
  a.ant-typography.ant-typography-disabled:hover,
  a.ant-typography[disabled]:active,
  a.ant-typography[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-typography a.ant-typography-disabled:active,
  .ant-typography a[disabled]:active,
  a.ant-typography.ant-typography-disabled:active,
  a.ant-typography[disabled]:active {
    pointer-events: none;
  }
  .ant-typography code {
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
    font-size: 85%;
    margin: 0 0.2em;
    padding: 0.2em 0.4em 0.1em;
  }
  .ant-typography kbd {
    background: rgba(150, 150, 150, 0.06);
    border: solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
    border-width: 1px 1px 2px;
    font-size: 90%;
    margin: 0 0.2em;
    padding: 0.15em 0.4em 0.1em;
  }
  .ant-typography mark {
    background-color: #ffe58f;
    padding: 0;
  }
  .ant-typography ins,
  .ant-typography u {
    -webkit-text-decoration-skip: ink;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
  .ant-typography del,
  .ant-typography s {
    text-decoration: line-through;
  }
  .ant-typography strong {
    font-weight: 600;
  }
  .ant-typography-copy,
  .ant-typography-edit,
  .ant-typography-expand {
    color: #1890ff;
    cursor: pointer;
    margin-left: 4px;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;
  }
  .ant-typography-copy:focus,
  .ant-typography-copy:hover,
  .ant-typography-edit:focus,
  .ant-typography-edit:hover,
  .ant-typography-expand:focus,
  .ant-typography-expand:hover {
    color: #40a9ff;
  }
  .ant-typography-copy:active,
  .ant-typography-edit:active,
  .ant-typography-expand:active {
    color: #096dd9;
  }
  .ant-typography-copy-success,
  .ant-typography-copy-success:focus,
  .ant-typography-copy-success:hover {
    color: #52c41a;
  }
  .ant-typography-edit-content {
    position: relative;
  }
  div.ant-typography-edit-content {
    left: -12px;
    margin-bottom: calc(1em - 5px);
    margin-top: -5px;
  }
  .ant-typography-edit-content-confirm {
    bottom: 8px;
    color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
    position: absolute;
    right: 10px;
  }
  .ant-typography-edit-content textarea {
    -moz-transition: none;
  }
  .ant-typography ol,
  .ant-typography ul {
    margin: 0 0 1em;
    padding: 0;
  }
  .ant-typography ol li,
  .ant-typography ul li {
    margin: 0 0 0 20px;
    padding: 0 0 0 4px;
  }
  .ant-typography ul {
    list-style-type: circle;
  }
  .ant-typography ul ul {
    list-style-type: disc;
  }
  .ant-typography ol {
    list-style-type: decimal;
  }
  .ant-typography blockquote,
  .ant-typography pre {
    margin: 1em 0;
  }
  .ant-typography pre {
    word-wrap: break-word;
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
    padding: 0.4em 0.6em;
    white-space: pre-wrap;
  }
  .ant-typography pre code {
    background: transparent;
    border: 0;
    display: inline;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
  }
  .ant-typography blockquote {
    border-left: 4px solid rgba(100, 100, 100, 0.2);
    opacity: 0.85;
    padding: 0 0 0 0.6em;
  }
  .ant-typography-single-line {
    white-space: nowrap;
  }
  .ant-typography-ellipsis-single-line {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a.ant-typography-ellipsis-single-line,
  span.ant-typography-ellipsis-single-line {
    vertical-align: bottom;
  }
  .ant-typography-ellipsis-multiple-line {
    -webkit-line-clamp: 3; /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .ant-typography-rtl {
    direction: rtl;
  }
  .ant-typography-rtl .ant-typography-copy,
  .ant-typography-rtl .ant-typography-edit,
  .ant-typography-rtl .ant-typography-expand {
    margin-left: 0;
    margin-right: 4px;
  }
  .ant-typography-rtl .ant-typography-expand {
    float: left;
  }
  div.ant-typography-edit-content.ant-typography-rtl {
    left: auto;
    right: -12px;
  }
  .ant-typography-rtl .ant-typography-edit-content-confirm {
    left: 10px;
    right: auto;
  }
  .ant-typography-rtl.ant-typography ol li,
  .ant-typography-rtl.ant-typography ul li {
    margin: 0 20px 0 0;
    padding: 0 4px 0 0;
  }
  .ant-upload {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 0;
  }
  .ant-upload p {
    margin: 0;
  }
  .ant-upload-btn {
    display: block;
    outline: none;
    width: 100%;
  }
  .ant-upload input[type='file'] {
    cursor: pointer;
  }
  .ant-upload.ant-upload-select {
    display: inline-block;
  }
  .ant-upload.ant-upload-disabled {
    cursor: not-allowed;
  }
  .ant-upload.ant-upload-select-picture-card {
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    height: 104px;
    margin-bottom: 8px;
    margin-right: 8px;
    text-align: center;
    transition: border-color 0.3s;
    vertical-align: top;
    width: 104px;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff;
  }
  .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #d9d9d9;
  }
  .ant-upload.ant-upload-drag {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    height: 100%;
    position: relative;
    text-align: center;
    transition: border-color 0.3s;
    width: 100%;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0;
  }
  .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: #096dd9;
  }
  .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #40a9ff;
    font-size: 48px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    margin: 0 0 4px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(0, 0, 0, 0.25);
    font-size: 30px;
    transition: all 0.3s;
  }
  .ant-upload.ant-upload-drag .anticon-plus:hover,
  .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 100%;
  }
  .ant-upload-picture-card-wrapper:before {
    content: '';
    display: table;
  }
  .ant-upload-picture-card-wrapper:after {
    clear: both;
    content: '';
    display: table;
  }
  .ant-upload-list {
    font-feature-settings: 'tnum';
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-upload-list:after,
  .ant-upload-list:before {
    content: '';
    display: table;
  }
  .ant-upload-list:after {
    clear: both;
  }
  .ant-upload-list-item {
    font-size: 14px;
    height: 22.001px;
    margin-top: 8px;
    position: relative;
  }
  .ant-upload-list-item-name {
    display: inline-block;
    line-height: 1.5715;
    overflow: hidden;
    padding-left: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .ant-upload-list-item-card-actions {
    position: absolute;
    right: 0;
  }
  .ant-upload-list-item-card-actions-btn {
    opacity: 0;
  }
  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    height: 20px;
    line-height: 1;
  }
  .ant-upload-list-item-card-actions.picture {
    line-height: 0;
    top: 22px;
  }
  .ant-upload-list-item-card-actions-btn:focus,
  .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item-card-actions .anticon {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-upload-list-item-info {
    height: 100%;
    padding: 0 4px;
    transition: background-color 0.3s;
  }
  .ant-upload-list-item-info > span {
    display: block;
    height: 100%;
    width: 100%;
  }
  .ant-upload-list-item-info .ant-upload-text-icon .anticon,
  .ant-upload-list-item-info .anticon-loading .anticon {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    position: absolute;
    top: 5px;
  }
  .ant-upload-list-item .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    font-size: 10px;
    line-height: 0;
    opacity: 0;
    position: absolute;
    right: 4px;
    top: 6px;
    transition: all 0.3s;
  }
  .ant-upload-list-item .anticon-close:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #f5f5f5;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn,
  .ant-upload-list-item:hover .anticon-close {
    opacity: 1;
  }
  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #ff4d4f;
  }
  .ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item-progress {
    bottom: -12px;
    font-size: 14px;
    line-height: 0;
    padding-left: 26px;
    position: absolute;
    width: 100%;
  }
  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-list-picture .ant-upload-list-item {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 66px;
    padding: 8px;
    position: relative;
  }
  .ant-upload-list-picture-card .ant-upload-list-item:hover,
  .ant-upload-list-picture .ant-upload-list-item:hover {
    background: transparent;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-error,
  .ant-upload-list-picture .ant-upload-list-item-error {
    border-color: #ff4d4f;
  }
  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info,
  .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading,
  .ant-upload-list-picture .ant-upload-list-item-uploading {
    border-style: dashed;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    height: 48px;
    line-height: 54px;
    opacity: 0.8;
    text-align: center;
    width: 48px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon {
    font-size: 26px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #fff2f0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
    fill: #ff4d4f;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-icon,
  .ant-upload-list-picture .ant-upload-list-item-icon {
    font-size: 26px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon,
  .ant-upload-list-picture .ant-upload-list-item-icon .anticon {
    font-size: 26px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-image,
  .ant-upload-list-picture .ant-upload-list-item-image {
    max-width: 100%;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    display: block;
    height: 48px;
    overflow: hidden;
    width: 48px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-name,
  .ant-upload-list-picture .ant-upload-list-item-name {
    box-sizing: border-box;
    display: inline-block;
    line-height: 44px;
    margin: 0 0 0 8px;
    max-width: 100%;
    overflow: hidden;
    padding-left: 48px;
    padding-right: 8px;
    text-overflow: ellipsis;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name,
  .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name {
    line-height: 28px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-progress,
  .ant-upload-list-picture .ant-upload-list-item-progress {
    bottom: 14px;
    margin-top: 0;
    padding-left: 56px;
    width: calc(100% - 24px);
  }
  .ant-upload-list-picture-card .anticon-close,
  .ant-upload-list-picture .anticon-close {
    line-height: 1;
    opacity: 1;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .ant-upload-list-picture-card-container {
    display: inline-block;
    height: 104px;
    margin: 0 8px 8px 0;
    vertical-align: top;
    width: 104px;
  }
  .ant-upload-list-picture-card.ant-upload-list:after {
    display: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100%;
    margin: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: ' ';
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: all 0.3s;
    width: 100%;
    z-index: 1;
  }
  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
    opacity: 1;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
    white-space: nowrap;
    z-index: 10;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    font-size: 16px;
    margin: 0 4px;
    transition: all 0.3s;
    width: 16px;
    z-index: 10;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover {
    color: #fff;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions {
    opacity: 1;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    position: static;
    width: 100%;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: none;
    line-height: 1.5715;
    margin: 8px 0 0;
    padding: 0;
    text-align: center;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    bottom: 10px;
    display: block;
    position: absolute;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before {
    display: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 32px;
    padding-left: 0;
    width: calc(100% - 14px);
  }
  .ant-upload-list-picture-container,
  .ant-upload-list-text-container {
    transition: opacity 0.3s, height 0.3s;
  }
  .ant-upload-list-picture-container:before,
  .ant-upload-list-text-container:before {
    content: '';
    display: table;
    height: 0;
    width: 0;
  }
  .ant-upload-list-picture-container .ant-upload-span,
  .ant-upload-list-text-container .ant-upload-span {
    display: block;
    flex: auto;
  }
  .ant-upload-list-picture .ant-upload-span,
  .ant-upload-list-text .ant-upload-span {
    align-items: center;
    display: flex;
  }
  .ant-upload-list-picture .ant-upload-span > *,
  .ant-upload-list-text .ant-upload-span > * {
    flex: none;
  }
  .ant-upload-list-picture .ant-upload-list-item-name,
  .ant-upload-list-text .ant-upload-list-item-name {
    flex: auto;
    padding: 0 8px;
  }
  .ant-upload-list-picture .ant-upload-list-item-card-actions,
  .ant-upload-list-text .ant-upload-list-item-card-actions,
  .ant-upload-list-text .ant-upload-text-icon .anticon {
    position: static;
  }
  .ant-upload-list .ant-upload-animate-inline-appear,
  .ant-upload-list .ant-upload-animate-inline-enter,
  .ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-upload-list .ant-upload-animate-inline-appear,
  .ant-upload-list .ant-upload-animate-inline-enter {
    -webkit-animation-name: uploadAnimateInlineIn;
    animation-name: uploadAnimateInlineIn;
  }
  .ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-name: uploadAnimateInlineOut;
    animation-name: uploadAnimateInlineOut;
  }
  @-webkit-keyframes uploadAnimateInlineIn {
    0% {
      height: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      width: 0;
    }
  }
  @keyframes uploadAnimateInlineIn {
    0% {
      height: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      width: 0;
    }
  }
  @-webkit-keyframes uploadAnimateInlineOut {
    to {
      height: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      width: 0;
    }
  }
  @keyframes uploadAnimateInlineOut {
    to {
      height: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      width: 0;
    }
  }
  .ant-upload-rtl {
    direction: rtl;
  }
  .ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
    margin-left: 8px;
    margin-right: auto;
  }
  .ant-upload-list-rtl {
    direction: rtl;
  }
  .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-left: 14px;
    padding-right: 22px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-left: 28px;
    padding-right: 22px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-name {
    padding-left: 0;
    padding-right: 22px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
    padding-left: 14px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-card-actions {
    left: 0;
    right: auto;
  }
  .ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
    padding-left: 5px;
    padding-right: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-item-info {
    padding: 0 4px 0 12px;
  }
  .ant-upload-list-rtl .ant-upload-list-item .anticon-close {
    left: 4px;
    right: auto;
  }
  .ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    padding-left: 5px;
    padding-right: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-item-progress {
    padding-left: 0;
    padding-right: 26px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info,
  .ant-upload-list-picture .ant-upload-list-item-info {
    padding: 0;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail {
    left: auto;
    right: 8px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon,
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon {
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name,
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name {
    margin: 0 8px 0 0;
    padding-left: 8px;
    padding-right: 48px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1,
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1 {
    padding-left: 18px;
    padding-right: 48px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2,
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2 {
    padding-left: 36px;
    padding-right: 48px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress,
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close,
  .ant-upload-list-rtl.ant-upload-list-picture .anticon-close {
    left: 8px;
    right: auto;
  }
  .ant-upload-list-rtl .ant-upload-list-picture-card-container {
    margin: 0 0 8px 8px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
  }
  .ant-divider-horizontal.ant-divider-with-text-center:after,
  .ant-divider-horizontal.ant-divider-with-text-center:before,
  .ant-divider-horizontal.ant-divider-with-text-left:after,
  .ant-divider-horizontal.ant-divider-with-text-left:before,
  .ant-divider-horizontal.ant-divider-with-text-right:after,
  .ant-divider-horizontal.ant-divider-with-text-right:before {
    border-top-color: #cecece !important;
  }
  .ls-renderall {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .ls-renderall > div {
    margin-right: 1%;
    width: 49%;
  }
  .ls-fade {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  .htx-highlight > .htx-highlight {
    padding: 2px;
  }
  .htx-highlight-last:after {
    margin-left: 2px;
  }
  .htx-yoyo:after {
    content: 'Yoyo';
  }
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .ant-modal-confirm-error ul {
    padding-left: 1.5em;
  }
  .react-dropdown-tree-select .node > .toggle {
    color: initial;
    cursor: pointer;
    font-family: monospace;
    font-style: normal;
  }
  .react-dropdown-tree-select .node.disabled > .toggle {
    margin-right: -100%;
    padding-right: 100%;
    position: relative;
    user-select: none;
    z-index: 1;
  }
  .react-dropdown-tree-select .node.disabled > label {
    color: initial;
    cursor: default;
  }
  .react-dropdown-tree-select .node.disabled > label > input {
    opacity: 0;
  }
  .lsf-button {
    --button-color: #000;
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    color: var(--button-color);
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    justify-content: center;
    outline: none;
    padding: 0 15px;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s ease;
  }
  .lsf-button:disabled,
  .lsf-button[disabled],
  .lsf-button_disabled,
  .lsf-button_waiting {
    --button-color: rgba(0, 0, 0, 0.5);
    background-color: #efefef;
    pointer-events: none;
  }
  .lsf-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  }
  .lsf-button:active {
    background: linear-gradient(0, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #fff;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  }
  .lsf-button:focus {
    box-shadow: 0 0 0 6px rgba(0, 153, 255, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 0 0 1px rgba(0, 153, 255, 0.2);
    outline: none;
  }
  .lsf-button__extra {
    align-items: center;
    color: rgba(0, 0, 0, 0.4);
    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-left: 7px;
    margin-right: -7px;
  }
  .lsf-button__icon {
    align-items: center;
    display: flex;
    height: 100%;
    width: 16px;
  }
  .lsf-button__icon:not(:only-child) {
    margin-right: 12px;
  }
  .lsf-button__icon:only-child {
    align-items: center;
    flex: 1;
    justify-content: center;
  }
  .lsf-button_type_text {
    padding: 0;
  }
  .lsf-button[href],
  .lsf-button_type_link,
  .lsf-button_type_text {
    background: none;
    box-shadow: none;
    min-width: 0;
    padding: 0;
  }
  .lsf-button[href]:hover,
  .lsf-button_type_link:hover,
  .lsf-button_type_text:hover {
    box-shadow: none;
  }
  .lsf-button[href],
  .lsf-button_type_link {
    --button-color: #09f;
    text-decoration: underline;
  }
  .lsf-button_look_primary {
    --button-color: #fff;
    background-color: #09f;
    box-shadow: none;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }
  .lsf-button_look_primary:disabled {
    --button-color: rgba(255, 255, 255, 0.8);
    background-color: #bbb;
  }
  .lsf-button_look_primary:hover {
    background: linear-gradient(0, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #09f;
    box-shadow: 0 2px 4px rgba(0, 153, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    color: var(--button-color);
  }
  .lsf-button_look_primary:active {
    background: linear-gradient(0, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #09f;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    color: var(--button-color);
  }
  .lsf-button_look_primary:focus {
    box-shadow: 0 0 0 6px rgba(0, 153, 255, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }
  .lsf-button_look_danger {
    --button-color: #d00;
  }
  .lsf-button_look_destructive {
    --button-color: #fff;
    background-color: #d00;
  }
  .lsf-button_look_destructive.lsf-button_waiting,
  .lsf-button_look_destructive:disabled {
    --button-color: rgba(255, 255, 255, 0.5);
    background-color: #d00;
  }
  .lsf-button_size_compact {
    font-size: 16px;
    height: 36px;
    line-height: 20px;
  }
  .lsf-button_size_medium {
    font-size: 14px;
    height: 32px;
    line-height: 20px;
  }
  .lsf-button_size_small {
    font-size: 12px;
    height: 24px;
    line-height: 12px;
    padding: 0 10px;
  }
  .lsf-button_size_large {
    font-size: 16px;
    height: 40px;
  }
  .lsf-button_size_small .lsf-button__extra {
    margin-left: 5px;
    margin-right: -5px;
  }
  .lsf-button_size_compact .lsf-button__extra,
  .lsf-button_size_medium .lsf-button__extra {
    margin-left: 7px;
    margin-right: -7px;
  }
  .lsf-button_size_large .lsf-button__extra {
    margin-left: 10px;
    margin-right: -10px;
  }
  .lsf-button_withIcon {
    justify-content: space-between;
  }
  .lsf-button_withIcon:not(.lsf-button_type_link):not(.lsf-button[href]) {
    padding: 0 14px;
  }
  .lsf-button_withIcon.lsf-button_size_small {
    padding: 0 10px;
  }
  .lsf-button_waiting {
    animation: lsf-button-waiting 1s linear infinite;
    background-color: #fff;
    background-image: repeating-linear-gradient(-63.43deg, rgba(255, 255, 255, 0.2) 1px, #efefef 2px, #efefef 6px, rgba(255, 255, 255, 0.2) 7px, rgba(255, 255, 255, 0.2) 12px);
    background-position: 40px;
    background-repeat: repeat;
    background-size: 37px 100%;
    pointer-events: none;
  }
  .lsf-button_waiting.lsf-button_look_primary {
    background-color: #09f;
    background-image: repeating-linear-gradient(-63.43deg, rgba(255, 255, 255, 0.2) 1px, transparent 2px, transparent 6px, rgba(255, 255, 255, 0.2) 7px, rgba(255, 255, 255, 0.2) 12px);
  }
  .lsf-button_size_small .lsf-button__icon {
    width: 12px;
  }
  .lsf-button_size_small .lsf-button__icon:not(:only-child) {
    margin-right: 8px;
  }
  .lsf-button-group {
    display: flex;
  }
  .lsf-button-group:not(.lsf-button-group_collapsed) .lsf-button + .lsf-button {
    margin-left: 16px;
  }
  .lsf-button-group_collapsed .lsf-button:first-child {
    border-radius: 5px 0 0 5px;
  }
  .lsf-button-group_collapsed .lsf-button:last-child {
    border-radius: 0 5px 5px 0;
  }
  .lsf-button-group_collapsed .lsf-button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  @-moz-keyframes lsf-button-waiting {
    0% {
      background-position: 0 0;
    }
    to {
      background-position: 37px 0;
    }
  }
  @-webkit-keyframes lsf-button-waiting {
    0% {
      background-position: 0 0;
    }
    to {
      background-position: 37px 0;
    }
  }
  @-o-keyframes lsf-button-waiting {
    0% {
      background-position: 0 0;
    }
    to {
      background-position: 37px 0;
    }
  }
  @keyframes lsf-button-waiting {
    0% {
      background-position: 0 0;
    }
    to {
      background-position: 37px 0;
    }
  }
  .lsf-space {
    grid-gap: 16px;
    display: grid;
  }
  .lsf-space_direction_horizontal {
    align-items: center;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
  }
  .lsf-space_direction_vertical {
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    justify-content: center;
  }
  .lsf-space_spread {
    grid-gap: 0;
    justify-content: space-between;
    width: 100%;
  }
  .lsf-space_align_start {
    justify-content: flex-start;
  }
  .lsf-space_align_end {
    justify-content: flex-end;
  }
  .lsf-space_stretch.lsf-space_direction_horizontal {
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
  }
  .lsf-space_size_large {
    grid-gap: 32px;
  }
  .lsf-space_size_small {
    grid-gap: 8px;
  }
  .lsf-space_collapsed,
  .lsf-space_size_none {
    grid-gap: 0;
  }
  .lsf-field-label {
    margin-bottom: 0;
  }
  .lsf-field-label__text {
    display: flex;
    font-size: 14px;
    height: 22px;
    justify-content: space-between;
    line-height: 22px;
    margin-bottom: 4px;
    padding: 0 16px;
  }
  .lsf-field-label__description {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
  }
  .lsf-field-label__field {
    line-height: 0;
  }
  .lsf-field-label_size_large .lsf-field-label__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 16px;
  }
  .lsf-field-label_flat .lsf-field-label__text {
    padding: 0;
  }
  .lsf-field-label .lsf-input,
  .lsf-field-label .lsf-select,
  .lsf-field-label .lsf-textarea {
    width: 100%;
  }
  .lsf-field-label[data-required] .lsf-field-label__text:after {
    color: rgba(0, 0, 0, 0.2);
    content: 'Required';
  }
  .lsf-field-label_large .lsf-field-label__text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .lsf-field-label_placement_right {
    display: inline-flex;
    flex-direction: row-reverse;
  }
  .lsf-field-label_placement_left {
    display: inline-flex;
  }
  .lsf-field-label_empty .lsf-field-label__text,
  .lsf-field-label_placement_left .lsf-field-label__text,
  .lsf-field-label_placement_right .lsf-field-label__text {
    align-items: center;
    font-size: 16px;
    height: auto;
    line-height: 22px;
    margin-bottom: 0;
  }
  .lsf-field-label_placement_left:not(.lsf-field-label_withDescription) .lsf-field-label__field,
  .lsf-field-label_placement_right:not(.lsf-field-label_withDescription) .lsf-field-label__field {
    align-items: center;
    display: flex;
  }
  .lsf-field-label_placement_left.lsf-field-label_withDescription .lsf-field-label__field,
  .lsf-field-label_placement_right.lsf-field-label_withDescription .lsf-field-label__field {
    margin-top: 5px;
  }
  .lsf-toggle {
    background: linear-gradient(0, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #fff;
    border-radius: 12px;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    color: #09f;
    cursor: pointer;
    display: block;
    height: 24px;
    max-height: 24px;
    min-width: 42px;
    position: relative;
    transition: box-shadow 80ms ease;
    width: 42px;
  }
  .lsf-toggle__input {
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .lsf-toggle__indicator {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    transition: all 0.12s ease;
    width: 24px;
  }
  .lsf-toggle__indicator:before {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    content: '';
    height: 10px;
    transition: all 0.12s ease;
    width: 10px;
  }
  .lsf-toggle_checked .lsf-toggle__indicator {
    margin-left: 18px;
  }
  .lsf-toggle_checked .lsf-toggle__indicator:before {
    background: currentColor;
    box-shadow: 0 5px 10px rgba(0, 153, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    height: 16px;
    width: 16px;
  }
  .lsf-toggle_disabled {
    background: rgba(0, 0, 0, 0.08);
    box-shadow: none;
    pointer-events: none;
  }
  .lsf-toggle_disabled .lsf-toggle__indicator {
    transition: none;
  }
  .lsf-toggle_disabled .lsf-toggle__indicator:before,
  .lsf-toggle_disabled.lsf-toggle_checked .lsf-toggle__indicator:before {
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    transition: none;
  }
  .lsf-dynamic-preannotations {
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    height: 44px;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .lsf-dynamic-preannotations__wrapper {
    width: 100%;
  }
  .lsf-dynamic-preannotations__action {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    height: 28px;
    justify-content: center;
    margin: 2px;
    padding: 0;
    width: 28px;
  }
  .lsf-dynamic-preannotations__action_type_accept svg {
    height: 10px;
    width: 15px;
  }
  .lsf-dynamic-preannotations__action_type_reject svg {
    height: 12.5px;
    width: 12.5px;
  }
  .lsf-tooltip {
    --animation-duration: 100ms;
    --animation-curve: cubic-bezier(0.21, 1.04, 0.68, 1);
    --animation-start: -10px;
    border-radius: 3px;
    color: #fff;
    display: none;
    left: -1000px;
    padding: 4px 10px;
    pointer-events: none;
    top: -1000px;
    z-index: 10000;
  }
  .lsf-tooltip,
  .lsf-tooltip:before {
    background-color: #000;
    position: absolute;
  }
  .lsf-tooltip:before {
    bottom: 0;
    content: '';
    display: block;
    height: 10px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    width: 10px;
  }
  .lsf-tooltip.lsf-before-appear,
  .lsf-tooltip.lsf-before-disappear {
    transition-iming-function: var(--animation-curve);
    transition-duration: var(--animation-duration);
    transition-property: opacity, transform;
  }
  .lsf-tooltip.lsf-before-appear {
    display: flex;
    opacity: 0;
    transform: translate3d(0, var(--animation-start), 0);
  }
  .lsf-tooltip.lsf-appear {
    opacity: 1;
    transform: translateZ(0);
  }
  .lsf-tooltip.lsf-visible {
    display: flex;
    opacity: 1;
  }
  .lsf-tooltip.lsf-before-disappear {
    display: flex;
    opacity: 1;
    transform: translateZ(0);
  }
  .lsf-tooltip.lsf-disappear {
    opacity: 0;
    transform: translate3d(0, var(--animation-start), 0);
  }
  .lsf-tooltip_align_top-center {
    --animation-start: -10px;
  }
  .lsf-tooltip_align_top-center:before {
    bottom: 0;
    transform: translate(-50%, 50%) rotate(45deg);
  }
  .lsf-tooltip_align_bottom-center {
    --animation-start: 10px;
  }
  .lsf-tooltip_align_bottom-center:before {
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .lsf-ground-truth__toggle {
    padding: 0;
  }
  .lsf-ground-truth__indicator {
    color: rgba(0, 0, 0, 0.4);
    height: 16px;
    width: 16px;
  }
  .lsf-ground-truth__indicator_active {
    color: #ffbb1a;
  }
  .lsf-ground-truth__indicator_active path {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  .lsf-history {
    display: flex;
  }
  .lsf-history__action {
    background: none !important;
    border: none;
    height: 36px;
    padding: 0 !important;
    width: 36px;
  }
  .lsf-history__action:disabled {
    opacity: 0.6;
  }
  .lsf-history__action svg {
    display: block;
  }
  .lsf-history__action_delete {
    color: #d00;
  }
  .lsf-modal {
    --transition-duration: 100ms;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    will-change: opacity;
    z-index: 1000;
  }
  .lsf-modal__wrapper {
    box-sizing: border-box;
    max-height: 100%;
    overflow: auto;
    padding: 40px 0;
    width: 100%;
    will-change: transform;
  }
  .lsf-modal__content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    min-height: 100px;
    min-width: 400px;
    width: 400px;
  }
  .lsf-modal__header {
    align-items: center;
    box-sizing: initial;
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    padding: 16px 40px;
  }
  .lsf-modal__header_divided {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .lsf-modal__title {
    font-size: 20px;
    margin: 0 auto 0 0;
  }
  .lsf-modal__body {
    padding: 0 40px 32px;
  }
  .lsf-modal__body_bare {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  .lsf-modal__footer {
    background: rgba(0, 0, 0, 0.03);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
    font-size: 14px;
    line-height: 22px;
    padding: 16px 40px;
    text-align: center;
  }
  .lsf-modal_fullscreen .lsf-modal__content {
    height: calc(100vh - 80px);
    width: calc(100vw - 80px);
  }
  .lsf-modal_visible {
    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }
  .lsf-modal_visible .lsf-modal__wrapper {
    transform: scale(1.05);
    transition: transform var(--transition-duration) ease;
  }
  .lsf-modal.lsf-visible {
    opacity: 1;
  }
  .lsf-modal.lsf-visible .lsf-modal__wrapper {
    transform: none;
  }
  .lsf-modal.lsf-before-appear {
    opacity: 0;
  }
  .lsf-modal.lsf-before-appear .lsf-modal__wrapper {
    transform: scale(1.05);
  }
  .lsf-modal.lsf-appear {
    opacity: 1;
  }
  .lsf-modal.lsf-appear .lsf-modal__wrapper {
    transform: scale(1);
  }
  .lsf-modal.lsf-before-disappear {
    opacity: 1;
  }
  .lsf-modal.lsf-before-disappear .lsf-modal__wrapper {
    transform: scale(1);
  }
  .lsf-modal.lsf-disappear {
    opacity: 0;
  }
  .lsf-modal.lsf-disappear .lsf-modal__wrapper {
    transform: scale(1.05);
  }
  .lsf-userpic {
    align-items: center;
    background: #f5f5f5;
    border-radius: 13.5px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: flex;
    height: 28px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    user-select: none;
    width: 28px;
  }
  .lsf-userpic img {
    font-size: 12px;
    height: 100%;
    line-height: 22px;
    object-fit: cover;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  .lsf-userpic__username {
    display: block;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    opacity: 0.4;
  }
  .lsf-annotations-list {
    box-sizing: border-box;
    min-width: 264px;
    position: relative;
  }
  .lsf-annotations-list__list {
    background-color: #fff;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 8px -2px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    left: -1px;
    position: absolute;
    right: -1px;
    top: calc(100% - 1px);
  }
  .lsf-annotations-list__create,
  .lsf-annotations-list__entity {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 0 16px;
  }
  .lsf-annotations-list__create:hover,
  .lsf-annotations-list__entity:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .lsf-annotations-list__entity_selected {
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }
  .lsf-annotations-list__user {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    max-width: 165px;
  }
  .lsf-annotations-list__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .lsf-annotations-list__entity-id {
    flex: none;
    font-size: 11px;
    opacity: 0.5;
    padding-left: 5px;
  }
  .lsf-annotations-list__review {
    font-size: 11px;
    line-height: 13px;
  }
  .lsf-annotations-list__review_state_accepted {
    color: #2aa000;
  }
  .lsf-annotations-list__review_state_fixed {
    color: #fa8c16;
  }
  .lsf-annotations-list__review_state_rejected {
    color: #d00;
  }
  .lsf-annotations-list__counter {
    color: rgba(0, 0, 0, 0.4);
    font-size: 11px;
    line-height: 13px;
  }
  .lsf-annotations-list__toggle {
    height: 32px;
    position: relative;
    width: 32px;
  }
  .lsf-annotations-list__toggle:before {
    border: 2px solid #000;
    border-bottom: none;
    border-right: none;
    content: '';
    display: block;
    height: 10px;
    left: 50%;
    margin-top: -3px;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(225deg);
    width: 10px;
  }
  .lsf-annotations-list__toggle_opened:before {
    margin-top: 3px;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
  .lsf-controls {
    display: grid;
    flex: 1;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: 100%;
    justify-content: flex-end;
  }
  .lsf-controls__tooltip-wrapper button {
    width: 100%;
  }
  .lsf-controls__skipped-info {
    display: flex;
  }
  .lsf-controls__skipped-info svg {
    margin: 0 8px 0 4px;
  }
  .lsf-controls .lsf-button {
    border-radius: 0;
    box-shadow: none;
    font-size: 16px;
    height: 100%;
    min-width: 160px;
  }
  .lsf-current-task {
    align-content: center;
    align-items: center;
    display: flex;
    padding: 0 8px;
  }
  .lsf-current-task_with-history {
    display: grid;
    grid-template-areas: 'id buttons' 'id buttons';
    grid-template-columns: 1fr min-content;
    grid-template-rows: 1fr 1fr;
    height: 100%;
    width: 220px;
  }
  .lsf-current-task__task-id {
    color: #000;
    font-size: 14px;
    grid-area: id;
    user-select: text;
  }
  .lsf-current-task__task-id:before {
    content: '#';
  }
  .lsf-current-task__task-count {
    color: rgba(0, 0, 0, 0.4);
    font-size: 11px;
    grid-area: counter;
    user-select: none;
  }
  .lsf-current-task__history-controls {
    display: flex;
    grid-area: buttons;
  }
  .lsf-current-task__prevnext {
    align-self: center;
    height: 32px;
    justify-self: center;
    width: 32px;
  }
  .lsf-current-task__prevnext:before {
    border: 2px solid #000;
    border-bottom: none;
    border-right: none;
    content: '';
    display: block;
    height: 10px;
    left: 50%;
    margin-top: -3px;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(225deg);
    width: 10px;
  }
  .lsf-current-task__prevnext_next {
    grid-area: n;
    transform: rotate(-90deg);
  }
  .lsf-current-task__prevnext_prev {
    grid-area: p;
    transform: rotate(90deg);
  }
  .lsf-current-task__prevnext_hidden {
    display: none;
  }
  .lsf-current-task__prevnext_disabled:before {
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-bottom: none;
    border-right: none;
  }
  .lsf-topbar {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex: none;
    height: 48px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    user-select: none;
    width: 100%;
    z-index: 101;
  }
  .lsf-topbar,
  .lsf-topbar__group {
    align-items: stretch;
    display: flex;
  }
  .lsf-topbar__section {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 0 8px;
  }
  .lsf-topbar__section_flat {
    padding: 0;
  }
  .lsf-topbar__section + .lsf-topbar__section {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-divider-horizontal--2_B7I.ant-divider-with-text-center--3pS_p:after,
  .ant-divider-horizontal--2_B7I.ant-divider-with-text-center--3pS_p:before,
  .ant-divider-horizontal--2_B7I.ant-divider-with-text-left--2vK-T:after,
  .ant-divider-horizontal--2_B7I.ant-divider-with-text-left--2vK-T:before,
  .ant-divider-horizontal--2_B7I.ant-divider-with-text-right--D36tc:after,
  .ant-divider-horizontal--2_B7I.ant-divider-with-text-right--D36tc:before {
    border-top-color: #cecece !important;
  }
  .ls-renderall--GuD2c {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .ls-renderall--GuD2c > div {
    margin-right: 1%;
    width: 49%;
  }
  .ls-fade--1YyFQ {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity--1cQK8;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  .htx-highlight--1EiC9 > .htx-highlight--1EiC9 {
    padding: 2px;
  }
  .htx-no-label--3euX2:after {
    display: none;
  }
  .htx-highlight-last--3K9fK:after {
    margin-left: 2px;
  }
  .htx-yoyo--9Yx6s:after {
    content: 'Yoyo';
  }
  @keyframes fadeInOpacity--1cQK8 {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .ant-modal-confirm-error--2S-Db ul {
    padding-left: 1.5em;
  }
  .react-dropdown-tree-select--3jjv9 .node--14Etw > .toggle--1AsYT {
    color: initial;
    cursor: pointer;
    font-family: monospace;
    font-style: normal;
  }
  .react-dropdown-tree-select--3jjv9 .node--14Etw.disabled--2tN8i > .toggle--1AsYT {
    margin-right: -100%;
    padding-right: 100%;
    position: relative;
    user-select: none;
    z-index: 1;
  }
  .react-dropdown-tree-select--3jjv9 .node--14Etw.disabled--2tN8i > label {
    color: initial;
    cursor: default;
  }
  .react-dropdown-tree-select--3jjv9 .node--14Etw.disabled--2tN8i > label > input {
    opacity: 0;
  }
  .block--1JMwd {
    border-radius: 0.28571429rem;
    margin-bottom: 1em;
    padding: 1em;
    position: relative;
    width: 100%;
  }
  @media (max-width: 760px) {
    .block--1JMwd {
      margin-right: 0;
      width: 100%;
    }
  }
  .main--3sNRF {
    font-size: 9px;
  }
  .lsf-keys__key {
    align-items: center;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.08);
    display: inline-flex;
    font-size: 12px;
    font-weight: 700;
    height: 16px;
    padding: 0 4px;
    text-transform: uppercase;
  }
  .lsf-keys__key + .lsf-keys__key {
    margin-left: 4px;
  }
  .lsf-keys__key-group {
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .lsf-keys__key-group + .lsf-keys__key-group {
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    margin-top: 5px;
  }
  .progress--3U-pB {
    color: #ff5630;
  }
  .wave--3UTNo {
    position: relative;
  }
  .wave--3UTNo canvas {
    max-width: unset;
  }
  .menu--3O5Z6 {
    margin: 2em 0;
  }
  .link--1GXtV {
    color: #1890ff;
    cursor: pointer;
  }
  .link--1GXtV:hover {
    color: #40a9ff;
  }
  .link--1GXtV:focus {
    color: #1890ff;
  }
  .error--O7ftV {
    background-color: #ffc1ae;
    border: 1px solid #e68a6e;
    border-radius: 3px;
    color: #771b04;
    display: block;
    margin: 16px 0;
    padding: 10px 15px;
  }
  .error--O7ftV + .error--O7ftV {
    margin: 0 0 16px;
  }
  .lsf-range {
    align-items: center;
    display: flex;
  }
  .lsf-range_align_horizontal {
    flex-direction: row;
    height: 28px;
    min-width: 120px;
    padding: 0 5px;
  }
  .lsf-range_align_vertical {
    flex-direction: column;
    min-height: 120px;
    padding: 5px 0;
    width: 28px;
  }
  .lsf-range__body {
    position: relative;
  }
  .lsf-range_align_horizontal .lsf-range__body {
    height: 4px;
    margin: 0 5px;
    width: 120px;
  }
  .lsf-range_align_vertical .lsf-range__body {
    height: 120px;
    margin: 5px 0;
    width: 4px;
  }
  .lsf-range__line {
    background: linear-gradient(0, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #fff;
    border-radius: 8px;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  }
  .lsf-range_align_horizontal .lsf-range__line {
    height: 4px;
    width: 100%;
  }
  .lsf-range_align_vertical .lsf-range__line {
    height: 100%;
    width: 4px;
  }
  .lsf-range__indicator {
    background-color: rgba(0, 153, 255, 0.5);
    border-radius: 8px;
    position: absolute;
  }
  .lsf-range_align_horizontal .lsf-range__indicator {
    height: 4px;
  }
  .lsf-range_align_vertical .lsf-range__indicator {
    width: 4px;
  }
  .lsf-range__range-handle {
    height: 0;
    position: absolute;
    width: 0;
  }
  .lsf-range__range-handle:before {
    background: #09f;
    border-radius: 2px;
    box-shadow: 0 5px 10px rgba(0, 153, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }
  .lsf-range__range-handle:after,
  .lsf-range__range-handle:before {
    content: '';
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
  }
  .lsf-range__range-handle:after {
    height: 25px;
    width: 25px;
  }
  .lsf-range_align_horizontal .lsf-range__range-handle {
    top: 50%;
  }
  .lsf-range_align_horizontal .lsf-range__range-handle:before {
    height: 16px;
    width: 10px;
  }
  .lsf-range_align_vertical .lsf-range__range-handle {
    left: 50%;
  }
  .lsf-range_align_vertical .lsf-range__range-handle:before {
    height: 10px;
    width: 16px;
  }
  .lsf-range__icon {
    align-items: center;
    display: flex;
    height: 28px;
    justify-content: center;
    width: 28px;
  }
  .node--LDDGK {
    color: var(--labelColor, rgba(0, 0, 0, 0.6));
    flex-basis: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .minimal--2DIXG {
    align-items: center;
    display: flex;
  }
  .minimal--2DIXG > * {
    margin-right: 6px;
  }
  .alias--2GhPL {
    border-bottom: 1px solid #000;
    font-weight: 700;
    margin-right: 5px;
  }
  .lsf-node-minimal {
    align-items: center;
    display: inline-flex;
  }
  .lsf-node-minimal__counter {
    align-items: center;
    color: #4f00ff;
    display: flex;
    flex: none;
    justify-content: center;
    padding-right: 7px;
  }
  .lsf-node-minimal__icon {
    margin-right: 5px;
  }
  .lsf-node-minimal__icon svg {
    height: 24px;
    width: 24px;
  }
  .block--1kvYS {
    align-items: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    display: flex;
    flex-flow: column;
    padding: 0.5em;
    width: fit-content;
  }
  .divider--ZWwTQ {
    margin: 12px 0;
  }
  .button--1sh6I {
    margin: 0.3rem 0;
  }
  .block--2awKU {
    align-items: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    display: flex;
    flex-flow: column;
    padding: 0.5em;
    width: fit-content;
  }
  .block--2awKU:empty {
    display: none;
  }
  .divider--1wUlv {
    margin: 12px 0;
  }
  .button--cWhwC {
    margin: 0.3rem 0;
  }
  .wrapper--i-it1 {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .loading--34nhb {
    align-items: center;
    background: rgba(125, 125, 125, 0.15);
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    font-size: 24px;
    height: auto;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 10;
  }
  .container--Xb7Oz {
    overflow: hidden;
  }
  .container--Xb7Oz.rotated--Mvlud {
    position: relative;
    width: 100%;
  }
  .container--Xb7Oz.rotated--Mvlud .filler--37S2l {
    content: '';
    display: block;
  }
  .container--Xb7Oz.rotated--Mvlud img {
    position: absolute;
    top: 0;
  }
  .withGallery--3b8tR {
    margin-bottom: 80px;
  }
  .gallery--jBUKK {
    bottom: -80px;
    display: flex;
    overflow-x: auto;
    padding-bottom: 8px;
    position: absolute;
    width: 100%;
  }
  .gallery--jBUKK img {
    border: 4px solid transparent;
    cursor: pointer;
    height: 60px;
    margin-right: 4px;
    max-width: 120px;
    object-fit: cover;
  }
  .gallery--jBUKK img:hover {
    border-color: #1890ff66;
  }
  .gallery--jBUKK img.active--1beCx {
    border-color: #1890ff;
  }
  .lsf-toolbar {
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 70px;
    width: 40px;
  }
  .lsf-toolbar:before {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 7px 7px 0 0;
    content: '';
    display: block;
    height: 12px;
  }
  .lsf-toolbar__group ~ .lsf-toolbar__group {
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    margin-top: 4px;
  }
  .lsf-toolbar_expanded {
    display: flex;
    flex-direction: column;
    min-width: 210px;
    width: auto;
  }
  .lsf-tool {
    --text-color: rgba(0, 0, 0, 0.4);
    --text-color-hover: #000;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 4px;
    padding: 0;
    position: relative;
    user-select: none;
    width: 32px;
    z-index: 1;
  }
  .lsf-tool:hover {
    z-index: 100;
  }
  .lsf-tool__icon {
    color: var(--text-color-hover);
    height: 32px;
    opacity: 0.5;
    width: 32px;
  }
  .lsf-tool__icon svg {
    height: 100%;
    width: 100%;
  }
  .lsf-tool:hover .lsf-tool__icon,
  .lsf-tool_active .lsf-tool__icon {
    opacity: 1;
  }
  .lsf-tool_active > .lsf-tool__icon {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  }
  .lsf-tool_smart.lsf-tool_active > .lsf-tool__icon {
    background: rgba(127, 100, 255, 0.2);
    box-shadow: inset 0 1px 0 rgba(127, 100, 255, 0.1), inset 0 0 0 1px rgba(127, 100, 255, 0.1);
  }
  .lsf-tool__tooltip {
    box-sizing: border-box;
    display: none;
    font-size: 14px;
    font-weight: 500;
    padding-right: 10px;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    user-select: none;
  }
  .lsf-tool__tooltip-body {
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
    display: flex;
    height: 40px;
    padding: 0 14px;
    white-space: nowrap;
  }
  .lsf-tool:hover .lsf-tool__tooltip {
    display: block;
  }
  .lsf-tool_active .lsf-tool__tooltip {
    display: none;
  }
  .lsf-tool:hover .lsf-tool__tooltip-body,
  .lsf-tool_active .lsf-tool__tooltip-body {
    color: var(--text-color-hover);
  }
  .lsf-tool_alignment_right .lsf-tool__tooltip-body {
    flex-direction: row-reverse;
  }
  .lsf-tool_alignment_left .lsf-tool__tooltip-body {
    flex-direction: row;
  }
  .lsf-tool__tooltip_controlled {
    pointer-events: all;
  }
  .lsf-tool__controls {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
  .lsf-tool__controls-body {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
    display: flex;
  }
  .lsf-tool_alignment_right .lsf-tool__controls {
    padding-right: 10px;
  }
  .lsf-tool_alignment_left .lsf-tool__controls-body {
    padding-left: 10px;
  }
  .lsf-tool__shortcut {
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.6;
  }
  .lsf-tool:hover .lsf-tool__shortcut,
  .lsf-tool_active .lsf-tool__shortcut {
    opacity: 1;
  }
  .lsf-tool_alignment_right .lsf-tool__shortcut {
    margin: 0 20px 0 0;
  }
  .lsf-tool_alignment_left .lsf-tool__shortcut {
    margin: 0 0 0 20px;
  }
  .lsf-tool_expanded.lsf-tool_alignment_right .lsf-tool__shortcut {
    margin: 0 20px 0 0;
  }
  .lsf-tool_expanded.lsf-tool_alignment_left .lsf-tool__shortcut {
    margin: 0 0 0 20px;
  }
  .lsf-tool__key {
    align-items: center;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    font-family: Roboto;
    height: 16px;
    padding: 0 4px;
    text-transform: uppercase;
  }
  .lsf-tool__key + .lsf-tool__key {
    margin-left: 4px;
  }
  .lsf-tool__label {
    align-items: center;
    color: var(--text-color);
    display: flex;
    flex: 1;
    font-weight: 500;
    justify-content: space-between;
  }
  .lsf-tool_alignment_right .lsf-tool__label {
    flex-direction: row-reverse;
    padding-left: 8px;
    padding-right: 12px;
  }
  .lsf-tool_alignment_left .lsf-tool__label {
    flex-direction: row;
    padding-left: 12px;
    padding-right: 8px;
  }
  .lsf-tool:hover .lsf-tool__label,
  .lsf-tool_active .lsf-tool__label {
    color: var(--text-color-hover);
  }
  .lsf-tool__smart {
    display: flex;
  }
  .lsf-tool_expanded {
    width: calc(100% - 8px);
  }
  .lsf-tool_active.lsf-tool_expanded {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 5px;
  }
  .lsf-tool_alignment_left {
    flex-direction: row;
  }
  .lsf-tool_alignment_right {
    flex-direction: row-reverse;
  }
  .lsf-tool_smart {
    --text-color: rgba(127, 100, 255, 0.6);
    --text-color-hover: #7f64ff;
  }
  .lsf-tool_active.lsf-tool_smart {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 5px;
  }
  .lsf-richtext {
    position: relative;
  }
  .lsf-richtext__container {
    fontsize: 16;
    lineheight: 26px;
    overflow: auto;
  }
  .lsf-richtext__orig-container,
  .lsf-richtext__work-container {
    display: none !important;
  }
  .lsf-richtext__iframe {
    border: none;
    width: 100%;
  }
  .lsf-richtext__orig-iframe,
  .lsf-richtext__work-iframe {
    display: none !important;
  }
  .lsf-richtext__loading {
    align-items: center;
    background: rgba(125, 125, 125, 0.15);
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    font-size: 24px;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .htx-no-label:after {
    display: none;
  }
  .lsf-labels {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin: 1em 0;
  }
  .lsf-labels_hidden {
    display: none;
  }
  .lsf-labels:not(.lsf-labels_inline) {
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
  }
  .lsf-label {
    --color: #ccc;
    --background: #e8e8e8;
    align-items: center;
    background-color: var(--background);
    border-left: 4px solid var(--color);
    border-radius: 3px;
    color: #333;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    height: 26px;
    line-height: 26px;
    margin: 0;
    padding: 0 8px;
    position: relative;
  }
  .lsf-label_margins {
    margin: 0 8px 8px 0;
  }
  .lsf-label_margins:last-child {
    margin: 0 0 8px;
  }
  .lsf-label_empty {
    border-left-width: 0;
  }
  .lsf-label_hidden {
    display: none;
  }
  .lsf-label_clickable {
    cursor: pointer;
  }
  .lsf-label_selected,
  .lsf-label_solid {
    background-color: var(--color);
  }
  .lsf-label_selected,
  .lsf-label_selected .lsf-label__hotkey {
    color: #fff;
  }
  .lsf-label__text {
    position: relative;
    white-space: nowrap;
  }
  .lsf-label__hotkey {
    color: rgba(0, 0, 0, 0.4);
    font-size: 13px;
    margin-left: 12px;
    position: relative;
  }
  .mark--2sZtr {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 5px;
    cursor: pointer;
    margin: 0;
    padding: 0.4em 1em;
    white-space: pre-line;
  }
  .mark--2sZtr.selected--23NH3 {
    border: 1px solid red;
  }
  .mark--2sZtr.highlighted--34qAb {
    border: 1px dashed #00aeff;
  }
  .mark--2sZtr.relation--E4JQn {
    cursor: crosshair;
  }
  .delete--LC6Rz {
    color: #1890ff;
    line-height: 1.8em;
    padding-left: 1em;
    padding-top: 0.5em;
  }
  .row--zlvG1 {
    display: flex;
    margin-bottom: 0.5em;
  }
  .row--zlvG1 div[class~='ant-typography-edit-content'] {
    flex-grow: 1;
    left: -1px;
    margin-bottom: calc(1.6em - 9px);
    margin-top: -1px;
    padding: 0;
  }
  .input--VOXHQ,
  .row--zlvG1 div[class~='ant-typography-edit-content'] textarea {
    padding: 0.4em 1em;
  }
  .input--VOXHQ {
    display: block;
    width: 100%;
  }
  .editing--2GJSX {
    padding: 0;
    position: relative;
  }
  .editing--2GJSX .enter--12U-1 {
    pointer-events: all;
  }
  .delete--3L0o- {
    color: #1890ff;
    line-height: 1.8em;
    padding-left: 1em;
    padding-top: 0.5em;
  }
  .lsf-textarea-tag {
    margin-bottom: 12px;
  }
  .lsf-textarea-tag:last-child {
    margin-bottom: 0;
  }
  .lsf-textarea-tag__form {
    margin-bottom: 12px;
  }
  .lsf-textarea-tag__form:last-child {
    margin-bottom: 0;
  }
  .lsf-textarea-tag__form_hidden {
    display: none;
  }
  .lsf-textarea-tag__item {
    margin-bottom: 12px;
    position: relative;
  }
  .lsf-textarea-tag__item:last-child {
    margin-bottom: 0;
  }
  .lsf-textarea-tag__input {
    background: transparent;
    border: none !important;
    box-shadow: none !important;
    color: #666;
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    margin-bottom: 0;
    outline: none !important;
    padding: 0 24px 0 0;
  }
  .lsf-textarea-tag__action {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
  }
  .lsf-textarea-tag__action > span {
    margin: 0 !important;
  }
  .phrase--3va5P {
    background: #e8ffd0;
    border-radius: 5px;
    display: table;
    margin-bottom: 10px;
    margin-right: 5px;
    padding: 0.5em;
  }
  .phrase--3va5P.numbered--FuR10:before {
    content: counter(phrase);
    counter-increment: phrase;
    margin-right: 4px;
  }
  .name--ikW4Q {
    -webkit-touch-callout: none;
    margin-right: 5px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .name--ikW4Q,
  .name--ikW4Q span {
    background: transparent !important;
  }
  .name--ikW4Q span {
    padding: 0 !important;
  }
  .text--3Ztgr {
    zoom: 1;
  }
  .dialoguename--3GGrq {
    -webkit-touch-callout: none;
    background: #fff !important;
    border-radius: 5px;
    font-weight: 700;
    margin-right: 10px;
    padding: 5px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .dialoguename--3GGrq span {
    background: #fff !important;
    padding: 0 !important;
  }
  .dialoguetext--358fr {
    zoom: 1;
  }
  .container--2Qetk {
    counter-reset: phrase;
    overflow: auto;
    position: relative;
  }
  .withAudio--UfYZG {
    margin-left: 36px;
  }
  .audio--1PTBT {
    height: 32px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .play--LRPte {
    font-size: inherit;
    left: 0;
    margin-top: -0.3em;
    position: absolute;
    user-select: none;
  }
  .play--LRPte:active path,
  .play--LRPte:focus path:first-child {
    fill: #1890ff;
  }
  .lsf-video video {
    max-width: 100%;
  }
  .lsf-video__controls {
    display: flex;
  }
  .lsf-video__frame,
  .lsf-video__play {
    align-items: center;
    background-color: #09f;
    border-radius: 8px;
    box-shadow: 0 1px 0 #06c;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    margin-right: 4px;
    width: 36px;
  }
  .lsf-video__frame:active,
  .lsf-video__play:active {
    box-shadow: none;
    transform: translateY(1px);
  }
  .lsf-video__frame:hover,
  .lsf-video__play:hover {
    opacity: 0.8;
  }
  .lsf-video__progress {
    border: 1px solid #ccc;
    border-radius: 8px;
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  .lsf-video__current-time {
    background: #333;
    height: 36px;
    position: relative;
    transform: translate(-1px);
    transition: left 0.1s;
    width: 2px;
  }
  .taxonomy--3ZIQ_ {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    max-width: 100%;
    padding: 4px 4px 0;
    position: relative;
    width: max-content;
  }
  .taxonomy--3ZIQ_ > span {
    cursor: pointer;
    display: inline-block;
    line-height: 32px;
    margin-bottom: 4px;
    padding: 0 5px;
    white-space: nowrap;
  }
  .taxonomy--3ZIQ_ > span:after {
    border-color: #444 transparent transparent;
    border-style: solid;
    border-width: 9px 5px 0;
    content: '';
    display: inline-block;
    margin-left: 6px;
  }
  .taxonomy_open--1jQzp > span:after {
    transform: rotate(-180deg);
  }
  .taxonomy__selected--Fqzb6 {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .taxonomy__selected--Fqzb6 div {
    background: #f4f4f4;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin: 0 4px 4px 0;
    padding: 2px 4px;
  }
  .taxonomy__selected--Fqzb6 input[type='button'] {
    background: none;
    border: none;
    cursor: pointer;
    padding: 6px 0 6px 12px;
  }
  .taxonomy__selected--Fqzb6 input[type='button']:hover {
    color: red;
  }
  .taxonomy__dropdown--2SSCE {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
    left: -1px;
    margin-top: 9px;
    max-height: 60vh;
    max-width: 50vw;
    min-width: 100%;
    overflow-y: auto;
    padding: 8px;
    position: absolute;
    width: max-content;
    z-index: 10;
  }
  .taxonomy__dropdown--2SSCE input[type='checkbox'] {
    font-size: 20px;
    height: 0.8em;
    line-height: 30px;
    margin-right: 4px;
    vertical-align: -3px;
    width: 0.8em;
  }
  .taxonomy__dropdown--2SSCE input[type='checkbox']:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .taxonomy__search--32kx4 {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    box-shadow: 0 -2px 0 6px #fff;
    margin-bottom: 4px;
    padding: 6px 16px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .taxonomy__item--3qB7k ~ div {
    margin-left: 16px;
  }
  .taxonomy__item--3qB7k {
    align-items: center;
    display: flex;
  }
  .taxonomy__item--3qB7k:focus-within {
    background: #eee;
  }
  .taxonomy__item--3qB7k label {
    flex-grow: 1;
    flex-shrink: 0;
    line-height: 2em;
  }
  .taxonomy__grouping--1K7Ux {
    display: inline-block;
    flex-shrink: 0;
    font-family: monospace;
    font-size: 16px;
    margin-left: -2px;
    text-align: center;
    user-select: none;
    width: 20px;
  }
  .taxonomy__collapsable--3gQxI,
  .taxonomy__grouping--1K7Ux:not(:empty) {
    cursor: pointer;
  }
  .block--aErAg {
    background-color: #f8f9f9;
    border: 1px solid #f2f3f4;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    margin: 10px 0;
    padding: 7px 20px;
    position: relative;
  }
  .block--aErAg:last-of-type {
    margin-bottom: 20px;
  }
  .block_selected--3fQdI {
    border: 2px solid #ff4d4f;
  }
  .name--2Y__f {
    font-weight: 700;
  }
  .tag--wibDx {
    margin-top: 10px;
  }
  .date--3X3XS {
    font-size: 0.8rem;
    font-style: italic;
  }
  .ant-divider-horizontal--3_QVz.ant-divider-with-text-center--2ghLj:after,
  .ant-divider-horizontal--3_QVz.ant-divider-with-text-center--2ghLj:before,
  .ant-divider-horizontal--3_QVz.ant-divider-with-text-left--1es8c:after,
  .ant-divider-horizontal--3_QVz.ant-divider-with-text-left--1es8c:before,
  .ant-divider-horizontal--3_QVz.ant-divider-with-text-right--34JtC:after,
  .ant-divider-horizontal--3_QVz.ant-divider-with-text-right--34JtC:before {
    border-top-color: #cecece !important;
  }
  .ls-renderall--2p_YK {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .ls-renderall--2p_YK > div {
    margin-right: 1%;
    width: 49%;
  }
  .ls-fade--120yD {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity--1SyqN;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  .htx-highlight--2sEcE > .htx-highlight--2sEcE {
    padding: 2px;
  }
  .htx-no-label--1PHG7:after {
    display: none;
  }
  .htx-highlight-last--2Y8G7:after {
    margin-left: 2px;
  }
  .htx-yoyo--8gZvk:after {
    content: 'Yoyo';
  }
  @keyframes fadeInOpacity--1SyqN {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .ant-modal-confirm-error--uV-7e ul {
    padding-left: 1.5em;
  }
  .react-dropdown-tree-select--_jp-a .node--1EDf9 > .toggle--2jObT {
    color: initial;
    cursor: pointer;
    font-family: monospace;
    font-style: normal;
  }
  .react-dropdown-tree-select--_jp-a .node--1EDf9.disabled--2379R > .toggle--2jObT {
    margin-right: -100%;
    padding-right: 100%;
    position: relative;
    user-select: none;
    z-index: 1;
  }
  .react-dropdown-tree-select--_jp-a .node--1EDf9.disabled--2379R > label {
    color: initial;
    cursor: default;
  }
  .react-dropdown-tree-select--_jp-a .node--1EDf9.disabled--2379R > label > input {
    opacity: 0;
  }
  .ant-list-item-action--1XVgM {
    margin-left: 10px !important;
  }
  .segment--2c03A {
    min-width: 0;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .editor--38IjC,
  .main-content-wrapper--1qjJ0 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .editor--38IjC {
    --main-bg-color: #fafafa;
    --sticky-items-offset: 48px;
    align-items: stretch;
    background-color: var(--main-bg-color);
    flex-wrap: wrap;
    height: 100%;
    margin: 0 auto;
    min-width: 320px;
  }
  @media (max-width: 760px) {
    .editor--38IjC {
      min-width: 300px;
      width: 300px;
    }
  }
  .editorfs--35DEA {
    background: #fff;
    left: 0;
    padding: 2em;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .menu--389bf {
    align-self: stretch;
    background-color: #fff;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-flow: column;
    min-width: 320px;
    width: 320px;
    z-index: 100;
  }
  @media (max-width: 760px) {
    .menu--389bf {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  .menubsp--1dgDv {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
  }
  .menubsp--1dgDv > div {
    margin-right: 2em;
    width: 33.3%;
  }
  .menubsp--1dgDv > div:last-child {
    margin-right: 0;
  }
  .menu--389bf:empty {
    min-width: auto;
    width: 0;
  }
  .content--3AwAm {
    border: 1px solid #e8e8e8;
    padding: 10px 20px;
  }
  @media (max-width: 640px) {
    .content--3AwAm {
      border: 0;
      padding: 0;
    }
  }
  .common--1n20d {
    align-items: stretch;
    display: grid;
    flex: 1;
    grid-template-columns: calc(100% - 320px) 320px;
    grid-template-rows: 1fr;
    max-width: 100%;
  }
  .common--1n20d.view-all--kQzfL {
    grid-template-columns: 100%;
  }
  .commonbsp--34Ryp {
    display: block;
  }
  .pins--rc2IU {
    display: flex;
    width: 100%;
  }
  .pins--rc2IU div {
    text-align: right;
  }
  .pinsright--3SMzu {
    min-width: 320px;
    width: 320px;
  }
  @media only screen and (max-width: 768px) {
    .common--1n20d {
      display: initial !important;
    }
  }
  .grid--11Oww {
    grid-column-gap: 30px;
    display: grid;
    flex-grow: 1;
    grid-auto-columns: calc(50% - 15px);
    grid-auto-flow: column;
    margin: 0 30px;
    overflow: auto;
    position: relative;
  }
  .grid--11Oww > div > h4 {
    cursor: pointer;
  }
  .container--2d8bU {
    flex-grow: 1;
    min-width: 0;
    position: relative;
  }
  .container--2d8bU > button {
    height: 100%;
    position: absolute;
    top: 0;
    width: 30px;
  }
  .container--2d8bU > button:hover {
    background: #eee;
  }
  .container--2d8bU > button.left--eVd72 {
    left: 0;
  }
  .container--2d8bU > button.right--3WGsC {
    right: 0;
  }
  .grid--11Oww > div.hover {
    background: #eee;
  }
  .grid--11Oww > div.hover h4 {
    color: #1890ff;
  }
  .grid--11Oww > div:empty {
    display: none;
  }
  .lsf-annotation-tabs {
    background-color: var(--main-bg-color);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    display: grid;
    grid-template-columns: 1fr;
    height: 44px;
    position: sticky;
    top: var(--sticky-items-offset);
    width: 100%;
    z-index: 100;
  }
  .lsf-annotation-tabs_viewAll {
    grid-template-columns: 1fr 44px;
  }
  .lsf-annotation-tabs_addNew {
    grid-template-columns: 44px 1fr;
  }
  .lsf-annotation-tabs_viewAll.lsf-annotation-tabs_addNew {
    grid-template-columns: 44px 1fr 44px;
  }
  .lsf-annotation-tabs__add,
  .lsf-annotation-tabs__all {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 44px;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 44px;
  }
  .lsf-annotation-tabs__add {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .lsf-annotation-tabs__all {
    color: rgba(0, 0, 0, 0.6);
    outline: none;
  }
  .lsf-annotation-tabs__all_active {
    color: #09f;
  }
  .lsf-annotation-tabs__list {
    -ms-overflow-style: none;
    display: flex;
    flex: 1;
    overflow: auto;
    position: relative;
    scrollbar-width: none;
  }
  .lsf-annotation-tabs__list::-webkit-scrollbar {
    display: none;
  }
  .lsf-entity-tab {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 100%;
    min-width: 170px;
    padding: 10px 12px;
  }
  .lsf-entity-tab_bordered {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .lsf-entity-tab_selected {
    background-color: var(--main-bg-color);
    box-shadow: inset 0 2px 0 #09f;
    cursor: default;
    opacity: 1;
  }
  .lsf-entity-tab__userpic {
    height: 24px;
    width: 24px;
  }
  .lsf-entity-tab__userpic_prediction {
    background-color: #e9ddf8;
    box-shadow: inset 0 0 0 1px rgba(148, 75, 255, 0.15);
    color: #944bff;
  }
  .lsf-entity-tab__ground-truth {
    color: #ffbb1a;
    margin-top: -2px;
  }
  .lsf-entity-tab__ground-truth path {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  .lsf-entity-tab__skipped {
    color: #d00;
  }
  .lsf-sidebar-tabs {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 100vh;
    position: sticky;
    top: var(--sticky-items-offset);
  }
  .lsf-sidebar-tabs__toggle {
    background-color: #f3f3f3;
    display: flex;
    height: 44px;
    width: 100%;
  }
  .lsf-sidebar-tabs__tab {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 12px 16px;
  }
  .lsf-sidebar-tabs__tab:not(:last-child) {
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.1);
  }
  .lsf-sidebar-tabs__tab_active {
    background-color: #fff;
    box-shadow: none;
    cursor: default;
  }
  .lsf-sidebar-tabs__tab_active:not(:last-child) {
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  }
  .lsf-sidebar-tabs__content {
    flex: 1;
    overflow: auto;
  }
  .lsf-annotation-history {
    margin: 0 0 8px;
  }
  .lsf-annotation-history__divider {
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin: 14px 0;
    position: relative;
  }
  .lsf-annotation-history__divider:before {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.4);
    content: attr(title);
    font-size: 12px;
    left: 0;
    padding-right: 5px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }
  .lsf-history-item {
    border-radius: 5px;
    cursor: default;
    margin: 0 -10px;
    padding: 6px 10px;
  }
  .lsf-history-item__date {
    opacity: 0.4;
  }
  .lsf-history-item:not(.lsf-history-item_disabled) {
    cursor: pointer;
  }
  .lsf-history-item:hover:not(.lsf-history-item_disabled),
  .lsf-history-item_selected:not(.lsf-history-item_disabled) {
    background-color: #efefef;
  }
  .lsf-history-item_disabled {
    background-color: #fcfcfc;
    border-radius: 5px;
    opacity: 0.6;
  }
  .lsf-history-item__userpic_prediction {
    background-color: #e9ddf8;
    box-shadow: inset 0 0 0 1px rgba(148, 75, 255, 0.15);
    color: #944bff;
  }
  .lsf-annotation {
    padding: 16px 16px 4px;
  }
  .lsf-annotation__id {
    align-items: center;
    display: flex;
  }
  .lsf-annotation__type {
    height: 14px;
    margin-right: 5px;
    width: 14px;
  }
  .lsf-annotation__type svg {
    display: block;
    height: 100%;
    width: 100%;
  }
  .lsf-annotation__text_id {
    position: relative;
    top: -3px;
  }
  .lsf-annotation__info {
    height: 32px;
    justify-content: space-between;
    margin-left: 9px;
  }
  .lsf-annotation__info,
  .lsf-annotation__parent,
  .lsf-annotation__parent_info {
    align-items: center;
    display: flex;
  }
  .lsf-annotation__parent_info {
    height: 20px;
    justify-content: space-between;
    margin-left: 8px;
    position: relative;
  }
  .lsf-annotation__parent_link {
    color: #000;
    left: -12px;
    opacity: 20%;
    position: absolute;
    top: -20px;
  }
  .lsf-annotation__parent_icon {
    display: inline;
    height: 14px;
    margin-right: 5px;
    width: 14px;
  }
  .lsf-annotation__parent_icon_prediction {
    color: #944bff;
  }
  .lsf-annotation__parent_icon_annotation {
    color: #09f;
  }
  .lsf-annotation__parent_text {
    color: #000;
    opacity: 40%;
  }
  .lsf-draft-panel {
    margin: 8px 0;
  }
  .lsf-draft-panel__toggle {
    background: none;
    border: 0;
    color: #09f;
    cursor: pointer;
    height: auto;
    padding: 0;
    vertical-align: -0.5px;
  }
  .lsf-draft-panel__toggle:hover {
    opacity: 0.8;
  }
  .ls-menu .ant-tree {
    padding: 16px 0;
  }
  .ls-menu .ant-tree-node-content-wrapper {
    min-width: 0;
  }
  .ls-menu .ant-tree .ant-tree-list-holder-inner {
    padding: 0 16px;
  }
  .ls-menu .ant-tree .ant-tree-treenode {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    position: relative;
  }
  .ls-menu .ant-tree .ant-tree-node-content-wrapper {
    margin-bottom: 12px;
    padding: 0;
    transition: margin-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ls-menu .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ls-menu .ant-tree .ant-tree-node-content-wrapper:hover {
    background: none;
  }
  .ls-menu .ant-tree .ant-tree-switcher {
    display: flex;
    flex-flow: column;
    height: 32px;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    width: 24px;
    z-index: 100;
  }
  .ls-menu .ant-tree .ant-tree-switcher.ant-tree-switcher_close {
    transform: scaleY(-1);
  }
  .ls-menu .ant-tree .ant-tree-indent-unit {
    width: 16px;
  }
  .ls-menu .ant-tree .ant-tree-indent:empty + .ant-tree-switcher {
    margin-left: -12px;
  }
  .ls-menu .ant-tree .ant-tree-indent:empty + .ant-tree-switcher.ant-tree-switcher-noop {
    display: none;
    margin-left: 0;
  }
  .section--3stUo .header--2gZVc {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 4px;
    padding-right: 4px;
  }
  .section--3stUo .title--6Chjw {
    flex: 1;
  }
  .treelabels--Gyqxz {
    max-height: auto;
  }
  .treelabel--1x6NT .ant-tree-node-content-wrapper {
    cursor: default;
  }
  .treetag--dUykE {
    margin: '5px';
  }
  .treetag--dUykE:hover {
    opacity: 1;
  }
  .item--DBLXE {
    color: get-color(error);
    font-size: 14px;
  }
  .item--DBLXE:hover {
    color: #f5222d;
    cursor: pointer;
  }
  .lstitem--2MvV3 {
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0;
    position: relative;
    user-select: none;
  }
  .lstitem--2MvV3 [role='img'],
  .lstitem--2MvV3 > :not(:last-child) {
    margin-right: 6px;
  }
  .lstitem--2MvV3 button.hidden--OeH1g,
  .lstitem--2MvV3 button.visible--2xjRS {
    align-items: flex-end;
    display: flex;
    height: 20px;
    opacity: 0.8;
  }
  .lstitem--2MvV3 button.hidden--OeH1g span,
  .lstitem--2MvV3 button.visible--2xjRS span {
    margin-right: 0;
  }
  .lstitem--2MvV3:hover {
    background-color: var(--labelBgColor);
  }
  .lstitem--2MvV3.selected--26EPW {
    box-shadow: 0 0 0 4px var(--labelBgColor, rgba(0, 0, 0, 0.2));
  }
  .lstitem_label--1L6UI {
    justify-content: space-between;
  }
  .lstitem__actions--3NJ0J {
    flex: 0 0 auto;
  }
  .lstitem__actions--3NJ0J [role='img'] {
    margin: 0;
  }
  .lstitem__actionIcon--1tWdg {
    flex: 0 0 auto;
    justify-content: center;
    text-align: center;
  }
  .list--28nXE .lstitem--2MvV3 {
    border-bottom: none;
  }
  .uihidden--3w0An {
    opacity: 0.5;
  }
  .uivisible--3gELS {
    opacity: 1;
  }
  .ant-tree-switcher--2IZ4x {
    margin-top: 15px;
  }
  .lsf-region-item {
    width: 100%;
  }
  .lsf-region-item__header {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: 32px;
    line-height: 22px;
    padding: 0 8px;
    width: 100%;
  }
  .lsf-region-item__counter {
    align-items: center;
    color: #999;
    display: flex;
    flex: none;
    justify-content: center;
    width: 24px;
  }
  .lsf-region-item_hidden .lsf-region-item__counter,
  .lsf-region-item_hidden .lsf-region-item__title {
    opacity: 0.4;
  }
  .lsf-region-item__id {
    color: var(--labelColor);
    display: flex;
  }
  .lsf-region-item_hidden .lsf-region-item__id {
    opacity: 0.4;
  }
  .lsf-region-item__id svg {
    height: 24px;
    width: 24px;
  }
  .lsf-region-item_hidden .lsf-region-item__score {
    opacity: 0.4;
  }
  .lsf-region-item__toggle {
    flex: 0 0 24px;
    padding: 0 !important;
    padding: 0;
    text-align: center;
    width: 24px;
  }
  .lsf-region-item__desc {
    background: #f7f7f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #e5e5e5;
    padding: 7px 32px 7px 12px;
    position: relative;
  }
  .lsf-region-item_hidden .lsf-region-item__desc {
    background: #fff;
  }
  .lsf-region-item__desc_empty {
    display: none;
  }
  .lsf-region-item__desc_collapsed {
    height: 36px;
    overflow: hidden;
  }
  .lsf-region-item__collapse {
    height: 24px;
    opacity: 0.25;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 6px;
    width: 24px;
  }
  .lsf-region-item__id {
    height: 16px;
    width: 16px;
  }
  .lsf-region-item__id svg {
    color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
  }
  .lsf-region-item__prediction {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }
  .lsf-region-item__prediction svg {
    color: #7f64ff;
    height: 100%;
    width: 100%;
  }
  .lsf-list-item {
    --color: #666;
    padding: 0 8px 0 0;
  }
  .lsf-list-item__title {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
  }
  .lsf-list-item__counter {
    color: var(--color);
    margin-left: 12px;
  }
  .lsf-list-item__visibility {
    padding: 0 !important;
    padding: 24px;
    width: 24px;
  }
  .lsf-sort-menu__option-inner {
    display: flex;
    justify-content: space-between;
    width: 135px;
  }
  .lsf-sort-menu__icon,
  .lsf-sort-menu__title {
    align-items: center;
    display: flex;
  }
  .lsf-sort-menu__icon {
    height: 24px;
    justify-content: center;
    margin-right: 4px;
    width: 24px;
  }
  .lsf-sort-menu__icon > * {
    margin: 0 !important;
  }
  .lsf-radio-group {
    --radius: 8px;
    --height: 40px;
    --padding: 4px;
    --font-size: 16px;
    --button-padding: 0 10px;
    --button-checked-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02), 0 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(0, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #fff;
    border-radius: var(--radius);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    height: var(--height);
    padding: var(--padding);
  }
  .lsf-radio-group__buttons {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: calc(var(--height) - var(--padding) * 2);
  }
  .lsf-radio-group__button {
    align-items: center;
    border-radius: calc(var(--radius) - var(--padding));
    cursor: pointer;
    display: flex;
    font-size: var(--font-size);
    font-weight: 500;
    height: calc(var(--height) - var(--padding) * 2);
    justify-content: center;
    opacity: 0.6;
    padding: var(--button-padding);
    position: relative;
    text-align: center;
  }
  .lsf-radio-group__button_checked {
    background-color: #fff;
    box-shadow: var(--button-checked-shadow);
    opacity: 1;
  }
  .lsf-radio-group__button_disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .lsf-radio-group__input {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .lsf-radio-group_size_medium {
    --height: 32px;
    --radius: 8px;
    --font-size: 14px;
  }
  .lsf-radio-group_size_small {
    --height: 24px;
    --radius: 4px;
    --padding: 2px;
    --font-size: 12px;
    --button-padding: 0 5px;
    --button-checked-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  .lsf-entities {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 12px;
    padding-top: 12px;
  }
  .lsf-entities__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    height: 46px;
    line-height: 22px;
    padding: 12px 15px 12px 24px;
  }
  .lsf-entities__header,
  .lsf-entities__sort,
  .lsf-entities__sort-icon {
    align-items: center;
    display: flex;
  }
  .lsf-entities__sort-icon {
    height: 24px;
    justify-content: center;
    margin-right: 4px;
    width: 24px;
  }
  .lsf-entities__source {
    padding: 0 15px;
  }
  .lsf-entities__counter {
    color: rgba(0, 0, 0, 0.4);
  }
  .lsf-entities__empty {
    align-items: center;
    display: flex;
    height: 32px;
    padding: 0 15px;
  }
  .lsf-entities__visibility {
    padding: 0 !important;
    width: 24px;
  }
  .lsf-entities__visibility_hidden {
    opacity: 1;
  }
  .lsf-entities__labels,
  .lsf-entities__regions {
    padding: 0;
  }
  .block--1mrhB {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1em;
  }
  .button--23Zqq {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .labels--36qif {
    word-break: break-word;
  }
  .tag--24Ubt {
    margin-bottom: 5px;
    white-space: normal !important;
  }
  .statesblk--2SB5r > span {
    display: block;
  }
  .statesblk--2SB5r > div {
    margin-bottom: 0;
  }
  .row--1uJfQ {
    display: flex;
    margin-bottom: 12px;
    white-space: pre-wrap;
  }
  .long--pgH_K {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .lsf-tag {
    --height: 22px;
    --radius: 5px;
    --shadow-color: #ccc;
    --font-size: 14px;
    --padding: 0 12px;
    --background: #efefef;
    --color: #ccc;
    background-color: var(--background);
    border-radius: var(--radius);
    box-shadow: 0 0 0 1px var(--shadow-color) inset;
    color: var(--color);
    display: inline-block;
    font-size: var(--font-size);
    font-weight: 500;
    height: var(--height);
    line-height: var(--height);
    padding: var(--padding);
  }
  .lsf-tag_size_small {
    --padding: 0 5px;
    --height: 18px;
    --radius: 3px;
    --font-size: 12px;
  }
  .lsf-tag_size_compact {
    --padding: 0 8px;
    --height: 28px;
    --radius: 4px;
    --font-size: 14px;
  }
  .lsf-entity {
    margin-top: 12px;
    padding: 0 15px;
  }
  .lsf-entity__info {
    margin-bottom: 12px;
  }
  .list--2wbr2 {
    max-height: 350px;
    overflow-y: auto;
  }
  .item--3FTWu {
    display: flex;
    justify-content: space-between;
  }
  .selected--qE5Wn {
    background: #f1f1f1;
  }
  .block--8wq2d {
    align-items: center;
    display: flex;
    margin-bottom: 1em;
  }
  .relationbtn--1w1OU {
    margin-left: 7px;
    margin-right: 7px;
  }
  .header--1wd7P {
    color: #666;
    font-size: 12px;
    font-weight: 700;
  }
  .section--i0mAU {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.2rem;
    display: block;
    padding: 1em;
    position: relative;
    width: 100%;
  }
  .section--i0mAU:before {
    background: rgba(0, 0, 0, 0.05);
    content: '';
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }
  .section__blocks--gGygY {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
  .uihidden--5e397 {
    opacity: 0.5;
  }
  .uivisible--3ndzq {
    opacity: 1;
  }
  .lsf-relations__header {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 46px;
    justify-content: space-between;
    line-height: 22px;
    padding: 12px 15px;
  }
  .lsf-relations__title {
    flex: 1;
  }
  .lsf-relations__content {
    padding: 0 15px;
  }
  .lsf-main-view {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  .lsf-main-view__annotation {
    padding: 1em;
  }
  .lsf-main-view__infobar {
    background-color: var(--main-bg-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    padding: 6px 1em 7px;
    position: sticky;
  }
  .lsf-input:not([type='checkbox']):not([type='radio']),
  .lsf-textarea {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    height: 32px;
    padding: 0 16px;
    transition: box-shadow 80ms ease;
    width: 100%;
  }
  .lsf-input:not([type='checkbox']):not([type='radio'])_size_compact {
    height: 32px;
  }
  .lsf-input:not([type='checkbox']):not([type='radio'])_size_small {
    font-size: 12px;
    height: 24px;
    padding: 0 5px;
  }
  .lsf-input:not([type='checkbox']):not([type='radio'])_size_large {
    font-size: 16px;
    height: 40px;
  }
  .lsf-textarea {
    min-height: 50px;
    padding: 12px 16px;
  }
  .lsf-input:not([type='checkbox']):not([type='radio']):focus,
  .lsf-textarea:focus {
    border-color: rgba(0, 153, 255, 0.2);
    box-shadow: 0 0 0 6px rgba(0, 153, 255, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 0 0 1px rgba(0, 153, 255, 0.2);
    outline: none;
  }
  .lsf-input[type='checkbox'],
  .lsf-input[type='radio'],
  .lsf-input__spinner {
    height: 16px;
    width: 16px;
  }
  .lsf-input__spinner {
    animation: lsf-waiting-spin 1s linear infinite;
    border: 2px solid #ffd666;
    border-radius: 100%;
    border-right-color: transparent;
    box-sizing: border-box;
  }
  @-moz-keyframes lsf-waiting-spin {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  @-webkit-keyframes lsf-waiting-spin {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  @-o-keyframes lsf-waiting-spin {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  @keyframes lsf-waiting-spin {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  .lsf-dynamic-preannotations-control {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
    left: 50%;
    padding: 10px;
    position: absolute;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
}
