@import 'antd/dist/antd.css';
@import 'normalize.css';
@import 'label-studio.scss';

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/SF-Pro-Display-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/SF-Pro-Display-Ultralight.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/SF-Pro-Display-Light.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/SF-Pro-Display-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/SF-Pro-Display-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/SF-Pro-Display-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/SF-Pro-Display-Black.otf) format('opentype');
}

@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/SF-Pro-Display-Heavy.otf) format('opentype');
}

*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
}

body {
  background: #fff;
  font-family: 'SF-Pro';
  opacity: 1;
  color: #000;
}

.ant-menu-item a {
  color: #000;
}

.ant-menu {
  font-size: 1.14rem;
}

body,
button,
input {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'SF-Pro';
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #9b27b0;
    border: 1px solid #9b27b0;
    &:after {
      left: 5px;
      top: 8px;
    }
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    border: 1px solid #9b27b0;
    &:after {
      background-color: #9b27b0;
    }
  }
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

a {
  color: #9b27b0;
  &:hover {
    text-decoration: underline;
  }
}
.wraper {
  width: 100%;
  max-width: 1650px;
  padding: 50px;
  margin: auto;
}

@media only screen and (max-width: 1080px) {
  .wraper {
    max-width: 100%;
  }
}

@media only screen and (max-width: 680px) {
}

@media only screen and (max-width: 480px) {
}
